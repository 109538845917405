import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';
import { getSimplifiedDate } from '../../../helpers/dateHelpers';

import { Row, Col, Nav, Navbar, NavItem, Form, Button } from 'react-bootstrap';
import FormCountry from '../../forms/FormCountry';
import FormProvinceState from '../../forms/FormProvinceState';
import FormPhone from '../../forms/FormPhone';
import FormEmail from '../../forms/FormEmail';
import FormSlideToggle from '../../forms/FormSlideToggle';

function ProcurementEntitiesForm(props) {
	const {
		handleCloseForm,
		result
	} = props;

	const {
		INPUT_MAX_LENGTH
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const referenceData = useSelector(state => state.referenceData);
	const hasChanges = useSelector(state => state.hasChanges);

	const [validated, setValidated] = useState(false);
	const [sectorOptions, setSectorOptions] = useState([]);
	const formSubmitRef = useRef(null);

	const [data, setData] = useState({
		'changeType': 'SAVE',
		'name': '',
		'nameFr': '',
		'enabled': true
	});

	const {
		organizationId,
		sector,
		name,
		streetAddress,
		unitNumber,
		city,
		country,
		province,
		postalCode,
		phoneNumber,
		email,
		enabled,
		tenderCloseLocation,
		tenderPublicOpenLocation
	} = data;

	// Result changes
	useEffect(() => {
		if (result && typeof result === 'object') {
			setData(data => ({...data, ...result}));
		} else {
			handleCloseForm();
		}
	}, [result]);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData.success && referenceData.data) {
			if (referenceData.data.sectorDataList) {
				setSectorOptions(referenceData.data.sectorDataList);
			}
		}
	}, [referenceData]);

	/**
	 * Field functions
	 */

	const checkChanges = () => {
		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleChange = (event, isNumeric, trim) => {
		const { name, value } = event.target;
		let val;

		if (isNumeric) {
			if (value) {
				val = parseInt(value, 10);
			} else {
				val = null;
			}
		} else if (value) {
			if (trim) {
				val = value.replace(/\s/g, '');
			} else {
				val = value;
			}
		} else {
			val = '';
		}

		setData(data => ({...data, [name]: val }));

		checkChanges();
	};

	const handleEnabledChange = (id, value) => {
		let val;

		if (value == 'yes') {
			val = true;
		} else {
			val = false;
		}

		setData(data => ({...data, enabled: val }));
	};

	const handleSubmit = async (event) => {
		event.currentTarget.blur();
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		data.changeType = 'SAVE';
		delete data.createdBy;
		delete data.modifiedBy;
		delete data.createdDate;
		delete data.modifiedDate;

		dispatch(referenceTableActions.updateReferenceTable('procuremententity', data, 'procurement-entities'));
		handleCloseForm();
	};

	const handleClose = () => {
		handleCloseForm();
	};

	return (
		<div className={'box box--blue-light box--wide'}>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row className="align-items-center">
					<Col md={7}>
						<h2 className="mb-2"><Translate text={(result && result.id) ? 'Edit Procurement Entity' : 'Add Procurement Entity'} /></h2>
					</Col>
					<Col md={5}>
						<Navbar className={'form-nav justify-content-end'}>
							<Nav>
								<NavItem>
									<Button variant="primary" onClick={() => handleClose()}>
										<Translate text={'Cancel'}/>
									</Button>
								</NavItem>

								<NavItem>
									<Button variant="success" type="submit" ref={formSubmitRef}>
										<Translate text={'Save'}/>
									</Button>
								</NavItem>
							</Nav>
						</Navbar>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId="procurementEntity-organizationId">
							<Form.Label>
								<Translate text={'Organization ID'} /> <span className="required">*</span>
							</Form.Label>

							{ !result.id &&
								<Form.Control
									type="text"
									name="organizationId"
									value={ organizationId }
									maxLength={ INPUT_MAX_LENGTH }
									required
									onChange={(e) => { handleChange(e, false, true); }}
								/>
							}

							{ result.id &&
								<Form.Control
									type="text"
									value={ organizationId }
									readOnly
								/>
							}

							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Organization ID'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="procurementEntity-sector">
							<Form.Label>
								<Translate text={'Sector'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								as="select"
								name="sector"
								value={ (sector) ? sector : '' }
								required
								onChange={(e) => { handleChange(e); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
									)}
								</Translator>

								{ (Array.isArray(sectorOptions)) &&
									sectorOptions.map((item, index) => {
										return (
											<option value={ item.description } key={ index }>
												{ item.description }
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Sector'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="procurementEntity-name">
							<Form.Label>
								<Translate text={'Name (English)'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="name"
								value={ name }
								maxLength={ INPUT_MAX_LENGTH }
								required
								onChange={(e) => { handleChange(e); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Name (English)'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="procurementEntity-streetAddress">
							<Form.Label>
								<Translate text={'Street Address'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="streetAddress"
								value={ streetAddress }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group controlId="procurementEntity-unitNumber">
							<Form.Label>
								<Translate text={'Unit Number'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="unitNumber"
								value={ unitNumber }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group controlId="procurementEntity-city">
							<Form.Label>
								<Translate text={'City'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="city"
								value={ city }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group>
							<FormCountry
								id="procurementEntity-country"
								name="country"
								value={ country }
								handleOnChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group>
							<FormProvinceState
								id="provinceState"
								name="province"
								value={ province }
								country={ country }
								handleOnChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="procurementEntity-postalCode">
							<Form.Label>
								<Translate text={'Postal Code'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="postalCode"
								value={ postalCode }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group>
							<FormPhone
								id="procurementEntity-phoneNumber"
								name="phoneNumber"
								value={ phoneNumber }
								handleOnChange={(id, value) => { handleChange({ target: { name: 'phoneNumber', value: value }}); }}
							/>
						</Form.Group>
						<Form.Group>
							<FormEmail
								id="procurementEntity-email"
								name="email"
								value={ email }
								handleOnChange={(id, value) => { handleChange({ target: { name: 'email', value: value }}); }}
							/>
						</Form.Group>
						<Form.Group controlId="procurementEntity-enabled">
							<FormSlideToggle
								id="enabled"
								name="enabled"
								title="Enabled"
								value={ (enabled) ? 'yes' : 'no' }
								options={[
									{ value: 'no', label: 'No' },
									{ value: 'yes', label: 'Yes' }
								]}
								handleOnChange={ handleEnabledChange }
							/>
						</Form.Group>
						<Form.Group controlId="procurementEntity-tenderCloseLocation">
							<Form.Label>
								<Translate text={'Tender Close Location'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="tenderCloseLocation"
								value={ tenderCloseLocation }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>
						<Form.Group controlId="procurementEntity-tenderPublicOpenLocation">
							<Form.Label>
								<Translate text={'Tender Public Open Location'} />
							</Form.Label>
							<Form.Control
								type="text"
								name="tenderPublicOpenLocation"
								value={ tenderPublicOpenLocation }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={(e) => { handleChange(e); }}
							/>
						</Form.Group>

						{ result.id &&
							<>
								<Form.Group controlId="createdBy">
									<Form.Label>
										<Translate text={'Created By'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ result.createdBy }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="modifiedBy">
									<Form.Label>
										<Translate text={'Modified By'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ result.modifiedBy }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="createdDate">
									<Form.Label>
										<Translate text={'Date Created'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ getSimplifiedDate(result.createdDate) }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="modifiedDate">
									<Form.Label>
										<Translate text={'Date Modified'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ getSimplifiedDate(result.modifiedDate) }
										readOnly
									/>
								</Form.Group>
							</>
						}
					</Col>
				</Row>
			</Form>
		</div>
	);
}
export default ProcurementEntitiesForm;
