import { notificationConstants } from '../constants/notificationConstants';

export function notification(state = {}, action) {
	switch (action.type) {
		case notificationConstants.NOTIFICATION_REQUEST:
			return {
				request: true
			};
		case notificationConstants.NOTIFICATION_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case notificationConstants.NOTIFICATION_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		case notificationConstants.NOTIFICATION_RESET:
			return {};
		default:
			return state;
	}
}

export function getNotifications(state = {}, action) {
	switch (action.type) {
		case notificationConstants.NOTIFICATION_GET_REQUEST:
			return {
				request: true
			};
		case notificationConstants.NOTIFICATION_GET_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case notificationConstants.NOTIFICATION_GET_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		default:
			return state;
	}
}
