/* eslint-disable no-unused-vars */

export const resolvers = {
	post
};

function post(req, res, ctx) {
	return res(
		ctx.status(200),
		ctx.json({
			success: true
		})
	);
}
