import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';

import { Row, Col, Nav, Navbar, NavItem, Form, Button } from 'react-bootstrap';
import FormSlideToggle from '../../forms/FormSlideToggle';

function ProcurementMethodsForm(props) {
	const {
		handleCloseForm,
		result
	} = props;

	const {
		INPUT_MAX_LENGTH
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);

	const [validated, setValidated] = useState(false);
	const formSubmitRef = useRef(null);

	const [data, setData] = useState({
		'changeType': 'SAVE',
		'name': '',
		'description': '',
		'enabled': true
	});

	const {
		name,
		description,
		enabled
	} = data;

	// Result changes
	useEffect(() => {
		if (result && typeof result === 'object') {
			setData(data => ({...data, ...result}));
		} else {
			handleCloseForm();
		}
	}, [result]);

	/**
	 * Field functions
	 */

	const checkChanges = () => {
		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleChange = (event, isNumeric, trim) => {
		const { name, value } = event.target;
		let val;

		if (isNumeric) {
			if (value) {
				val = parseInt(value, 10);
			} else {
				val = null;
			}
		} else if (value) {
			if (trim) {
				val = value.replace(/\s/g, '');
			} else {
				val = value;
			}
		} else {
			val = '';
		}

		setData(data => ({...data, [name]: val }));

		checkChanges();
	};

	const handleEnabledChange = (id, value) => {
		let val;

		if (value == 'yes') {
			val = true;
		} else {
			val = false;
		}

		setData(data => ({...data, enabled: val }));
	};

	const handleSubmit = async (event) => {
		event.currentTarget.blur();
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		dispatch(referenceTableActions.updateReferenceTable('procurementmethod', data, 'procurement-methods'));
		handleCloseForm();
	};

	const handleClose = () => {
		handleCloseForm();
	};

	return (
		<div className={'box box--blue-light box--wide'}>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row className="align-items-center">
					<Col md={7}>
						<h2 className="mb-2"><Translate text={(result && result.id) ? 'Edit Procurement Method' : 'Add Procurement Method'} /></h2>
					</Col>
					<Col md={5}>
						<Navbar className={'form-nav justify-content-end'}>
							<Nav>
								<NavItem>
									<Button variant="primary" onClick={() => handleClose()}>
										<Translate text={'Cancel'}/>
									</Button>
								</NavItem>

								<NavItem>
									<Button variant="success" type="submit" ref={formSubmitRef}>
										<Translate text={'Save'}/>
									</Button>
								</NavItem>
							</Nav>
						</Navbar>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId="procurementMethod-name">
							<Form.Label>
								<Translate text={'Name (English)'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="name"
								value={ name }
								maxLength={ INPUT_MAX_LENGTH }
								required
								onChange={(e) => { handleChange(e, false, true); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Name (English)'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="procurementMethod-description">
							<Form.Label>
								<Translate text={'Description (English)'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="description"
								value={ description }
								maxLength={ INPUT_MAX_LENGTH }
								required
								onChange={(e) => { handleChange(e); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Description (English)'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="endUserEntity-enabled">
							<FormSlideToggle
								id="enabled"
								name="enabled"
								title="Enabled"
								value={ (enabled) ? 'yes' : 'no' }
								options={[
									{ value: 'no', label: 'No' },
									{ value: 'yes', label: 'Yes' }
								]}
								handleOnChange={ handleEnabledChange }
							/>
						</Form.Group>
					</Col>
				</Row>
			</Form>
		</div>
	);
}
export default ProcurementMethodsForm;
