/* eslint-disable no-unused-vars */

export const resolvers = {
	user
};

function user(req, res, ctx) {
	return res(
		ctx.status(200),
		ctx.json({
			jwttoken: 'XXXXXX'
		})
	);
}
