import axios from 'axios';
const env = window.env;
import { authHeader } from '../helpers/authHeader';

export const fileService = {
	get,
	getStream
};

function get(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/files/guid`,
		data: {guid: data},
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getStream(data) {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/files/stream`,
		params: {guid: data},
		responseType: 'blob',
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return [];
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
