import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';

import { Row, Col, Button, ButtonToolbar, Form } from 'react-bootstrap';

import SearchUNSPSC from './SearchUNSPSC';
import CommodityFields from './CommodityFields';

function AddCommodity(props) {
	const {
		UNSPSC_LEVEL_1,
		UNSPSC_LEVEL_2,
		UNSPSC_LEVEL_3,
		UNSPSC_LEVEL_4,
		UNSPSC_NEW_CODE_LENGTH
	} = require('../../../config/constants');

	const dispatch = useDispatch();

	const [validated, setValidated] = useState(false);
	const [data, setData] = useState({
		changeType: 'SAVE',
		enabled: true
	});
	const [preCode, setPreCode] = useState(null);
	const formRef = useRef(null);

	const handleBack = () => {
		setData({
			changeType: 'SAVE',
			enabled: true
		});
		setValidated(false);
		setPreCode(null);
	};

	const handleCancel = () => {
		if (props.handleCancel) {
			props.handleCancel();
		}
	};

	const handleUpdateParents = (levels) => {
		if (levels && levels.id) {
			setPreCode(levels.code);

			setData(data => ({
				...data,
				parentUnspscData: levels
			}));
		} else {
			setPreCode(null);

			setData(data => ({
				...data,
				parentUnspscData: null
			}));
		}
	};

	const handleUpdateData = (data) => {
		setData(data);
	};

	const handleChange = (event) => {
		const { name, value } = event.target;

		if (value) {
			setData(data => ({
				...data,
				[name]: value
			}));
		}
	};

	const form = formRef.current;

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if ((form && form.checkValidity() === false) || !data.code || data.code.toString().length !== UNSPSC_NEW_CODE_LENGTH) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		let updatedCode;

		if (data.levelType == UNSPSC_LEVEL_1) {
			updatedCode = data.code;
		} else if (preCode) {
			updatedCode = parseInt(preCode.toString() + data.code.toString(), 10);
		}

		const updatedData = {
			...data,
			changeType: 'SAVE',
			code: updatedCode
		};

		dispatch(referenceTableActions.updateReferenceTable('unspsc/add', updatedData, 'add-commodity'));
	};

	return (
		<>
			<Row>
				<Col md={7}>
					<h2>
						<Translate text={'Add Commodity'} />
					</h2>
				</Col>
				<Col md={5}>
					<ButtonToolbar className="justify-content-end">
						{ data && data.levelType &&
							<Button
								variant="link"
								onClick={ handleBack }
							>
								<Translate text={'Back to Level Select'} />
							</Button>
						}

						<Button onClick={ handleCancel }>
							<Translate text={'Cancel'} />
						</Button>

						{ data && data.levelType &&
							<Button
								variant="success"
								onClick={ handleSubmit }
							>
								<Translate text={'Save'} />
							</Button>
						}
					</ButtonToolbar>
				</Col>
			</Row>

			{ !data.levelType &&
				<Translator>
					{({translate}) => (
						<Form.Group role="group" aria-label={ translate({ text: 'Commodity Level' }) }>
							<h4>
								<Translate text={'Commodity Level'} />
							</h4>
							<Form.Check
								label="1"
								name="levelType"
								id="commodity-level-1"
								className="form-check-big"
								type="radio"
								value={ UNSPSC_LEVEL_1 }
								required
								onChange={(e) => {
									handleChange(e);

									if (e.target.validity.valid) {
										e.target.closest('.form-group').classList.remove('is-invalid');
									}
								}}
								onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
							/>
							<Form.Check
								label="2"
								name="levelType"
								id="commodity-level-2"
								className="form-check-big"
								type="radio"
								value={ UNSPSC_LEVEL_2 }
								required
								onChange={(e) => {
									handleChange(e);

									if (e.target.validity.valid) {
										e.target.closest('.form-group').classList.remove('is-invalid');
									}
								}}
								onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
							/>
							<Form.Check
								label="3"
								name="levelType"
								id="commodity-level-3"
								className="form-check-big"
								type="radio"
								value={ UNSPSC_LEVEL_3 }
								required
								onChange={(e) => {
									handleChange(e);

									if (e.target.validity.valid) {
										e.target.closest('.form-group').classList.remove('is-invalid');
									}
								}}
								onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
							/>
							<Form.Check
								label="4"
								name="levelType"
								id="commodity-level-4"
								className="form-check-big"
								type="radio"
								value={ UNSPSC_LEVEL_4 }
								required
								onChange={(e) => {
									handleChange(e);

									if (e.target.validity.valid) {
										e.target.closest('.form-group').classList.remove('is-invalid');
									}
								}}
								onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate text={'Please choose an option'} />
							</Form.Control.Feedback>
						</Form.Group>
					)}
				</Translator>
			}

			{ data && data.levelType &&
				<>
					<Form
						ref={ formRef }
						noValidate
						validated={ validated }
						onSubmit={ handleSubmit }
					>
						<SearchUNSPSC
							method="manual"
							newCommodity={ data }
							handleOnChangeMultiple={(levels) => { handleUpdateParents(levels); }}
						/>

						<h3>
							<Translate
								text={'New Commodity Information <LevelText>'}
								renderMap={{
									renderLevelText: () => (data.levelType == UNSPSC_LEVEL_1) ? <Translate text={'(Level 1)'} /> : (data.levelType == UNSPSC_LEVEL_2) ? <Translate text={'(Level 2)'} /> : (data.levelType == UNSPSC_LEVEL_3) ? <Translate text={'(Level 3)'} /> : (data.levelType == UNSPSC_LEVEL_4) ? <Translate text={'(Level 4)'} /> : '' // eslint-disable-line react/display-name
								}}
							/>
						</h3>

						<CommodityFields
							data={ data }
							isNew={ true }
							preCode={ preCode }
							handleUpdateData={ handleUpdateData }
						/>
					</Form>
				</>
			}
		</>
	);
}

export default AddCommodity;
