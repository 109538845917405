/* eslint-disable id-length */
import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import {Provider} from 'react-translated';
import translation from './translations';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';
import IdleTimer from 'react-idle-timer';
import {
	INACTIVITY_TIMEOUT,
	LANGUAGE_ENGLISH_CODE,
	LANGUAGE_MAPPINGS,
	LANGUAGE_ENGLISH,
	ROLE_MAS,
	ROLE_MAS_ACCOUNT_MANAGER,
	ROLE_SUPPLIER,
	GUEST_RPID,
	USER_STATUS_ACTIVE
} from './config/constants';

// Styles.
import './scss/style.scss';

// Pages.
import Home from './components/pages/Home';
import ContentPage from './components/pages/ContentPage';

import { userDataActions } from './store/actions/userDataActions';
import { authenticateActions } from './store/actions/authenticateActions';
import { update } from './store/actions/languageActions';
import { setHasChanges } from './store/actions/globalMessagesActions';
import { referenceDataActions } from './store/actions/referenceDataActions';
import { contentActions } from './store/actions/contentActions';

// Components.
import GlobalAlert from './components/misc/GlobalAlert';
import AccountSettings from './components/pages/AccountSettings';
import Tenders from './components/pages/Tenders';
import PostTender from './components/pages/PostTender';
import EditTender from './components/pages/EditTender';
import Admin from './components/pages/Admin';
import {userActions} from './store/actions/userActions';
import {isRole, isLoggedIn} from './helpers/userHelpers';
import AccountDisabled from './components/pages/AccountDisabled';
import {notificationActions} from './store/actions/notificationActions';

function App() {
	const timeout = INACTIVITY_TIMEOUT;
	const [timedOut, setTimedOut] = useState(false);
	const timerRef = useRef(null);
	const history = useHistory();
	const dispatch = useDispatch();
	let location = useLocation();
	const currentPath = location.pathname;
	const env = window.env;

	// State data
	const hasChanges = useSelector(state => state.hasChanges);
	const language = useSelector(state => state.language);
	const authenticate = useSelector(state => state.authenticate);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const userData = useSelector(state => state.userData);

	const languageMappings = LANGUAGE_MAPPINGS;
	const [languageCode, setLanguageCode] = useState(LANGUAGE_ENGLISH_CODE);
	const [isContentPage, setIsContentPage] = useState(false);

	// Setting language.
	useEffect(() => {
		if (language.value && Array.isArray(languageMappings)) {
			const found = languageMappings.find(item => item.name == language.value);

			if (found) {
				setLanguageCode(found.code);
				document.documentElement.setAttribute('lang', found.code);
			}
		}
	}, [language]);

	// Detecting FR URL
	useEffect(() => {
		if (currentPath == '/en') {
			dispatch(update(LANGUAGE_ENGLISH, LANGUAGE_ENGLISH_CODE));
			history.replace('/');
		}
	}, [currentPath]);

	// Get authentication info
	useEffect(() => {
		// Get User Data.
		dispatch(userDataActions.getUserData());

		// Little polyfill for .closest() in IE11
		if (window.Element && !Element.prototype.closest) {
			Element.prototype.closest =
			function(s) {
				var matches = (this.document || this.ownerDocument).querySelectorAll(s),
					i,
					el = this;
				do {
					i = matches.length;
					while (--i >= 0 && matches.item(i) !== el) {}; // eslint-disable-line
				} while ((i < 0) && (el = el.parentElement));
				return el;
			};
		}
	}, []);

	useEffect(() => {
		if (userData && !userData.request && (userData.success || userData.error)) {
			dispatch(authenticateActions.retrieve((env.USERNAME_TOKEN) ? env.USERNAME_TOKEN : userData && userData.data && userData.data.username ? userData.data.username : GUEST_RPID));
		}
	}, [userData]);

	// Getting reference data & notification data.
	useEffect(() => {
		if (authenticate && authenticate.success) {
			if (!authenticate.data || !authenticate.data.jwttoken) {
				dispatch(userActions.unauthenticatedUser());
				dispatch(authenticateActions.retrieve(GUEST_RPID));
			} else {
				// User Data.
				if (authenticate && authenticate.data && authenticate.data.userID) {
					dispatch(userActions.getUser(authenticate.data.userID));
				}

				if (isRole(userData, [ROLE_SUPPLIER])) {
					dispatch(notificationActions.getNotifications());
				}

				dispatch(referenceDataActions.getReferenceData());
			}
		}
	}, [authenticate, userData]);

	useEffect(() => {
		if (authenticate.success && authenticate.data && authenticate.data.jwttoken) {
			if (currentPath) {
				dispatch(contentActions.getPage(currentPath));
			}

			if (currentPath) {
				dispatch(contentActions.getNavigation(currentPath));
			}
		}
	}, [currentPath, authenticate]);

	useEffect(() => {
		if (
			(isRole(userData, [ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER, ROLE_SUPPLIER]) && getUser.success && getUser.user && (getUser.user.termsOfUseAccepted !== true || getUser.user.userStatus !== USER_STATUS_ACTIVE) && !isContentPage) ||
			(isLoggedIn(userData) && getUser.error && !isContentPage)
		) {
			history.replace('/request-activation');
		}
	}, [getUser, userData, authenticate]);

	// Timeout functions
	const handleOnActive = () => {
		setTimedOut(false);
	};

	const handleOnIdle = () => {
		if (timedOut) {
			// Slight delay for any functions on unmount to execute.
			setTimeout(function() {
				dispatch(setHasChanges(false));
				dispatch(userDataActions.logout());
				const redirectUrl = (env.BASE_URL) ? env.BASE_URL + '/saml/logout' : '/saml/logout';
				window.location.href = redirectUrl;
			}, 500);
		} else {
			timerRef.current.reset();
			setTimedOut(true);
		}
	};

	const handleOnAction = () => {
		setTimedOut(false);
	};

	// Popup when user tries to leave with changes made.
	const handleOnLeave = (event) => {
		if (!hasChanges) {
			return undefined; // eslint-disable-line
		}

		const message = event.returnValue = 'Changes you made may not be saved.';
		(event || window.event).returnValue = message;

		return message;
	};

	useBeforeunload(event => {
		if (hasChanges) {
			return handleOnLeave(event);
		}
	});

	const handleOnContentPage = () => {
		setIsContentPage(true);
	};

	return (
		<>
			<IdleTimer
				ref={ timerRef }
				element={ document }
				onActive={ handleOnActive }
				onIdle={ handleOnIdle }
				onAction={ handleOnAction }
				debounce={ 250 }
				timeout={ timeout }
			/>

			<Provider language={ languageCode } translation={ translation }>
				<div className="page-wrapper">
					<Switch>
						<Route
							exact
							path={[
								'/'
							]}
							component={ Home }
						/>
						<Route
							path={[
								'/account-settings',
								'/account/preferences'
							]}
							component={ AccountSettings }
						/>
						<Route
							path='/request-activation'
							component={ AccountDisabled }
						/>
						<Route
							path='/admin'
							component={ Admin }
						/>
						<Route
							exact
							path={[
								'/tenders/post'
							]}
							component={ PostTender }
						/>
						<Route
							exact
							path={[
								'/tenders/:id/edit'
							]}
							component={ EditTender }
						/>
						<Route
							exact
							path={[
								'/tenders',
								'/tenders/alternative-procurement',
								'/tenders/alternative-procurement/:id',
								'/tenders/standing-offers',
								'/tenders/standing-offers/:id',
								'/tenders/:id'
							]}
							component={ Tenders }
						/>
						<Route
							path='*'
							component={() => <ContentPage onRender={ handleOnContentPage } /> }
						/>
					</Switch>
					<GlobalAlert />
				</div>
			</Provider>
		</>
	);
}

export default App;
