import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { isMobile } from 'react-device-detect';
import { format, parse, parseISO, isValid, subYears, addYears } from 'date-fns';
import { fr } from 'date-fns/locale';

import 'react-datepicker/dist/react-datepicker.css';

export default function FormDatePicker(props) {
	const {
		refValue,
		id,
		name,
		value,
		minDate,
		maxDate,
		required,
		label,
		disabled,
		weekdayOnly,
		yearOnly,
		time,
		size,
		readOnly,
		alwaysBlur,
		autoFocus,
		placeholder
	} = props;
	const {
		LANGUAGE_ENGLISH_CODE,
		LANGUAGE_FRENCH_CODE
	} = require('../../config/constants');

	const [startDate, setDate] = useState(null);
	const [locale, setLocale] = useState(LANGUAGE_ENGLISH_CODE);

	// State data.
	const language = useSelector(state => state.language);

	let placeholderText;

	if (placeholder && placeholder !== 'false') {
		placeholderText = placeholder;
	} else if (placeholder == 'false') {
		placeholderText = '';
	} else if (yearOnly) {
		placeholderText = 'YYYY';
	} else if (time) {
		placeholderText = 'DD/MM/YYYY h:mm aa';
	} else {
		placeholderText = 'DD/MM/YYYY';
	}

	registerLocale(LANGUAGE_FRENCH_CODE, fr);

	useEffect(() => {
		if (language && language.code) {
			setLocale(language.code);
			setDefaultLocale(language.code);
		}
	}, [language]);

	useEffect(() => {
		if (isValid(parse(value, "yyyy-MM-dd'T'HH:mmxx", new Date())) || isValid(parseISO(value))) { // eslint-disable-line
			setDate(value);
		} else if (isValid(parse(value, 'dd-MM-yyyy', new Date()))) {
			const parsedDate = parse(value, 'dd-MM-yyyy', new Date());
			const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mmxx"); // eslint-disable-line
			setDate(formattedDate);
		} else if (isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
			const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
			const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mmxx"); // eslint-disable-line
			setDate(formattedDate);
		} else {
			setDate(null);
		}
	}, [value]);

	useEffect(() => {
		if (autoFocus && refValue && refValue.current && typeof refValue.current.setFocus === 'function') {
			refValue.current.setFocus();
		}
	}, [autoFocus, refValue]);

	const handleChange = (date) => {
		if (yearOnly && isMobile) {
			setDate(date);

			if (props.handleOnChange) {
				props.handleOnChange(date);
			}

		} else if (isValid(date)) {
			const formattedDate = format(date, "yyyy-MM-dd'T'HH:mmxx"); // eslint-disable-line
			setDate(formattedDate);

			if (props.handleOnChange) {
				props.handleOnChange(formattedDate);
			}
		} else if (!date) {
			setDate('');

			if (props.handleOnChange) {
				props.handleOnChange('');
			}
		}
	};

	const handleBlur = (date) => {
		if (yearOnly && props.handleOnBlur) {
			if (props.handleOnBlur) {
				props.handleOnBlur(date);
			}
		} else if (isValid(date) && props.handleOnBlur) {
			const formattedDate = format(date, "yyyy-MM-dd'T'HH:mmxx"); // eslint-disable-line
			props.handleOnBlur(formattedDate);
		} else if (alwaysBlur && props.handleOnBlur) {
			props.handleOnBlur(date);
		}
	};

	const handleClose = () => {
		if (props.handleOnClose) {
			props.handleOnClose();
		}
	};

	if (isMobile) {
		return (
			<div className={ (size == 'sm') ? 'datepicker-wrapper datepicker-wrapper--small' : 'datepicker-wrapper' }>
				<Form.Control
					ref={ refValue }
					type={yearOnly ? 'text' : 'date'}
					id={ id }
					name={ name }
					className={ (size == 'sm') ? 'form-control--small' : null }
					aria-label={ label }
					value={ (startDate) ? yearOnly ? startDate.toString().length > 4 ? format(parseISO(startDate), 'yyyy') : startDate : format(parseISO(startDate), 'yyyy-MM-dd') : '' }
					required={ required }
					disabled={disabled}
					showTimeSelect={!!time}
					maxLength={yearOnly ? '4' : null}
					onChange={(e) => handleChange(yearOnly ? e.target.value : parseISO(e.target.value)) }
					onBlur={(e) => handleBlur(yearOnly ? e.target.value : parseISO(e.target.value)) }
					readOnly={readOnly}
				/>
			</div>
		);
	}

	const isWeekday = date => {
		if (weekdayOnly) {
			const day = date.getDay();
			return day !== 0 && day !== 6;
		}

		return true;
	};

	return (
		<>
			<DatePicker
				selected={ (startDate) ? parseISO(startDate) : '' }
				className={ (size == 'sm') ? 'form-control form-control--small' : 'form-control' }
				wrapperClassName={ (size == 'sm') ? 'datepicker-wrapper--small' : null }
				ref={ refValue }
				id={ id }
				name={ name }
				customInput={ <input type="text" aria-label={ label } /> }
				placeholderText={ placeholderText }
				dateFormat={yearOnly ? 'yyyy' : time ? 'dd/MM/yyyy h:mm aa' : 'dd/MM/yyyy'}
				autoComplete="off"
				minDate={ (minDate) ? minDate : subYears(new Date(), 50) }
				maxDate={ (maxDate) ? maxDate : addYears(new Date(), 50) }
				showMonthDropdown
				showYearDropdown
				showYearPicker={yearOnly}
				showTimeSelect={!!time}
				filterDate={ isWeekday }
				dropdownMode="select"
				disabled={ disabled }
				required={ required }
				onChange={(date) => { handleChange(date); handleBlur(date); }}
				onBlur={(e) => { yearOnly ? handleBlur(parse(e.target.value, 'yyyy', new Date())) : handleBlur(parse(e.target.value, 'dd/MM/yyyy', new Date())); }}
				onCalendarClose={ handleClose }
				readOnly={readOnly}
				locale={ locale }
			/>
		</>
	);
}
