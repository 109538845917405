import React, { useState, useEffect } from 'react';
import { Translate } from 'react-translated';
import { useDispatch, useSelector } from 'react-redux';

import { tenderActions } from '../../store/actions/tenderActions';
import { success, error } from '../../store/actions/globalMessagesActions';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Tooltip from '../misc/Tooltip';

function TenderClone() {
	const {
		TENDER_STATUS_DRAFT,
		BAD_CHARACTERS_REGEX,
		BAD_CHARACTERS_MESSAGE,
		INPUT_MAX_LENGTH
	} = require('../../config/constants');
	const dispatch = useDispatch();

	const currentTender = useSelector(state => state.currentTender);
	const postTender = useSelector(state => state.postTender);

	const [showClone, setShowClone] = useState(false);
	const [tenderId, setTenderId] = useState(null);
	const [tenderIdTriedBadChar, setTenderIdTriedBadChar] = useState(false);
	const [validated, setValidated] = useState(false);

	useEffect(() => {
		if (postTender.success && postTender.callback === 'clone-tender') {
			dispatch(success('Tender successfully copied'));
			dispatch(tenderActions.resetCurrentTender());
			window.location.href = '/tenders/' + encodeURIComponent(postTender.data.tenderId) + '/edit';
			dispatch(tenderActions.resetPostTender());
		} else if (postTender.error && postTender.callback === 'clone-tender') {
			if (postTender.status == 409) {
				dispatch(error('Error: Tender ID already in the system.'));
			} else {
				dispatch(error('Error: Tender could not be copied. Please try again later.'));
			}
		}
	}, [postTender]);

	const cleanDataObjects = (object, deleteKey, exclude) => {
		if (!object || typeof object !== 'object') {
			return;
		}

		delete object[deleteKey];
		object.changeType = 'SAVE';

		Object.entries(object).forEach(([key, val]) => {
			if (Array.isArray(exclude) && exclude.includes(key)) {
				return;
			} else if (typeof val == 'object') {
				cleanDataObjects(val, deleteKey, exclude);
			} else if (Array.isArray(val)) {
				val.forEach((item) => {
					cleanDataObjects(item, deleteKey, exclude);
				});
			} else {
				return;
			}
		});
	};

	const handleShowClone = () => {
		setShowClone(true);
	};

	const handleChangeTenderId = (event) => {
		const { value } = event.target;
		let val;

		if (value) {
			if (value.match(BAD_CHARACTERS_REGEX)) {
				setTenderIdTriedBadChar(true);
			} else {
				setTenderIdTriedBadChar(false);
			}

			val = value.replace(BAD_CHARACTERS_REGEX, '');
		} else {
			val = '';
		}

		setTenderId(val);
	};

	const handleCloseClone = () => {
		setShowClone(false);
		setTenderId(null);
		setTenderIdTriedBadChar(false);
		setValidated(false);
	};

	const handleConfirmClone = async (event) => {
		event.preventDefault();

		if (!tenderId) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			setValidated(false);
			setShowClone(false);

			let clonedData = {
				...currentTender
			};

			delete clonedData.tenderId;
			delete clonedData.attachments;
			delete clonedData.tenderAwardData;
			delete clonedData.awardMemo;
			delete clonedData.tenderBidInformationDataList;
			delete clonedData.closingDate;
			delete clonedData.contactName;
			delete clonedData.postDate;

			await cleanDataObjects(clonedData, 'id', ['tradeAgreement', 'procumentEntityData', 'endUserEntityDataList']);

			const updatedData = {
				...clonedData,
				changeType: 'SAVE',
				tenderStatus: TENDER_STATUS_DRAFT,
				tenderId: tenderId,
				attachments: []
			};

			dispatch(tenderActions.postTender(JSON.stringify(updatedData), 'clone-tender'));
		}

		setValidated(true);
	};

	if (currentTender.id && currentTender.tenderStatus !== TENDER_STATUS_DRAFT) {
		return (
			<>
				<Button
					variant="secondary"
					size="sm"
					onClick={handleShowClone}
				>
					<Translate text={'Copy Tender'}/>
				</Button>

				<Modal show={ showClone } onHide={ handleCloseClone } centered>
					<Modal.Header closeButton>
						<h2><Translate text={'Copy this tender?'} /></h2>
					</Modal.Header>
					<Form
						noValidate
						validated={ validated }
						onSubmit={ handleConfirmClone }
					>
						<Modal.Body>
							<p><Translate text={'Copying will result in a new draft tender. A new *Tender ID* is required.'} /></p>
							<Form.Group controlId="tenderId" className="m-0">
								<Form.Label>
									<Tooltip text={'Tender ID is a unique identifier to your organization\'s tender notice and you may set this value as desired using an alphanumeric structure. The system will ensure you do not create a duplicate value.\n\nIf you are new to the service and are looking for a format to use for your tender numbering, the below is suggested.\n\nStart with your organizations acronym, followed by the year, adding a hyphen and ending with your organization\'s own unique three (3) digit number. i.e CORPX2022-001'} />
									<Translate text={'Tender ID'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="tenderId"
									value={tenderId}
									maxLength={INPUT_MAX_LENGTH}
									required
									onChange={(e) => { handleChangeTenderId(e); }}
								/>

								{ tenderIdTriedBadChar &&
									<Form.Text className="text-muted">
										<Translate text={BAD_CHARACTERS_MESSAGE} />
									</Form.Text>
								}

								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Tender ID'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="success" type="submit"><Translate text={'Yes, Copy Tender'} /></Button>
							<Button variant="primary" onClick={ handleCloseClone }><Translate text={'Back to Tender Detail'} /></Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</>
		);
	}

	return null;
}

export default TenderClone;
