import React, {useEffect, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Translate, Translator } from 'react-translated';
import { ReactTitle } from 'react-meta-tags';

import {isLoggedIn, isNotLoggedIn, isRole} from '../../helpers/userHelpers';
import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import {
	ROLE_NAME_MAPPING,
	ROLE_PROCUREMENT_REGULAR,
	ROLE_PROCUREMENT_INTERNAL,
	ROLE_SUPPLIER,
	ROLE_PROCUREMENT_ADMIN
} from '../../config/constants';
import NotificationSignup from '../tenders/NotificationSignup';

function Content() {
	// State data.
	const [role, setRole] = useState(null);
	const [organization, setOrganization] = useState(null);
	const [endUserEntity, setEndUserEntity] = useState(null);
	const [notifications, setNotifications] = useState([]);

	// State data.
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const getNotifications = useSelector(state => state.getNotifications);

	// Application & user data.
	useEffect(() => {
		if (userData.success) {
			if (
				userData.data.authorities &&
				userData.data.authorities.length &&
				userData.data.authorities[0].authority &&
				ROLE_NAME_MAPPING
			) {
				const roleReference = Object.keys(ROLE_NAME_MAPPING).find(item => item === userData.data.authorities[0].authority);

				if (roleReference && ROLE_NAME_MAPPING[roleReference]) {
					setRole(ROLE_NAME_MAPPING[roleReference]);
				}
			}
		}
	}, [userData]);

	useEffect(() => {
		if (getUser && getUser.success && getUser.user) {
			if (getUser.user.procurementEntityName) {
				setOrganization(getUser.user.procurementEntityName);
			}

			if (getUser.user.endUserEntity) {
				setEndUserEntity(getUser.user.endUserEntity);
			}
		}
	}, [getUser]);

	useEffect(() => {
		if (getNotifications.success && getNotifications.data) {
			setNotifications(getNotifications.data);
		}
	}, [getNotifications]);

	return (
		<Row>
			<Col md={4}>
				<h1><Translate text="Account Settings" /></h1>

				{ userData.data.email &&
					<>
						<h4><strong><Translate text="Email Address" /></strong></h4>
						<p>{ userData.data.email }</p>
					</>
				}

				{ role &&
					<>
						<h4><strong><Translate text="User Type" /></strong></h4>
						<p>{ role }</p>
					</>
				}

				{ organization &&
					<>
						<h4><strong><Translate text="Organization" /></strong></h4>
						<p>
							{ organization }
							{ endUserEntity &&
								<>
									<br />
									{ endUserEntity }
								</>
							}
						</p>
					</>
				}

				{ userData.data && userData.data.editProfileUrl && !isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL]) &&
					<Button href={ userData.data.editProfileUrl }>
						<Translate text="Change Account Details" />
					</Button>
				}

				{ notifications && notifications.length > 0 && (isRole(userData, [ROLE_SUPPLIER, ROLE_PROCUREMENT_REGULAR])) &&
					<>
						<h2><Translate text={'Notifications'} /></h2>
						<div className={'notifications'}>
							<NotificationSignup editNotification={notifications[0]} />
						</div>
					</>
				}
			</Col>
		</Row>
	);
}

function AccountSettings() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	let history = useHistory();
	let location = useLocation();
	const currentPath = location.pathname;

	// State data.
	const userData = useSelector(state => state.userData);
	const language = useSelector(state => state.language);

	useEffect(() => {
		const pageTitle = 'Account Settings - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			pageTitle,
			language.code
		);
	}, []);

	// User Data state.
	useEffect(() => {
		if (isNotLoggedIn(userData)) {
			history.replace('/');
		}
	}, [userData]);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{pageTitle} - {siteTitle}',
								data: { siteTitle: PAGE_TITLE_BASE, pageTitle: 'Account Settings' }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<ContentLoading>
					{ isLoggedIn(userData) &&
						<Content />
					}
				</ContentLoading>
			</Container>

			<Footer />
		</>
	);
}

export default AccountSettings;
