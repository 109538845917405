/* eslint-disable id-length */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { altpActions } from '../../store/actions/altpActions';
import { searchActions } from '../../store/actions/searchActions';
import { currencyFormat } from '../../helpers/miscHelpers';
import { getSimplifiedDate } from '../../helpers/dateHelpers';
import { clearMessages, error, success } from '../../store/actions/globalMessagesActions';
import { isOrganization, isRole } from '../../helpers/userHelpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Pagination from '../misc/Pagination';
import Form from 'react-bootstrap/Form';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import ContentLoading from '../misc/ContentLoading';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function DeleteConfirmation({altp}) {
	const dispatch = useDispatch();
	const handleDelete = (altp) => {
		const deleteData = JSON.parse(JSON.stringify(altp));
		deleteData.changeType = 'DELETE';
		dispatch(altpActions.postAltp(deleteData, 'delete-altp'));
	};
	return (
		<ButtonToolbar>
			<Button variant={'danger'} onClick={() => handleDelete(altp)}>
				<Translate text={'Yes, Delete'} />
			</Button>
			<Button onClick={() => dispatch(clearMessages())}>
				<Translate text={'Return to ALTPs'} />
			</Button>
		</ButtonToolbar>
	);
}

function AlternativeProcurementResults(props) {
	const {
		BLANK_VALUE,
		SORT_DATE_CREATED_ASC,
		SORT_DATE_CREATED_DESC,
		SORT_DATE_MODIFIED_ASC,
		SORT_DATE_MODIFIED_DESC,
		SORT_AWARD_DATE_ASC,
		SORT_AWARD_DATE_DESC,
		ALTP_PAGINATION_LIMIT,
		TAB_ALTPS,
		ALL_TABS,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		ROLE_PROCUREMENT_ADMIN,
		ROLE_PROCUREMENT_REGULAR
	} = require('../../config/constants');
	const dispatch = useDispatch();

	const authenticate = useSelector(state => state.authenticate);
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const referenceData = useSelector(state => state.referenceData);
	const altp = useSelector(state => state.altp);
	const altpPost = useSelector(state => state.altpPost);
	const search = useSelector(state => state.search);

	const altpLoading = authenticate.success && !altp.success && !altp.error;
	const [paginationData, setPaginationData] = useState({});
	const [allCount, setAllCount] = useState(0);
	const [keyword, setKeyword] = useState(null);
	const [mine, setMine] = useState(null);
	const [myOrganization, setMyOrganization] = useState(null);
	const [filterData, setFilterData] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [altpData, setAltpData] = useState([]);
	const [altpSort, setAltpSort] = useState(SORT_AWARD_DATE_DESC);
	const [resultsNum, setResultsNum] = useState(ALTP_PAGINATION_LIMIT);
	const [contactList, setContactList] = useState([]);
	const [tableHasOverflow, setTableHasOverflow] = useState(false);
	const tableControlsRef = useRef(null);
	const tableWrapperRef = useRef(null);
	const tableRef = useRef(null);
	const defaultErrorMessage = 'No Alternative Procurement notices available.';
	const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);

	const checkTableSize = () => {
		if (tableRef.current && tableWrapperRef.current && tableRef.current.offsetWidth > tableWrapperRef.current.offsetWidth) {
			setTableHasOverflow(true);
		} else {
			setTableHasOverflow(false);
		}
	};

	useEffect(() => {
		checkTableSize();

		window.addEventListener('resize', checkTableSize);

		return () => {
			window.removeEventListener('resize', checkTableSize);
		};
	}, []);

	useEffect(() => {
		checkTableSize();
	}, [altpData, props.isVisible]);

	useEffect(() => {
		if (referenceData.success && referenceData.data) {
			if (referenceData.data.altpContactDataList) {
				setContactList(referenceData.data.altpContactDataList);
			}
		}
	}, [referenceData]);

	useEffect(() => {
		if (authenticate && authenticate.success) {
			setAltpData([]);

			let query = {
				page: currentPage,
				numberOfRecords: resultsNum || ALTP_PAGINATION_LIMIT,
				sortType: altpSort || SORT_AWARD_DATE_DESC,
				mine: mine,
				myOrganization: myOrganization
			};

			if (keyword) {
				query = {
					...query,
					keyword: encodeURIComponent(keyword),
					mine: mine
				};
			}

			dispatch(altpActions.getAltps(query, null, filterData));
		}
	}, [currentPage, altpSort, resultsNum, authenticate]);

	// Load on search.
	useEffect(() => {
		if (search.updated && [TAB_ALTPS, ALL_TABS].includes(search.category)) {
			setAltpData([]);

			if (search.fields || search.filters) {
				if (search.fields.keyword || search.fields.mine || search.fields.myOrganization || search.filters) {
					let query = {
						page: 1,
						numberOfRecords: resultsNum || ALTP_PAGINATION_LIMIT,
						sortType: altpSort || SORT_AWARD_DATE_DESC
					};
					let keywordValue, mineValue, myOrganizationValue, filterDataValue;

					if (search.fields.keyword) {
						keywordValue = search.fields.keyword;
						setKeyword(keywordValue);
						query = {
							...query,
							keyword: encodeURIComponent(keywordValue)
						};
					} else {
						setKeyword(null);
					}

					if (search.fields.mine) {
						mineValue = search.fields.mine;
						setMine(mineValue);
						query = {
							...query,
							mine: mineValue
						};
					} else {
						setMine(null);
					}

					if (search.fields.myOrganization) {
						myOrganizationValue = search.fields.myOrganization;
						setMyOrganization(myOrganizationValue);
						query = {
							...query,
							myOrganization: myOrganizationValue
						};
					} else {
						setMyOrganization(null);
					}

					if (search.filters && typeof search.filters === 'object') {
						filterDataValue = search.filters;
						setFilterData(filterDataValue);
					} else {
						setFilterData(null);
					}

					dispatch(altpActions.getAltps(query, null, filterDataValue));
				} else {
					setKeyword(null);
					setMine(null);
					setMyOrganization(null);
					setFilterData(null);
					dispatch(altpActions.getAltps({page: 1, numberOfRecords: resultsNum || ALTP_PAGINATION_LIMIT, sortType: altpSort || SORT_AWARD_DATE_DESC}));
				}
			} else {
				setKeyword(null);
				setMine(null);
				setMyOrganization(null);
				setFilterData(null);
				dispatch(altpActions.getAltps({page: 1, numberOfRecords: resultsNum || ALTP_PAGINATION_LIMIT, sortType: altpSort || SORT_AWARD_DATE_DESC}));
			}
		} else {
			setKeyword(null);
			setMine(null);
			setMyOrganization(null);
			setFilterData(null);
		}
	}, [search]);

	useEffect(() => {
		if (keyword || filterData) {
			setErrorMessage('No opportunities matched your search. Try using different filters.');
		} else {
			setErrorMessage(defaultErrorMessage);
		}

		if (altp.success && altp.data && altp.data.altpDataList) {
			setAltpData(altp.data.altpDataList);

			if (altp.data.paginationData) {
				setAllCount(altp.data.paginationData.totalRecords);
				setPaginationData(altp.data.paginationData);

				if (props.handleUpdateAltpsCount) {
					props.handleUpdateAltpsCount(altp.data.paginationData.totalRecords);
				}
			}
		}
	}, [altp]);

	useEffect(() => {
		if (altpPost.callback === 'delete-altp' && (altpPost.success || altpPost.error)) {
			if (altpPost.success) {
				dispatch(success('Alternative Procurement Notice successfully deleted.'));
			} else if (altpPost.error) {
				dispatch(error('Error: Alternative Procurement Notice could not be deleted. Please try again later.'));
			}

			dispatch(altpActions.resetPostAltp());
			setCurrentPage(1);
			setAltpSort('');
			setFilterData(null);
			dispatch(altpActions.getAltps({page: currentPage, numberOfRecords: resultsNum || ALTP_PAGINATION_LIMIT, sortType: altpSort || SORT_AWARD_DATE_DESC}));
		}
	}, [altpPost]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleEditAltp = (altp) => {
		if (props.handleEditAltp) {
			props.handleEditAltp(altp);
		}
	};

	const handleDeleteAltp = (altp) => {
		dispatch(success('Delete this Alternative Procurement Notice?', <DeleteConfirmation altp={altp}/>));
	};

	const handleReset = () => {
		dispatch(searchActions.update({
			category: TAB_ALTPS,
			fields: null,
			filters: null
		}));
	};

	return (
		<ContentLoading loading={altpLoading}>
			<div className="search-results">
				<Row
					ref={ tableControlsRef }
					className="align-items-center"
				>
					<Col md={4}>
						<h2>
							{ (allCount > 1 || allCount < 1) &&
								<Translate
									text="{num} Results"
									data={{
										num: (altpLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
									}}
								/>
							}

							{ (allCount == 1) &&
								<Translate
									text="{num} Result"
									data={{
										num: (altpLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
									}}
								/>
							}

							{ (!altpLoading && props.isSearching) &&
								<Button
									variant="link"
									className="ml-2 mt-0"
									onClick={ handleReset }
								>
									<Translate text={'See All Results'} />
								</Button>
							}
						</h2>
					</Col>
					<Col md={8} className="text-md-right">
						<label className="sort-control">
							<span><Translate text={'Sort'} /></span>
							<Form.Control
								as="select"
								value={altpSort}
								onChange={(event) => { setCurrentPage(1); setAltpSort(event.currentTarget.value); }}
							>
								<Translator>
									{({ translate }) => (
										<>
											<option value={ SORT_DATE_CREATED_ASC }>
												{ translate({ text: 'Date Created (ASC)' }) }
											</option>
											<option value={ SORT_DATE_CREATED_DESC }>
												{ translate({ text: 'Date Created (DESC)' }) }
											</option>
											<option value={ SORT_DATE_MODIFIED_ASC }>
												{ translate({ text: 'Date Modified (ASC)' }) }
											</option>
											<option value={ SORT_DATE_MODIFIED_DESC }>
												{ translate({ text: 'Date Modified (DESC)' }) }
											</option>
											<option value={ SORT_AWARD_DATE_ASC }>
												{ translate({ text: 'Award Date (ASC)' }) }
											</option>
											<option value={ SORT_AWARD_DATE_DESC }>
												{ translate({ text: 'Award Date (DESC)' }) }
											</option>
										</>
									)}
								</Translator>
							</Form.Control>
						</label>
					</Col>
				</Row>

				<div className={`data-table search-results-table table-responsive${(tableHasOverflow) ? ' has-overflow' : ''}`} ref={ tableWrapperRef }>
					<Table bordered hover ref={ tableRef }>
						<thead>
							<tr>
								<th><Translate text={'ALTP ID'} /></th>
								<th><Translate text={'Title'} /></th>
								<th><Translate text={'Organization'} /></th>
								<th><Translate text={'Circumstance'} /></th>
								<th><Translate text={'Category'} /></th>
								<th><Translate text={'Supplier'} /></th>
								<th><Translate text={'Award Date'} /></th>
								<th><Translate text={'Awarded Amount'} /></th>
								<th><Translate text={'Contact Information'} /></th>
								<th hidden><Translate text={'Controls'} /></th>
							</tr>
						</thead>
						<tbody>
							{ Array.isArray(altpData) && altpData.length !== 0 && altpData.map(altp => {
								const contactInformation = !isNaN(altp.contactInformation) ? contactList.find(item => item.id == altp.contactInformation) : false;
								let categories;
								let canEdit = false;
								let canDelete = false;

								if (Array.isArray(altp.altpCategoryValueList) && altp.altpCategoryValueList.length) {
									// Filtering ones with duplicate description.
									const categoriesArray = altp.altpCategoryValueList.filter((item, index, array) => array.findIndex(e => e.description == item.description) == index);

									if (categoriesArray.length) {
										categories = Array.from(categoriesArray, item => item.description).join(', ');
									}
								}

								// Edit permissions.
								if (userData && userData.success) {
									if (isRole(userData, ROLE_PROCUREMENT_ADMIN)) {
										canEdit = true;
									} else if ((isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && altp.procurementEntityData && isOrganization(getUser.user, altp.procurementEntityData.organizationId))) {
										canEdit = true;
									} else {
										canEdit = false;
									}
								}

								// Delete permissions.
								if (userData && userData.success) {
									if (isRole(userData, ROLE_PROCUREMENT_ADMIN)) {
										canDelete = true;
									} else {
										canDelete = false;
									}
								}

								return (
									<tr key={altp.id}>
										<td>{altp.altpId || BLANK_VALUE}</td>
										<td>{altp.title || BLANK_VALUE}</td>
										<td>{altp.endUserEntity || BLANK_VALUE}</td>
										<td>{altp.circumstance || BLANK_VALUE}</td>
										<td>{categories || BLANK_VALUE}</td>
										<td>{altp.vendor || BLANK_VALUE}</td>
										<td>{getSimplifiedDate(altp.awardDate) || BLANK_VALUE}</td>
										<td>{currencyFormat(altp.awardedAmount, altp.currency) || BLANK_VALUE}</td>
										<td>{contactInformation && contactInformation.contactInformation ? contactInformation.contactInformation : altp.contactInformation || BLANK_VALUE}</td>
										<td className={'row-actions'}>
											{ canEdit &&
												<>
													<Button variant="link" className={'mb-0'} onClick={() => handleEditAltp(altp)}>
														<FontAwesomeIcon icon={ faPencilAlt } />
														<span className="sr-only"><Translate text={'Edit'} /></span>
													</Button>
												</>
											}

											{ canDelete &&
												<Button variant="link" className={'mb-0'} onClick={() => handleDeleteAltp(altp)}>
													<FontAwesomeIcon icon={ faTimesCircle } />
													<span className="sr-only"><Translate text={'Remove'} /></span>
												</Button>
											}
										</td>
									</tr>
								);
							})}

							{(!Array.isArray(altpData) || altpData.length === 0) &&
								<tr>
									<td colSpan="9"><Translate text={ errorMessage } /></td>
								</tr>
							}
						</tbody>
					</Table>
				</div>

				<Row className="align-items-center">
					{ paginationData &&
						<Col md={6} className="text-center text-md-left">
							<Pagination
								data={ paginationData }
								handlePaginationChange={handlePageChange}
							/>
						</Col>
					}

					<Col md={(paginationData) ? 6 : 12} className="text-center text-md-right">
						<label className="sort-control">
							<span><Translate text="Results" /></span>
							<Form.Control
								as="select"
								value={ resultsNum }
								onChange={(e) => { setCurrentPage(1); setResultsNum(e.currentTarget.value); }}
							>
								<Translator>
									{({ translate }) => (
										<>
											<option value={ALTP_PAGINATION_LIMIT}>
												{ translate({ text: `${ALTP_PAGINATION_LIMIT}` }) }
											</option>
											<option value={15}>
												{ translate({ text: '15' }) }
											</option>
											<option value={25}>
												{ translate({ text: '25' }) }
											</option>
											<option value={50}>
												{ translate({ text: '50' }) }
											</option>
										</>
									)}
								</Translator>
							</Form.Control>
						</label>
					</Col>
				</Row>
			</div>
		</ContentLoading>
	);
}

export default AlternativeProcurementResults;
