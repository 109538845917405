/* eslint-disable id-length */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { tenderActions } from '../../store/actions/tenderActions';
import { searchActions } from '../../store/actions/searchActions';
import { getSimplifiedDate } from '../../helpers/dateHelpers';
import { isRole } from '../../helpers/userHelpers';
import { formatFilterData } from '../../helpers/miscHelpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Pagination from '../misc/Pagination';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';
import FormSlideToggle from '../forms/FormSlideToggle';

function TableLayout(props) {
	const {
		data,
		noDataMessage,
		allCount,
		sortType,
		paginationData,
		resultsNum,
		isLoading,
		isSearching
	} = props;

	const {
		BLANK_VALUE,
		SORT_POSTED_DATE_ASC,
		SORT_POSTED_DATE_DESC,
		SORT_CLOSING_DATE_ASC,
		SORT_CLOSING_DATE_DESC,
		SORT_DATE_MODIFIED_DESC,
		ROLE_PROCUREMENT_ADMIN,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		ROLE_SUPPLIER,
		TAB_TENDERS,
		TENDERS_PAGINATION_LIMIT
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const history = useHistory();
	const tableRef = useRef(null);
	const tableControlsRef = useRef(null);

	// Get cases state.
	const authenticate = useSelector(state => state.authenticate);
	const tender = useSelector(state => state.tender);
	const userData = useSelector(state => state.userData);
	const postWatchlist = useSelector(state => state.postWatchlist);
	const watchlist = useSelector(state => state.watchlist);

	const [watchList, setWatchList] = useState({});
	const [watchListLoading, setWatchListLoading] = useState({});

	const truncate = (str, max = 12) => {
		if (str) {
			const array = str.trim().split(' ');
			const ellipsis = array.length > max ? '...' : '';

			return array.slice(0, max).join(' ') + ellipsis;
		}
	};

	useEffect(() => {
		if (authenticate && authenticate.success && isRole(userData, ROLE_SUPPLIER)) {
			dispatch(tenderActions.getWatchlist());
		}
	}, [authenticate, userData]);

	useEffect(() => {
		if (postWatchlist.success) {
			dispatch(tenderActions.getWatchlist(postWatchlist.callback));

			if (postWatchlist.callback) {
				const loadingList = {};
				Object.keys(watchListLoading).forEach((key) => {
					if (key === postWatchlist.callback) {
						loadingList[key] = false;
					}
				});
				setWatchListLoading(loadingList);
			}
		}
	}, [postWatchlist]);

	useEffect(() => {
		if (watchlist.success && watchlist.data) {
			const list = {};
			watchlist.data.forEach((item) => {
				list[item.id] = true;
			});
			setWatchList(list);
		}
	}, [watchlist]);

	/**
	 * Table config
	 */

	// Custom table headers.
	const headerFormatter = (column, colIndex, components) => {
		return (
			<>
				<Translate text={ column.text } />
				{ components.sortElement }
				{ components.filterElement }
			</>
		);
	};

	const cellDisplayFormatter = (cell, row) => {
		let status = row.tenderStatus;
		let organizationValue;

		if (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) {
			organizationValue = row.procurementEntity;
		} else if (row.endUserEntity) {
			organizationValue = row.endUserEntity;
		} else {
			organizationValue = row.procurementEntity;
		}

		const handleViewTender = (event, tender) => {
			event.stopPropagation();
			event.preventDefault();
			tableControlsRef.current.scrollIntoView();
			dispatch(tenderActions.currentTenderUpdate(tender));
			history.push({
				pathname: '/tenders/' + encodeURIComponent(row.tenderId),
				state: {
					tenderId: row.tenderId
				}
			});
		};

		const handleToggleWatchlist = (value, tender) => {
			setWatchListLoading({...watchListLoading, [tender.id]: true});
			dispatch(tenderActions.postWatchlist(tender, (value === 'yes'), tender.id));
		};

		return (
			<>
				<Table>
					<tbody>
						<tr>
							<td className="id-col" onClick={(e) => { handleViewTender(e, row); }}>
								<a href={`/tenders/${row.tenderId}`}>{ row.tenderId || BLANK_VALUE }</a>
							</td>
							<td className="st-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ row.solicitationType || BLANK_VALUE }
							</td>
							<td className="title-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ truncate(row.title) || BLANK_VALUE }
							</td>
							<td className="issuer-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ (organizationValue && truncate(organizationValue)) || BLANK_VALUE }
							</td>
							{ (isRole(userData, ROLE_PROCUREMENT_ADMIN) || isRole(userData, ROLE_PROCUREMENT_REGULAR) || isRole(userData, ROLE_PROCUREMENT_INTERNAL)) &&
								<td className="end-user-entity-col" onClick={(e) => { handleViewTender(e, row); }}>
									{ row.endUserEntity ? row.endUserEntity : BLANK_VALUE }
								</td>
							}
							<td className="date-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ getSimplifiedDate(row.postDate) || BLANK_VALUE }
							</td>
							<td className="date-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ getSimplifiedDate(row.closingDate) || BLANK_VALUE }
							</td>
							<td className="status-col" onClick={(e) => { handleViewTender(e, row); }}>
								{ status || BLANK_VALUE }
							</td>

							{ (isRole(userData, ROLE_SUPPLIER)) &&
								<td className="watchlist-col">
									<Form.Group controlId={'watched-tender-' + row.id} className={'mb-0'}>
										<FormSlideToggle
											id={'watched-tender-' + row.id}
											name={'watched-tender-' + row.id}
											ariaLabel="Watched"
											value={ watchList && row.id in watchList && watchList[row.id] === true ? 'yes' : 'no' }
											options={[
												{ value: 'no', label: 'No' },
												{ value: 'yes', label: 'Yes' }
											]}
											size={'sm'}
											readOnly={watchListLoading[row.id] === true}
											handleOnChange={ (id, value) => { handleToggleWatchlist(value, row); } }
										/>
									</Form.Group>
								</td>
							}
						</tr>
					</tbody>
				</Table>
			</>
		);
	};

	// Table columns.
	let tableColumns = [{
		dataField: 'cellDisplay',
		formatter: cellDisplayFormatter,
		classes: 'p-0',
		headerAttrs: {
			hidden: true
		},
		attrs: {
			colSpan: '8'
		}
	}, {
		dataField: 'tenderId',
		text: 'Tender ID',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'id-col'
	}, {
		dataField: 'solicitationType',
		text: 'Solicitation Type',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'st-col'
	}, {
		dataField: 'title',
		text: 'Title',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'title-col'
	}, {
		dataField: 'procumentEntity',
		text: 'Organization',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'issuer-col'
	}, {
		dataField: 'endUserEntity',
		text: 'End User Entity',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'end-user-entity-col'
	}, {
		dataField: 'postDate',
		text: 'Posted Date',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'date-col'
	}, {
		dataField: 'closingDate',
		text: 'Closing Date',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'date-col'
	}, {
		dataField: 'tenderStatus',
		text: 'Status',
		headerFormatter: headerFormatter,
		isDummyField: true,
		classes: 'd-none',
		headerClasses: 'status-col'
	}];

	if (isRole(userData, ROLE_SUPPLIER)) {
		tableColumns.push({
			dataField: 'watched',
			text: 'Watchlist',
			headerFormatter: headerFormatter,
			isDummyField: true,
			classes: 'd-none',
			headerClasses: 'watchlist-col'
		});
		tableColumns[0].attrs.colSpan = (parseInt(tableColumns[0].attrs.colSpan, 10) + 1).toString();
	}

	if (!isRole(userData, ROLE_PROCUREMENT_ADMIN) && !isRole(userData, ROLE_PROCUREMENT_REGULAR) && !isRole(userData, ROLE_PROCUREMENT_INTERNAL)) {
		tableColumns = tableColumns.filter(col => col.dataField !== 'endUserEntity');
		tableColumns[0].attrs.colSpan = (parseInt(tableColumns[0].attrs.colSpan, 10) - 1).toString();
	}

	/**
	 * Action functions
	 */

	const handlePaginationChange = (newPage) => {
		tableControlsRef.current.scrollIntoView();
		props.onPaginationChange(newPage);
	};

	const handleSortChange = (newSort) => {
		tableControlsRef.current.scrollIntoView();
		props.onSortChange(newSort);
	};

	const handleResultsChange = (newNum) => {
		tableControlsRef.current.scrollIntoView();
		props.onResultsChange(newNum);
	};

	const handleReset = () => {
		dispatch(searchActions.update({
			category: TAB_TENDERS,
			fields: null,
			filters: null
		}));
	};

	return (
		<ToolkitProvider
			keyField="id"
			data={ data }
			columns={ tableColumns }
		>
			{props => (
				<>
					<div className="search-results">
						<Row
							ref={ tableControlsRef }
							className="align-items-center"
						>
							<Col md={6}>
								<h2>
									{ (allCount > 1 || allCount < 1) &&
										<Translate
											text="{num} Results"
											data={{
												num: (isLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
											}}
										/>
									}

									{ (allCount == 1) &&
										<Translate
											text="{num} Result"
											data={{
												num: (isLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
											}}
										/>
									}

									{ (!isLoading && isSearching) &&
										<Button
											variant="link"
											className="ml-2 mt-0"
											onClick={ handleReset }
										>
											<Translate text={'See All Results'} />
										</Button>
									}
								</h2>
							</Col>
							<Col md={6} className="text-md-right">
								<label className="sort-control">
									<span><Translate text="Sort" /></span>
									<Form.Control
										as="select"
										value={ sortType }
										onChange={(e) => { handleSortChange(e.target.value); }}
									>
										<Translator>
											{({ translate }) => (
												<>
													<option value={ SORT_POSTED_DATE_DESC }>
														{ translate({ text: 'Posted Date (DESC)' }) }
													</option>
													<option value={ SORT_POSTED_DATE_ASC }>
														{ translate({ text: 'Posted Date (ASC)' }) }
													</option>
													<option value={ SORT_CLOSING_DATE_DESC }>
														{ translate({ text: 'Closing Date (DESC)' }) }
													</option>
													<option value={ SORT_CLOSING_DATE_ASC }>
														{ translate({ text: 'Closing Date (ASC)' }) }
													</option>
													<option value={ SORT_DATE_MODIFIED_DESC }>
														{ translate({ text: 'Modified Date (most recent)' }) }
													</option>
												</>
											)}
										</Translator>
									</Form.Control>
								</label>
							</Col>
						</Row>
						<Translator>
							{({ translate }) => (
								<div className="table-responsive">
									<BootstrapTable
										{ ...props.baseProps }
										bootstrap4
										hover
										noDataIndication={
											translate({ text: noDataMessage })
										}
										loading={ tender.request }
										overlay={ overlayFactory({
											spinner: true,
											text: translate({ text: 'Loading tenders...' })
										}) }
										ref={ tableRef }
										wrapperClasses="data-table search-results-table tenders-table"
									/>
								</div>
							)}
						</Translator>

						<Row className="align-items-center">
							{ paginationData &&
								<Col md={6} className="text-center text-md-left">
									<Pagination
										data={ paginationData }
										handlePaginationChange={ handlePaginationChange }
									/>
								</Col>
							}

							<Col md={(paginationData) ? 6 : 12} className="text-center text-md-right">
								<label className="sort-control">
									<span><Translate text="Results" /></span>
									<Form.Control
										as="select"
										value={ resultsNum }
										onChange={(e) => { handleResultsChange(e.target.value); }}
									>
										<Translator>
											{({ translate }) => (
												<>
													<option value={TENDERS_PAGINATION_LIMIT}>
														{ translate({ text: `${TENDERS_PAGINATION_LIMIT}` }) }
													</option>
													<option value={15}>
														{ translate({ text: '15' }) }
													</option>
													<option value={25}>
														{ translate({ text: '25' }) }
													</option>
													<option value={50}>
														{ translate({ text: '50' }) }
													</option>
												</>
											)}
										</Translator>
									</Form.Control>
								</label>
							</Col>
						</Row>
					</div>
				</>
			)}
		</ToolkitProvider>
	);
}

function TendersResults(props) {
	const {
		SORT_POSTED_DATE_DESC,
		TENDERS_PAGINATION_LIMIT,
		TAB_TENDERS,
		ALL_TABS
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const numberOfRecords = TENDERS_PAGINATION_LIMIT;
	const [data, setData] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [myOrganization, setMyOrganization] = useState(null);
	const [mine, setMine] = useState(null);
	const [watchlist, setWatchlist] = useState(null);
	const [filterData, setFilterData] = useState(null);
	const [sortType, setSortType] = useState(SORT_POSTED_DATE_DESC);
	const [resultsNum, setResultsNum] = useState(numberOfRecords);
	const [noDataMessage, setNoDataMessage] = useState('\u00A0');
	const [allCount, setAllCount] = useState(0);
	const [paginationData, setPaginationData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// State.
	const authenticate = useSelector(state => state.authenticate);
	const tender = useSelector(state => state.tender);
	const search = useSelector(state => state.search);
	const postTender = useSelector(state => state.postTender);

	// Getting teachers data.
	const loadData = (page, numberOfRecords, sortType, keyword, myOrganization, mine, watchlist, filterData) => {
		const filters = formatFilterData(filterData);

		dispatch(tenderActions.getTenders({
			page: page,
			numberOfRecords: numberOfRecords,
			sortType: sortType,
			keyword: keyword ? encodeURIComponent(keyword) : null,
			myOrganization: myOrganization,
			mine: mine,
			watchlist: watchlist
		}, null, filters));
	};

	// Initial load.
	useEffect(() => {
		if (authenticate && authenticate.success) {
			loadData(1, resultsNum, SORT_POSTED_DATE_DESC);
		}
	}, [authenticate]);

	// Load on search.
	useEffect(() => {
		if (search.updated && [TAB_TENDERS, ALL_TABS].includes(search.category)) {
			setData([]);

			if (search.fields || search.filters) {
				if (search.fields.keyword || search.fields.myOrganization || search.fields.mine || search.fields.watchlist || search.filters) {
					let keywordValue, myOrganizationValue, mineValue, watchlistValue, filterDataValue;

					if (search.fields.keyword) {
						keywordValue = search.fields.keyword;
						setKeyword(keywordValue);
					} else {
						setKeyword(null);
					}

					if (search.fields.myOrganization) {
						myOrganizationValue = search.fields.myOrganization;
						setMyOrganization(myOrganizationValue);
					} else {
						setMyOrganization(null);
					}

					if (search.fields.mine) {
						mineValue = search.fields.mine;
						setMine(mineValue);
					} else {
						setMine(null);
					}

					if (search.fields.watchlist) {
						watchlistValue = search.fields.watchlist;
						setWatchlist(watchlistValue);
					} else {
						setWatchlist(null);
					}

					if (search.filters && typeof search.filters === 'object') {
						filterDataValue = search.filters;
						setFilterData(filterDataValue);
					} else {
						setFilterData(null);
					}

					loadData(1, resultsNum, sortType, keywordValue, myOrganizationValue, mineValue, watchlistValue, filterDataValue);
				} else {
					setKeyword(null);
					setMyOrganization(null);
					setMine(null);
					setWatchlist(null);
					setFilterData(null);
					loadData(1, resultsNum, sortType);
				}
			} else {
				setKeyword(null);
				setMyOrganization(null);
				setMine(null);
				setWatchlist(null);
				setFilterData(null);
				loadData(1, resultsNum, sortType);
			}
		} else {
			setKeyword(null);
			setMyOrganization(null);
			setMine(null);
			setWatchlist(null);
			setFilterData(null);
		}
	}, [search]);

	// Listening to teachers state.
	useEffect(() => {
		if (tender.request) {
			setNoDataMessage('\u00A0');
			setIsLoading(true);
		} else if (tender.error) {
			setNoDataMessage('Error loading tenders');
			setIsLoading(false);
		} else {
			setNoDataMessage('No opportunities matched your search. Try using different filters.');
			setIsLoading(false);
		}

		if (tender.success) {
			if (Array.isArray(tender.data.tenderDataList)) {
				setData(tender.data.tenderDataList);
			}

			if (tender.data.paginationData) {
				setAllCount(tender.data.paginationData.totalRecords);
				setPaginationData(tender.data.paginationData);

				if (props.handleUpdateTendersCount) {
					props.handleUpdateTendersCount(tender.data.paginationData.totalRecords);
				}
			}

			setIsLoading(false);
		}
	}, [tender]);

	// Reload after tender update.
	useEffect(() => {
		if (postTender.success && postTender.callback !== 'clone-tender') {
			loadData(1, resultsNum, SORT_POSTED_DATE_DESC);
		}
	}, [postTender]);

	const handlePaginationChange = (newPage) => {
		setData([]);
		loadData(newPage, resultsNum, sortType, keyword, myOrganization, mine, watchlist, filterData);
	};

	const handleSortChange = (newSort) => {
		setData([]);
		setSortType(newSort);
		loadData(1, resultsNum, newSort, keyword, myOrganization, mine, watchlist, filterData);
	};

	const handleResultsChange = (newNum) => {
		setData([]);
		setResultsNum(newNum);
		loadData(1, newNum, sortType, keyword, myOrganization, mine, watchlist, filterData);
	};

	return (
		<TableLayout
			data={ data }
			noDataMessage={ noDataMessage }
			allCount={ allCount }
			sortType={ sortType }
			paginationData={ paginationData }
			isLoading={ isLoading }
			isVisible={ props.isVisible }
			isSearching={ props.isSearching }
			onPaginationChange={ handlePaginationChange }
			onSortChange={ handleSortChange }
			onResultsChange={ handleResultsChange }
		/>
	);
}

export default TendersResults;
