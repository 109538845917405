import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import unorm from 'unorm';

import { setHasChanges } from '../../store/actions/globalMessagesActions';
import { fileToBase64, bytesToSize } from '../../helpers/fileHelpers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from '../misc/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function UploadAttachment(props) {
	const {
		item,
		index,
		validated,
		required,
		data,
		hasTotalFilesizeError,
		additionalField,
		documentType,
		title
	} = props;
	const {
		DOC_CONTENT_TYPE_PDF,
		DOC_CONTENT_TYPE_JPEG,
		DOC_CONTENT_TYPE_PNG,
		DOC_CONTENT_TYPE_DOC,
		DOC_CONTENT_TYPE_DOCX,
		DOC_CONTENT_TYPE_TEXT,
		DOC_CONTENT_TYPE_XLS,
		DOC_CONTENT_TYPE_XLSX,
		DOC_CONTENT_TYPE_ZIP,
		DOC_MIME_TYPE_PDF,
		DOC_MIME_TYPE_JPEG,
		DOC_MIME_TYPE_PNG,
		DOC_MIME_TYPE_DOC,
		DOC_MIME_TYPE_DOCX,
		DOC_MIME_TYPE_TEXT,
		DOC_MIME_TYPE_XLS,
		DOC_MIME_TYPE_XLSX,
		DOC_MIME_TYPE_ZIP,
		DOC_MIME_TYPE_X_ZIP,
		DOC_MIME_TYPES,
		DOC_MAX_FILESIZE,
		DOC_MAX_FILENAME,
		DESCRIPTION_MAX_LENGTH,
		INPUT_MAX_LENGTH
	} = require('../../config/constants');

	if (!documentType || !data) {
		return null;
	}

	const dispatch = useDispatch();
	const [uploads, setUploads] = useState([{documentType: documentType}]);
	const [typeError, setTypeError] = useState('');
	const [filesizeError, setFilesizeError] = useState('');
	const [filenameError, setFilenameError] = useState('');
	let hasErrors = false;
	const [typeFiles, setTypeFiles] = useState([]);
	const [filesizeFiles, setFilesizeFiles] = useState([]);
	const [filenameFiles, setFilenameFiles] = useState([]);
	const [additionalFieldValue, setAdditionalFieldValue] = useState((additionalField.defaultValue) ? additionalField.defaultValue : '');
	const filesizeString = bytesToSize(DOC_MAX_FILESIZE);
	const fileElement = useRef(null);
	const mimeTypes = DOC_MIME_TYPES;

	// State data.
	const hasChanges = useSelector(state => state.hasChanges);

	useEffect(() => {
		if (data && data.attachments) {
			let currentUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
			currentUploads = currentUploads.filter(item => item && item.documentType == documentType);

			if (currentUploads.length) {
				setUploads(currentUploads);

				if (additionalField && additionalField.name && !isNaN(index) && currentUploads[index] && currentUploads[index][additionalField.name] !== null) {
					setAdditionalFieldValue(currentUploads[index][additionalField.name]);
				}
			}
		}
	}, [data, additionalField]);

	const processFiles = async (fileList, index) => {
		let files;
		fileElement.current.setCustomValidity('');
		setTypeError('');
		setFilesizeError('');
		setFilenameError('');
		hasErrors = false;
		setTypeFiles([]);
		setFilesizeFiles([]);
		setFilenameFiles([]);

		files = [...fileList];

		const promises = files.map((file) => {
			let contentType;
			let newUpload;

			return new Promise((res) => {
				if (Array.isArray(mimeTypes) && !mimeTypes.includes(file.type)) {
					setTypeError('Incorrect file type.');
					fileElement.current.setCustomValidity('Incorrect file type.');
					hasErrors = true;
					setTypeFiles(typeFiles => [...typeFiles, file.name]);
					return false;
				}

				if (file.size > DOC_MAX_FILESIZE) {
					setFilesizeError(`Some files exceed the ${filesizeString} limit.`);
					fileElement.current.setCustomValidity(`Some files exceed the ${filesizeString} limit.`);
					hasErrors = true;
					setFilesizeFiles(filesizeFiles => [...filesizeFiles, file.name]);
					return false;
				}

				if (file.name.length > DOC_MAX_FILENAME) {
					setFilenameError(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
					fileElement.current.setCustomValidity(`Some file names exceed the ${DOC_MAX_FILENAME} character limit.`);
					hasErrors = true;
					setFilenameFiles(filenameFiles => [...filenameFiles, file.name]);
					return false;
				}

				switch (file.type) {
					case DOC_MIME_TYPE_PDF:
						contentType = DOC_CONTENT_TYPE_PDF;
						break;
					case DOC_MIME_TYPE_JPEG:
						contentType = DOC_CONTENT_TYPE_JPEG;
						break;
					case DOC_MIME_TYPE_PNG:
						contentType = DOC_CONTENT_TYPE_PNG;
						break;
					case DOC_MIME_TYPE_DOC:
						contentType = DOC_CONTENT_TYPE_DOC;
						break;
					case DOC_MIME_TYPE_DOCX:
						contentType = DOC_CONTENT_TYPE_DOCX;
						break;
					case DOC_MIME_TYPE_TEXT:
						contentType = DOC_CONTENT_TYPE_TEXT;
						break;
					case DOC_MIME_TYPE_XLS:
						contentType = DOC_CONTENT_TYPE_XLS;
						break;
					case DOC_MIME_TYPE_XLSX:
						contentType = DOC_CONTENT_TYPE_XLSX;
						break;
					case DOC_MIME_TYPE_ZIP:
					case DOC_MIME_TYPE_X_ZIP:
						contentType = DOC_CONTENT_TYPE_ZIP;
						break;
					default:
						break;
				}

				// Convert to Base64 & add to array.
				fileToBase64(file)
					.then((raw) => {
						const data = raw.split(',')[1];

						if (data && file) {
							let filenameExt = file.name.split('.').pop();
							let filename = file.name.split('.').slice(0, -1).join('.');

							if (filename) {
								filename = filename.trim() + '-' + index + (Math.floor(100000 + (Math.random() * 900000))) + '.' + filenameExt;
							} else {
								filename = file.name;
							}

							newUpload = {
								changeType: 'SAVE',
								id: item.id ? item.id : null,
								filename: unorm.nfc(filename),
								filesize: file.size,
								documentContentType: contentType,
								documentType: documentType,
								data: data,
								url: (additionalField.name == 'url' && additionalFieldValue) ? additionalFieldValue : null,
								notes: (additionalField.name == 'notes' && additionalFieldValue) ? additionalFieldValue : null
							};

							res(newUpload);
						}
					})
					.catch((error) => {
						console.log(error); // eslint-disable-line
						return false;
					});
			});
		});

		const newUpload = await Promise.all(promises);

		if (!hasErrors) {
			const newFile = (newUpload && newUpload.length) ? newUpload[0] : {};

			let otherUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
			otherUploads = otherUploads.filter(item => item && item.documentType !== documentType);

			let items = [...uploads];
			items[index] = newFile;

			const updatedFiles = otherUploads.concat(items);

			if (props.handleUpdateAttachments) {
				props.handleUpdateAttachments(updatedFiles);
			}
		}
	};

	const handleUpdate = (event, index) => {
		const fileList = fileElement.current.files;
		processFiles(fileList, index);

		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleRemoveFile = (event, index) => {
		fileElement.current.value = '';
		fileElement.current.setCustomValidity('');

		let otherUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
		otherUploads = otherUploads.filter(item => item && item.documentType !== documentType);
		let items = [...uploads];
		const attachment = (items.length) ? items[index] : null;

		if (index > -1) {
			if (attachment && attachment.id) {
				items[index].changeType = 'DELETE';
				items[index].filename = '';
				items[index].url = '';
				items[index].notes = '';
			} else {
				items.splice(index, 1);
			}
		}

		const updatedFiles = otherUploads.concat(items);

		if (props.handleUpdateAttachments) {
			props.handleUpdateAttachments(updatedFiles);
		}
	};

	const handleBlurAdditionalField = (event) => {
		const { name, value } = event.target;

		let otherUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
		otherUploads = otherUploads.filter(item => item && item.documentType !== documentType);

		let items = [...uploads];

		if (!items[index]) {
			items[index] = { documentType: documentType };
		}

		items[index].changeType = 'SAVE';

		if (value) {
			items[index][name] = value;
		} else {
			items[index][name] = '';
		}

		const updatedFiles = otherUploads.concat(items);

		if (props.handleUpdateAttachments) {
			props.handleUpdateAttachments(updatedFiles);
		}

		setAdditionalFieldValue(value);
	};

	const handleChangeAdditionalField = (event) => {
		const { value } = event.target;

		setAdditionalFieldValue(value);
	};

	return (
		<>
			<Row>
				<Col md={10}>
					<Translator>
						{({ translate }) => (
							<Row>
								<Col lg={ (additionalField) ? 6 : 12 }>
									<Form.Control
										ref={ fileElement }
										id={`${documentType}-attachment-${index}`}
										type="file"
										className="visuallyhidden"
										aria-label={
											translate({
												text: (title) ? 'Choose File - {type} #{number}' : 'Choose File #{number}',
												data: { type: title, number: (index.toString()) ? index + 1 : null }
											})
										}
										required={ required && (!item || !item.data) }
										accept={ (Array.isArray(mimeTypes)) ? mimeTypes.toString() : null }
										isInvalid={ !item || !item.data || typeError !== '' || filesizeError !== '' || filenameError !== '' || hasTotalFilesizeError }
										isValid={ item && item.data && typeError == '' && filesizeError == '' && filenameError == '' && !hasTotalFilesizeError }
										onChange={(e) => { handleUpdate(e, index); }}
									/>
									<Form.Label htmlFor={`${documentType}-attachment-${index}`}>
										<Translate text={ (item && item.filename) ? item.filename : 'Choose File' } />{ (item && item.data && !hasTotalFilesizeError) && <FontAwesomeIcon icon={ faCheckCircle } /> }
									</Form.Label>
								</Col>

								{ additionalField &&
									<Col lg={6}>
										<div className="additional-field-wrapper">
											{ (additionalField.type == 'text') &&
												<Form.Control
													type="text"
													name={ additionalField.name }
													defaultValue={ additionalFieldValue }
													className="additional-field additional-field--text"
													placeholder={ additionalField.label }
													aria-label={ additionalField.label }
													maxLength={ INPUT_MAX_LENGTH }
													onBlur={ handleBlurAdditionalField }
													onChange={ handleChangeAdditionalField }
												/>
											}

											{ (additionalField.type == 'textarea') &&
												<Form.Control
													as="textarea"
													name={ additionalField.name }
													defaultValue={ additionalFieldValue }
													className="additional-field additional-field--textarea"
													placeholder={ additionalField.label }
													aria-label={ additionalField.label }
													maxLength={ DESCRIPTION_MAX_LENGTH }
													onBlur={ handleBlurAdditionalField }
													onChange={ handleChangeAdditionalField }
												/>
											}

											{ additionalField.tooltipText &&
												<div className="additional-field-tooltip">
													<Tooltip text={ additionalField.tooltipText } link={ additionalField.tooltipLink } />
												</div>
											}
										</div>
									</Col>
								}
							</Row>
						)}
					</Translator>
				</Col>
				<Col md={2} className="upload-remove repeater-remove">
					<Translator>
						{({ translate }) => (
							<Button
								variant="link"
								className="text-danger ml-2"
								aria-label={
									translate({
										text: (title) ? 'Remove - {type} #{number}' : 'Remove #{number}',
										data: { type: title, number: (index.toString()) ? index + 1 : null }
									})
								}
								onClick={(e) => { handleRemoveFile(e, index); }}
							>
								<Translate text="Remove" />
							</Button>
						)}
					</Translator>
				</Col>
			</Row>

			<div className={`invalid-feedback error-attachment-upload-feedback ${(typeError || filesizeError || filenameError) ? 'show' : ''}`}>
				<div><Translate text={ typeError } /></div>
				{ typeFiles && (typeFiles.length > 0) &&
					<ul>
						{
							typeFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}

				<div><Translate text={ filesizeError } /></div>
				{ filesizeFiles && (filesizeFiles.length > 0) &&
					<ul>
						{
							filesizeFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}

				<div><Translate text={ filenameError } /></div>
				{ filenameFiles && (filenameFiles.length > 0) &&
					<ul>
						{
							filenameFiles.map((file, index) => {
								return (
									<li key={ index }>
										{ file }
									</li>
								);
							})
						}
					</ul>
				}
			</div>

			{ required && validated && (!item || !item.data) &&
				<div className="invalid-feedback show error-attachment-upload-feedback">
					<Translate text="File upload is required." />
				</div>
			}
		</>
	);
}

export default UploadAttachment;
