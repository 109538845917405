import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-translated';

import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
	const [showMessage, setShowMessage] = useState(false);

	useEffect(() => {
		const delay = setTimeout(() => {
			setShowMessage(true);
		}, '6000');

		return () => {
			clearTimeout(delay);
			setShowMessage(false);
		};
	}, []);

	return (
		<div className="loader-wrapper">
			<Spinner animation="border" role="status" className="loader">
				<span className="sr-only"><Translate text={'Loading'} /></span>
			</Spinner>

			{ showMessage &&
				<span className="loader-message">
					<Translate text={'Still working...'} />
				</span>
			}
		</div>
	);
}

function ContentLoading(props) {
	const { loading, isFixed } = props;

	// State data.
	const authenticate = useSelector(state => state.authenticate);

	const isLoading = authenticate.request || loading;
	const loadingClass = (isLoading) ? 'is-loading' : '';
	const fixedClass = (isFixed) ? 'is-fixed' : '';

	return (
		<div className={`content-loading loading-container ${loadingClass} ${fixedClass}`}>
			{ props.children }

			{ isLoading &&
				<LoadingSpinner />
			}
		</div>
	);
}

export default ContentLoading;
