/* eslint-disable id-length */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import { useDispatch } from 'react-redux';

import { standingOfferActions } from '../../store/actions/standingOfferActions';
import { searchActions } from '../../store/actions/searchActions';
import { getSimplifiedDate, getFormattedMonth } from '../../helpers/dateHelpers';
import { isRole } from '../../helpers/userHelpers';
import { tenderService } from '../../services/tenderService';
import { error } from '../../store/actions/globalMessagesActions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Pagination from '../misc/Pagination';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import overlayFactory from 'react-bootstrap-table2-overlay';

function TableLayout(props) {
	const {
		data,
		noDataMessage,
		allCount,
		paginationData,
		resultsNum,
		isLoading,
		isVisible,
		isSearching
	} = props;

	const {
		BLANK_VALUE,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		ROLE_PROCUREMENT_ADMIN,
		TENDER_NEXT_CALL_DATE,
		TENDER_NEXT_CALL_ID,
		TAB_STANDING_OFFERS,
		TENDERS_PAGINATION_LIMIT
	} = require('../../config/constants');
	const history = useHistory();
	const [tableHasOverflow, setTableHasOverflow] = useState(false);
	const tableWrapperRef = useRef(null);
	const tableRef = useRef(null);
	const tableControlsRef = useRef(null);
	const dispatch = useDispatch();

	// Get cases state.
	const standingOffer = useSelector(state => state.standingOffer);
	const userData = useSelector(state => state.userData);

	const checkTableSize = () => {
		if (tableWrapperRef.current && document.querySelector('.standing-offers-table').offsetWidth > tableWrapperRef.current.offsetWidth) {
			setTableHasOverflow(true);
		} else {
			setTableHasOverflow(false);
		}
	};

	useEffect(() => {
		checkTableSize();

		window.addEventListener('resize', checkTableSize);

		return () => {
			window.removeEventListener('resize', checkTableSize);
		};
	}, []);

	useEffect(() => {
		checkTableSize();
	}, [data, isVisible]);

	/**
	 * Table config
	 */

	// Custom table headers.
	const headerFormatter = (column, colIndex, components) => {
		return (
			<>
				<Translate text={ column.text } />
				{ components.sortElement }
				{ components.filterElement }
			</>
		);
	};

	const cellDisplayFormatter = (cell) => {
		return cell || BLANK_VALUE;
	};

	const dateDisplayFormatter = (cell) => {
		return getSimplifiedDate(cell) || BLANK_VALUE;
	};

	const nextCallFormatter = (cell, row) => {
		let output;

		const handleClick = (tenderId) => {
			tenderService.getTenders({ keyword: tenderId })
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error && Array.isArray(response.tenderDataList) && response.tenderDataList.length && response.tenderDataList[0].tenderId) {
							history.push({
								pathname: '/tenders/' + response.tenderDataList[0].tenderId,
								state: {
									tenderId: response.tenderDataList[0].tenderId,
									standingOffers: true
								}
							});
						} else {
							dispatch(error('Error: Tender not available.'));
						}
					}
				);
		};

		if (row.nextTenderCall) {
			if (row.nextTenderCallType == TENDER_NEXT_CALL_DATE) {
				output = getFormattedMonth(row.nextTenderCall);
			} else if (row.nextTenderCallType == TENDER_NEXT_CALL_ID) {
				output = <Button variant="link" className="mb-0 text-left" onClick={() => handleClick(row.nextTenderCall) }><Translate text={'Ongoing Onboarding'} /></Button>;
			} else {
				output = row.nextTenderCall;
			}
		}

		return output || BLANK_VALUE;
	};

	const contactFormatter = (cell, row) => {
		let output, isEmail;

		if (row.contactEmail) {
			const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
			isEmail = regexExp.test(row.contactEmail);

			if (isEmail) {
				output = <Button variant="link" href={`mailto:${row.contactEmail}?subject=${row.contractName}`} className="mb-0 text-left">{ row.contactEmail }</Button>;
			} else {
				output = row.contactEmail;
			}
		}

		return output || BLANK_VALUE;
	};

	// Table columns.
	const tableColumns = [{
		dataField: 'contractName',
		text: 'Contract Name',
		headerFormatter: headerFormatter,
		formatter: cellDisplayFormatter,
		classes: 'contract-name-col',
		headerClasses: 'contract-name-col'
	}, {
		dataField: 'unspsc',
		text: 'Commodity',
		headerFormatter: headerFormatter,
		formatter: cellDisplayFormatter,
		classes: 'unspsc-col',
		headerClasses: 'unspsc-col'
	}, {
		dataField: 'keywords',
		text: 'Keywords',
		headerFormatter: headerFormatter,
		formatter: cellDisplayFormatter,
		classes: 'keywords-col',
		headerClasses: 'keywords-col'
	}, {
		dataField: 'supplier',
		text: 'Supplier',
		headerFormatter: headerFormatter,
		formatter: cellDisplayFormatter,
		classes: 'general-col',
		headerClasses: 'general-col',
		hidden: (!isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER]))
	}, {
		dataField: 'contractEffectiveDate',
		text: 'Effective Date',
		headerFormatter: headerFormatter,
		formatter: dateDisplayFormatter,
		classes: 'date-col',
		headerClasses: 'date-col'
	}, {
		dataField: 'contractExpirationDate',
		text: 'Expiration Date',
		headerFormatter: headerFormatter,
		formatter: dateDisplayFormatter,
		classes: 'date-col',
		headerClasses: 'date-col'
	}, {
		dataField: 'nextTenderCall',
		text: 'Next Tender Call',
		headerFormatter: headerFormatter,
		formatter: nextCallFormatter,
		classes: 'next-tender-call-col',
		headerClasses: 'next-tender-call-col',
		hidden: (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER]))
	}, {
		dataField: 'contractId',
		text: 'Ariba Contract ID',
		headerFormatter: headerFormatter,
		formatter: cellDisplayFormatter,
		classes: 'contract-id-col',
		headerClasses: 'contract-id-col',
		hidden: (!isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER]))
	}, {
		dataField: 'contactEmail',
		text: 'Contact',
		headerFormatter: headerFormatter,
		formatter: contactFormatter,
		classes: 'general-col',
		headerClasses: 'general-col',
		hidden: (!isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER]))
	}];

	/**
	 * Action functions
	 */

	const handlePaginationChange = (newPage) => {
		tableControlsRef.current.scrollIntoView();
		props.onPaginationChange(newPage);
	};

	const handleResultsChange = (newNum) => {
		tableControlsRef.current.scrollIntoView();
		props.onResultsChange(newNum);
	};

	const handleReset = () => {
		dispatch(searchActions.update({
			category: TAB_STANDING_OFFERS,
			fields: null,
			filters: null
		}));
	};

	return (
		<ToolkitProvider
			keyField="id"
			data={ data }
			columns={ tableColumns }
		>
			{props => (
				<>
					<div className="search-results">
						<Row
							ref={ tableControlsRef }
							className="align-items-center"
						>
							<Col>
								<h2>
									{ (allCount > 1 || allCount < 1) &&
										<Translate
											text="{num} Results"
											data={{
												num: (isLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
											}}
										/>
									}

									{ (allCount == 1) &&
										<Translate
											text="{num} Result"
											data={{
												num: (isLoading) ? BLANK_VALUE : (allCount) ? allCount.toString() : BLANK_VALUE
											}}
										/>
									}

									{ (!isLoading && isSearching) &&
										<Button
											variant="link"
											className="ml-2 mt-0"
											onClick={ handleReset }
										>
											<Translate text={'See All Results'} />
										</Button>
									}
								</h2>
							</Col>
						</Row>
						<Translator>
							{({ translate }) => (
								<div className={`data-table search-results-table table-responsive${(tableHasOverflow) ? ' has-overflow' : ''}`} ref={ tableWrapperRef }>
									<BootstrapTable
										{ ...props.baseProps }
										bootstrap4
										hover
										noDataIndication={
											translate({ text: noDataMessage })
										}
										loading={ standingOffer.request }
										overlay={ overlayFactory({
											spinner: true
										}) }
										ref={ tableRef }
										classes="standing-offers-table"
									/>
								</div>
							)}
						</Translator>

						<Row className="align-items-center">
							{ paginationData &&
								<Col md={6} className="text-center text-md-left">
									<Pagination
										data={ paginationData }
										handlePaginationChange={ handlePaginationChange }
									/>
								</Col>
							}

							<Col md={(paginationData) ? 6 : 12} className="text-center text-md-right">
								<label className="sort-control">
									<span><Translate text="Results" /></span>
									<Form.Control
										as="select"
										value={ resultsNum }
										onChange={(e) => { handleResultsChange(e.target.value); }}
									>
										<Translator>
											{({ translate }) => (
												<>
													<option value={TENDERS_PAGINATION_LIMIT}>
														{ translate({ text: `${TENDERS_PAGINATION_LIMIT}` }) }
													</option>
													<option value={15}>
														{ translate({ text: '15' }) }
													</option>
													<option value={25}>
														{ translate({ text: '25' }) }
													</option>
													<option value={50}>
														{ translate({ text: '50' }) }
													</option>
												</>
											)}
										</Translator>
									</Form.Control>
								</label>
							</Col>
						</Row>
					</div>
				</>
			)}
		</ToolkitProvider>
	);
}

function StandingOffersResults(props) {
	const {
		SORT_DATE_CREATED_DESC,
		TENDERS_PAGINATION_LIMIT,
		TAB_STANDING_OFFERS,
		ALL_TABS
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const numberOfRecords = TENDERS_PAGINATION_LIMIT;
	const [data, setData] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [sortType, setSortType] = useState(SORT_DATE_CREATED_DESC);
	const [resultsNum, setResultsNum] = useState(numberOfRecords);
	const [noDataMessage, setNoDataMessage] = useState('\u00A0');
	const [allCount, setAllCount] = useState(0);
	const [paginationData, setPaginationData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	// State.
	const authenticate = useSelector(state => state.authenticate);
	const standingOffer = useSelector(state => state.standingOffer);
	const search = useSelector(state => state.search);

	// Getting teachers data.
	const loadData = (page, numberOfRecords, sortType, keyword) => {
		dispatch(standingOfferActions.getStandingOffers({
			page: page,
			numberOfRecords: numberOfRecords,
			sortType: sortType,
			keyword: keyword ? encodeURIComponent(keyword) : null
		}));
	};

	// Initial load.
	useEffect(() => {
		if (authenticate && authenticate.success) {
			loadData(1, resultsNum, SORT_DATE_CREATED_DESC);
		}
	}, [authenticate]);

	// Load on search.
	useEffect(() => {
		if (search.updated && [TAB_STANDING_OFFERS, ALL_TABS].includes(search.category)) {
			setData([]);

			if (search.fields) {
				if (search.fields.keyword) {
					let keywordValue;

					if (search.fields.keyword) {
						keywordValue = search.fields.keyword;
						setKeyword(keywordValue);
					} else {
						setKeyword(null);
					}

					loadData(1, resultsNum, sortType, keywordValue);
				} else {
					setKeyword(null);
					loadData(1, resultsNum, sortType);
				}
			} else {
				setKeyword(null);
				loadData(1, resultsNum, sortType);
			}
		} else {
			setKeyword(null);
		}
	}, [search]);

	// Listening to teachers state.
	useEffect(() => {
		if (standingOffer.request) {
			setNoDataMessage('\u00A0');
		} else if (standingOffer.error) {
			setNoDataMessage('Error loading standing offers');
			setIsLoading(false);
		} else {
			setNoDataMessage('No opportunities matched your search.');
			setIsLoading(false);
		}

		if (standingOffer.success) {
			if (Array.isArray(standingOffer.data.standingOfferDataList)) {
				setData(standingOffer.data.standingOfferDataList);
			}

			if (standingOffer.data.paginationData) {
				setAllCount(standingOffer.data.paginationData.totalRecords);
				setPaginationData(standingOffer.data.paginationData);

				if (props.handleUpdateStandingOffersCount) {
					props.handleUpdateStandingOffersCount(standingOffer.data.paginationData.totalRecords);
				}
			}

			setIsLoading(false);
		}
	}, [standingOffer]);

	const handlePaginationChange = (newPage) => {
		setData([]);
		loadData(newPage, resultsNum, sortType, keyword);
	};

	const handleSortChange = (newSort) => {
		setData([]);
		setSortType(newSort);
		loadData(1, resultsNum, newSort, keyword);
	};

	const handleResultsChange = (newNum) => {
		setData([]);
		setResultsNum(newNum);
		loadData(1, newNum, sortType, keyword);
	};

	return (
		<TableLayout
			data={ data }
			noDataMessage={ noDataMessage }
			allCount={ allCount }
			sortType={ sortType }
			paginationData={ paginationData }
			isLoading={ isLoading }
			isVisible={ props.isVisible }
			isSearching={ props.isSearching }
			onPaginationChange={ handlePaginationChange }
			onSortChange={ handleSortChange }
			onResultsChange={ handleResultsChange }
		/>
	);
}

export default StandingOffersResults;
