export const tenderConstants = {
	TENDERS_REQUEST: 'TENDERS_REQUEST',
	TENDERS_SUCCESS: 'TENDERS_SUCCESS',
	TENDERS_ERROR: 'TENDERS_ERROR',
	TENDERS_WATCHLIST_REQUEST: 'TENDERS_WATCHLIST_REQUEST',
	TENDERS_WATCHLIST_SUCCESS: 'TENDERS_WATCHLIST_SUCCESS',
	TENDERS_WATCHLIST_ERROR: 'TENDERS_WATCHLIST_ERROR',
	TENDERS_POST_REQUEST: 'TENDERS_POST_REQUEST',
	TENDERS_POST_SUCCESS: 'TENDERS_POST_SUCCESS',
	TENDERS_POST_ERROR: 'TENDERS_POST_ERROR',
	TENDERS_POST_RESET: 'TENDERS_POST_RESET',
	TENDERS_WATCHLIST_POST_REQUEST: 'TENDERS_WATCHLIST_POST_REQUEST',
	TENDERS_WATCHLIST_POST_SUCCESS: 'TENDERS_WATCHLIST_POST_SUCCESS',
	TENDERS_WATCHLIST_POST_ERROR: 'TENDERS_WATCHLIST_POST_ERROR',
	TENDERS_WATCHLIST_POST_RESET: 'TENDERS_WATCHLIST_POST_RESET',
	CURRENT_TENDER_UPDATE: 'CURRENT_TENDER_UPDATE',
	CURRENT_TENDER_RESET: 'CURRENT_TENDER_RESET'
};
