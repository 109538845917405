import React, {useEffect, useRef, useState} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { userActions } from '../../../store/actions/userActions';
import { getSimplifiedDate } from '../../../helpers/dateHelpers';
import { formatFilterData } from '../../../helpers/miscHelpers';
import { isPseManager } from '../../../helpers/userHelpers';

import {Row, Col, Table, Spinner, Button, Form} from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import UserForm from './UserForm';
import {error, success} from '../../../store/actions/globalMessagesActions';
import Pagination from '../../misc/Pagination';

function UsersTable({ status, isActive }) {
	const {
		BLANK_VALUE,
		LANGUAGE_ENGLISH,
		ROLE_MAS,
		ROLE_NAME_MAPPING,
		ROLE_NONE,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		USERS_PAGINATION_LIMIT,
		SORT_DATE_CREATED_ASC,
		SORT_DATE_CREATED_DESC,
		SORT_DATE_MODIFIED_ASC,
		SORT_DATE_MODIFIED_DESC,
		USER_STATUS_PENDING,
		USER_STATUS_INACTIVE
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	const statusName = (status) ? status.toLowerCase() : 'users';

	// State data
	const userData = useSelector(state => state.userData);
	const authenticate = useSelector(state => state.authenticate);
	const getUsers = useSelector(state => state.getUsers, shallowEqual);
	const postUser = useSelector(state => state.postUser);
	const removeUser = useSelector(state => state.deleteUser);
	const authorizeUser = useSelector(state => state.authorizeUser);
	const search = useSelector(state => state.search);
	const userEditing = useSelector(state => state.userEditing);

	const language = LANGUAGE_ENGLISH;
	const [users, setUsers] = useState([]);
	const [keyword, setKeyword] = useState(null);
	const [filterData, setFilterData] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [sortType, setSortType] = useState(SORT_DATE_CREATED_DESC);
	const [resultsNum, setResultsNum] = useState(USERS_PAGINATION_LIMIT);
	const [paginationData, setPaginationData] = useState({});
	const [isUsersLoading, setIsUsersLoading] = useState(false);
	const [noUsersMessage, setNoUsersMessage] = useState('\u00A0');
	const [currentUser, setCurrentUser] = useState(false);

	const usersRef = useRef(null);

	const handleEditUser = (user) => {
		setCurrentUser(user);
		dispatch(userActions.userIsEditing(true));
	};

	const handleActivateUser = (user) => {
		if (user.id) {
			if (user.roleData && (user.roleData !== ROLE_NONE || user.id === 1) && (user.procurementEntityName || (user.roleData !== ROLE_MAS && user.roleData !== ROLE_PROCUREMENT_REGULAR && user.roleData !== ROLE_PROCUREMENT_INTERNAL))) {
				const confirmChange = confirm('Are you sure you would like to activate ' + user.firstName.trim() + ' ' + user.lastName.trim() + '?'); // eslint-disable-line no-alert

				if (!confirmChange) {
					return false;
				}

				const data = {
					'userId': user.id ? user.id : null,
					'role': user.roleData ? user.roleData : null,
					'approvalType': 'APPROVE'
				};
				dispatch(userActions.authorizeUser(data, 'APPROVE'));
			} else {
				setCurrentUser(user);
				dispatch(userActions.userIsEditing(true));
			}
		}
	};

	const handleDenyUser = (user) => {
		if (user.id) {
			const confirmChange = confirm('Are you sure you would like to deny ' + user.firstName.trim() + ' ' + user.lastName.trim() + '?'); // eslint-disable-line no-alert

			if (!confirmChange) {
				return false;
			}

			const data = {
				'userId': user.id ? user.id : null,
				'role': user.roleData ? user.roleData : null,
				'approvalType': 'DENY'
			};
			dispatch(userActions.authorizeUser(data, 'DENY'));
		}
	};

	const handleDeleteUser = (user) => {
		const confirmChange = confirm('Are you sure you would like to remove ' + user.firstName.trim() + ' ' + user.lastName.trim() + '?'); // eslint-disable-line no-alert

		if (!confirmChange) {
			return false;
		}

		dispatch(userActions.deleteUser(user));
		setCurrentUser(false);
	};

	const handleCloseForm = () => {
		setCurrentUser(false);
		dispatch(userActions.userIsEditing(false));
	};

	// Getting user data.
	const loadData = (keyword, sortType, currentPage, resultsNum, filterData) => {
		const filters = formatFilterData(filterData);

		setUsers([]);
		setIsUsersLoading(true);
		dispatch(userActions.getUsers({
			language: language,
			keyword: keyword,
			sortType: sortType,
			page: currentPage,
			numberOfRecords: resultsNum,
			admin: true,
			userStatus: (status) ? status : null
		}, filters));
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSortChange = (newSort) => {
		setCurrentPage(1);
		setSortType(newSort);
	};

	const handleResultsChange = (newNum) => {
		setResultsNum(newNum);
	};

	useEffect(() => {
		if (!isActive) {
			setCurrentUser(false);
			dispatch(userActions.userIsEditing(false));
		}
	}, [isActive]);

	useEffect(() => {
		if (authenticate && authenticate.success) {
			loadData(keyword, sortType, currentPage, resultsNum, filterData);
		}
	}, [currentPage, authenticate, sortType, resultsNum]);

	// Load on search.
	useEffect(() => {
		if (search.updated && search.category == 'users') {
			setUsers([]);
			setCurrentPage(1);

			if (search.fields) {
				if (search.fields.keyword || search.filters) {
					let keywordValue, filterDataValue;

					if (search.fields.keyword) {
						keywordValue = search.fields.keyword;
						setKeyword(keywordValue);
					} else {
						setKeyword(null);
					}

					if (search.filters && typeof search.filters === 'object') {
						filterDataValue = search.filters;
						setFilterData(filterDataValue);
					} else {
						setFilterData(null);
					}

					loadData(keywordValue, sortType, currentPage, resultsNum, filterDataValue);
				} else {
					setKeyword(null);
					setFilterData(null);
					loadData(null, sortType, 1, resultsNum);
				}
			} else {
				setKeyword(null);
				setFilterData(null);
				loadData(null, sortType, 1, resultsNum);
			}
		} else {
			setKeyword(null);
			setFilterData(null);
		}
	}, [search]);

	// Listening to users state.
	useEffect(() => {
		if (getUsers.request) {
			setNoUsersMessage('\u00A0');
		} else if (getUsers.error) {
			setNoUsersMessage('Error loading users');
			setIsUsersLoading(false);
		} else {
			setNoUsersMessage('No users found');
		}

		if (getUsers.success && getUsers[statusName]) {
			if (getUsers[statusName].userDataList && getUsers[statusName].userDataList.length) {
				setUsers(getUsers[statusName].userDataList);
				setPaginationData(getUsers[statusName].paginationData);
			} else {
				setUsers([]);
				setPaginationData(false);
				setNoUsersMessage('No users found');
			}

			setIsUsersLoading(false);
		}
	}, [getUsers]);

	// Contact updated changes.
	useEffect(() => {
		const message = authorizeUser.callback && authorizeUser.callback === 'DENY' ? 'deactivated' : 'activated';
		if (authorizeUser.success && authorizeUser.data) {
			dispatch(success('User successfully ' + message + '.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
			setIsUsersLoading(true);
			setCurrentPage(1);
			loadData(keyword, sortType, 1, resultsNum, filterData);
		} else if (authorizeUser.error || (authorizeUser.success && !authorizeUser.data)) {
			dispatch(error('Error: User could not be ' + message + '. Please try again later.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
		}
	}, [authorizeUser]);

	// Contact updated changes.
	useEffect(() => {
		if (postUser.success) {
			dispatch(success('User successfully updated.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
			setIsUsersLoading(true);
			setCurrentPage(1);
			loadData(keyword, sortType, 1, resultsNum, filterData);
		} else if (postUser.error) {
			dispatch(error('Error: User could not be updated. Please try again later.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
		}
	}, [postUser]);

	// Contact removed changes.
	useEffect(() => {
		if (removeUser.success) {
			dispatch(success('User successfully removed.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
			setIsUsersLoading(true);
			setCurrentPage(1);
			loadData(keyword, sortType, 1, resultsNum, filterData);
		} else if (removeUser.error) {
			dispatch(error('Error: User could not be removed. Please try again later.', null, () => { (usersRef.current) ? usersRef.current.focus() : null; }));
		}
	}, [removeUser]);

	return (
		<>
			{ userEditing &&
				<UserForm handleCloseForm={handleCloseForm} user={currentUser} />
			}

			{ !userEditing &&
				<>
					<Row className={'mt-4'}>
						{ !isPseManager(userData) &&
							<Col className="text-md-right">
								<label className="sort-control mt-0">
									<span><Translate text="Sort" /></span>
									<Form.Control
										as="select"
										value={ sortType }
										onChange={(e) => { handleSortChange(e.target.value); }}
									>
										<Translator>
											{({ translate }) => (
												<>
													<option value={ SORT_DATE_CREATED_ASC }>
														{ translate({ text: 'Date Created (ASC)' }) }
													</option>
													<option value={ SORT_DATE_CREATED_DESC }>
														{ translate({ text: 'Date Created (DESC)' }) }
													</option>
													<option value={ SORT_DATE_MODIFIED_ASC }>
														{ translate({ text: 'Date Modified (ASC)' }) }
													</option>
													<option value={ SORT_DATE_MODIFIED_DESC }>
														{ translate({ text: 'Date Modified (DESC)' }) }
													</option>
												</>
											)}
										</Translator>
									</Form.Control>
								</label>
							</Col>
						}
					</Row>

					<Row className={'mt-0'}>
						<Col>
							{isUsersLoading &&
								<div className="text-center m-3">
									<Spinner animation="border" role="status" className="loader">
										<span className="sr-only"><Translate text={'Loading'}/></span>
									</Spinner>
								</div>
							}

							{(!isUsersLoading && users && users.length > 0) &&
								<>
									<Table bordered hover responsive ref={usersRef} className={'users-table'}>
										<thead>
											<tr>
												<th><Translate text={'Name'}/></th>
												<th><Translate text={'Email Address'}/></th>
												<th><Translate text={'Organization'}/></th>

												{ !isPseManager(userData) &&
													<th><Translate text={'End User Entity'}/></th>
												}

												<th><Translate text={'User Role'}/></th>

												{ !isPseManager(userData) &&
													<>
														<th><Translate text={'Date Created'}/></th>
														<th><Translate text={'Date Modified'}/></th>
													</>
												}

												<th hidden><Translate text={'Controls'}/></th>
											</tr>
										</thead>

										{users &&
											<tbody>
												{
													users.map((user, index) => {
														return (
															<tr key={index}>
																<td>{user.firstName + ' ' + user.lastName}</td>
																<td>{(user.emailAddress) ? user.emailAddress : BLANK_VALUE}</td>
																<td>{(user.procurementEntityName) ? user.procurementEntityName : BLANK_VALUE}</td>

																{ !isPseManager(userData) &&
																	<td>{(user.endUserEntity) ? user.endUserEntity : BLANK_VALUE}</td>
																}

																<td>{(user.roleData && ROLE_NAME_MAPPING[user.roleData] && (user.roleData !== ROLE_NONE || user.id === 1)) ? ROLE_NAME_MAPPING[user.roleData] : BLANK_VALUE}</td>

																{ !isPseManager(userData) &&
																	<>
																		<td>{(user.createdDate) ? getSimplifiedDate(user.createdDate) : BLANK_VALUE}</td>
																		<td>{(user.modifiedDate) ? getSimplifiedDate(user.modifiedDate) : BLANK_VALUE}</td>
																	</>
																}

																<td className={'row-actions'}>
																	<div className={'row-actions-buttons'}>
																		<div>
																			<Button variant="link" className={'mb-0'} onClick={() => handleEditUser(user)}>
																				<FontAwesomeIcon icon={faPencilAlt}/>
																				<span className="sr-only"><Translate text={'Edit'}/></span>
																			</Button>

																			{ (status == USER_STATUS_INACTIVE) &&
																				<Button variant="link" className={'mb-0'} onClick={() => handleDeleteUser(user)}>
																					<FontAwesomeIcon icon={faTimesCircle}/>
																					<span className="sr-only"><Translate text={'Remove'}/></span>
																				</Button>
																			}
																		</div>

																		{ (status == USER_STATUS_PENDING) &&
																			<div>
																				<Button size={'sm'} onClick={() => handleActivateUser(user)}><Translate text={'Activate'} /></Button>
																				<Button size={'sm'} variant={'danger'} onClick={() => handleDenyUser(user)}><Translate text={'Deny'} /></Button>
																			</div>
																		}
																	</div>
																</td>
															</tr>
														);
													})
												}
											</tbody>
										}
									</Table>

									<Row className="align-items-center">
										{ paginationData &&
											<Col md={6} className="text-center text-md-left">
												<Pagination
													data={ paginationData }
													handlePaginationChange={handlePageChange}
												/>
											</Col>
										}

										<Col md={(paginationData) ? 6 : 12} className="text-center text-md-right">
											<label className="sort-control">
												<span><Translate text="Results" /></span>
												<Form.Control
													as="select"
													value={ resultsNum }
													onChange={(e) => { handleResultsChange(e.target.value); }}
												>
													<Translator>
														{({ translate }) => (
															<>
																<option value={USERS_PAGINATION_LIMIT}>
																	{ translate({ text: `${USERS_PAGINATION_LIMIT}` }) }
																</option>
																<option value={15}>
																	{ translate({ text: '15' }) }
																</option>
																<option value={25}>
																	{ translate({ text: '25' }) }
																</option>
																<option value={50}>
																	{ translate({ text: '50' }) }
																</option>
															</>
														)}
													</Translator>
												</Form.Control>
											</label>
										</Col>
									</Row>
								</>
							}

							{!isUsersLoading && noUsersMessage && (!users || users.length <= 0) &&
								<div className={'alerts-container'}>
									<Alert variant="light">
										<span className="alert-message"><Translate text={noUsersMessage} /></span>
									</Alert>
								</div>
							}
						</Col>
					</Row>
				</>
			}
		</>
	);
}

export default UsersTable;
