/* eslint-disable no-unused-vars */

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Translate} from 'react-translated';
import ListGroup from 'react-bootstrap/ListGroup';
import React from 'react';

export const resolvers = {
	standingOffer
};

function standingOffer(req, res, ctx) {
	// GET
	if (req.method === 'GET') {
		return res(
			ctx.status(200),
			ctx.json({
				'paginationData': {
					'currentPage': 1,
					'numberOfRecords': 3,
					'totalRecords': 3
				},
				'standingOfferDataList': [
					{
						'errorMessage': 'string',
						'errorCode': 'string',
						'changeType': 'SAVE',
						'id': 1,
						'contractName': 'CBRM T70-2020',
						'contractEffectiveDate': '11 Mar 2021',
						'contractExpirationDate': '30 Mar 2022',
						'keywords': 'string',
						'unspsc': 'string',
						'contractId': 'string',
						'orgOfOwnership': 'Cape Breton Regional Municipality',
						'procurementContract': 'Supply Fountian/Aerator - John Bernard Croak Memorial Park',
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'string',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'string',
								'deleted': true,
								'entityId': 0
							}
						]
					},
					{
						'errorMessage': 'string',
						'errorCode': 'string',
						'changeType': 'SAVE',
						'id': 2,
						'contractName': 'CBRM T70-2020',
						'contractEffectiveDate': '11 Mar 2021',
						'contractExpirationDate': '30 Mar 2022',
						'keywords': 'string',
						'unspsc': 'string',
						'contractId': 'string',
						'orgOfOwnership': 'Cape Breton Regional Municipality',
						'procurementContract': 'Supply Fountian/Aerator - John Bernard Croak Memorial Park',
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'string',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'string',
								'deleted': true,
								'entityId': 0
							}
						]
					},
					{
						'errorMessage': 'string',
						'errorCode': 'string',
						'changeType': 'SAVE',
						'id': 3,
						'contractName': 'HRM T90-2021',
						'contractEffectiveDate': '13 Feb 2022',
						'contractExpirationDate': '30 Mar 2022',
						'keywords': 'string',
						'unspsc': 'string',
						'contractId': 'string',
						'orgOfOwnership': 'Halifax Regional Municipality',
						'procurementContract': 'Supply Bench - Public Gardens',
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'string',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'string',
								'deleted': true,
								'entityId': 0
							}
						]
					}
				]
			})
		);
	}
}
