/* eslint-disable id-length */
import React, {useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {Translate, Translator} from 'react-translated';
import {parseISO} from 'date-fns';

import {getSimplifiedDate} from '../../helpers/dateHelpers';
import {numValidate, cleanAmountValue} from '../../helpers/miscHelpers';

import Form from 'react-bootstrap/Form';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Tooltip from '../misc/Tooltip';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import FormDatePicker from '../forms/FormDatePicker';
import {
	AWARD_MAX_QUANTITY,
	INPUT_MAX_LENGTH,
	TEXTAREA_MAX_LENGTH,
	TENDER_STATUS_CANCELLED
} from '../../config/constants';
import {Button} from 'react-bootstrap';

class DateEditor extends React.Component {
	constructor(props) {
		super(props);
		this.ref = React.createRef();
	}

	getValue() {
		return typeof this.value === 'undefined' ? '' : this.value;
	}

	render() {
		const { value, onUpdate, onBlur, closingDate } = this.props;
		this.value = value;
		return (
			<Form.Group>
				<FormDatePicker
					refValue={this.ref}
					value={value}
					handleOnChange={(value) => { this.value = value; onUpdate(this.getValue()); } }
					handleOnBlur={() => { onBlur(); }}
					alwaysBlur
					autoFocus
					size="sm"
					minDate={ (closingDate) ? parseISO(closingDate) : new Date() }
				/>
			</Form.Group>
		);
	}
}

function TableLayout(props) {
	const {
		data,
		updateData,
		updateRow
	} = props;
	const {
		BLANK_VALUE
	} = require('../../config/constants');
	const tableRef = useRef(null);
	const referenceData = useSelector(state => state.referenceData);
	const currentTender = useSelector(state => state.currentTender);
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [tableKey, setTableKey] = useState(1);

	// Reference data
	useEffect(() => {
		if (referenceData.success && referenceData.data && referenceData.data.currencyDataList) {
			setCurrencyOptions([{ value: '', label: '- Select -'}, ...referenceData.data.currencyDataList.map(currency => ({ value: currency.name, label: currency.name }))]);
		}
	}, [referenceData]);

	const handleRemoveRow = (index) => {
		let updatedData = JSON.parse(JSON.stringify(data));
		if (updatedData[index || 0] && updatedData[index || 0].id) {
			updatedData[index || 0].changeType = 'DELETE';
		} else {
			updatedData = updatedData.filter((row) => row.index !== index);
		}
		updatedData = updatedData.map((row, index) => { row.index = index || 0; return row; });
		updateData(updatedData);
	};

	/**
	 * Table config
	 */

	// Custom table headers.
	const headerFormatter = (column, colIndex, components) => {
		return (
			<>
				<Translate text={ column.text } />
				{column && column.tooltip &&
					<Tooltip text={column.tooltip}/>
				}
				{ components.sortElement }
				{ components.filterElement }
			</>
		);
	};

	// Table columns.
	const tableColumns = [
		{
			dataField: 'id',
			text: 'ID',
			isDummyField: true,
			headerFormatter: headerFormatter,
			headerAttrs: {
				hidden: true
			},
			classes: 'd-none'
		},
		{
			dataField: 'supplier',
			text: 'Supplier',
			headerFormatter: headerFormatter,
			headerClasses: 'supplier-col',
			classes: 'supplier-col',
			editCellClasses: 'supplier-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			tooltip: 'Enter supplier name as entered on contract documents',
			formatter: (cell, row) => { return row['supplier'] || BLANK_VALUE; },
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editor: {
				type: Type.TEXT,
				maxLength: INPUT_MAX_LENGTH,
				required: true
			}
		},
		{
			dataField: 'supplierLocation',
			text: 'Supplier Location',
			headerFormatter: headerFormatter,
			headerClasses: 'supplier-location-col',
			classes: 'supplier-location-col',
			editCellClasses: 'supplier-location-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			tooltip: 'Add supplier location (City, Province/State, and Country) Example: Halifax, NS, CA',
			formatter: (cell, row) => { return row['supplierLocation'] || BLANK_VALUE; },
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editor: {
				type: Type.TEXT,
				maxLength: INPUT_MAX_LENGTH,
				required: true
			}
		},
		{
			dataField: 'awardAmount',
			text: 'Award Amount',
			headerFormatter: headerFormatter,
			headerClasses: 'award-amount-col',
			classes: 'award-amount-col',
			editCellClasses: 'award-amount-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			tooltip: 'Enter amount awarded to the specific supplier',
			formatter: (cell, row) => {
				const currency = referenceData && referenceData.data && referenceData.data.currencyDataList ? referenceData.data.currencyDataList.find(item => item.name === row['currency']) : false;
				const formatter = new Intl.NumberFormat(document.documentElement.getAttribute('lang') || 'en', {
					style: 'currency',
					currency: !currency || !currency.code || currency.code === 'CAD' ? 'USD' : currency.code
				});
				return !isNaN(row['awardAmount']) ? formatter.format(row['awardAmount']) : BLANK_VALUE;
			},
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editor: {
				maxLength: 15,
				pattern: '[0-9]+',
				inputMode: 'decimal',
				onKeyPress: (e) => {
					numValidate(e);
				},
				onPaste: (e) => {
					numValidate(e);
					let el = e.target;

					window.setTimeout(function() {
						el.value = cleanAmountValue(el.value);
					}, 0);
				},
				required: true
			}
		},
		{
			dataField: 'currency',
			text: 'Currency',
			headerFormatter: headerFormatter,
			headerClasses: 'currency-col',
			classes: 'currency-col',
			editCellClasses: 'currency-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editor: {
				type: Type.SELECT,
				required: true,
				options: currencyOptions
			},
			formatter: (cell, row) => { const currency = referenceData && referenceData.data && referenceData.data.currencyDataList ? referenceData.data.currencyDataList.find(item => item.name === row['currency']) : false; return currency && currency.code ? currency.code : BLANK_VALUE; }
		},
		{
			dataField: 'awardDate',
			text: 'Award Date',
			headerFormatter: headerFormatter,
			headerClasses: 'award-date-col',
			classes: 'award-date-col',
			editCellClasses: 'award-date-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			tooltip: 'Enter the date the contract  comes into effect.',
			formatter: (cell, row) => { return getSimplifiedDate(row['awardDate']) || BLANK_VALUE; },
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editorRenderer: function editor(editorProps, value) {
				return (
					<DateEditor {...editorProps} closingDate={currentTender.closingDate} value={value}/>
				);
			}
		},
		{
			dataField: 'additionalDetails',
			text: 'Additional Details',
			headerFormatter: headerFormatter,
			headerClasses: 'additional-details-col',
			classes: 'additional-details-col',
			editCellClasses: 'additional-details-col',
			editorClasses: 'form-control--small',
			attrs: {
				tabIndex: '0'
			},
			tooltip: 'Enter details that assists in identifying the breakdown of award (i.e. region, sevice location, trade or commodity type, etc.)',
			events: {
				onFocus: (e) => {
					if (e.target) {
						e.target.click();
					}
				}
			},
			editor: {
				type: Type.TEXTAREA,
				maxLength: TEXTAREA_MAX_LENGTH
			},
			formatter: (cell, row) => { return row['additionalDetails'] || BLANK_VALUE; }
		},
		{
			text: 'Controls',
			dataField: 'index',
			isDummyField: true,
			headerFormatter: headerFormatter,
			headerAttrs: {
				hidden: true
			},
			editable: false,
			formatter: function DeleteButton(cell, row) {
				return (
					<Button
						variant="link"
						aria-label="Remove award"
						className="text-danger m-0"
						onClick={(e) => {
							e.preventDefault();
							handleRemoveRow(row.index);
						}}>
						<Translate text={'Remove'} />
					</Button>
				);
			}
		}
	];

	const cellEditOptions = {
		mode: 'click',
		blurToSave: true,
		beforeSaveCell: (oldValue, newValue, row, column) => {
			if (column.dataField === 'currency' && newValue !== oldValue) {
				setTableKey(tableKey + 1);
			}
		}
	};

	if (typeof updateRow === 'function') {
		cellEditOptions.afterSaveCell = updateRow;
	}

	const cellEdit = cellEditFactory(cellEditOptions);

	return (
		<ToolkitProvider keyField="index" data={ data.filter(item => item.changeType !== 'DELETE') } columns={ tableColumns }>
			{props => (
				<>
					<div className="award-data">
						<div className="table-responsive has-overflow">
							<BootstrapTable
								{ ...props.baseProps }
								key={'table-' + tableKey}
								bootstrap4
								hover
								ref={ tableRef }
								wrapperClasses="data-table award-table"
								cellEdit={ cellEdit }
							/>
						</div>
					</div>
				</>
			)}
		</ToolkitProvider>
	);
}

function AwardTable(props, ref) {
	const {data, handleSave} = props;
	const validationRef = useRef();
	const wrapperRef = useRef();
	const [errorMessage, setErrorMessage] = useState('Please populate all rows');

	const currentTender = useSelector(state => state.currentTender);

	useImperativeHandle(ref, () => ({
		setCustomValidity: (message) => {
			validationRef.current.setCustomValidity(message);
		},
		get input() {
			return validationRef.current;
		}
	}));

	useEffect(() => {
		let valid = true;
		const missingFields = [];
		data.forEach(item => {
			if (!item.supplier) {
				valid = false;
				if (!missingFields.includes('supplier')) {
					missingFields.push('supplier');
				}
			}

			if (!item.supplierLocation) {
				valid = false;
				if (!missingFields.includes('supplier location')) {
					missingFields.push('supplier location');
				}
			}

			if (!item.awardDate) {
				valid = false;
				if (!missingFields.includes('award date')) {
					missingFields.push('award date');
				}
			}

			if (!item.awardAmount) {
				valid = false;
				if (!missingFields.includes('award amount')) {
					missingFields.push('award amount');
				}
			}

			if (!item.currency) {
				valid = false;
				if (!missingFields.includes('currency')) {
					missingFields.push('currency');
				}
			}
		});

		if (valid) {
			validationRef.current.setCustomValidity('');
		} else {
			let message = 'Please populate all rows';
			if (missingFields.length > 0) {
				let article = 'a';
				if (missingFields[0].match(/^[aieouAIEOU].*/)) {
					article = 'an';
				}

				let separator = ' and ';
				if (missingFields.length === 1) {
					separator = ' ';
				} else if (missingFields.length > 2) {
					separator = ', and ';
				}
				const lastField = missingFields.pop();
				const fieldMessage = missingFields.join(', ') + separator + lastField;
				message = 'Please enter ' + article + ' ' + fieldMessage + ' for all rows';
			}

			setErrorMessage(message);
			validationRef.current.setCustomValidity(message);
		}
	}, [data]);

	const validate = () => {
		let valid = true;
		const missingFields = [];
		data.forEach(item => {
			if (!item.supplier) {
				valid = false;
				if (!missingFields.includes('supplier')) {
					missingFields.push('supplier');
				}
			}

			if (!item.supplierLocation) {
				valid = false;
				if (!missingFields.includes('supplier location')) {
					missingFields.push('supplier location');
				}
			}

			if (!item.awardDate) {
				valid = false;
				if (!missingFields.includes('award date')) {
					missingFields.push('award date');
				}
			}

			if (!item.awardAmount) {
				valid = false;
				if (!missingFields.includes('award amount')) {
					missingFields.push('award amount');
				}
			}

			if (!item.currency) {
				valid = false;
				if (!missingFields.includes('currency')) {
					missingFields.push('currency');
				}
			}
		});

		if (valid) {
			validationRef.current.setCustomValidity('');
		} else {
			let message = 'Please populate all rows';
			if (missingFields.length > 0) {
				let article = 'a';
				if (missingFields[0].match(/^[aieouAIEOU].*/)) {
					article = 'an';
				}

				let separator = ' and ';
				if (missingFields.length === 1) {
					separator = ' ';
				} else if (missingFields.length > 2) {
					separator = ', and ';
				}
				const lastField = missingFields.pop();
				const fieldMessage = missingFields.join(', ') + separator + lastField;
				message = 'Please enter ' + article + ' ' + fieldMessage + ' for all rows';
			}

			setErrorMessage(message);
			validationRef.current.setCustomValidity(message);
		}
	};

	const handleChange = (name, index, value) => {
		const dataUpdate = JSON.parse(JSON.stringify(data));
		if (!dataUpdate[index || 0]) {
			dataUpdate[index || 0] = {
				'index': index || 0,
				'id': null,
				'supplier': '',
				'supplierLocation': '',
				'awardAmount': '',
				'currency': 'Canadian Dollar',
				'awardDate': '',
				'additionalDetails': '',
				'changeType': 'SAVE'
			};
		}

		dataUpdate[index || 0]['changeType'] = 'SAVE';
		dataUpdate[index || 0][name] = (typeof value === 'undefined') ? '' : value;

		let valid = true;
		const missingFields = [];
		dataUpdate.forEach(item => {
			if (!item.supplier) {
				valid = false;
				if (!missingFields.includes('supplier')) {
					missingFields.push('supplier');
				}
			}

			if (!item.supplierLocation) {
				valid = false;
				if (!missingFields.includes('supplier location')) {
					missingFields.push('supplier location');
				}
			}

			if (!item.awardDate) {
				valid = false;
				if (!missingFields.includes('award date')) {
					missingFields.push('award date');
				}
			}

			if (!item.awardAmount) {
				valid = false;
				if (!missingFields.includes('award amount')) {
					missingFields.push('award amount');
				}
			}

			if (!item.currency) {
				valid = false;
				if (!missingFields.includes('currency')) {
					missingFields.push('currency');
				}
			}
		});

		if (valid) {
			validationRef.current.setCustomValidity('');
		} else {
			let message = 'Please populate all rows';
			if (missingFields.length > 0) {
				let article = 'a';
				if (missingFields[0].match(/^[aieouAIEOU].*/)) {
					article = 'an';
				}

				let separator = ' and ';
				if (missingFields.length === 1) {
					separator = ' ';
				} else if (missingFields.length > 2) {
					separator = ', and ';
				}
				const lastField = missingFields.pop();
				const fieldMessage = missingFields.join(', ') + separator + lastField;
				message = 'Please enter ' + article + ' ' + fieldMessage + ' for all rows';
			}

			setErrorMessage(message);
			validationRef.current.setCustomValidity(message);
		}
		if (handleSave && typeof handleSave === 'function') {
			handleSave(dataUpdate);
		}
	};

	return (
		<div className="award-data-wrapper" ref={ wrapperRef }>
			<TableLayout
				data={ data.map((row, index) => { row.index = index || 0; return row; }) }
				noDataMessage={'\u00A0'}
				handleChange={ handleChange }
				updateData={ handleSave }
				updateRow={ validate }
			/>

			{ (currentTender.tenderStatus !== TENDER_STATUS_CANCELLED && data && data.length < AWARD_MAX_QUANTITY) &&
				<div className="repeater-controls text-right">
					<Translator>
						{({ translate }) => (
							<Button
								onClick={() => { handleChange('index', data.length, data.length); wrapperRef.current.focus(); }}
								aria-label={
									translate({
										text: 'Add Another - Award'
									})
								}
							>
								<Translate text={'Add Another'} />
							</Button>
						)}
					</Translator>
				</div>
			}

			<Form.Group controlId="awardDetails">
				<Form.Control
					className={'d-none'}
					name="awardDetails"
					ref={validationRef}
				/>
				<Form.Control.Feedback type="invalid">
					<Translate text={errorMessage}/>
				</Form.Control.Feedback>
			</Form.Group>
		</div>
	);
}

export { AwardTable };
