import { userConstants } from '../constants/userConstants';
import { userService } from '../../services/userService';

export const userActions = {
	getUser,
	getUsers,
	getUserRpid,
	resetUserRpid,
	updateUser,
	activateUser,
	authorizeUser,
	deleteUser,
	resetPostUser,
	unauthenticatedUser,
	userIsEditing
};

function getUser(id) {
	return dispatch => {
		dispatch(request());

		userService.get({id: id})
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						if (response.userDataList && Array.isArray(response.userDataList) && response.userDataList.length) {
							dispatch(success(response.userDataList[0]));
						} else {
							dispatch(success({}));
						}
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_SINGLE_GET_REQUEST }; }

	function success(data) { return { type: userConstants.USER_SINGLE_GET_SUCCESS, user: data }; }

	function error() { return { type: userConstants.USER_SINGLE_GET_ERROR }; }
}

function getUsers(data, filters) {
	return dispatch => {
		dispatch(request());

		const status = (data.userStatus) ? data.userStatus.toLowerCase() : 'users';

		userService.get(data, filters)
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						dispatch(success(response, status));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_GET_REQUEST }; }

	function success(data, status) { return { type: userConstants.USER_GET_SUCCESS, users: data, status: status }; }

	function error() { return { type: userConstants.USER_GET_ERROR }; }
}

function getUserRpid(rpid, callback) {
	return dispatch => {
		dispatch(request());

		userService.getRpid(rpid)
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_GET_RPID_REQUEST }; }

	function success(data) { return { type: userConstants.USER_GET_RPID_SUCCESS, user: data, callback: callback }; }

	function error() { return { type: userConstants.USER_GET_RPID_ERROR }; }
}

function resetUserRpid() {
	return {
		type: userConstants.USER_GET_RPID_RESET
	};
}

function updateUser(data, callback) {
	return dispatch => {
		dispatch(request());

		userService.post(data)
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_POST_REQUEST }; }

	function success(data) { return { type: userConstants.USER_POST_SUCCESS, users: data, callback: callback }; }

	function error() { return { type: userConstants.USER_POST_ERROR, callback: callback }; }
}

function activateUser(data, callback) {
	return dispatch => {
		dispatch(request());

		userService.activate(data)
			.then(
				response => {
					if (response) {
						dispatch(success(response, callback));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_ACTIVATE_REQUEST }; }

	function success(data) { return { type: userConstants.USER_ACTIVATE_SUCCESS, data: data, callback: callback }; }

	function error() { return { type: userConstants.USER_ACTIVATE_ERROR, callback: callback }; }
}

function authorizeUser(data, callback) {
	return dispatch => {
		dispatch(request());

		userService.authorize(data)
			.then(
				response => {
					if (response) {
						dispatch(success(response, callback));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_AUTHORIZE_REQUEST }; }

	function success(data) { return { type: userConstants.USER_AUTHORIZE_SUCCESS, data: data, callback: callback }; }

	function error() { return { type: userConstants.USER_AUTHORIZE_ERROR, callback: callback }; }
}

function deleteUser(data) {
	return dispatch => {
		dispatch(request());

		userService.remove(data)
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: userConstants.USER_DELETE_REQUEST }; }

	function success(data) { return { type: userConstants.USER_DELETE_SUCCESS, users: data }; }

	function error() { return { type: userConstants.USER_DELETE_ERROR }; }
}

function resetPostUser() {
	return {
		type: userConstants.USER_POST_RESET
	};
}

function unauthenticatedUser() {
	return {
		type: userConstants.USER_UNAUTHENTICATED
	};
}

function userIsEditing(isEditing) {
	return {
		type: userConstants.USER_IS_EDITING,
		isEditing: isEditing
	};
}
