import React, { useState, useEffect } from 'react';
import { Translate } from 'react-translated';

import {useSelector} from 'react-redux';
import {isRole} from '../../helpers/userHelpers';
import {ROLE_SUPPLIER} from '../../config/constants';

function TenderSidebar() {
	const { BLANK_VALUE } = require('../../config/constants');

	const [tradeAgreements, setTradeAgreements] = useState(null);
	const [watchList, setWatchList] = useState({});
	const [watchListLoading, setWatchListLoading] = useState({});

	const currentTender = useSelector(state => state.currentTender);
	const watchlist = useSelector(state => state.watchlist);
	const userData = useSelector(state => state.userData);

	useEffect(() => {
		if (currentTender.id) {
			if (Array.isArray(currentTender.tradeAgreement) && currentTender.tradeAgreement.length) {
				setTradeAgreements(currentTender.tradeAgreement);
			} else {
				setTradeAgreements(null);
			}
		}
	}, [currentTender]);

	useEffect(() => {
		if (watchlist.success && watchlist.data) {
			const list = {};
			watchlist.data.forEach((item) => {
				list[item.id] = true;
			});
			setWatchList(list);

			if (watchlist.callback) {
				const loadingList = {};
				Object.keys(watchListLoading).forEach((key) => {
					if (key !== watchlist.callback) {
						loadingList[key] = true;
					}
				});
				setWatchListLoading(loadingList);
			}
		}
	}, [watchlist]);

	if (currentTender.id) {
		return (
			<div className="tender-sidebar">
				<dl>
					{ isRole(userData, ROLE_SUPPLIER) &&
						<>
							<dt>
								<Translate text={'Watched'} />
							</dt>
							<dd>
								<Translate text={watchList && currentTender.id in watchList && watchList[currentTender.id] === true ? 'Yes' : 'No'} />
							</dd>
						</>
					}
					<dt>
						<Translate text={'Status'} />
					</dt>
					<dd className="large">
						{ currentTender.tenderStatus || BLANK_VALUE }
					</dd>
					<dt>
						<Translate text={'Posted Date'} />
					</dt>
					<dd>
						{ currentTender.postDate || BLANK_VALUE }
					</dd>
				</dl>
				<hr />
				<dl>
					<dt>
						<Translate text={'Procurement Method'} />
					</dt>
					<dd>
						{ currentTender.procurementMethod || BLANK_VALUE }
					</dd>
					<dt>
						<Translate text={'Solicitation Type'} />
					</dt>
					<dd>
						{ currentTender.solicitationType || BLANK_VALUE }
					</dd>
					<dt>
						<Translate text={'Trade Agreement'} />
					</dt>
					<dd>
						{ tradeAgreements && Array.isArray(tradeAgreements) && tradeAgreements.length > 0 &&
							tradeAgreements.map(item => item.description).join(', ')
						}

						{ (!tradeAgreements || !Array.isArray(tradeAgreements) || tradeAgreements.length <= 0) && BLANK_VALUE }
					</dd>
				</dl>
			</div>
		);
	}

	return null;
}

export default TenderSidebar;
