/* eslint-disable id-length */
import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import { useSelector } from 'react-redux';
import { format, parse, parseISO, isValid } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import {isRole} from '../../helpers/userHelpers';
import {ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER} from '../../config/constants';
import FormCustomSelect from '../forms/FormCustomSelect';
import FormDatePicker from '../forms/FormDatePicker';

function AltpFilters(props) {
	const userData = useSelector(state => state.userDataPersist);
	const [entityOptions, setEntityOptions] = useState([]);
	const [circumstanceOptions, setCircumstanceOptions] = useState([]);
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [mine, setMine] = useState((props.defaultMine) ? props.defaultMine : null);
	const [mineValue, setMineValue] = useState(null);
	const [myOrganization, setMyOrganization] = useState((props.defaultMyOrganization) ? props.defaultMyOrganization : null);
	const [filterData, setFilterData] = useState((props.defaultFilterData) ? props.defaultFilterData : {});
	const dateFormat = 'dd-MM-yyyy';

	const {
		procurementEntity,
		circumstance,
		categories,
		fromAwardDate,
		toAwardDate
	} = filterData;

	// State data.
	const referenceData = useSelector(state => state.referenceData);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (Array.isArray(referenceData.data.procurementEntityDataList)) {
				setEntityOptions(referenceData.data.procurementEntityDataList);
			}

			if (Array.isArray(referenceData.data.tenderCircumstanceDataList)) {
				setCircumstanceOptions(referenceData.data.tenderCircumstanceDataList.sort((a, b) => a.name.localeCompare(b.name)));
			}

			if (Array.isArray(referenceData.data.tenderCategoryDataList)) {
				let options = [];
				referenceData.data.tenderCategoryDataList.map(item => options.push({ value: item.name, label: item.description }));
				setCategoryOptions(options);
			}
		}
	}, [referenceData]);

	const handleChangeFilter = (event) => {
		const { name, value } = event.target;
		let val;

		if (value !== '') {
			val = value;
		} else {
			val = null;
		}

		setFilterData(data => ({...data, [name]: val }));
	};

	const handleChangeMine = (event) => {
		const value = event.target.value;

		setMineValue(value);

		if (value == 'me') {
			setMyOrganization(null);
			setMine(true);
			setFilterData(data => ({...data, procurementEntity: null }));
		} else if (value == 'organization') {
			setMyOrganization(true);
			setMine(null);
		} else {
			setMyOrganization(null);
			setMine(null);
		}
	};

	const handleChangeFilterMultiple = (values, name) => {
		let val = [];

		if (Array.isArray(values)) {
			values.map(item => val.push(item.value));
		} else {
			val = null;
		}

		setFilterData(data => ({...data, [name]: val }));
	};

	const handleChangeDateFilter = (name, value) => {
		let formattedVal;
		const parsedVal = parseISO(value);

		if (isValid(parsedVal)) {
			formattedVal = format(parsedVal, dateFormat);
		}

		setFilterData(data => ({ ...data, [name]: formattedVal }));
	};

	const handleSearch = (event) => {
		event.preventDefault();
		let updatedFilterData = filterData;

		if (fromAwardDate && !toAwardDate) {
			updatedFilterData = {
				...updatedFilterData,
				toAwardDate: format(new Date(), dateFormat)
			};
		}

		setFilterData(updatedFilterData);

		if (props.handleFilter) {
			props.handleFilter(updatedFilterData, mine, null, myOrganization);
		}
	};

	const handleReset = (event) => {
		event.preventDefault();

		if (props.handleFilter) {
			props.handleFilter(null);
		}

		setFilterData({});
		setMine(null);
		setMineValue(null);
		setMyOrganization(null);
	};

	return (
		<div className="filters-box">
			<h3 className="filters-box__title"><Translate text={'Filters'} /></h3>

			<ButtonToolbar className="filters-box__top-controls justify-content-between">
				<Button
					variant="link"
					onClick={ handleSearch }
				>
					<Translate text={'Apply Filters'} />
				</Button>

				<Button
					variant="link"
					onClick={ handleReset }
				>
					<Translate text={'Reset'} />
				</Button>
			</ButtonToolbar>

			{ (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) &&
				<Form.Group controlId="altp-filterMine">
					<Form.Label><Translate text={'Created By'} /></Form.Label>
					<Form.Control
						as="select"
						value={ mineValue ? mineValue : '' }
						onChange={ handleChangeMine }
					>
						<Translator>
							{({ translate }) => (
								<>
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
									<option value="organization">
										{ translate({ text: 'My Organization' }) }
									</option>
									<option value="me">
										{ translate({ text: 'Me' }) }
									</option>
								</>
							)}
						</Translator>
					</Form.Control>
				</Form.Group>
			}

			<Form.Group controlId="filterDepartment">
				<Form.Label><Translate text={'Organization'} /></Form.Label>
				<Form.Control
					as="select"
					name="procurementEntity"
					value={ (procurementEntity) ? procurementEntity : '' }
					required
					disabled={ !entityOptions || !entityOptions.length || mine || myOrganization }
					onChange={ handleChangeFilter }
				>
					<Translator>
						{({ translate }) => (
							<>
								<option value="">
									{ translate({ text: '- Select -' }) }
								</option>

								{ (Array.isArray(entityOptions)) &&
									entityOptions.map((item, index) => {
										return (
											<React.Fragment key={index}>
												{ (!item.endUserEntityDataList || !item.endUserEntityDataList.length) &&
													<option value={ item.name }>
														{ item.name }
													</option>
												}

												{ (Array.isArray(item.endUserEntityDataList) && item.endUserEntityDataList.length > 0) &&
													<>
														<optgroup label={ translate({ text: '- {procurementEntity}, Departments and Partners -', data: { procurementEntity: item.name } }) }>
															{ item.endUserEntityDataList.map((item, index) => {
																return (
																	<option value={ item.name } key={index}>
																		{ item.name }
																	</option>
																);
															})}
														</optgroup>
														<option disabled>{' - '}</option>
													</>
												}
											</React.Fragment>
										);
									})
								}
							</>
						)}
					</Translator>
				</Form.Control>
			</Form.Group>

			<Form.Group controlId="filterCircumstance">
				<Form.Label><Translate text={'Circumstance'} /></Form.Label>
				<Form.Control
					as="select"
					name="circumstance"
					value={ (circumstance) ? circumstance : '' }
					required
					disabled={ !circumstanceOptions || !circumstanceOptions.length }
					onChange={ handleChangeFilter }
				>
					<Translator>
						{({ translate }) => (
							<option value="">
								{ translate({ text: '- Select -' }) }
							</option>
						)}
					</Translator>

					{ (Array.isArray(circumstanceOptions)) &&
						circumstanceOptions.map((item, index) => {
							return (
								<option value={ item.name } key={ index }>
									{ item.name }
								</option>
							);
						})
					}
				</Form.Control>
			</Form.Group>

			<Form.Group>
				<FormCustomSelect
					id="filterCategory"
					name="categories"
					label="Category"
					placeholder="- Select -"
					value={ (Array.isArray(categories) && categories.length) ? categories.map(item => { return { value: item, label: item }; }) : null }
					options={ categoryOptions }
					height={44}
					isMulti={true}
					handleOnChange={(id, values) => handleChangeFilterMultiple(values, 'categories')}
				/>
			</Form.Group>

			<Form.Group className="filter-date-range">
				<h4><Translate text={'Award Date'} /></h4>
				<Translator>
					{({ translate }) => (
						<Row>
							<Col>
								<Form.Label htmlFor="filterAltpsFromAwardDate" aria-label={ translate({ text: 'Award Date - From' }) }>
									<Translate text={'From'} />
								</Form.Label>
								<FormDatePicker
									id="filterAltpsFromAwardDate"
									name="fromAwardDate"
									value={(fromAwardDate) ? fromAwardDate : null}
									placeholder="false"
									maxDate={ (toAwardDate) ? parse(toAwardDate, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('fromAwardDate', value) }
								/>
							</Col>
							<Col>
								<Form.Label htmlFor="filterAltpsToAwardDate" aria-label={ translate({ text: 'Award Date - To' }) }>
									<Translate text={'To'} />
								</Form.Label>
								<FormDatePicker
									id="filterAltpsToAwardDate"
									name="toAwardDate"
									value={(toAwardDate) ? toAwardDate : null}
									placeholder="false"
									minDate={ (fromAwardDate) ? parse(fromAwardDate, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('toAwardDate', value) }
								/>
							</Col>
						</Row>
					)}
				</Translator>
			</Form.Group>

			<Button onClick={ handleSearch }>
				<Translate text={'Apply Filters'} />
			</Button>
		</div>
	);
}

export default AltpFilters;
