import { referenceDataConstants } from '../constants/referenceDataConstants';
import { referenceDataService } from '../../services/referenceDataService';

export const referenceDataActions = {
	getReferenceData
};

function getReferenceData() {
	return dispatch => {
		dispatch(request());

		referenceDataService.get()
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);

	};

	function request() { return { type: referenceDataConstants.DATA_REQUEST }; }

	function success(data) { return { type: referenceDataConstants.DATA_SUCCESS, data }; }

	function error() { return { type: referenceDataConstants.DATA_ERROR }; }
}
