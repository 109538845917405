import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';

import { Row, Col, Button, ButtonToolbar, Form } from 'react-bootstrap';

import SearchUNSPSC from './SearchUNSPSC';
import CommodityFields from './CommodityFields';

function EditCommodity(props) {
	const dispatch = useDispatch();

	const [commodities, setCommodities] = useState(null);
	const [validated, setValidated] = useState(false);
	const [data, setData] = useState({});
	const [updateChildren, setUpdateChildren] = useState(null);
	const formRef = useRef(null);

	const handleBack = () => {
		setCommodities(null);
		setData({});
		setValidated(false);
	};

	const handleCancel = () => {
		if (props.handleCancel) {
			props.handleCancel();
		}
	};

	const handleSelectLevels = (levels) => {
		if (Array.isArray(levels) && levels.length) {
			setCommodities(levels);
		} else {
			setCommodities(null);
		}
	};

	const handleSelectCurrent = (data) => {
		if (data && data.id) {
			setData(data);
		} else {
			setData({});
		}
	};

	const handleUpdateData = (data) => {
		setData(data);
	};

	const handleUpdateChildren = (shouldUpdate) => {
		if (shouldUpdate) {
			setUpdateChildren(true);
		} else {
			setUpdateChildren(null);
		}
	};

	const form = formRef.current;

	const handleSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		if ((form && form.checkValidity() === false) || !data.id) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		const updatedData = {
			changeType: 'SAVE',
			...data
		};

		dispatch(referenceTableActions.updateReferenceTable('unspsc/edit', updatedData, 'edit-commodity', { updateChildren: updateChildren }));
	};

	return (
		<>
			<Row>
				<Col md={7}>
					<h2>
						<Translate text={'Edit Commodity'} />
					</h2>
				</Col>
				<Col md={5}>
					<ButtonToolbar className="justify-content-end">
						{ commodities &&
							<Button
								variant="link"
								onClick={ handleBack }
							>
								<Translate text={'Back to Commodities'} />
							</Button>
						}

						<Button onClick={ handleCancel }>
							<Translate text={'Cancel'} />
						</Button>

						{ commodities &&
							<Button
								variant="success"
								onClick={ handleSubmit }
							>
								<Translate text={'Save'} />
							</Button>
						}
					</ButtonToolbar>
				</Col>
			</Row>

			<SearchUNSPSC
				method="keyword"
				commodities={ commodities }
				currentCommodity={ data }
				handleOnChangeMultiple={(levels) => { handleSelectLevels(levels); }}
				handleSelectCurrent={(data) => { handleSelectCurrent(data); }}
			/>

			{ data && data.id &&
				<Form
					ref={ formRef }
					noValidate
					validated={ validated }
					onSubmit={ handleSubmit }
				>
					<CommodityFields
						data={ data }
						handleUpdateData={ handleUpdateData }
						handleUpdateChildren={ handleUpdateChildren }
					/>
				</Form>
			}
		</>
	);
}

export default EditCommodity;
