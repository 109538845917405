import React, { useState } from 'react';
import { Translate } from 'react-translated';
import { useDispatch } from 'react-redux';

import { userActions } from '../../store/actions/userActions';

import { Row, Col, Tab, Badge, Nav } from 'react-bootstrap';

import UsersTable from './users/UsersTable';

function TabTitle(props) {
	const { title, count } = props;

	return (
		<>
			<span className="tab-title tab-title--uppercase"><Translate text={title} /></span>
			{ count &&
			<Badge variant="primary">{ count }</Badge>
			}
		</>
	);
}

function Users({ isUsersActive }) {
	const {
		USER_STATUS_ACTIVE,
		USER_STATUS_INACTIVE,
		USER_STATUS_PENDING
	} = require('../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const [key, setKey] = useState('active');

	const handleSelectTab = (key) => {
		setKey(key);
		dispatch(userActions.userIsEditing(false));
	};

	return (
		<Row className="mt-2">
			<Col>
				<Tab.Container defaultActiveKey={key} onSelect={ handleSelectTab }>
					<Row>
						<Col md={12}>
							<Nav role="tablist" as="nav" className={'nav-tabs small mb-0'}>
								<Nav.Item as={Nav.Link} eventKey="active">
									<TabTitle title="Active Users" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="inactive">
									<TabTitle title="Inactive Users" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="pending">
									<TabTitle title="Pending Users" />
								</Nav.Item>
							</Nav>
						</Col>
					</Row>
					<Row className={'mt-0'}>
						<Col md={12}>
							<Tab.Content>
								<Tab.Pane eventKey="active">
									<UsersTable
										status={ USER_STATUS_ACTIVE }
										isActive={ key == 'active' && isUsersActive }
									/>
								</Tab.Pane>
								<Tab.Pane eventKey="inactive">
									<UsersTable
										status={ USER_STATUS_INACTIVE }
										isActive={ key == 'inactive' && isUsersActive }
									/>
								</Tab.Pane>
								<Tab.Pane eventKey="pending">
									<UsersTable
										status={ USER_STATUS_PENDING }
										isActive={ key == 'pending' && isUsersActive }
									/>
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</Col>
		</Row>
	);
}

export default Users;
