/* eslint-disable id-length */
import React, {useEffect, useState} from 'react';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import NumberFormat from 'react-number-format';
import {Translate, Translator} from 'react-translated';

import {Button, Col, Row} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {success, error} from '../../store/actions/globalMessagesActions';
import {
	INPUT_MAX_LENGTH,
	ALTP_PAGINATION_LIMIT,
	ROLE_MAS,
	ROLE_MAS_ACCOUNT_MANAGER,
	ROLE_PROCUREMENT_REGULAR,
	ROLE_PROCUREMENT_INTERNAL,
	BAD_CHARACTERS_REGEX,
	BAD_CHARACTERS_MESSAGE
} from '../../config/constants';
import FormDatePicker from '../forms/FormDatePicker';
import {altpActions} from '../../store/actions/altpActions';
import Tooltip from '../misc/Tooltip';
import {isRole, isEndUserEntity} from '../../helpers/userHelpers';

const PostAltpModal = ({buttonLabel, buttonVariant, editAltp, onClose}) => {
	const dispatch = useDispatch();

	const altpPost = useSelector(state => state.altpPost);
	const referenceData = useSelector(state => state.referenceData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const userData = useSelector(state => state.userDataPersist);

	const [showModal, setShowModal] = useState(false);
	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [altpIdTriedBadChar, setAltpIdTriedBadChar] = useState(false);
	const [categoriesList, setCategoriesList] = useState([]);
	const [procurementEntityList, setProcurementEntityList] = useState([]);
	const [hasOldEndUserEntity, setHasOldEndUserEntity] = useState(false);
	const [hasOldProcurementEntity, setHasOldProcurementEntity] = useState(false);
	const [circumstanceList, setCircumstanceList] = useState([]);
	const [contactList, setContactList] = useState([]);
	const [currencyList, setCurrencyList] = useState([]);
	const initialData = {
		'changeType': 'SAVE',
		'altpId': '',
		'title': '',
		'procurementEntityData': '',
		'endUserEntity': '',
		'circumstance': '',
		'altpCategoryValueList': null,
		'awardDate': '',
		'awardedAmount': '',
		'currency': 'CAD',
		'vendor': '',
		'contactInformation': ''
	};

	const [data, setData] = useState(initialData);

	const {
		altpId,
		title,
		procurementEntityData,
		endUserEntity,
		circumstance,
		awardDate,
		awardedAmount,
		currency,
		vendor,
		contactInformation,
		altpCategoryValueList
	} = data;

	const updateData = async (field, value, trim) => {
		let val = value;

		if ((typeof val === 'string' || val instanceof String) && trim) {
			val = val.replace(/\s/g, '');
		}

		setData({...data, [field]: val});
	};

	const updateAltpId = async (value) => {
		let val = value;

		if ((typeof val === 'string' || val instanceof String)) {
			if (val.match(BAD_CHARACTERS_REGEX)) {
				setAltpIdTriedBadChar(true);
			} else {
				setAltpIdTriedBadChar(false);
			}

			val = val.replace(BAD_CHARACTERS_REGEX, '');
		}

		setData({...data, altpId: val});
	};

	const updateEndUserEntity = (entity) => {
		const department = contactList.find(item => {
			return item.departmentName === entity || 'Department of ' + item.departmentName === entity;
		});

		let departmentVal = '';
		if (department && department.contactInformation && department.enabled) {
			departmentVal = department.contactInformation;
		}
		setData({...data, 'contactInformation': departmentVal, 'endUserEntity': entity});
	};

	const updateCategories = (event) => {
		const { checked, value } = event.target;
		const currentList = JSON.parse(JSON.stringify(altpCategoryValueList));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (!items) {
			return false;
		}

		if (checked) {
			if (!items.find(item => item.description == value)) {
				items.push({ changeType: 'SAVE', description: value });
			} else {
				const foundIndex = items.findIndex(item => item.description == value);
				items[foundIndex].changeType = 'SAVE';
			}
		} else if (items.find(item => item.description == value && item.id)) {
			const foundIndex = items.findIndex(item => item.description == value);
			items[foundIndex].changeType = 'DELETE';
		} else {
			items = items.filter(item => item.description !== value);
		}

		setData(data => ({...data, altpCategoryValueList: items }));
	};

	const resetData = () => {
		setData(initialData);
		setValidated(false);
		setHasErrors(false);
		setAltpIdTriedBadChar(false);
	};

	const updateProcurementEntity = (event) => {
		const { value } = event.target;
		let entity;

		if (Array.isArray(procurementEntityList)) {
			entity = procurementEntityList.find(item => item.id == value);
		}

		if (entity && entity.id && entity.name) {
			setData({
				...data,
				procurementEntityData: {
					...entity,
					changeType: 'SAVE'
				},
				contactInformation: ''
			});
		} else {
			setData({...data, procurementEntityData: null, endUserEntity: '', contactInformation: ''});
		}
	};

	const handleAltpSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();
		if (form.checkValidity() !== false) {
			setHasErrors(false);

			const updatedData = data;

			if (!updatedData.endUserEntity && updatedData.procurementEntityData) {
				updatedData.endUserEntity = updatedData.procurementEntityData.name;
			}

			dispatch(altpActions.postAltp(updatedData, 'create-altp'));
		} else {
			setHasErrors(true);
		}
		setValidated(true);
	};

	useEffect(() => {
		if (editAltp !== false) {
			let procurementEntityData = editAltp.procurementEntityData;
			let updatedProcurementEntity;

			// The procurement entity data returned in the ALTP is incomplete, so retrieve from reference data.
			if (Array.isArray(procurementEntityList) && editAltp.procurementEntityData && editAltp.procurementEntityData.id) {
				updatedProcurementEntity = procurementEntityList.find(item => item.id == editAltp.procurementEntityData.id);
			}

			setData({
				'changeType': 'SAVE',
				'id': editAltp.id,
				'altpId': editAltp.altpId,
				'title': editAltp.title,
				'procurementEntityData': updatedProcurementEntity ? updatedProcurementEntity : procurementEntityData,
				'endUserEntity': editAltp.endUserEntity,
				'circumstance': editAltp.circumstance,
				'altpCategoryValueList': editAltp.altpCategoryValueList,
				'awardDate': editAltp.awardDate,
				'awardedAmount': editAltp.awardedAmount,
				'currency': editAltp.currency,
				'vendor': editAltp.vendor,
				'contactInformation': editAltp.contactInformation
			});

			setShowModal(true);
		}
	}, [editAltp, referenceData]);

	useEffect(() => {
		if (altpPost.callback === 'create-altp' && (altpPost.success || altpPost.error)) {
			if (altpPost.success) {
				setShowModal(false);
				resetData();
				if (onClose) {
					onClose();
				}
				setData(initialData);
				dispatch(success(editAltp === false ? 'Successfully created a new alternative procurement notice.' : 'Successfully edited the alternative procurement notice.'));
				dispatch(altpActions.getAltps({page: 1, numberOfRecords: ALTP_PAGINATION_LIMIT, sortType: 'DATE_CREATED_DESC'}));
			} else if (altpPost.error) {
				if (altpPost.status == 409) {
					dispatch(error('Error: ALTP ID already in the system.'));
				} else {
					dispatch(error(editAltp === false ? 'Error creating a new alternative procurement notice. Please try again.' : 'Error editing the alternative procurement notice. Please try again.'));
				}
			}

			dispatch(altpActions.resetPostAltp());
		}
	}, [altpPost]);

	useEffect(() => {
		if (referenceData.success && referenceData.data) {
			if (Array.isArray(referenceData.data.tenderCategoryDataList)) {
				setCategoriesList(referenceData.data.tenderCategoryDataList);
			}

			if (Array.isArray(referenceData.data.procurementEntityDataList)) {
				let entities = referenceData.data.procurementEntityDataList;
				if (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER]) && getUser && getUser.user && getUser.user.organizationId) {
					entities = entities.filter(item => item.organizationId === getUser.user.organizationId);
				}
				updateEntites(entities);
			}

			if (Array.isArray(referenceData.data.tenderCircumstanceDataList)) {
				setCircumstanceList(referenceData.data.tenderCircumstanceDataList.sort((a, b) => a.name.localeCompare(b.name)));
			}

			if (Array.isArray(referenceData.data.altpContactDataList)) {
				setContactList(referenceData.data.altpContactDataList);
			}

			if (Array.isArray(referenceData.data.currencyDataList)) {
				setCurrencyList(referenceData.data.currencyDataList);
			}
		}

		async function updateEntites(entities) {
			await setProcurementEntityList(entities);
			if (entities.length === 1) {
				setData(data => ({
					...data,
					procurementEntityData: entities[0]
				}));
			}
		}
	}, [referenceData]);

	// Checking if Procurement Entity or End User Entity value is old / no longer available.
	useEffect(() => {
		if (procurementEntityData) {
			if (procurementEntityData.id && Array.isArray(procurementEntityList) && !procurementEntityList.find(item => item.id === procurementEntityData.id)) {
				setHasOldProcurementEntity(true);
			} else {
				setHasOldProcurementEntity(false);
			}
		}

		if (editAltp.endUserEntity && procurementEntityData) {
			if (Array.isArray(procurementEntityData.endUserEntityDataList) && !procurementEntityData.endUserEntityDataList.find(item => item.name === editAltp.endUserEntity)) {
				setHasOldEndUserEntity(true);
			} else {
				setHasOldEndUserEntity(false);
			}
		}
	}, [editAltp, procurementEntityData]);

	return (
		<>
			<Button onClick={() => setShowModal(true)} variant={buttonVariant || 'primary'}>{<Translate text={ buttonLabel || 'Create an Alternative Procurement Notice'} />}</Button>
			<Modal show={ showModal } onHide={ () => { setShowModal(false); resetData(); if (onClose) { onClose(); } } } backdrop="static" centered size={'lg'} className="post-altp-modal">
				<Form noValidate validated={ validated } onSubmit={ handleAltpSubmit }>
					<Modal.Header closeButton>
						<h2>
							{ editAltp === false &&
								<Translate text={'Post an Alternative Procurement Notice'} />
							}
							{ editAltp !== false &&
								<Translate text={'Edit an Alternative Procurement Notice'} />
							}
						</h2>
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={6}>
								<Form.Group controlId={ 'altpId' }>
									<Form.Label>
										<Tooltip text={'The Province uses the ALTP-related Ariba Contract Workspace number (CW#) in this field or in the case of one-time emergencies, the Purchase Order (PO) or Outline Agreement numbers. Other Public Sector Entities (PSEs) will want to choose a numbering system that works for their organization, which should be unique to improve searchability. Recommend using a prefix like Tender IDs.'} />
										<Translate text={ 'ALTP ID' } /><span className="required">*</span>
									</Form.Label>
									<Form.Control
										type="text"
										name={ 'altpId' }
										value={altpId}
										required
										maxLength={ INPUT_MAX_LENGTH }
										onChange={(event) => { updateAltpId(event.currentTarget.value); }}
									/>

									{ altpIdTriedBadChar &&
										<Form.Text className="text-muted">
											<Translate text={BAD_CHARACTERS_MESSAGE} />
										</Form.Text>
									}

									<Form.Control.Feedback type="invalid">
										<Translate text={'ALTP ID is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId={ 'title' }>
									<Form.Label>
										<Tooltip text={'A concise description of the untendered product or service procured using valid ALTP justification.'} />
										<Translate text={ 'Title' } /><span className="required">*</span>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name={ 'title' }
										value={title}
										maxLength={ INPUT_MAX_LENGTH }
										onChange={(event) => { updateData('title', event.currentTarget.value); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Title is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group role="group" className="category-container">
									<h4>
										<Tooltip text={'Select category or categories (Goods, Services, Construction) that apply from the list provided. '} />
										<Translate text={'Category'} /><span className="required">*</span>
									</h4>

									{ (Array.isArray(categoriesList)) &&
										categoriesList.map((item, index) => {
											return (
												<Form.Check
													key={ index }
													label={ item.description }
													name="category"
													id={`category-${index}`}
													className="form-check-big max-width-none"
													type="checkbox"
													value={ item.description }
													required={ !altpCategoryValueList || (Array.isArray(altpCategoryValueList) && !altpCategoryValueList.length) }
													checked={ (Array.isArray(altpCategoryValueList) && altpCategoryValueList.find(el => el.description == item.description && el.changeType !== 'DELETE')) ? true : false }
													onChange={(e) => {
														updateCategories(e);

														if (e.target.validity.valid) {
															e.target.closest('.form-group').classList.remove('is-invalid');
														}
													}}
													onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
												/>
											);
										})
									}

									<Form.Control.Feedback type="invalid">
										<Translate text={'Category is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId="procurementEntityData">
									<Form.Label><Translate text={'Procurement Entity'} /><span className="required">*</span></Form.Label>
									<Form.Control
										as="select"
										required
										value={ (procurementEntityData) ? procurementEntityData.id : '' }
										onChange={(event) => { updateProcurementEntity(event); }}
									>
										<Translator>
											{({translate}) => (
												<>
													<option value="">{ translate({ text: '- Select -' }) }</option>

													{ procurementEntityData && hasOldProcurementEntity &&
														<option value={ procurementEntityData.id }>
															{ procurementEntityData.name }
														</option>
													}

													{ procurementEntityList && procurementEntityList.map((entity, index) => {
														return <option value={entity.id} key={index}>{ translate({ text: entity.name }) }</option>;
													})}
												</>
											)}
										</Translator>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Procurement Entity is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								{ (procurementEntityData && Array.isArray(procurementEntityData.endUserEntityDataList) && procurementEntityData.endUserEntityDataList.length > 0) &&
									<Form.Group controlId="endUserEntity">
										<Form.Label>
											<Tooltip text={'Choose the organization that applies from the list provided.'} />
											<Translate text={'End User Entity'} /> <span className="required">*</span>
										</Form.Label>
										<Form.Control
											as="select"
											name="endUserEntity"
											value={ (endUserEntity) ? endUserEntity : '' }
											required
											onChange={(event) => { updateEndUserEntity(event.currentTarget.value); }}
										>
											<Translator>
												{({ translate }) => (
													<option value="">
														{ translate({ text: '- Select -' }) }
													</option>
												)}
											</Translator>

											{ endUserEntity && hasOldEndUserEntity &&
												<option value={ endUserEntity }>
													{ endUserEntity }
												</option>
											}

											{ procurementEntityData.endUserEntityDataList.map((item, index) => {
												if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && item.organizationId && getUser.user && !isEndUserEntity(getUser.user, item.organizationId)) {
													return null;
												}

												if (item.name) {
													return (
														<option value={ item.name } key={ index }>
															{ item.name }
														</option>
													);
												}

												return null;
											})}
										</Form.Control>
										<Form.Control.Feedback type="invalid">
											<Translate
												text={'{formLabel} is required'}
												data={{
													formLabel: 'End User Entity'
												}}
											/>
										</Form.Control.Feedback>
									</Form.Group>
								}

								<Form.Group controlId={ 'contactInformation' }>
									<Form.Label>
										<Tooltip text={'Include the contact information (name, title, email) of the person responsible for inquiries around the ALTP purchase.'} />
										<Translate text={ 'Contact Information' } /><span className="required">*</span>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name={ 'contactInformation' }
										value={contactInformation}
										maxLength={ INPUT_MAX_LENGTH }
										onChange={(event) => { updateData('contactInformation', event.currentTarget.value); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Contact Information is required.'} />
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Col md={6}>
								<Form.Group className={ (validated && !awardDate) ? 'is-invalid' : null }>
									<Form.Label htmlFor="awardDate">
										<Tooltip text={'Choose the date that the purchase order, outline agreement, and/or the contract was executed.'} />
										<Translate text={'Award Date'} /> <span className="required">*</span>
									</Form.Label>
									<FormDatePicker
										id="awardDate"
										name="awardDate"
										value={awardDate}
										maxDate={new Date()}
										required={true}
										handleOnChange={(value) => { updateData('awardDate', value); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate
											text={'{formLabel} is required'}
											data={{
												formLabel: 'Award Date'
											}}
										/>
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId={ 'awardedAmount' }>
									<Form.Label>
										<Tooltip text={'Add in the original ALTP award.'} />
										<Translate text={ 'Awarded Amount' } /><span className="required">*</span>
									</Form.Label>
									<NumberFormat
										customInput={ Form.Control }
										type="text"
										name="awardedAmount"
										value={awardedAmount}
										maxLength={12}
										allowNegative={false}
										required={true}
										onChange={(event) => { updateData('awardedAmount', event.currentTarget.value); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Awarded Amount is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId="currency">
									<Form.Label>
										<Tooltip text={'Select appropriate currency.'} />
										<Translate text={'Currency'} /><span className="required">*</span>
									</Form.Label>
									<Form.Control as="select" required value={currency} onChange={(event) => { updateData('currency', event.currentTarget.value); }}>
										<Translator>
											{({translate}) => (
												<>
													<option value="">{ translate({ text: '- Select -' }) }</option>
													{ currencyList && currencyList.map(currency => {
														return <option value={currency.code}>{ translate({ text: currency.name }) }</option>;
													})}
												</>
											)}
										</Translator>
									</Form.Control>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Currency is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId={ 'vendor' }>
									<Form.Label>
										<Tooltip text={'Name of the Supplier that was awarded the contract.  Please be consistent by using the same spelling/form if previously used in other ALTP postings.'} />
										<Translate text={ 'Supplier' } /><span className="required">*</span>
									</Form.Label>
									<Form.Control
										required
										type="text"
										name={ 'vendor' }
										value={vendor}
										maxLength={ INPUT_MAX_LENGTH }
										onChange={(event) => { updateData('vendor', event.currentTarget.value); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Supplier is required.'} />
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group controlId="circumstance">
									<Form.Label>
										<Tooltip>
											<Translate
												text={'Select the circumstance from the dropdown list. <Link>'}
												renderMap={{
													renderLink: () => <a href="https://procurement.novascotia.ca/media/53284/Protocols.pdf#page=31" target="_blank" rel="noopener noreferrer"><Translate text={'Click here to view the Alternative Procurement Practices Protocol.'} /></a> // eslint-disable-line react/display-name
												}}
											/>
										</Tooltip>
										<Translate text={'Circumstance'} /><span className="required">*</span>
									</Form.Label>
									<Form.Control as="select" required value={circumstance} onChange={(event) => { updateData('circumstance', event.currentTarget.value); }}>
										<Translator>
											{({translate}) => (
												<>
													<option value="">{ translate({ text: '- Select -' }) }</option>
													{ circumstanceList && circumstanceList.map((circumstance, index) => {
														return <option value={circumstance.name} key={index}>{ translate({ text: circumstance.name }) }</option>;
													})}
												</>
											)}
										</Translator>
									</Form.Control>
									{ circumstance && circumstanceList.find(item => item.id == circumstance) && circumstanceList.find(item => item.id == circumstance).description &&
										<Form.Text id="circumstanceHelp" muted>
											{ circumstanceList.find(item => item.id == circumstance).description }
										</Form.Text>
									}
									<Form.Control.Feedback type="invalid">
										<Translate text={'Circumstance is required.'} />
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
						</Row>

						{ validated && hasErrors &&
							<div className="invalid-feedback show">
								<p><Translate text="Form contains errors. Please fill out all required fields." /></p>
							</div>
						}
					</Modal.Body>
					<Modal.Footer className={'text-right'}>
						<Button variant="primary" type={'submit'}><Translate text={'Submit'} /></Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};
export default PostAltpModal;
