/* eslint-disable id-length */
import React, {useEffect} from 'react';
import {ReactTitle} from 'react-meta-tags';
import {useLocation} from 'react-router-dom';
import {Translate, Translator} from 'react-translated';
import {useSelector, useDispatch} from 'react-redux';
import {isValid, parseISO} from 'date-fns';

import {dataLayerPageView} from '../../helpers/miscHelpers';
import {tenderActions} from '../../store/actions/tenderActions';
import {hasClosingDatePassed} from '../../helpers/dateHelpers';

import {Container, Col, Row, Alert, Button, ButtonToolbar} from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import TenderForm from '../tenders/TenderForm';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function EditTender({ match }) {
	const dataId = match.params.id;
	const {
		PAGE_TITLE_BASE,
		TENDER_STATUS_DRAFT
	} = require('../../config/constants');
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPath = location.pathname;

	// State data.
	const language = useSelector(state => state.language);
	const postTender = useSelector(state => state.postTender);
	const tender = useSelector(state => state.tender);
	const currentTender = useSelector(state => state.currentTender);

	const isLoading = postTender.request || tender.request;

	useEffect(() => {
		const title = 'Edit Tender - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			title,
			language.code
		);
	}, [language]);

	// Jump user to top is loaded in the middle.
	useEffect(() => {
		window.scrollTo(0, 0);
		document.documentElement.scrollTop;

		// Clear any lingering current tender data.
		dispatch(tenderActions.resetCurrentTender());
	}, []);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{pageTitle} - {siteTitle}',
								data: { siteTitle: PAGE_TITLE_BASE, pageTitle: 'Edit Tender' }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<ContentLoading loading={isLoading} isFixed={true}>
				<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
					{ (currentTender.tenderStatus == TENDER_STATUS_DRAFT) &&
						<Alert variant="primary">
							<span className="alert-icon">
								<FontAwesomeIcon icon={ faExclamationTriangle } />
							</span>
							<span className="alert-message text-center"><Translate text={'This tender is currently saved as draft.'} /></span>
							<span className="alert-icon">
								<FontAwesomeIcon icon={ faExclamationTriangle } />
							</span>
						</Alert>
					}

					{ (currentTender.closingDate && isValid(parseISO(currentTender.closingDate)) && hasClosingDatePassed(currentTender.closingDate, currentTender.closingTime)) &&
						<Alert variant="primary">
							<span className="alert-icon">
								<FontAwesomeIcon icon={ faExclamationTriangle } />
							</span>
							<span className="alert-message text-center"><Translate text={'Closing Date has passed, editing is limited.'} /></span>
							<span className="alert-icon">
								<FontAwesomeIcon icon={ faExclamationTriangle } />
							</span>
						</Alert>
					}

					<Row>
						<Col md={{ span: 9, order: 'last' }}>
							<div className="post-tender">
								<Row className="pt-3 pb-2">
									<Col lg={7}>
										<h1 className="mt-0"><Translate text={'Edit Tender'} /></h1>
									</Col>
								</Row>

								<TenderForm
									isEdit={true}
									dataId={ dataId }
									callback="edit-tender"
								/>
							</div>
						</Col>
						<Col md={{ span: 3, order: 'first' }}>
							<ButtonToolbar className="pt-3">
								<Button
									variant="link"
									href="/tenders"
								>
									<Translate text={'Back to Tenders'} />
								</Button>
							</ButtonToolbar>
						</Col>
					</Row>
				</Container>
			</ContentLoading>
			<Footer />
		</>
	);
}

export default EditTender;
