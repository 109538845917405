export const userConstants = {
	USER_SINGLE_GET_REQUEST: 'USER_SINGLE_GET_REQUEST',
	USER_SINGLE_GET_SUCCESS: 'USER_SINGLE_GET_SUCCESS',
	USER_SINGLE_GET_ERROR: 'USER_SINGLE_GET_ERROR',
	USER_GET_REQUEST: 'USER_GET_REQUEST',
	USER_GET_SUCCESS: 'USER_GET_SUCCESS',
	USER_GET_ERROR: 'USER_GET_ERROR',
	USER_GET_RPID_REQUEST: 'USER_GET_RPID_REQUEST',
	USER_GET_RPID_SUCCESS: 'USER_GET_RPID_SUCCESS',
	USER_GET_RPID_ERROR: 'USER_GET_RPID_ERROR',
	USER_GET_RPID_RESET: 'USER_GET_RPID_RESET',
	USER_POST_REQUEST: 'USER_POST_REQUEST',
	USER_POST_SUCCESS: 'USER_POST_SUCCESS',
	USER_POST_ERROR: 'USER_POST_ERROR',
	USER_POST_RESET: 'USER_POST_RESET',
	USER_ACTIVATE_REQUEST: 'USER_ACTIVATE_REQUEST',
	USER_ACTIVATE_SUCCESS: 'USER_ACTIVATE_SUCCESS',
	USER_ACTIVATE_ERROR: 'USER_ACTIVATE_ERROR',
	USER_ACTIVATE_RESET: 'USER_ACTIVATE_RESET',
	USER_AUTHORIZE_REQUEST: 'USER_AUTHORIZE_REQUEST',
	USER_AUTHORIZE_SUCCESS: 'USER_AUTHORIZE_SUCCESS',
	USER_AUTHORIZE_ERROR: 'USER_AUTHORIZE_ERROR',
	USER_AUTHORIZE_RESET: 'USER_AUTHORIZE_RESET',
	USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
	USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
	USER_DELETE_ERROR: 'USER_DELETE_ERROR',
	USER_DELETE_RESET: 'USER_DELETE_RESET',
	USER_UNAUTHENTICATED: 'USER_UNAUTHENTICATED',
	USER_IS_EDITING: 'USER_IS_EDITING'
};
