/* eslint-disable id-length */
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Translate, Translator } from 'react-translated';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import { format, isValid, isBefore, isAfter, isToday, isPast, parse, parseISO, differenceInCalendarDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { setHasChanges, success, error, clearMessages } from '../../store/actions/globalMessagesActions';
import { tenderActions } from '../../store/actions/tenderActions';
import { isRole, isOrganization, isEndUserEntity, isDeptPublicWorks } from '../../helpers/userHelpers';
import { bytesToSize } from '../../helpers/fileHelpers';
import { hasClosingDatePassed } from '../../helpers/dateHelpers';

import { Alert, Button, ButtonToolbar, Col, Row, Form, Modal } from 'react-bootstrap';
import FormDatePicker from '../forms/FormDatePicker';
import FormPhone from '../forms/FormPhone';
import FormEmail from '../forms/FormEmail';
import FormUNSPSC from '../forms/FormUNSPSC';
import Tooltip from '../misc/Tooltip';
import Attachments from '../forms/Attachments';
import { AwardTable as AwardTableComponent } from './AwardTable';
import { BidTable as BidTableComponent } from './BidTable';
import FormCountry from '../forms/FormCountry';
import FormProvinceState from '../forms/FormProvinceState';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function PostTenderSuccess() {
	const { TENDER_STATUS_SCHEDULED } = require('../../config/constants');
	const history = useHistory();
	const dispatch = useDispatch();

	// State data.
	const postTender = useSelector(state => state.postTender);

	const handleViewTender = async () => {
		const header = document.getElementById('header');
		history.replace('/tenders/' + encodeURIComponent(postTender.data.tenderId));
		dispatch(clearMessages());
		await header.scrollIntoView();
		dispatch(tenderActions.resetPostTender());
	};

	const handleCreateAnotherTender = async () => {
		dispatch(tenderActions.resetPostTender());
		await dispatch(clearMessages());
		window.scrollTo(0, 0);
		document.documentElement.scrollTop;
		//location.reload();
		window.location.href = '/tenders/post';
	};

	return (
		<ButtonToolbar>
			{postTender && postTender.data && postTender.data.tenderId && postTender.data.tenderStatus !== TENDER_STATUS_SCHEDULED &&
				<Button onClick={handleViewTender}>
					<Translate text={'View Tender'} />
				</Button>
			}

			<Button onClick={handleCreateAnotherTender}>
				<Translate text={'Create Another Tender'} />
			</Button>
			<Button href="/tenders">
				<Translate text={'View All Tenders'} />
			</Button>
		</ButtonToolbar>
	);
}

function TenderForm(props) {
	const {
		isEdit,
		dataId,
		callback
	} = props;

	const {
		TENDER_STATUS_OPEN,
		TENDER_STATUS_DRAFT,
		TENDER_STATUS_SCHEDULED,
		TENDER_STATUS_AWARDED,
		TENDER_STATUS_CANCELLED,
		INPUT_MAX_LENGTH,
		MESSAGE_MAX_LENGTH,
		DESCRIPTION_MAX_LENGTH,
		NOT_APPLICABLE,
		DEFAULT_CLOSING_TIME,
		CLOSING_TIME_OPTIONS,
		CLOSING_LOCATION_DISCOVERY,
		CLOSING_LOCATION_BIDX,
		CLOSING_LOCATION_OTHER,
		CLOSING_LOCATION_OPTIONS,
		LANGUAGE_OPTIONS,
		LANGUAGE_ENGLISH,
		LANGUAGE_FRENCH,
		DOCUMENT_TYPE_TENDER,
		DOCUMENT_TYPE_ADDENDUM,
		CLOSING_TIME_FORMAT_TWELVE,
		TENDERS_EMAIL,
		TENDERS_PHONE,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		ROLE_PROCUREMENT_ADMIN,
		DOC_MAX_TOTAL_FILESIZE,
		TRADE_AGREEMENT_NONE_CODE,
		PNS_ORG_ID,
		BAD_CHARACTERS_REGEX,
		BAD_CHARACTERS_MESSAGE,
		PROCUREMENT_METHOD_ACAN,
		SOLICITATION_TYPE_ACAN,
		ENTITY_PUBLIC_WORKS_ID
	} = require('../../config/constants');
	const history = useHistory();
	const dispatch = useDispatch();

	// State data.
	const authenticate = useSelector(state => state.authenticate);
	const language = useSelector(state => state.language);
	const userData = useSelector(state => state.userDataPersist);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const hasChanges = useSelector(state => state.hasChanges);
	const tender = useSelector(state => state.tender);
	const postTender = useSelector(state => state.postTender);
	const referenceData = useSelector(state => state.referenceData);
	const currentTender = useSelector(state => state.currentTender);

	const awardDetailsRef = useRef(null);
	const awardRef = useRef(null);
	const AwardTable = React.forwardRef(AwardTableComponent);

	const bidDetailsRef = useRef(null);
	const bidRef = useRef(null);
	const BidTable = React.forwardRef(BidTableComponent);

	const formRef = useRef(null);

	const [solicitationTypeOptions, setSolicitationTypeOptions] = useState([]);
	const [procurementMethodOptions, setProcurementMethodOptions] = useState([]);
	const [tradeAgreementOptions, setTradeAgreementOptions] = useState([]);
	const [sustainableReasonOptions, setSustainableReasonOptions] = useState([]);
	const [sustainableSourceOptions, setSustainableSourceOptions] = useState([]);
	const [statusOptions, setStatusOptions] = useState([]);
	const [entityOptions, setEntityOptions] = useState([]);
	const [endUserEntityOptions, setEndUserEntityOptions] = useState([]);
	const [publicFieldsHidden, setPublicFieldsHidden] = useState(true);
	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [hasDraftErrors, setHasDraftErrors] = useState(false);
	const [hasTenderIdError, setHasTenderIdError] = useState(false);
	const [tenderIdTriedBadChar, setTenderIdTriedBadChar] = useState(false);
	const commoditiesError = 'Please select at least one commodity.';
	const [hasCommoditiesError, setHasCommoditiesError] = useState(true);
	const [hasProcurementEntityError, setHasProcurementEntityError] = useState(false);
	const [hasOldProcurementMethod, setHasOldProcurementMethod] = useState(false);
	const [hasOldEndUserEntity, setHasOldEndUserEntity] = useState(false);
	const [hasOldProcurementEntity, setHasOldProcurementEntity] = useState(false);
	const defaultClosingTime = DEFAULT_CLOSING_TIME;
	const [hasCustomClosingLocation, setHasCustomClosingLocation] = useState(false);
	const tenderDocsError = 'Tender information must be provided by uploading document(s) or entering a URL to access tender document(s).';
	const [hasTenderDocsError, setHasTenderDocsError] = useState(true);
	const totalFilesizeString = bytesToSize(DOC_MAX_TOTAL_FILESIZE);
	const tenderDocsFilesizeError = `Tender documents total filesize exceeds the ${totalFilesizeString} limit.`;
	const addendumDocsFilesizeError = `Addendum documents total filesize exceeds the ${totalFilesizeString} limit.`;
	const addendumNoteDefault = 'All communications regarding this Event, including Addenda, will only be available through SAP Ariba.';
	const addendumNoteBidx = 'All communications regarding this letting, including Addenda, will only be available through Bid Express.';
	const [hasTenderDocsFilesizeError, setHasTenderDocsFilesizeError] = useState(false);
	const [hasAddendumDocsFilesizeError, setHasAddendumDocsFilesizeError] = useState(false);
	const contactDetailsDefault = `For inquiries specific to tender content, please use the Ariba Event Messages accessible within the Sourcing Event. For more information about e-bidding and Event Messages, please refer to ${window.location.hostname}/suppliers-ebidding-ariba. For all general inquiries please contact Procurement at 902-424-3333 or via email at ptenders@novascotia.ca.`;
	const contactDetailsBidx = 'For all general inquiries please contact Procurement at 902-424-3333 or via email at ptenders@novascotia.ca';
	const [hasPostClosingDateWarning, setHasPostClosingDateWarning] = useState(false);
	const [data, setData] = useState({
		'changeType': 'SAVE',
		'tenderId': '',
		'solicitationType': '',
		'solicitationTypeOther': null,
		'title': '',
		'procurementMethod': (!isEdit) ? 'Competitive – Open Bidding' : '',
		'unspscLevelData': [{
			'changeType': 'SAVE',
			'unspscLevel1': '',
			'unspscLevel2': '',
			'unspscLevel3': '',
			'unspscLevel4': ''
		}],
		'contactName': '',
		'contactMethod': (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? [{ changeType: 'SAVE', method: 'phone' }, { changeType: 'SAVE', method: 'email' }] : [],
		'contactDetails': (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? contactDetailsDefault : '',
		'contactEmail': (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? TENDERS_EMAIL : '',
		'contactPhoneNumber': (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? TENDERS_PHONE : '',
		'endUserEntity': '',
		'tradeAgreement': [],
		'issuedDate': (!isEdit) ? format(new Date(), "yyyy-MM-dd'T'HH:mmxx") : '', // eslint-disable-line
		'closingDate': '',
		'closingTime': (!isEdit) ? defaultClosingTime : '',
		'closingDateDisplay': (!isEdit) ? CLOSING_TIME_FORMAT_TWELVE : '',
		'closingLocation': (!isEdit && isRole(userData, ROLE_PROCUREMENT_INTERNAL) && isDeptPublicWorks(getUser.user)) ? CLOSING_LOCATION_BIDX : '',
		'closingLocationOtherText': '',
		'publicOpeningDate': '',
		'publicOpeningTime': (!isEdit) ? NOT_APPLICABLE : '',
		'publicOpeningLocation': (!isEdit) ? NOT_APPLICABLE : '',
		'submissionLanguage': (!isEdit) ? LANGUAGE_ENGLISH : '',
		'pickUpFee': (!isEdit) ? NOT_APPLICABLE : '',
		'termsOfPayment': (!isEdit) ? NOT_APPLICABLE : '',
		'description': '',
		'memo': '',
		'tenderStatus': (!isEdit) ? TENDER_STATUS_OPEN : '',
		'expectedDurationOfContract': '',
		'sustainablePrimarySource': '',
		'sustainablePrimaryReason': '',
		'tenderAwardData': [],
		'awardMemo': '',
		'tenderBidInformationDataList': [],
		'postDate': (!isEdit) ? format(new Date(), "yyyy-MM-dd'T'HH:mmxx") : '', // eslint-disable-line
		'attachments': (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? [{
			'changeType': 'SAVE',
			'documentType': DOCUMENT_TYPE_ADDENDUM,
			'notes': addendumNoteDefault
		}] : []
	});

	const {
		tenderId,
		solicitationType,
		solicitationTypeOther,
		title,
		procurementMethod,
		unspscLevelData,
		procumentEntityData,
		contactName,
		contactMethod,
		contactDetails,
		contactEmail,
		contactPhoneNumber,
		endUserEntity,
		tradeAgreement,
		closingDate,
		closingTime,
		closingLocation,
		closingLocationOtherText,
		publicOpeningDate,
		publicOpeningTime,
		publicOpeningLocation,
		submissionLanguage,
		pickUpFee,
		termsOfPayment,
		description,
		memo,
		expectedDurationOfContract,
		sustainableCriteriaUsed,
		sustainablePrimarySource,
		sustainablePrimaryReason,
		tenderStatus,
		postDate,
		attachments,
		awardMemo
	} = data;

	const closingTimeOptions = CLOSING_TIME_OPTIONS;
	const [closingLocationOptions, setClosingLocationOptions] = useState(CLOSING_LOCATION_OPTIONS);
	const languageOptions = LANGUAGE_OPTIONS;

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (referenceData.data.solicitationTypeDataList) {
				setSolicitationTypeOptions(referenceData.data.solicitationTypeDataList);
			}

			if (referenceData.data.procurementMethodDataList) {
				setProcurementMethodOptions(referenceData.data.procurementMethodDataList);
			}

			if (referenceData.data.tradeAgreementDataList) {
				setTradeAgreementOptions(referenceData.data.tradeAgreementDataList);
			}

			if (referenceData.data.sustainablePrimaryReasonDataList) {
				setSustainableReasonOptions(referenceData.data.sustainablePrimaryReasonDataList);
			}

			if (referenceData.data.sustainablePrimarySourceDataList) {
				setSustainableSourceOptions(referenceData.data.sustainablePrimarySourceDataList);
			}

			if (referenceData.data.procurementEntityDataList) {
				let entities = referenceData.data.procurementEntityDataList;
				let name = '';

				if (getUser && getUser.user && getUser.user.organizationId) {
					if (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER]) || (!isEdit && isRole(userData, ROLE_PROCUREMENT_ADMIN))) {
						entities = entities.filter(item => item.organizationId === getUser.user.organizationId);
						name = getUser.user.firstName + ' ' + getUser.user.lastName;
					}
				}

				setEntityOptions(entities);
				updateEntites(entities, name.trim());
			}
		}

		async function updateEntites(entities, name) {
			if (!isEdit && entities.length === 1) {
				let closingLocationValue = '';
				let updatedContactDetails;
				let updatedAttachments;

				if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && isDeptPublicWorks(getUser.user)) {
					closingLocationValue = CLOSING_LOCATION_BIDX;

					updatedAttachments = attachments;
					const addendumIndex = attachments.findIndex(x => x.documentType == DOCUMENT_TYPE_ADDENDUM);
					updatedAttachments[addendumIndex].changeType = 'SAVE';
					updatedAttachments[addendumIndex].notes = addendumNoteBidx;

					updatedContactDetails = contactDetailsBidx;
				} else if (entities[0] && entities[0].tenderCloseLocation) {
					closingLocationValue = entities[0].tenderCloseLocation;
				}

				await setData(data => ({
					...data,
					procumentEntityData: entities[0],
					endUserEntity: (getUser && getUser.user && getUser.user.endUserEntity) ? getUser.user.endUserEntity : '',
					closingLocation: closingLocationValue,
					publicOpeningLocation: (entities[0] && entities[0].tenderPublicOpenLocation) ? entities[0].tenderPublicOpenLocation : NOT_APPLICABLE,
					contactName: (name) ? name : null,
					contactDetails: (updatedContactDetails) ? updatedContactDetails : data.contactDetails,
					attachments: (updatedAttachments) ? updatedAttachments : data.attachments
				}));
			}
		}
	}, [referenceData, getUser, userData]);

	// Listen to current tender & reference data.
	useEffect(() => {
		if (currentTender.id && referenceData.success && referenceData.data) {
			if (referenceData.data.tenderStatusDataList) {
				let statusOptions = referenceData.data.tenderStatusDataList;

				if (currentTender.tenderStatus == TENDER_STATUS_AWARDED) {
					statusOptions = statusOptions.filter(item => item.name !== TENDER_STATUS_CANCELLED);
				}

				setStatusOptions(statusOptions);
			}
		}
	}, [currentTender, referenceData]);

	// Checking if Procurement Entity or End User Entity value is old / no longer available.
	useEffect(() => {
		if (procumentEntityData) {
			if (procumentEntityData.id && Array.isArray(entityOptions) && !entityOptions.find(item => item.id === procumentEntityData.id)) {
				setHasOldProcurementEntity(true);
			} else {
				setHasOldProcurementEntity(false);
			}
		}

		if (currentTender.endUserEntity && procumentEntityData) {
			if (Array.isArray(procumentEntityData.endUserEntityDataList) && !procumentEntityData.endUserEntityDataList.find(item => item.name === currentTender.endUserEntity)) {
				setHasOldEndUserEntity(true);
			} else {
				setHasOldEndUserEntity(false);
			}
		}
	}, [currentTender, procumentEntityData]);

	// Checking if other values are old.
	useEffect(() => {
		if (currentTender.procurementMethod && Array.isArray(procurementMethodOptions) && !procurementMethodOptions.find(item => item.description === currentTender.procurementMethod)) {
			setHasOldProcurementMethod(true);
		} else {
			setHasOldProcurementMethod(false);
		}
	}, [currentTender]);

	// Setting 'End User Entity' options.
	useEffect(() => {
		if (procumentEntityData && procumentEntityData.id && Array.isArray(procumentEntityData.endUserEntityDataList)) {
			let options;

			if (isRole(userData, ROLE_PROCUREMENT_INTERNAL)) {
				options = procumentEntityData.endUserEntityDataList.filter(item => (item.organizationId && getUser.user && isEndUserEntity(getUser.user, item.organizationId)) || item.name == currentTender.endUserEntity);
			} else {
				options = procumentEntityData.endUserEntityDataList;
			}

			setEndUserEntityOptions(options);
		}
	}, [getUser, currentTender, procumentEntityData]);

	// Updating closing location options for specific users.
	useEffect(() => {
		if (userData.success && getUser.success) {
			if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && isDeptPublicWorks(getUser.user)) {
				setClosingLocationOptions(closingLocationOptions => {
					return closingLocationOptions.filter(item => item.value == CLOSING_LOCATION_BIDX);
				});
			}
		}
	}, [userData, getUser]);

	// Checking if current Closing Location is a custom value.
	useEffect(() => {
		if (isEdit && currentTender.closingLocation && Array.isArray(closingLocationOptions) && !closingLocationOptions.find(item => item.description === currentTender.closingLocation)) {
			setHasCustomClosingLocation(true);
		} else {
			setHasCustomClosingLocation(false);
		}
	}, [currentTender]);

	// Checking if current date & closing date are within 10 days.
	useEffect(() => {
		if (isValid(parseISO(closingDate)) && isAfter(parseISO(closingDate), new Date())) {
			if (differenceInCalendarDays(parseISO(closingDate), new Date()) <= 10) {
				setHasPostClosingDateWarning(true);
			} else {
				setHasPostClosingDateWarning(false);
			}
		} else {
			setHasPostClosingDateWarning(false);
		}
	}, [closingDate]);

	// Listening to post updates.
	useEffect(() => {
		if (postTender.success && postTender.callback === callback) {
			dispatch(success('Tender successfully saved', <PostTenderSuccess />, handleCloseSuccessModal));
			dispatch(setHasChanges(false));
			setValidated(false);
		} else if (postTender.error && postTender.callback === callback) {
			if (postTender.status == 409) {
				dispatch(error('Error: Tender ID already in the system.'));
			} else {
				dispatch(error('Error: Tender could not be submitted. Please try again later.'));
			}
		}

		if (postTender.success && postTender.callback === 'edit-tender') {
			dispatch(success('Tender successfully edited', <PostTenderSuccess />, handleCloseSuccessModal));
			dispatch(setHasChanges(false));
			setValidated(false);
		} else if (postTender.error && postTender.callback === 'edit-tender') {
			if (postTender.status == 409) {
				dispatch(error('Error: Tender ID already in the system.'));
			} else {
				dispatch(error('Error: Tender could not be edited. Please try again later.'));
			}
		}

		if (postTender.success && postTender.callback === 'draft-tender') {
			dispatch(success('Tender draft successfully saved', <PostTenderSuccess />, handleCloseSuccessModal));
			setData({});
			history.replace({
				pathname: '/tenders/' + encodeURIComponent(postTender.data.tenderId) + '/edit'
			});
			dispatch(tenderActions.getTenders({ tenderId: encodeURIComponent(postTender.data.tenderId) }));
			dispatch(setHasChanges(false));
			setValidated(false);
		} else if (postTender.error && postTender.callback === 'draft-tender') {
			if (postTender.status == 409) {
				dispatch(error('Error: Tender ID already in the system.'));
			} else {
				dispatch(error('Error: Tender draft could not be saved. Please try again later.'));
			}
		}
	}, [postTender]);

	// Get tender data.
	useEffect(() => {
		if (isEdit && authenticate && authenticate.success) {
			if (dataId) {
				dispatch(tenderActions.getTenders({ tenderId: dataId }));
			} else {
				history.replace('/tenders');
			}
		}
	}, [authenticate, dataId]);

	// Set tender data.
	useEffect(() => {
		if (isEdit && tender.success && tender.single) {
			if (tender.data && tender.data.tenderDataList && tender.data.tenderDataList.length) {
				let tenderData = tender.data.tenderDataList[0];

				if (referenceData && referenceData.data) {
					// If procurement entity set has relevant End User Entities, we need to grab them from the reference data.
					if (Array.isArray(referenceData.data.procurementEntityDataList) && referenceData.data.procurementEntityDataList.length && tenderData.procumentEntityData) {
						const found = referenceData.data.procurementEntityDataList.find(item => item.organizationId === tenderData.procumentEntityData.organizationId);

						if (found && found.endUserEntityDataList) {
							tenderData = {
								...tenderData,
								procumentEntityData: {
									...procumentEntityData,
									endUserEntityDataList: found.endUserEntityDataList
								}
							};
						}
					}

					// If solicition type other.
					if (isEdit && Array.isArray(referenceData.data.solicitationTypeDataList) && tenderData.solicitationType) {
						const found = referenceData.data.solicitationTypeDataList.find(item => item.description == tenderData.solicitationType);

						if (!found) {
							tenderData = {
								...tenderData,
								solicitationTypeOther: true
							};
						}
					}
				}

				dispatch(tenderActions.currentTenderUpdate(tenderData));
			} else {
				history.replace('/tenders');
			}
		}
	}, [tender, referenceData]);

	useEffect(() => {
		if (currentTender && isEdit) {
			if (currentTender.id) {
				setData({ ...currentTender, changeType: 'SAVE' });
			}

			if (currentTender.attachments && currentTender.attachments.length && currentTender.attachments.some(item => item.documentType == DOCUMENT_TYPE_TENDER && (item.guid || item.data || item.url))) {
				setHasTenderDocsError(false);
			}

			if (currentTender.unspscLevelData && currentTender.unspscLevelData.length && currentTender.unspscLevelData.some(item => item.unspscLevel1 && item.unspscLevel2)) {
				setHasCommoditiesError(false);
			}
		}
	}, [currentTender]);

	useEffect(() => {
		if (Array.isArray(unspscLevelData) && unspscLevelData.filter(item => item.changeType !== 'DELETE').length && unspscLevelData.some(item => item.unspscLevel1 && item.unspscLevel2 && item.changeType !== 'DELETE')) {
			setHasCommoditiesError(false);
		} else {
			setHasCommoditiesError(true);
		}
	}, [unspscLevelData]);

	// Edit permission checks.
	useEffect(() => {
		if (isEdit) {
			if (tender.success && currentTender && userData.success && getUser.success) {
				const internalUserCheck = (!isDeptPublicWorks(getUser.user) && isEndUserEntity(getUser.user, currentTender.endUserEntityOrganizationId)) || (isDeptPublicWorks(getUser.user) && currentTender.closingLocation === CLOSING_LOCATION_BIDX && currentTender.endUserEntityOrganizationId === ENTITY_PUBLIC_WORKS_ID);

				if (!isRole(userData, ROLE_PROCUREMENT_ADMIN)) {
					if (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) {
						if (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && currentTender.procumentEntityData && !isOrganization(getUser.user, currentTender.procumentEntityData.organizationId)) {
							history.replace('/tenders');
						} else if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && currentTender.procumentEntityData && (!isOrganization(getUser.user, currentTender.procumentEntityData.organizationId) || (!internalUserCheck))) {
							history.replace('/tenders');
						}
					} else {
						history.replace('/tenders');
					}
				}
			} else if (userData.error || getUser.error) {
				history.replace('/tenders');
			}
		}
	}, [userData, getUser, data, tender, currentTender]);

	const canEdit = (fieldName) => {
		if (isEdit && currentTender.closingDate && isValid(parseISO(currentTender.closingDate))) {
			if (currentTender.id && currentTender.tenderStatus == TENDER_STATUS_CANCELLED) {
				let editableFields;

				if (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) {
					editableFields = [
						'awardMemo'
					];
				}

				return editableFields.includes(fieldName);
			}

			if (hasClosingDatePassed(currentTender.closingDate, currentTender.closingTime)) {
				let editableFields;

				if (isRole(userData, ROLE_PROCUREMENT_ADMIN)) {
					editableFields = [
						'tenderStatus',
						'closingDate',
						'closingTime',
						'tenderAwardData',
						'awardMemo',
						'postDate'
					];
				}

				if (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) {
					editableFields = [
						'tenderAwardData',
						'awardMemo'
					];
				}

				return editableFields.includes(fieldName);
			}
		}

		return true;
	};

	const handleCloseSuccessModal = () => {
		history.replace('/tenders/');
		dispatch(tenderActions.resetPostTender());
	};

	/**
	 * Field functions
	 */

	const checkChanges = () => {
		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleChange = (event, isNumeric, isMultiple, nestedField, trim) => {
		const { name, value } = event.target;
		let val;

		if (isNumeric) {
			if (value) {
				val = parseInt(value, 10);
			} else {
				val = null;
			}
		} else if (isMultiple) {
			if (name === 'tradeAgreement') {
				val = Array.from(event.target.selectedOptions, option => tradeAgreementOptions.find(item => item.name === option.value));
			} else {
				val = Array.from(event.target.selectedOptions, option => option.value);
			}
		} else if (value) {
			if (trim) {
				val = value.replace(/\s/g, '');
			} else {
				val = value;
			}
		} else {
			val = '';
		}

		if (nestedField) {
			if (nestedField === 'procumentEntityData' && name === 'country') {
				setData(data => ({
					...data,
					[nestedField]: {
						...data[nestedField],
						'changeType': 'SAVE',
						[name]: val,
						'province': ''
					}
				}));
			} else {
				setData(data => ({
					...data,
					[nestedField]: {
						...data[nestedField],
						'changeType': 'SAVE',
						[name]: val
					}
				}));
			}
		} else {
			setData(data => ({ ...data, [name]: val }));
		}

		checkChanges();
	};

	const handleDateChange = (name, value) => {
		setData(data => ({ ...data, [name]: value }));

		checkChanges();
	};

	const handleChangeTenderId = (event) => {
		const { name, value } = event.target;
		let val;

		if (value) {
			if (value.match(BAD_CHARACTERS_REGEX)) {
				setTenderIdTriedBadChar(true);
			} else {
				setTenderIdTriedBadChar(false);
			}

			val = value.replace(BAD_CHARACTERS_REGEX, '');
		} else {
			val = '';
		}

		setData(data => ({ ...data, [name]: val }));

		checkChanges();
	};

	const handleChangeClosingDate = (value) => {
		// If it's today and the current closingTime value has passed, clear it.
		if (value && isValid(parseISO(value)) && isToday(parseISO(value)) && closingTime) {
			const parsedTime = parse(closingTime, 'kk:mm:ss', new Date());

			if (isBefore(parsedTime, new Date())) {
				setData(data => ({ ...data, closingTime: '' }));
			}
		}
	};

	const handleCheckClosingLocation = (event) => {
		const { value } = event.target;

		if (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) {
			let updatedAttachments = attachments;
			const addendumIndex = updatedAttachments.findIndex(x => x.documentType == DOCUMENT_TYPE_ADDENDUM);
			let updatedClosingLocationOther;
			let updatedContactDetails;
			updatedAttachments.forEach((x) => { if (x.documentType == DOCUMENT_TYPE_ADDENDUM && x.changeType != 'DELETE') { x.changeType = 'SAVE' } })
			if (value == '' || value == CLOSING_LOCATION_DISCOVERY) {
				// updatedAttachments[addendumIndex].changeType = 'SAVE';
				updatedAttachments[addendumIndex].notes = addendumNoteDefault;
				updatedClosingLocationOther = '';
				updatedContactDetails = contactDetailsDefault;
			} else if (value == CLOSING_LOCATION_BIDX) {
				// updatedAttachments[addendumIndex].changeType = 'SAVE';
				updatedAttachments[addendumIndex].notes = addendumNoteBidx;
				updatedClosingLocationOther = '';
				updatedContactDetails = contactDetailsBidx;
			} else if (value == CLOSING_LOCATION_OTHER) {
				// updatedAttachments[addendumIndex].changeType = 'SAVE';
				updatedAttachments[addendumIndex].notes = '';
				updatedContactDetails = '';
			} else {
				// updatedAttachments[addendumIndex].changeType = 'SAVE';
				updatedAttachments[addendumIndex].notes = addendumNoteDefault;
				updatedClosingLocationOther = closingLocationOtherText;
				updatedContactDetails = contactDetailsDefault;
			}

			setData(data => ({
				...data,
				closingLocationOtherText: updatedClosingLocationOther,
				contactDetails: updatedContactDetails,
				attachments: updatedAttachments
			}));
		}
	};

	const handleAddRepeaterItem = (event, list) => {
		event.currentTarget.blur();
		const currentList = JSON.parse(JSON.stringify(data[list]));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (!items) {
			items = [];
		}

		items.push({
			'changeType': 'SAVE',
			'tempId': uuidv4()
		});

		setData(data => ({ ...data, [list]: items }));

		checkChanges();
	};

	const handleRemoveRepeaterItem = (event, index, list) => {
		event.currentTarget.blur();
		const currentList = JSON.parse(JSON.stringify(data[list]));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (index > -1) {
			if (items[index] && items[index].id) {
				items[index]['changeType'] = 'DELETE';
			} else {
				items.splice(index, 1);
			}
		}

		setData(data => ({ ...data, [list]: items }));

		checkChanges();
	};

	const handleTradeAgreementChange = (event, tradeAgreementItem) => {
		const { checked, value } = event.target;
		const currentList = JSON.parse(JSON.stringify(tradeAgreement));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (!items) {
			return false;
		}

		if (checked) {
			items.push(tradeAgreementItem);

			if (value == TRADE_AGREEMENT_NONE_CODE) {
				items = items.filter(item => item.name == TRADE_AGREEMENT_NONE_CODE); // If 'None' has been checked, can't have any other values selected.
			} else {
				items = items.filter(item => item.name !== TRADE_AGREEMENT_NONE_CODE); // If anything else has been checked, can't have 'None' selected.
			}
		} else {
			items = items.filter(item => item.name !== value);
		}

		setData(data => ({ ...data, tradeAgreement: items }));

		checkChanges();
	};

	const handleUnspscFieldChange = (levels, index) => {
		const currentList = JSON.parse(JSON.stringify(data.unspscLevelData));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (!items) {
			return false;
		}

		items[index]['changeType'] = 'SAVE';

		if (Array.isArray(levels)) {
			levels.map(item => {
				items[index][item.name] = item.value;
				return false;
			});
		} else if (levels) {
			items[index][levels.name] = levels.value;
		} else {
			items[index] = { 'changeType': 'SAVE' };
		}

		if (items.filter(item => item.changeType !== 'DELETE').length && items.some(item => item.unspscLevel1 && item.unspscLevel2 && item.changeType !== 'DELETE')) {
			setHasCommoditiesError(false);
		} else {
			setHasCommoditiesError(true);
		}

		setData(data => ({ ...data, unspscLevelData: items }));
	};

	const handleBooleanChange = (event) => {
		const { name, value } = event.target;
		let val;

		if (value == '1') {
			val = true;
		} else {
			val = false;
		}

		if (name === 'sustainableCriteriaUsed') {
			setData(data => ({ ...data, [name]: val, 'sustainablePrimarySource': '', 'sustainablePrimaryReason': '' }));
		} else {
			setData(data => ({ ...data, [name]: val }));
		}

		checkChanges();
	};

	const handleCheckboxChange = (event, list, fieldName) => {
		const { value, checked } = event.target;
		const currentList = JSON.parse(JSON.stringify(data[list]));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (checked) {
			if (fieldName) {
				items.push({ changeType: 'SAVE', [fieldName]: value });
			} else {
				items.push(value);
			}
		} else if (fieldName) {
			if (items[items.findIndex((element) => element[fieldName] == value)] && items[items.findIndex((element) => element[fieldName] == value)].id) {
				items[items.findIndex((element) => element[fieldName] == value)].changeType = 'DELETE';
			} else {
				items.splice(items.findIndex((element) => element[fieldName] == value), 1);
			}
		} else {
			items.splice(items.indexOf(value), 1);
		}

		setData(data => ({ ...data, [list]: items }));

		checkChanges();
	};

	const handleCheckProcurementMethod = (event) => {
		const { value } = event.target;

		if (value == PROCUREMENT_METHOD_ACAN) {
			setData(data => ({
				...data,
				solicitationType: SOLICITATION_TYPE_ACAN
			}));
		}
	};

	const handleCheckSolicitationType = (event) => {
		const { value } = event.target;

		if (value == 'Other') {
			if (procurementMethod == PROCUREMENT_METHOD_ACAN) {
				setData(data => ({
					...data,
					procurementMethod: '',
					solicitationTypeOther: true,
					solicitationType: ''
				}));
			} else {
				setData(data => ({
					...data,
					solicitationTypeOther: true,
					solicitationType: ''
				}));
			}
		} else if (value == SOLICITATION_TYPE_ACAN) {
			setData(data => ({
				...data,
				procurementMethod: PROCUREMENT_METHOD_ACAN,
				solicitationTypeOther: null
			}));
		} else {
			setData(data => ({ ...data, solicitationTypeOther: null }));
		}
	};

	const handleUpdateAttachments = (updatedAttachments, list) => {
		if (updatedAttachments.some(item => item.documentType == DOCUMENT_TYPE_TENDER && (item.data || item.url || item.guid) && item.changeType !== 'DELETE')) {
			setHasTenderDocsError(false);
		} else {
			setHasTenderDocsError(true);
		}

		setData(data => ({ ...data, [list]: updatedAttachments }));
	};

	const handleCheckTotalFilesize = (type, hasTotalFilesizeError) => {
		if (type == DOCUMENT_TYPE_TENDER && hasTotalFilesizeError) {
			setHasTenderDocsFilesizeError(true);
		} else {
			setHasTenderDocsFilesizeError(false);
		}

		if (type == DOCUMENT_TYPE_ADDENDUM && hasTotalFilesizeError) {
			setHasAddendumDocsFilesizeError(true);
		} else {
			setHasAddendumDocsFilesizeError(false);
		}
	};

	const handleChangeEntity = (event) => {
		const { value } = event.target;
		let entity;

		if (Array.isArray(entityOptions)) {
			entity = entityOptions.find(item => item.id == value);
		}

		if (entity && entity.id) {
			setData(data => ({
				...data,
				procumentEntityData: {
					changeType: 'SAVE',
					id: entity.id,
					name: entity.name,
					nameFr: entity.nameFr,
					streetAddress: entity.streetAddress,
					unitNumber: entity.unitNumber,
					city: entity.city,
					province: entity.province,
					country: entity.country,
					postalCode: entity.postalCode,
					phoneNumber: entity.phoneNumber,
					email: entity.email,
					endUserEntityDataList: entity.endUserEntityDataList
				}
			}));
		} else {
			setData(data => ({ ...data, procumentEntityData: null }));
		}

		checkChanges();
	};

	const handleUpdateAwardDetails = (updatedAwardDetails) => {
		setData(data => ({ ...data, 'tenderAwardData': updatedAwardDetails }));
	};

	const handleAddAwardDetails = () => {
		setData(data => ({
			...data, 'tenderAwardData': [{
				'index': 0,
				'id': null,
				'supplier': '',
				'supplierLocation': '',
				'awardAmount': '',
				'currency': 'Canadian Dollar',
				'awardDate': '',
				'additionalDetails': '',
				'changeType': 'SAVE'
			}]
		}));

		if (awardDetailsRef.current) {
			awardDetailsRef.current.focus();
		}
	};

	const handleUpdateBidDetails = (updatedBidDetails) => {
		setData(data => ({ ...data, 'tenderBidInformationDataList': updatedBidDetails }));
	};

	const handleAddBidDetails = () => {
		setData(data => ({
			...data, 'tenderBidInformationDataList': [{
				'index': 0,
				'id': null,
				'supplier': '',
				'bidAmount': '',
				'currency': 'Canadian Dollar',
				'additionalNotes': '',
				'changeType': 'SAVE'
			}]
		}));

		if (bidDetailsRef.current) {
			bidDetailsRef.current.focus();
		}
	};

	const [showPostClosingDateWarning, setShowPostClosingDateWarning] = useState(false);
	const handleClosePostClosingDateWarning = () => setShowPostClosingDateWarning(false);
	const handleShowPostClosingDateWarning = () => setShowPostClosingDateWarning(true);

	const handleSubmit = (event) => {
		event.preventDefault();
		const form = formRef.current;

		setShowPostClosingDateWarning(false);

		if (data && data.tenderAwardData && awardRef && awardRef.current && awardRef.current.input) {
			let awardsValid = true;
			const missingFields = [];
			data.tenderAwardData.forEach(item => {
				if (!item.supplier) {
					awardsValid = false;
					if (!missingFields.includes('supplier')) {
						missingFields.push('supplier');
					}
				}

				if (!item.supplierLocation) {
					awardsValid = false;
					if (!missingFields.includes('supplier location')) {
						missingFields.push('supplier location');
					}
				}

				if (!item.awardDate) {
					awardsValid = false;
					if (!missingFields.includes('award date')) {
						missingFields.push('award date');
					}
				}

				if (!item.awardAmount) {
					awardsValid = false;
					if (!missingFields.includes('award amount')) {
						missingFields.push('award amount');
					}
				}

				if (!item.currency) {
					awardsValid = false;
					if (!missingFields.includes('currency')) {
						missingFields.push('currency');
					}
				}
			});

			if (awardsValid) {
				awardRef.current.input.setCustomValidity('');
			} else {
				let message = 'Please populate all rows';
				if (missingFields.length > 0) {
					let article = 'a';
					if (missingFields[0].match(/^[aieouAIEOU].*/)) {
						article = 'an';
					}

					let separator = ' and ';
					if (missingFields.length === 1) {
						separator = ' ';
					} else if (missingFields.length > 2) {
						separator = ', and ';
					}
					const lastField = missingFields.pop();
					const fieldMessage = missingFields.join(', ') + separator + lastField;
					message = 'Please enter ' + article + ' ' + fieldMessage + ' for all rows';
				}

				awardRef.current.input.setCustomValidity(message);
			}
		}

		if (data && data.tenderBidInformationDataList && bidRef && bidRef.current && bidRef.current.input) {
			let bidsValid = true;
			const missingFields = [];
			data.tenderBidInformationDataList.forEach((item) => {
				if (!item.supplier) {
					bidsValid = false;
					if (!missingFields.includes('supplier')) {
						missingFields.push('supplier');
					}
				}

				if (!item.bidAmount) {
					bidsValid = false;
					if (!missingFields.includes('bid amount')) {
						missingFields.push('bid amount');
					}
				}

				if (!item.currency) {
					bidsValid = false;
					if (!missingFields.includes('currency')) {
						missingFields.push('currency');
					}
				}
			});

			if (bidsValid) {
				bidRef.current.input.setCustomValidity('');
			} else {
				let message = 'Please populate all rows';
				if (missingFields.length > 0) {
					let article = 'a';
					if (missingFields[0].match(/^[aieouAIEOU].*/)) {
						article = 'an';
					}

					let separator = ' and ';
					if (missingFields.length === 1) {
						separator = ' ';
					} else if (missingFields.length > 2) {
						separator = ', and ';
					}
					const lastField = missingFields.pop();
					const fieldMessage = missingFields.join(', ') + separator + lastField;
					message = 'Please enter ' + article + ' ' + fieldMessage + ' for all rows';
				}
				bidRef.current.input.setCustomValidity(message);
			}
		}

		if (form.checkValidity() === false) {
			setHasErrors(true);
			event.preventDefault();
			event.stopPropagation();
		} else if (hasCommoditiesError || hasTenderDocsError || hasTenderDocsFilesizeError || hasAddendumDocsFilesizeError) {
			setHasErrors(true);
			event.preventDefault();
			event.stopPropagation();
		} else {
			setValidated(false);
			setHasErrors(false);
			setHasDraftErrors(false);
			setHasCommoditiesError(false);
			setHasTenderDocsError(false);
			setHasTenderDocsFilesizeError(false);
			setHasAddendumDocsFilesizeError(false);

			const updatedData = data;

			// If tender was draft & post date happens to be in the past.
			if (currentTender.tenderStatus == TENDER_STATUS_DRAFT && postDate && isPast(parseISO(updatedData.postDate))) {
				updatedData.postDate = format(new Date(), "yyyy-MM-dd'T'HH:mmxx"); // eslint-disable-line
			}

			if (postDate && isAfter(parseISO(postDate), new Date())) {
				updatedData.tenderStatus = TENDER_STATUS_SCHEDULED;
			} else if (currentTender.tenderStatus == TENDER_STATUS_DRAFT) {
				updatedData.tenderStatus = TENDER_STATUS_OPEN;
			} else if (currentTender.tenderStatus == TENDER_STATUS_SCHEDULED && postDate && !isAfter(parseISO(postDate), new Date())) {
				updatedData.tenderStatus = TENDER_STATUS_OPEN;
			}

			if (!contactMethod.find(item => item.method == 'email')) {
				data.contactEmail = null;
			}

			if (!contactMethod.find(item => item.method == 'phone')) {
				data.contactPhoneNumber = null;
			}

			if (updatedData.tenderAwardData && updatedData.tenderAwardData.length > 0) {
				updatedData.tenderAwardData = updatedData.tenderAwardData.map((item) => {
					delete item.index;

					if (item.changeType !== 'DELETE') {
						item.changeType = 'SAVE';
					}

					return item;
				});
			}

			if (updatedData.tenderBidInformationDataList && updatedData.tenderBidInformationDataList.length > 0) {
				updatedData.tenderBidInformationDataList = updatedData.tenderBidInformationDataList.map((item) => {
					delete item.index;

					if (item.changeType !== 'DELETE') {
						item.changeType = 'SAVE';
					}

					return item;
				});
			}

			let filteredUnspsc = [];

			if (Array.isArray(unspscLevelData) && unspscLevelData.length) {
				filteredUnspsc = unspscLevelData.filter(item => item.unspscLevel1 && item.unspscLevel1 !== '');
			}

			updatedData.unspscLevelData = filteredUnspsc;

			dispatch(tenderActions.postTender(JSON.stringify(updatedData), callback));
		}

		setValidated(true);
	};

	const handleSaveDraft = (event) => {
		event.preventDefault();
		const updatedData = data;

		if (!updatedData.tenderId || !updatedData.procumentEntityData || hasTenderDocsFilesizeError || hasAddendumDocsFilesizeError) {
			setHasDraftErrors(true);
			event.preventDefault();
			event.stopPropagation();

			if (!updatedData.tenderId) {
				setHasTenderIdError(true);
			} else {
				setHasTenderIdError(false);
			}

			if (!updatedData.procumentEntityData) {
				setHasProcurementEntityError(true);
			} else {
				setHasProcurementEntityError(false);
			}
		} else {
			setHasDraftErrors(false);
			setHasTenderIdError(false);
			setHasProcurementEntityError(false);
			setHasTenderDocsFilesizeError(false);
			setHasAddendumDocsFilesizeError(false);
			updatedData.tenderStatus = TENDER_STATUS_DRAFT;

			if (!contactMethod.find(item => item.method == 'email')) {
				updatedData.contactEmail = null;
			}

			if (!contactMethod.find(item => item.method == 'phone')) {
				updatedData.contactPhoneNumber = null;
			}

			let filteredUnspsc = [];

			if (Array.isArray(unspscLevelData) && unspscLevelData.length) {
				filteredUnspsc = unspscLevelData.filter(item => item.unspscLevel1 && item.unspscLevel1 !== '');
			}

			updatedData.unspscLevelData = filteredUnspsc;

			dispatch(tenderActions.postTender(JSON.stringify(updatedData), 'draft-tender'));
		}
	};

	return (
		<Form
			noValidate
			validated={validated}
			onSubmit={handleSubmit}
			className={'post-tender__form'}
			ref={formRef}
		>
			<fieldset>
				<legend><Translate text={'Summary Information'} /></legend>

				<Form.Group controlId="tenderId">
					<Form.Label>
						<Tooltip text={'Tender ID is a unique identifier to your organization\'s tender notice and you may set this value as desired using an alphanumeric structure. The system will ensure you do not create a duplicate value.\n\nIf you are new to the service and are looking for a format to use for your tender numbering, the below is suggested.\n\nStart with your organizations acronym, followed by the year, adding a hyphen and ending with your organization\'s own unique three (3) digit number. i.e CORPX2022-001'} />
						<Translate text={'Tender ID'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						type="text"
						name="tenderId"
						value={tenderId}
						maxLength={INPUT_MAX_LENGTH}
						required
						disabled={!canEdit('tenderId')}
						onChange={(e) => { handleChangeTenderId(e); }}
					/>

					{tenderIdTriedBadChar &&
						<Form.Text className="text-muted">
							<Translate text={BAD_CHARACTERS_MESSAGE} />
						</Form.Text>
					}

					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Tender ID'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				{isEdit && tenderStatus !== TENDER_STATUS_DRAFT && isRole(userData, [ROLE_PROCUREMENT_ADMIN]) &&
					<Form.Group controlId="tenderStatus">
						<Form.Label>
							<Translate text={'Status'} /> <span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="tenderStatus"
							value={(tenderStatus) ? tenderStatus : ''}
							required
							disabled={!statusOptions || !statusOptions.length || (currentTender.id && currentTender.tenderStatus == TENDER_STATUS_CANCELLED)}
							onChange={(e) => {
								handleChange(e);
							}}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{translate({ text: '- Select -' })}
									</option>
								)}
							</Translator>

							{(Array.isArray(statusOptions)) &&
								statusOptions.map((item, index) => {
									return (
										<option value={item.name} key={index}>
											{item.description}
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Status'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>
				}

				<Form.Group controlId="procurementMethod">
					<Form.Label>
						<Tooltip text={'Select the Procurement Method applicable to this tender opportunity.\n\n"Advance Contract Award Notice (ACAN)" means a public notice indicating to the supplier community that the organization intends to award a Good, Service or Construction contract to a pre-identified supplier, believed to be the only one capable of performing the work, thereby allowing other suppliers to signal their interest in bidding by submitting a statement of capabilities.\n\n"Competitive - Open Bidding" means the solicitation of bids through a publicly posted solicitation type (document).'} />
						<Translate text={'Procurement Method'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						as="select"
						name="procurementMethod"
						value={(procurementMethod) ? procurementMethod : ''}
						required
						disabled={!procurementMethodOptions || !procurementMethodOptions.length || !canEdit('procurementMethod')}
						onChange={(e) => { handleChange(e); handleCheckProcurementMethod(e); }}
					>
						<Translator>
							{({ translate }) => (
								<option value="">
									{translate({ text: '- Select -' })}
								</option>
							)}
						</Translator>

						{procurementMethod && hasOldProcurementMethod &&
							<option value={procurementMethod}>
								{procurementMethod}
							</option>
						}

						{(Array.isArray(procurementMethodOptions)) &&
							procurementMethodOptions.map((item, index) => {
								return (
									<option value={item.description} key={index}>
										{item.description}
									</option>
								);
							})
						}
					</Form.Control>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Procurement Method'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId="solicitationType">
					<Form.Label>
						<Tooltip>
							<Translate text={'Choose the solicitation format being used to solicit bids or responses from bidders.\n\nExamples of solicitation document can be found under the Format Selection Protocol at: '} /><a href={'https://procurement.novascotia.ca/media/53284/Protocols.pdf'}><Translate text={'https://procurement.novascotia.ca/media/53284/Protocols.pdf'} /></a>
						</Tooltip>
						<Translate text={'Solicitation Type'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						as="select"
						name="solicitationType"
						value={(solicitationTypeOther) ? 'Other' : (solicitationType) ? solicitationType : ''}
						required
						disabled={!solicitationTypeOptions || !solicitationTypeOptions.length || !canEdit('solicitationType')}
						onChange={(e) => { handleChange(e); handleCheckSolicitationType(e); }}
					>
						<Translator>
							{({ translate }) => (
								<option value="">
									{translate({ text: '- Select -' })}
								</option>
							)}
						</Translator>

						{(Array.isArray(solicitationTypeOptions)) &&
							solicitationTypeOptions.map((item, index) => {
								return (
									<option value={item.description} key={index}>
										{item.description}
									</option>
								);
							})
						}
					</Form.Control>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Solicitation Type'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				{solicitationTypeOther &&
					<Form.Group controlId="solicitationTypeOther">
						<Form.Label>
							<Translate text={'If Other'} /> <span className="required">*</span>
						</Form.Label>
						<Form.Control
							type="text"
							name="solicitationType"
							value={solicitationType}
							maxLength={INPUT_MAX_LENGTH}
							required
							disabled={!canEdit('solicitationType')}
							onChange={(e) => { handleChange(e); }}
						/>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Solicitation Type Other'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>
				}

				<Form.Group controlId="title">
					<Form.Label>
						<Tooltip text={'Title of the notice needs to accurately reflect the procurement being tendered. Be specific.\n\nE.g. Drainage, Guard Rail, and Asphalt Concrete Patching and Repairing, Three Projects in Victoria County'} />
						<Translate text={'Title'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						type="text"
						name="title"
						value={title}
						maxLength={INPUT_MAX_LENGTH}
						required
						disabled={!canEdit('title')}
						onChange={(e) => { handleChange(e); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Title'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId="description">
					<Form.Label className="max-width-none">
						<Tooltip>
							<Translate
								text={'This field must be populated with a description of the procurement. The information in this field provides suppliers with a more detailed outline of the Goods, Services or Construction required. E.g. The Province is requesting proposals from qualified consultants to conduct a Electric Vehicle Study that will aid in both short and long-term strategies to adopting electric vehicles (EV) and advancing the Province in becoming an EV-ready province.\n\nFor procurements that are at or above CETA <Link>, description must also include when applicable:\n\n1) a description of any options;\n2) whether it will involve negotiation;\n3) whether the process will involve electronic auctions;\n4) for recurring contracts, an estimate, if possible, of the timing of subsequent notices of intended procurement;\n5) if limiting the number of suppliers able to participate, include the criteria being used to limit participation, the number of suppliers that will be permitted to participate, and the submission address to request to participate.'}
								renderMap={{
									renderLink: () => <a href="https://beta.novascotia.ca/sites/default/files/documents/1-1072/trade-agreements-thresholds-and-posting-timelines-en.pdf" target="_blank" rel="noopener noreferrer"><Translate text={'thresholds'} /></a> // eslint-disable-line react/display-name
								}}
							/>
						</Tooltip>
						<Translate text={'Description'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						as="textarea"
						name="description"
						value={description}
						maxLength={DESCRIPTION_MAX_LENGTH}
						required
						disabled={!canEdit('description')}
						onChange={(e) => { handleChange(e); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Description'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group role="group">
					<h4>
						<Tooltip>
							<Translate text={'Select all applicable trade agreement(s).\n\nFor more detailed information about each trade agreement visit '} /><a href={'https://beta.novascotia.ca/documents/trade-agreements-procurement?_ga=2.145261748.1281300658.1668446582-1068026663.1668446582'} target="_blank" rel="noopener noreferrer"><Translate text={'https://beta.novascotia.ca/documents/trade-agreements-procurement'} /></a>
						</Tooltip>
						<Translate text={'Trade Agreement (Select all that apply)'} /> <span className="required">*</span>
					</h4>

					{(Array.isArray(tradeAgreementOptions)) &&
						tradeAgreementOptions.map((item, index) => {
							return (
								<Form.Check
									key={index}
									label={item.description}
									name="tradeAgreement"
									id={`tradeAgreement-${index}`}
									className="form-check-big max-width-none"
									type="checkbox"
									value={item.name}
									required={!tradeAgreement || (Array.isArray(tradeAgreement) && !tradeAgreement.length)}
									checked={(Array.isArray(tradeAgreement) && tradeAgreement.find(el => el.name == item.name)) ? true : false}
									disabled={!canEdit('tradeAgreement')}
									onChange={(e) => {
										handleTradeAgreementChange(e, item);

										if (e.target.validity.valid) {
											e.target.closest('.form-group').classList.remove('is-invalid');
										}
									}}
									onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
								/>
							);
						})
					}

					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Trade Agreement'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>
			</fieldset>
			<fieldset>
				<legend>
					<Tooltip>
						<Translate
							text={'Add all commodities related to your tender.\n\nThe commodity list is based on the hierarchy of United Nations Standard Products and Services Codes (UNSPSC). For guidance on selecting commodities, watch this <Link>.'}
							renderMap={{
								renderLink: () => <a href="https://www.youtube.com/watch?v=k5rxuehmFIs&t=3s" target="_blank" rel="noopener noreferrer"><Translate text={'video'} /></a> // eslint-disable-line react/display-name
							}}
						/>
					</Tooltip>
					<Translate text={'Commodities'} /> <span className="required">*</span>
				</legend>

				<div className="repeater">
					{unspscLevelData && Array.isArray(unspscLevelData) && unspscLevelData.filter(item => item.changeType !== 'DELETE').length > 0 && unspscLevelData.map((item, index) => {
						if (item.changeType == 'DELETE') {
							return false;
						}

						const key = item.id || item.tempId;

						return (
							<div className="repeater-item" key={key || index}>
								<Row>
									<Col md={9}>
										<FormUNSPSC
											index={index}
											item={item}
											filterLevels={['LEVEL1']}
											disabled={!canEdit('unspscLevelData')}
											handleOnChange={(level) => { handleUnspscFieldChange(level, index); }}
											handleOnChangeMultiple={(levels) => { handleUnspscFieldChange(levels, index); }}
										/>
									</Col>
									<Col md={3} className="repeater-remove">
										{(canEdit('unspscLevelData')) &&
											<Translator>
												{({ translate }) => (
													<Button
														variant="link"
														className="text-danger ml-2"
														aria-label={
															translate({
																text: (title) ? 'Remove - Commodity #{number}' : 'Remove #{number}',
																data: { type: title, number: (index.toString()) ? index + 1 : null }
															})
														}
														onClick={(e) => { handleRemoveRepeaterItem(e, index, 'unspscLevelData'); }}
													>
														<Translate text={'Remove'} />
													</Button>
												)}
											</Translator>
										}
									</Col>
								</Row>
							</div>
						);
					})}

					{(canEdit('unspscLevelData')) &&
						<div className="repeater-controls">
							<Translator>
								{({ translate }) => (
									<Button
										onClick={(e) => { handleAddRepeaterItem(e, 'unspscLevelData'); }}
										aria-label={
											translate({
												text: 'Add Another - Commodity'
											})
										}
									>
										<Translate text={'Add Another'} />
									</Button>
								)}
							</Translator>
						</div>
					}
				</div>
			</fieldset>

			<fieldset>
				<legend><Translate text={'Bidding Details'} /></legend>

				<Form.Group controlId="expectedDurationOfContract">
					<Form.Label>
						<Tooltip text={'Provide the estimated duration of the contract in months. If not applicable (e.g., Request for Information, or Contract duration is to be specified in submissions), enter zero.'} />
						<Translate text={'Estimated Duration of Contract (months)'} /><span className="required">*</span>
					</Form.Label>
					<NumberFormat
						customInput={Form.Control}
						type="text"
						name="expectedDurationOfContract"
						value={expectedDurationOfContract}
						maxLength={3}
						allowNegative={false}
						required={true}
						disabled={!canEdit('expectedDurationOfContract')}
						onChange={(e) => { handleChange(e, true); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Expected Duration of Contract'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Translator>
					{({ translate }) => (
						<>
							<Attachments
								title={<Translate text={'Tender Documents<Req>'} renderMap={{ renderReq: () => <span className="required">*</span> }} />} // eslint-disable-line react/display-name
								titleBefore={<Tooltip text={translate({ text: 'Upload tender documents or add the link where suppliers can access the tender documents. Total filesize limit of {filesize}.', data: { filesize: totalFilesizeString } })} />}
								documentType={DOCUMENT_TYPE_TENDER}
								data={data}
								additionalField={{
									label: translate({ text: 'Document Location URL' }),
									name: 'url',
									type: 'text',
									tooltipText: 'Add link where suppliers can access tender documents.'
								}}
								readOnly={(isEdit && data.tenderStatus !== TENDER_STATUS_DRAFT && data.tenderStatus !== TENDER_STATUS_OPEN && data.tenderStatus !== TENDER_STATUS_SCHEDULED) || !canEdit('attachments')}
								validated={validated}
								handleUpdateAttachments={(data) => { handleUpdateAttachments(data, 'attachments'); }}
								handleCheckTotalFilesize={(hasTotalFilesizeError) => { handleCheckTotalFilesize(DOCUMENT_TYPE_TENDER, hasTotalFilesizeError); }}
							/>
						</>
					)}
				</Translator>

				{(isEdit && ![TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(data.tenderStatus)) &&
					<Translator>
						{({ translate }) => (
							<Attachments
								title={translate({ text: 'Addendum Documents / Notes' })}
								titleBefore={<Tooltip text={translate({ text: 'An addendum is an additional document not included in the main part of the tender documents. It is an ad hoc item, usually compiled and executed after the main document, which contains additional terms, obligations or information. Total filesize limit of {filesize}.', data: { filesize: totalFilesizeString } })} />}
								documentType={DOCUMENT_TYPE_ADDENDUM}
								data={data}
								additionalField={{
									label: translate({ text: 'Notes' }),
									name: 'notes',
									type: 'textarea',
									tooltipText: 'Add additional details related to addendum which may be useful to the supplier.',
									defaultValue: (!isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL])) ? addendumNoteDefault : null
								}}
								readOnly={!canEdit('attachments') && !data.tenderStatus == TENDER_STATUS_CANCELLED}
								validated={validated}
								handleUpdateAttachments={(data) => { handleUpdateAttachments(data, 'attachments'); }}
								handleCheckTotalFilesize={(hasTotalFilesizeError) => { handleCheckTotalFilesize(DOCUMENT_TYPE_ADDENDUM, hasTotalFilesizeError); }}
							/>
						)}
					</Translator>
				}

				<Form.Group className={(validated && !closingDate) ? 'is-invalid' : null}>
					<Form.Label htmlFor="closingDate">
						<Tooltip>
							<Translate
								text={'Enter the date when the tender will close for bidding.\n\nSuppliers must always be provided a reasonable amount of time to prepare and submit responses, regardless of any prescribed minimum solicitation periods. The setting of a solicitation closing date must take into account the level of complexity of the procurement and the extent of subcontracting anticipated. Sufficient time must be allowed for a supplier to obtain the solicitation, and any additional material, if applicable, and to prepare and submit a response. Information on the time periods that must be followed during the procurement of goods, services and construction are outlined here <Link>.'}
								renderMap={{
									renderLink: () => <a href="https://beta.novascotia.ca/sites/default/files/documents/1-2985/posting-time-periods-international-trade-agreements-en.pdf" target="_blank" rel="noopener noreferrer"><Translate text={'Posting Time Periods for International Trade Agreements'} /></a> // eslint-disable-line react/display-name
								}}
							/>
						</Tooltip>
						<Translate text={'Closing Date'} /> <span className="required">*</span>
					</Form.Label>
					<FormDatePicker
						id="closingDate"
						name="closingDate"
						value={closingDate}
						minDate={(postDate) ? parseISO(postDate) : new Date()}
						required={true}
						disabled={!canEdit('closingDate')}
						handleOnChange={(value) => { handleDateChange('closingDate', value); handleChangeClosingDate(value); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Closing Date'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Form.Group controlId="closingTime">
					<Form.Label>
						<Tooltip text={'This field represents the closing time associated with the submission of a bid and is always represented in Atlantic Time. The default value of this field is 2:00 PM, though users may change this time during notice creation to suit their organization\'s requirements.'} />
						<Translate text={'Closing Time (Atlantic Time)'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						as="select"
						name="closingTime"
						value={(closingTime) ? closingTime : ''}
						required
						disabled={!closingTimeOptions || !closingTimeOptions.length || !canEdit('closingTime')}
						onChange={(e) => { handleChange(e); }}
					>
						<Translator>
							{({ translate }) => (
								<option value="">
									{translate({ text: '- Select -' })}
								</option>
							)}
						</Translator>

						{(Array.isArray(closingTimeOptions)) &&
							closingTimeOptions.map((item, index) => {
								if (item.value) {
									let disabled = false;

									if (closingDate && isValid(parseISO(closingDate)) && isToday(parseISO(closingDate))) {
										const parsedValue = parse(item.value, 'kk:mm:ss', new Date());

										if (isBefore(parsedValue, new Date())) {
											disabled = true;
										} else {
											disabled = false;
										}
									}

									return (
										<option value={item.value} disabled={disabled} key={index}>
											{item.description}
										</option>
									);
								}

								return null;
							})
						}
					</Form.Control>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Closing Time'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				{(isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL]) && !hasCustomClosingLocation) &&
					<>
						<Form.Group controlId="closingLocation">
							<Form.Label>
								<Tooltip text={'Enter the full physical address or URL where the tender must be submitted.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.'} />
								<Translate text={'Closing Location'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								as="select"
								name="closingLocation"
								value={(closingLocation) ? closingLocation : ''}
								required
								disabled={!closingLocationOptions || !closingLocationOptions.length || !canEdit('closingLocation')}
								onChange={(e) => { handleChange(e); handleCheckClosingLocation(e); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{translate({ text: '- Select -' })}
										</option>
									)}
								</Translator>

								{(Array.isArray(closingLocationOptions)) &&
									closingLocationOptions.map((item, index) => {
										return (
											<option value={item.value} key={index}>
												{item.description}
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Closing Location'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>

						{closingLocation == CLOSING_LOCATION_OTHER &&
							<Form.Group controlId="closingLocationOtherText">
								<Form.Label>
									<Translate text={'If Other'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="closingLocationOtherText"
									value={closingLocationOtherText}
									maxLength={INPUT_MAX_LENGTH}
									required
									disabled={!canEdit('closingLocation')}
									onChange={(e) => { handleChange(e); }}
								/>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Closing Location Other'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>
						}
					</>
				}

				{(!isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL]) || hasCustomClosingLocation) &&
					<Form.Group controlId="closingLocation">
						<Form.Label>
							<Tooltip text={'Enter the full physical address or URL where the tender must be submitted.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.'} />
							<Translate text={'Closing Location'} /> <span className="required">*</span>
						</Form.Label>
						<Form.Control
							type="text"
							name="closingLocation"
							value={closingLocation}
							maxLength={INPUT_MAX_LENGTH}
							required
							disabled={!canEdit('closingLocation')}
							onChange={(e) => { handleChange(e); }}
						/>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Closing Location'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>
				}

				<div className="tender-form-hidden-fields">
					{publicFieldsHidden &&
						<Alert variant="primary" className="alert-small mb-0">
							<span className="alert-icon">
								<FontAwesomeIcon icon={faEyeSlash} />
							</span>
							<span className="alert-message text-center">
								<Translate text={'6 fields hidden.'} />
								<Button
									variant="link"
									onClick={(e) => { e.preventDefault(); setPublicFieldsHidden(false); }}
								>
									<Translate text={'Show Hidden Fields'} />
								</Button>
							</span>
							<span className="alert-icon">
								<FontAwesomeIcon icon={faEyeSlash} />
							</span>
						</Alert>
					}

					{!publicFieldsHidden &&
						<>
							<Form.Group>
								<Form.Label htmlFor="publicOpeningDate">
									<Tooltip text={'If a public opening is is part of your process, enter the date and time bids will be opened.'} />
									<Translate text={'Public Opening Date (Optional)'} />
								</Form.Label>
								<FormDatePicker
									id="publicOpeningDate"
									name="publicOpeningDate"
									value={(publicOpeningDate) ? publicOpeningDate : ''}
									minDate={new Date()}
									disabled={!canEdit('publicOpeningDate')}
									handleOnChange={(value) => { handleDateChange('publicOpeningDate', value); }}
								/>
							</Form.Group>

							<Form.Group controlId="publicOpeningTime">
								<Form.Label>
									<Translate text={'Public Opening Time (Optional)'} />
									<Tooltip text={'All times in AST'} />
								</Form.Label>
								<Form.Control
									as="select"
									name="publicOpeningTime"
									value={(publicOpeningTime) ? publicOpeningTime : ''}
									disabled={!closingTimeOptions || !closingTimeOptions.length || !canEdit('publicOpeningTime')}
									onChange={(e) => { handleChange(e); }}
								>
									<Translator>
										{({ translate }) => (
											<option value="">
												{translate({ text: NOT_APPLICABLE })}
											</option>
										)}
									</Translator>

									{(Array.isArray(closingTimeOptions)) &&
										closingTimeOptions.map((item, index) => {
											return (
												<option value={item.value} key={index}>
													{item.description}
												</option>
											);
										})
									}
								</Form.Control>
							</Form.Group>

							<Form.Group controlId="publicOpeningLocation">
								<Form.Label>
									<Tooltip text={'If a public opening is part of your process, enter the location where bids will be opened.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.'} />
									<Translate text={'Public Opening Location (Optional)'} />
								</Form.Label>
								<Form.Control
									type="text"
									name="publicOpeningLocation"
									value={publicOpeningLocation}
									maxLength={INPUT_MAX_LENGTH}
									disabled={!canEdit('publicOpeningLocation')}
									onChange={(e) => { handleChange(e); }}
								/>
							</Form.Group>

							<Form.Group controlId="submissionLanguage">
								<Form.Label>
									<Tooltip text={'Select the language in which responses may be submitted.'} />
									<Translate text={'Submission Language'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									as="select"
									name="submissionLanguage"
									value={(submissionLanguage) ? submissionLanguage : ''}
									required
									disabled={!languageOptions || !languageOptions.length || !canEdit('submissionLanguage')}
									onChange={(e) => { handleChange(e); }}
								>
									{(Array.isArray(languageOptions)) &&
										languageOptions.map((item, index) => {
											return (
												<option value={item.name} key={index}>
													{item.label}
												</option>
											);
										})
									}
								</Form.Control>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Submission Language'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="pickUpFee">
								<Form.Label>
									<Tooltip text={'If applicable, include any cost associated with accessing tender documents.'} />
									<Translate text={'Pick Up Fee'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="pickUpFee"
									value={pickUpFee}
									maxLength={INPUT_MAX_LENGTH}
									required
									disabled={!canEdit('pickUpFee')}
									onChange={(e) => { handleChange(e); }}
								/>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Pick Up Fee'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group controlId="termsOfPayment">
								<Form.Label>
									<Tooltip text={'If applicable, include terms of payment to access tender documents.'} />
									<Translate text={'Pick Up Fee Terms of Payment'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									type="text"
									name="termsOfPayment"
									value={termsOfPayment}
									maxLength={INPUT_MAX_LENGTH}
									required
									disabled={!canEdit('termsOfPayment')}
									onChange={(e) => { handleChange(e); }}
								/>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Terms of Payment'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>
						</>
					}

					{!publicFieldsHidden &&
						<Alert variant="primary" className="alert-small mb-0">
							<span className="alert-icon">
								<FontAwesomeIcon icon={faEyeSlash} />
							</span>
							<span className="alert-message text-center">
								<Button
									variant="link"
									onClick={(e) => { e.preventDefault(); setPublicFieldsHidden(true); }}
								>
									<Translate text={'Hide Fields'} />
								</Button>
							</span>
							<span className="alert-icon">
								<FontAwesomeIcon icon={faEyeSlash} />
							</span>
						</Alert>
					}
				</div>

				<Form.Group controlId="memo">
					<Form.Label className="max-width-none">
						<Tooltip text={'Use this space to detail specific information about the procurement. I.E. site visits, additional instructions, notifications'} />
						<Translate text={'Memorandum'} />
					</Form.Label>
					<Form.Control
						as="textarea"
						name="memo"
						value={memo}
						maxLength={MESSAGE_MAX_LENGTH}
						disabled={!canEdit('memo')}
						onChange={(e) => { handleChange(e); }}
					/>
				</Form.Group>

				{(isRole(userData, [ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER]) || (isEdit && isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL]) && procumentEntityData && procumentEntityData.organizationId && procumentEntityData.organizationId !== PNS_ORG_ID)) &&
					<>
						<Translator>
							{({ translate }) => (
								<Form.Group role="group" aria-label={translate({ text: 'Were sustainable procurement criteria used for this purchase?' })}>
									<h4>
										<Tooltip text={'Sustainable procurement helps drive best value for government purchasing. Sustainability specifications and guidance can be found in the Sourcing Library or by contacting the Manager of Sustainable Procurement. Sustainable purchasing requirements for new product and service categories can be developed in consultation with you and your clients.'} />
										<Translate text={'Were sustainable procurement criteria used for this purchase?'} /><span className="required">*</span>
									</h4>
									<Form.Check
										inline
										label={translate({ text: 'Yes' })}
										name="sustainableCriteriaUsed"
										id="sustainableCriteriaUsed-yes"
										className="form-check-big"
										type="radio"
										value="1"
										required
										disabled={!canEdit('sustainableCriteriaUsed')}
										checked={(sustainableCriteriaUsed == true || sustainablePrimarySource !== '')}
										onChange={(e) => {
											handleBooleanChange(e);

											if (e.target.validity.valid) {
												e.target.closest('.form-group').classList.remove('is-invalid');
											}
										}}
										onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
									/>
									<Form.Check
										inline
										label={translate({ text: 'No' })}
										name="sustainableCriteriaUsed"
										id="sustainableCriteriaUsed-no"
										className="form-check-big"
										type="radio"
										value="0"
										required
										disabled={!canEdit('sustainableCriteriaUsed')}
										checked={((sustainableCriteriaUsed == false) || sustainablePrimaryReason !== '')}
										onChange={(e) => {
											handleBooleanChange(e);

											if (e.target.validity.valid) {
												e.target.closest('.form-group').classList.remove('is-invalid');
											}
										}}
										onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
									/>
									<Form.Control.Feedback type="invalid">
										<Translate text={'Please choose an option'} />
									</Form.Control.Feedback>
								</Form.Group>
							)}
						</Translator>

						{(sustainableCriteriaUsed == true || (sustainablePrimarySource !== '' && sustainablePrimarySource !== null)) &&
							<Form.Group controlId="sustainablePrimarySource">
								<Form.Label className="max-width-none">
									<Tooltip text={'Client Provided Requirements: Client Department provided sustainability requirements.\n\nExisting Specification or Guidance: Sustainability specifications or guidance from Sourcing Library was used.\n\nNew Specification or Requirements: New sustainability specifications were developed for this category or project.'} />
									<Translate text={'Primary source of sustainable procurement criteria used'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									as="select"
									name="sustainablePrimarySource"
									value={(sustainablePrimarySource) ? sustainablePrimarySource : ''}
									required
									disabled={!sustainableSourceOptions || !sustainableSourceOptions.length || !canEdit('sustainablePrimarySource')}
									onChange={(e) => { handleChange(e); }}
								>
									<Translator>
										{({ translate }) => (
											<option value="">
												{translate({ text: '- Select -' })}
											</option>
										)}
									</Translator>

									{(Array.isArray(sustainableSourceOptions)) &&
										sustainableSourceOptions.map((item, index) => {
											return (
												<option value={item.description} key={index}>
													{item.description}
												</option>
											);
										})
									}
								</Form.Control>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Primary source'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>
						}

						{((sustainableCriteriaUsed !== null && sustainableCriteriaUsed == false) || sustainablePrimaryReason !== '') &&
							<Form.Group controlId="sustainablePrimarySource">
								<Form.Label className="max-width-none">
									<Tooltip text={'Client department did not support: Client department did not support alternative product/service for technical or operational reasons.\n\nCost: Alternative product/service exists but increases costs of procurement.\n\nNo market availability: Alternative product/service not available in local market.\n\nNo specifications available: Specifications not available for product/service category.\n\nNot Considered: Sustainability requirements were not considered for this purchase.\n\nPerformance: Performance of alternative product/service is unknown or unacceptable.\n\nTime constraints: Client required a short timeline for project.'} />
									<Translate text={'Primary reason for not using sustainable procurement criteria'} /> <span className="required">*</span>
								</Form.Label>
								<Form.Control
									as="select"
									name="sustainablePrimaryReason"
									value={(sustainablePrimaryReason) ? sustainablePrimaryReason : ''}
									required
									disabled={!sustainableReasonOptions || !sustainableReasonOptions.length || !canEdit('sustainablePrimaryReason')}
									onChange={(e) => { handleChange(e); }}
								>
									<Translator>
										{({ translate }) => (
											<option value="">
												{translate({ text: '- Select -' })}
											</option>
										)}
									</Translator>

									{(Array.isArray(sustainableReasonOptions)) &&
										sustainableReasonOptions.map((item, index) => {
											return (
												<option value={item.description} key={index}>
													{item.description}
												</option>
											);
										})
									}
								</Form.Control>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Primary reason'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>
						}
					</>
				}
			</fieldset>

			{isEdit && data.tenderStatus !== TENDER_STATUS_DRAFT && data.closingDate && parseISO(data.closingDate) <= new Date() &&
				<fieldset ref={bidDetailsRef} tabIndex="-1">
					<legend><Translate text={'Bids'} /></legend>
					{data && data.tenderBidInformationDataList && Array.isArray(data.tenderBidInformationDataList) && data.tenderBidInformationDataList.length > 0 &&
						<BidTable data={data.tenderBidInformationDataList} handleSave={handleUpdateBidDetails} ref={bidRef} />
					}

					{(currentTender.tenderStatus !== TENDER_STATUS_CANCELLED && (!data || !data.tenderBidInformationDataList || !Array.isArray(data.tenderBidInformationDataList) || data.tenderBidInformationDataList.length === 0)) &&
						<div className="text-right">
							<Button onClick={handleAddBidDetails}><Translate text={'Add a Bid'} /></Button>
						</div>
					}
				</fieldset>
			}

			<fieldset>
				<legend><Translate text={'Contact Information'} /></legend>

				<Form.Group controlId="procurementEntity">
					<Form.Label><Translate text={'Procurement Entity'} /> <span className="required">*</span></Form.Label>
					<Form.Control
						as="select"
						name="procumentEntityData"
						value={(procumentEntityData) ? procumentEntityData.id : ''}
						disabled={!entityOptions || !entityOptions.length || !canEdit('procumentEntityData')}
						required
						onChange={handleChangeEntity}
					>
						<Translator>
							{({ translate }) => (
								<option value="">
									{translate({ text: '- Select -' })}
								</option>
							)}
						</Translator>

						{procumentEntityData && hasOldProcurementEntity &&
							<option value={procumentEntityData.id}>
								{(language && language.value == LANGUAGE_FRENCH && procumentEntityData.nameFr) ? procumentEntityData.nameFr : procumentEntityData.name}
							</option>
						}

						{(Array.isArray(entityOptions)) &&
							entityOptions.map((item, index) => {
								return (
									<option value={item.id} key={index}>
										{(language && language.value == LANGUAGE_FRENCH && item.nameFr) ? item.nameFr : item.name}
									</option>
								);
							})
						}
					</Form.Control>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Procurement Entity'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				{procumentEntityData && procumentEntityData.id &&
					<>
						<hr />

						<Form.Group controlId="procurementEntityStreetAddress">
							<Form.Label>
								<Tooltip text={'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice.'} />
								<Translate text={'Street Address'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="streetAddress"
								value={(procumentEntityData.streetAddress) ? procumentEntityData.streetAddress : ''}
								maxLength={INPUT_MAX_LENGTH}
								required
								disabled={!canEdit('procumentEntityData')}
								onChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Street Address'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group controlId="procurementEntityUnitNumber">
							<Form.Label><Translate text={'Unit Number'} /></Form.Label>
							<Form.Control
								type="text"
								name="unitNumber"
								value={(procumentEntityData.unitNumber) ? procumentEntityData.unitNumber : ''}
								maxLength={INPUT_MAX_LENGTH}
								disabled={!canEdit('procumentEntityData')}
								onChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
							/>
						</Form.Group>

						<Form.Group controlId="procurementEntityCity">
							<Form.Label><Translate text={'City'} /> <span className="required">*</span></Form.Label>
							<Form.Control
								type="text"
								name="city"
								value={(procumentEntityData.city) ? procumentEntityData.city : ''}
								maxLength={INPUT_MAX_LENGTH}
								required
								disabled={!canEdit('procumentEntityData')}
								onChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'City'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>

						<FormCountry
							id="procurementEntityCountry"
							name="country"
							value={(procumentEntityData.country) ? procumentEntityData.country : ''}
							required={true}
							disabled={!canEdit('procumentEntityData')}
							handleOnChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
						/>

						<FormProvinceState
							id="procurementEntityProvince"
							name="province"
							value={(procumentEntityData.province) ? procumentEntityData.province : ''}
							country={(procumentEntityData.country) ? procumentEntityData.country : ''}
							required={true}
							disabled={!canEdit('procumentEntityData')}
							handleOnChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
						/>

						<Form.Group controlId="procurementEntityPostalCode">
							<Form.Label><Translate text={'Postal Code'} /> <span className="required">*</span></Form.Label>
							<Form.Control
								type="text"
								name="postalCode"
								value={(procumentEntityData.postalCode) ? procumentEntityData.postalCode : ''}
								maxLength={7}
								required
								disabled={!canEdit('procumentEntityData')}
								onChange={(e) => { handleChange(e, null, null, 'procumentEntityData'); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Postal Code'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>

						<hr />
					</>
				}

				<Form.Group controlId="contactName">
					<Form.Label>
						<Tooltip text={'Enter a specific contact name for inquiries, or directions for contact e.g., Call and leave message; or Send email to below address.'} />
						<Translate text={'Contact Name'} /> <span className="required">*</span>
					</Form.Label>
					<Form.Control
						type="text"
						name="contactName"
						value={contactName}
						maxLength={INPUT_MAX_LENGTH}
						required
						disabled={!canEdit('contactName')}
						onChange={(e) => { handleChange(e); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Contact Name'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>

				<Translator>
					{({ translate }) => (
						<>
							<Form.Group role="group" aria-label={translate({ text: 'Contact Method' })}>
								<h4>
									<Translate text={'Contact Method'} /><span className="required">*</span>
								</h4>
								<Form.Check
									inline
									label={translate({ text: 'Email' })}
									name="contactMethod"
									id="contactMethod-email"
									className="form-check-big"
									type="checkbox"
									value="email"
									required={(!contactMethod || !contactMethod.length)}
									disabled={!canEdit('contactMethod')}
									checked={(Array.isArray(contactMethod) && contactMethod.find(item => item.method == 'email' && item.changeType !== 'DELETE')) ? true : false}
									onChange={(e) => {
										handleCheckboxChange(e, 'contactMethod', 'method');

										if (e.target.validity.valid) {
											e.target.closest('.form-group').classList.remove('is-invalid');
										}
									}}
									onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
								/>
								<Form.Check
									inline
									label={translate({ text: 'Phone Number' })}
									name="contactMethod"
									id="contactMethod-phone"
									className="form-check-big"
									type="checkbox"
									value="phone"
									required={(!contactMethod || !contactMethod.length)}
									disabled={!canEdit('contactMethod')}
									checked={(Array.isArray(contactMethod) && contactMethod.find(item => item.method == 'phone' && item.changeType !== 'DELETE')) ? true : false}
									onChange={(e) => {
										handleCheckboxChange(e, 'contactMethod', 'method');

										if (e.target.validity.valid) {
											e.target.closest('.form-group').classList.remove('is-invalid');
										}
									}}
									onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
								/>
								<Form.Control.Feedback type="invalid">
									<Translate
										text={'{formLabel} is required'}
										data={{
											formLabel: 'Contact Method'
										}}
									/>
								</Form.Control.Feedback>
							</Form.Group>

							{(Array.isArray(contactMethod) && contactMethod.find(item => item.method == 'email' && item.changeType !== 'DELETE')) &&
								<FormEmail
									id="contactEmail"
									name="contactEmail"
									value={contactEmail}
									labelText={translate({ text: 'Procurement Entity Contact Email' })}
									labelBefore={<Tooltip text={'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice.'} />}
									required
									disabled={!canEdit('contactEmail')}
									handleOnChange={(id, value) => { handleChange({ target: { name: id, value: value } }); }}
								/>
							}

							{(Array.isArray(contactMethod) && contactMethod.find(item => item.method == 'phone' && item.changeType !== 'DELETE')) &&
								<FormPhone
									id="contactPhoneNumber"
									name="contactPhoneNumber"
									value={contactPhoneNumber}
									labelText={translate({ text: 'Procurement Entity Contact Phone' })}
									labelBefore={<Tooltip text={'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice.'} />}
									required
									disabled={!canEdit('contactPhoneNumber')}
									handleOnChange={(id, value) => { handleChange({ target: { name: id, value: value } }); }}
								/>
							}
						</>
					)}
				</Translator>

				<Form.Group controlId="contactDetails">
					<Form.Label className="max-width-none">
						<Tooltip text={'Add any additional contact details relative to the procurement.'} />
						<Translate text={'Additional Contact Info'} />
					</Form.Label>
					<Form.Control
						as="textarea"
						name="contactDetails"
						value={contactDetails}
						maxLength={DESCRIPTION_MAX_LENGTH}
						disabled={!canEdit('contactDetails')}
						onChange={(e) => { handleChange(e); }}
					/>
				</Form.Group>

				{(procumentEntityData && Array.isArray(endUserEntityOptions) && endUserEntityOptions.length > 0) &&
					<Form.Group controlId="endUserEntity">
						<Form.Label>
							<Tooltip text={'Select the end user, or client, entity.'} />
							<Translate text={'End User Entity'} /> <span className="required">*</span>
						</Form.Label>
						<Form.Control
							as="select"
							name="endUserEntity"
							value={(endUserEntity) ? endUserEntity : ''}
							required
							disabled={!canEdit('endUserEntity')}
							onChange={(e) => { handleChange(e); }}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{translate({ text: '- Select -' })}
									</option>
								)}
							</Translator>

							{endUserEntity && hasOldEndUserEntity &&
								<option value={endUserEntity}>
									{endUserEntity}
								</option>
							}

							{endUserEntityOptions.map((item, index) => {
								if (item.name) {
									return (
										<option value={item.name} key={index}>
											{item.name}
										</option>
									);
								}

								return null;
							})}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'End User Entity'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>
				}
			</fieldset>

			{isEdit && data.tenderStatus !== TENDER_STATUS_DRAFT && data.closingDate && parseISO(data.closingDate) <= new Date() &&
				<>
					<fieldset ref={awardDetailsRef} tabIndex="-1">
						<legend><Translate text={'Award Details'} /></legend>

						{data && data.tenderAwardData && Array.isArray(data.tenderAwardData) && data.tenderAwardData.length > 0 &&
							<AwardTable data={data.tenderAwardData} handleSave={handleUpdateAwardDetails} ref={awardRef} />
						}
					</fieldset>

					{(currentTender.tenderStatus !== TENDER_STATUS_CANCELLED && (!data || !data.tenderAwardData || !Array.isArray(data.tenderAwardData) || data.tenderAwardData.length === 0)) &&
						<div className="text-right">
							<Button onClick={handleAddAwardDetails}><Translate text={'Add Award Details'} /></Button>
						</div>
					}

					<Form.Group controlId="awardMemo">
						<Form.Label className="max-width-none">
							<Tooltip text={'Use this space to include any overall or additional information about the tender award. If a tender is NOT awarded and is cancelled after the closing date, please ensure the reason(s) for cancelling without award are entered here.'} />
							<Translate text={'Award Memo'} />
						</Form.Label>
						<Form.Control
							as="textarea"
							name="awardMemo"
							value={awardMemo}
							maxLength={MESSAGE_MAX_LENGTH}
							disabled={!canEdit('awardMemo')}
							onChange={(e) => { handleChange(e); }}
						/>
					</Form.Group>
				</>
			}

			{(![TENDER_STATUS_AWARDED, TENDER_STATUS_CANCELLED].includes(currentTender.tenderStatus)) &&
				<Form.Group className={(validated && !postDate) ? 'is-invalid' : null}>
					<Form.Label htmlFor="postDate">
						<Tooltip text={'Unless a future date is specified, selecting the “Publish Notice” button below will publish the tender immediately.'} />
						<Translate text={'Tender Post Date'} /> <span className="required">*</span>
					</Form.Label>
					<FormDatePicker
						id="postDate"
						name="postDate"
						value={postDate}
						minDate={(!isEdit) ? new Date() : null}
						maxDate={(closingDate) ? parseISO(closingDate) : null}
						required={true}
						disabled={!canEdit('postDate')}
						handleOnChange={(value) => { handleDateChange('postDate', value); }}
					/>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Tender Post Date'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>
			}

			{validated && (hasErrors || hasCommoditiesError || hasTenderDocsError || hasTenderDocsFilesizeError || hasAddendumDocsFilesizeError) &&
				<div className="invalid-feedback show mb-4">
					<p className="error-tender mb-2"><Translate text={'Form contains errors. Please fill out all required fields.'} /></p>

					{hasCommoditiesError &&
						<p className="error-tender-commodities mb-2"><Translate text={commoditiesError} /></p>
					}

					{hasTenderDocsError &&
						<p className="error-tender-docs mb-2"><Translate text={tenderDocsError} /></p>
					}

					{hasTenderDocsFilesizeError &&
						<p className="error-tender-docs-filesize mb-2"><Translate text={tenderDocsFilesizeError} /></p>
					}

					{hasAddendumDocsFilesizeError &&
						<p className="error-tender-addendum-filesize mb-2"><Translate text={addendumDocsFilesizeError} /></p>
					}
				</div>
			}

			<ButtonToolbar className="justify-content-between">
				<Button
					variant="success"
					type={!hasPostClosingDateWarning ? 'submit' : null}
					onClick={hasPostClosingDateWarning ? handleShowPostClosingDateWarning : null}
				>
					<Translate text={'Publish Notice'} />
				</Button>

				{(!isEdit || data.tenderStatus == TENDER_STATUS_DRAFT) &&
					<Button
						variant="secondary"
						onClick={handleSaveDraft}
					>
						<Translate text={'Save Tender as Draft'} />
					</Button>
				}
			</ButtonToolbar>

			{hasDraftErrors &&
				<div className="text-right">
					{hasTenderIdError &&
						<div className="invalid-feedback show max-width-none error-draft-tender">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Tender ID'
								}}
							/>
						</div>
					}

					{hasProcurementEntityError &&
						<div className="invalid-feedback show max-width-none error-draft-tender">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Procurement Entity'
								}}
							/>
						</div>
					}

					{hasTenderDocsFilesizeError &&
						<div className="invalid-feedback show max-width-none error-draft-tender">
							<Translate text={tenderDocsFilesizeError} />
						</div>
					}

					{hasAddendumDocsFilesizeError &&
						<div className="invalid-feedback show max-width-none error-draft-tender">
							<Translate text={addendumDocsFilesizeError} />
						</div>
					}
				</div>
			}

			{hasPostClosingDateWarning &&
				<Modal show={showPostClosingDateWarning} onHide={handleClosePostClosingDateWarning} centered>
					<Modal.Header closeButton>
						<h2><Translate text={'The posting time you have selected is shorter than 10 days. Do you wish to proceed?'} /></h2>
					</Modal.Header>
					<Modal.Footer>
						<Button variant="success" onClick={handleSubmit}><Translate text={'Yes, Continue to Publish'} /></Button>
						<Button variant="secondary" onClick={handleClosePostClosingDateWarning}><Translate text={'No, Return to Edit'} /></Button>
					</Modal.Footer>
				</Modal>
			}
		</Form>
	);
}

export default TenderForm;
