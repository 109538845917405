/* eslint-disable no-unused-vars */

export const resolvers = {
	tender
};

function tender(req, res, ctx) {
	// GET
	if (req.method === 'GET') {
		return res(
			ctx.status(200),
			ctx.json({
				'paginationData': {
					'currentPage': 1,
					'numberOfRecords': 3,
					'totalRecords': 3
				},
				'tenderDataList': [
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 1,
						'solicitationType': 'string',
						'tenderId': 'TENDER-1234',
						'title': 'CBRM T70-2020',
						'procurementMethod': 'string',
						'unspscLevelData': [
							{
								'id': 0,
								'unspscLevel1': 'UNSPSC Level 1',
								'unspscLevel2': 'UNSPSC Level 2',
								'unspscLevel3': 'UNSPSC Level 3',
								'unspscLevel4': 'UNSPSC Level 4'
							},
							{
								'id': 1,
								'unspscLevel1': 'UNSPSC Level 1',
								'unspscLevel2': 'UNSPSC Level 2',
								'unspscLevel3': 'UNSPSC Level 3',
								'unspscLevel4': 'UNSPSC Level 4'
							}
						],
						'procumentEntity': 'Cape Breton Regional Municipality',
						'contactName': 'John Smith',
						'contactMethod': [
							{
								'id': 0,
								'method': 'email'
							},
							{
								'id': 1,
								'method': 'phone'
							}
						],
						'contactEmail': 'john.smith@test.com',
						'contactPhoneNumber': '902-555-1234',
						'contactDetails': 'Some details about the contact.',
						'endUserEntity': 'string',
						'tradeAgreement': [
							'Trade Agreement 1',
							'Trade Agreement 2',
							'Trade Agreement 3'
						],
						'informationInDocument': true,
						'tenderUrl': 'string',
						'closingDate': '2021-06-30',
						'closingTime': '11:00 AM',
						'description': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et magna elementum nisi bibendum volutpat. Donec iaculis hendrerit magna et bibendum. Integer eleifend lectus id sollicitudin suscipit. Suspendisse sit amet mauris nec tortor consequat vehicula nec sit amet nibh. Nullam id lacus ornare turpis euismod sollicitudin a vel sapien.\n\nCras hendrerit vitae dolor id ultrices. Nam dictum dignissim sollicitudin. Nulla nec dictum lectus, vitae mollis est. Sed sollicitudin est id odio efficitur, eu pellentesque elit condimentum. Donec finibus egestas ante in sodales. Vestibulum diam odio, mattis at dui sed, condimentum tempor turpis. Nam fermentum erat velit, sit amet rutrum lectus faucibus a. Donec quis aliquet dolor.',
						'relevantRegions': [
							{
								'id': 0,
								'region': 'string'
							}
						],
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'A PDF File.pdf',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'TENDER_DOCS',
								'deleted': true,
								'entityId': 0
							},
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': '',
								'filename': '',
								'documentContentType': '',
								'data': '',
								'attachmentType': 'TENDER_DOCS',
								'deleted': true,
								'entityId': 0,
								'url': 'www.test.com'
							},
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'An Addendum Document.pdf',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'ADDENDUM_DOCS',
								'deleted': true,
								'entityId': 0
							},
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': '',
								'filename': '',
								'documentContentType': '',
								'data': '',
								'attachmentType': 'ADDENDUM_DOCS',
								'deleted': true,
								'entityId': 0,
								'notes': 'Some addendum notes. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et magna elementum nisi bibendum volutpat.'
							}
						],
						'memo': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam et magna elementum nisi bibendum volutpat. Donec iaculis hendrerit magna et bibendum. Integer eleifend lectus id sollicitudin suscipit.',
						'issuedDate': '2021-03-24',
						'tenderStatus': 'OPEN',
						'expectedDurationOfContract': 6,
						'pickUpFee': 0,
						'termsOfPayment': 'string'
					},
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 2,
						'solicitationType': 'string',
						'title': 'CBRM T70-2020',
						'procurementMethod': 'string',
						'unspscLevel1': 'string',
						'unspscLevel2': 'string',
						'unspscLevel3': 'string',
						'procumentEntity': 'Cape Breton Regional Municipality',
						'contactInformation': 'string',
						'endUserEntity': 'string',
						'tradeAgreement': 'string',
						'informationInDocument': true,
						'tenderUrl': 'string',
						'closingDate': 'Mar 11 2021',
						'closingTime': '11:00 AM',
						'description': 'string',
						'relevantRegions': [
							{
								'id': 0,
								'region': 'string'
							}
						],
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'string',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'string',
								'deleted': true,
								'entityId': 0
							}
						],
						'memo': 'Supply Fountian/Aerator - John Bernard Croak Memorial Park',
						'issuedDate': 'string',
						'tenderStatus': 'OPEN',
						'expectedDurationOfContract': 0,
						'pickUpFee': 0,
						'termsOfPayment': 'string'
					},
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 3,
						'solicitationType': 'string',
						'title': 'CBRM T70-2020',
						'procurementMethod': 'string',
						'unspscLevel1': 'string',
						'unspscLevel2': 'string',
						'unspscLevel3': 'string',
						'procumentEntity': 'Cape Breton Regional Municipality',
						'contactInformation': 'string',
						'endUserEntity': 'string',
						'tradeAgreement': 'string',
						'informationInDocument': true,
						'tenderUrl': 'string',
						'closingDate': 'Mar 11 2021',
						'closingTime': '11:00 AM',
						'description': 'string',
						'relevantRegions': [
							{
								'id': 0,
								'region': 'string'
							}
						],
						'attachments': [
							{
								'errorMessage': 'string',
								'errorCode': 'string',
								'changeType': 'SAVE',
								'id': 0,
								'guid': 'string',
								'filename': 'string',
								'documentContentType': 'PDF',
								'data': [
									'string'
								],
								'attachmentType': 'string',
								'deleted': true,
								'entityId': 0
							}
						],
						'memo': 'Supply Fountian/Aerator - John Bernard Croak Memorial Park',
						'issuedDate': 'string',
						'tenderStatus': 'OPEN',
						'expectedDurationOfContract': 0,
						'pickUpFee': 0,
						'termsOfPayment': 'string'
					}
				]
			})
		);
	}
}
