import React from 'react';
import Badge from 'react-bootstrap/Badge';

export default function TabTitle(props) {
	const { title, count } = props;

	function kFormatter(num) {
		return Math.abs(num) > 999 ? (Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1))) + 'k' : Math.sign(num) * Math.abs(num);
	}

	return (
		<>
			<span className="tab-title">{ title }</span>
			{ count &&
				<Badge variant="primary">{ kFormatter(count) }</Badge>
			}
		</>
	);
}
