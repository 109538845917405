import axios from 'axios';
const env = window.env;
import { authHeader } from '../helpers/authHeader';

export const userService = {
	get,
	getRpid,
	post,
	remove,
	activate,
	authorize
};

function get(params, filters) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/users`,
		params: params,
		data: filters,
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getRpid(rpid) {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/user/rpid/${rpid}`,
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function post(data) {
	data.changeType = 'SAVE';
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/user`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function activate(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/user/activate`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function authorize(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/user/authorize`,
		params: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function remove(data) {
	data.changeType = 'DELETE';
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/user`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return [];
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
