import { geographyConstants } from '../constants/geographyConstants';
import { geographyService } from '../../services/geographyService';

export const geographyActions = {
	get
};

function get() {
	return dispatch => {
		dispatch(request());
		
		geographyService.get()
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);

	};

	function request() { return { type: geographyConstants.GEO_REQUEST }; }

	function success(data) { return { type: geographyConstants.GEO_SUCCESS, data }; }

	function error() { return { type: geographyConstants.GEO_ERROR }; }
}
