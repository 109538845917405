import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import { useSelector, useDispatch } from 'react-redux';

import { geographyActions } from '../../store/actions/geographyActions';

import Form from 'react-bootstrap/Form';

export default function FormProvinceState(props) {
	const {
		id,
		name,
		value,
		required,
		country,
		refValue,
		disabled,
		readOnly
	} = props;
	const dispatch = useDispatch();
	const {
		COUNTRY_CANADA,
		COUNTRY_US,
		COUNTRY_US_FR,
		INPUT_MAX_LENGTH,
		LANGUAGE_FRENCH
	} = require('../../config/constants');

	const fieldName = name || 'provinceState';

	const [options, setOptions] = useState([]);
	const [showRegion, setShowRegion] = useState(false);

	// State data.
	const authenticate = useSelector(state => state.authenticate);
	const language = useSelector(state => state.language);
	const geography = useSelector(state => state.geography);

	// Getting geography data.
	useEffect(() => {
		if (authenticate && authenticate.success && !geography.init) {
			dispatch(geographyActions.get());
		}
	}, [authenticate, geography]);

	// Listen to geography data state & country value.
	useEffect(() => {
		if (geography.success && geography.data.provinceStateData) {
			filterOptions(country, geography.data.provinceStateData);
		}
	}, [geography, country]);

	// Listen to country value.
	useEffect(() => {
		if (country && ![COUNTRY_CANADA, COUNTRY_US, COUNTRY_US_FR].includes(country)) {
			setShowRegion(true);
		} else {
			setShowRegion(false);
		}
	}, [country]);

	const filterOptions = (country, options) => {
		if (country == COUNTRY_CANADA) {
			setOptions(options.filter(item => item.country == COUNTRY_CANADA));
		} else if ([COUNTRY_US, COUNTRY_US_FR].includes(country)) {
			setOptions(options.filter(item => item.country == COUNTRY_US));
		} else {
			setOptions(options);
		}
	};

	const handleChange = (event) => {
		if (props.handleOnChange) {
			props.handleOnChange(event);
		}
	};

	const handleBlur = (event) => {
		if (props.handleOnBlur) {
			props.handleOnBlur(event);
		}
	};

	return (
		<Translator>
			{({translate}) => (
				<Form.Group controlId={ id }>
					{ !showRegion &&
						<>
							<Form.Label>
								<Translate text="Province / State" />

								{ required &&
									<span className="required">*</span>
								}
							</Form.Label>
							<Form.Control
								as="select"
								name={ fieldName }
								ref={ refValue }
								value={ (value) ? value : '' }
								required={ (required) ? true : null }
								disabled={ (disabled || readOnly) ? true : null }
								onChange={ handleChange }
								onBlur={ handleBlur }
								readOnly={ readOnly }
							>
								<option value="">
									{ translate({ text: '- Select -' }) }
								</option>

								{ (Array.isArray(options)) &&
									options.map((item, index) => {
										return (
											<option value={ (language && language.value == LANGUAGE_FRENCH) ? item.nameFr : item.nameEn } key={ index }>
												{ (language && language.value == LANGUAGE_FRENCH) ? item.nameFr : item.nameEn }
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: translate({ text: 'Province / State' })
									}}
								/>
							</Form.Control.Feedback>
						</>
					}

					{ showRegion &&
						<>
							<Form.Label>
								<Translate text="Region" />
							</Form.Label>
							<Form.Control
								type="text"
								name={ fieldName }
								value={ (value) ? value : '' }
								disabled={ (disabled || readOnly) ? true : null }
								maxLength={ INPUT_MAX_LENGTH }
								onChange={ handleChange }
								onBlur={ handleBlur }
								readOnly={ readOnly }
							/>
						</>
					}
				</Form.Group>
			)}
		</Translator>
	);
}
