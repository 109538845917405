/* eslint-disable no-unused-vars */

export const resolvers = {
	data
};

function data(req, res, ctx) {
	// GET
	if (req.method === 'GET') {
		return res(
			ctx.status(200),
			ctx.json({
				'solicitationTypeDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'procurementMethodDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'tenderCircumstanceDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'unspscLevel1DataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'unspscLevel2DataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'unspscLevel3DataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'tradeAgreementDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'closingDateDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'closingTimeDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'relevantRegionDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'categoryOpportunityDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'tenderIssuerDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'tenderStatusDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'regionDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'departmentDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'contractDurationDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'tenderCategoryDataList': [
					{
						'name': 'category-1',
						'description': 'Test Category One'
					},
					{
						'name': 'category-2',
						'description': 'Test Category Two'
					},
					{
						'name': 'category-3',
						'description': 'Test Category Three'
					}
				],
				'vendorDataList': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'documentContentTypeData': [
					{
						'name': 'string',
						'description': 'string'
					}
				],
				'attachmentTypeData': [
					{
						'name': 'string',
						'description': 'string'
					}
				]
			})
		);
	}
}
