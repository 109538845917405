import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';
import { getSimplifiedDate } from '../../../helpers/dateHelpers';

import { Row, Col, Nav, Navbar, NavItem, Form, Button } from 'react-bootstrap';
import FormSlideToggle from '../../forms/FormSlideToggle';

function AltpContactsForm(props) {
	const {
		handleCloseForm,
		result
	} = props;

	const {
		INPUT_MAX_LENGTH
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	// State data.
	const referenceData = useSelector(state => state.referenceData);
	const hasChanges = useSelector(state => state.hasChanges);

	const [validated, setValidated] = useState(false);
	const [departmentOptions, setDepartmentOptions] = useState([]);
	const formSubmitRef = useRef(null);

	const [data, setData] = useState({
		'changeType': 'SAVE',
		'contactInformation': '',
		'departmentName': '',
		'enabled': true
	});

	const {
		contactInformation,
		departmentCode,
		departmentName,
		enabled
	} = data;

	// Result changes
	useEffect(() => {
		if (result && typeof result === 'object') {
			setData(data => ({...data, ...result}));
		} else {
			handleCloseForm();
		}
	}, [result]);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData.success && referenceData.data) {
			if (referenceData.data.endUserEntityDataList) {
				setDepartmentOptions(referenceData.data.endUserEntityDataList);
			}
		}
	}, [referenceData]);

	/**
	 * Field functions
	 */

	const checkChanges = () => {
		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleChange = (event, isNumeric, trim) => {
		const { name, value } = event.target;
		let val;

		if (isNumeric) {
			if (value) {
				val = parseInt(value, 10);
			} else {
				val = null;
			}
		} else if (value) {
			if (trim) {
				val = value.replace(/\s/g, '');
			} else {
				val = value;
			}
		} else {
			val = '';
		}

		setData(data => ({...data, [name]: val }));

		checkChanges();
	};

	const handleDepartmentNameChange = (event) => {
		let val;

		val = event.target[event.target.selectedIndex].getAttribute('data-organization-id');

		if (val) {
			setData(data => ({...data, departmentCode: val }));
		} else {
			setData(data => ({...data, departmentCode: '' }));
		}

		checkChanges();
	};

	const handleEnabledChange = (id, value) => {
		let val;

		if (value == 'yes') {
			val = true;
		} else {
			val = false;
		}

		setData(data => ({...data, enabled: val }));
	};

	const handleSubmit = async (event) => {
		event.currentTarget.blur();
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		data.changeType = 'SAVE';
		delete data.createdBy;
		delete data.modifiedBy;
		delete data.createdDate;
		delete data.modifiedDate;

		dispatch(referenceTableActions.updateReferenceTable('altpcontact', data, 'altp-contacts'));
		handleCloseForm();
	};

	const handleClose = () => {
		handleCloseForm();
	};

	return (
		<div className={'box box--blue-light box--wide'}>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row className="align-items-center">
					<Col md={7}>
						<h2 className="mb-2"><Translate text={(result && result.id) ? 'Edit ALTP Contact' : 'Add ALTP Contact'} /></h2>
					</Col>
					<Col md={5}>
						<Navbar className={'form-nav justify-content-end'}>
							<Nav>
								<NavItem>
									<Button variant="primary" onClick={() => handleClose()}>
										<Translate text={'Cancel'}/>
									</Button>
								</NavItem>

								<NavItem>
									<Button variant="success" type="submit" ref={formSubmitRef}>
										<Translate text={'Save'}/>
									</Button>
								</NavItem>
							</Nav>
						</Navbar>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						<Form.Group controlId="altpContact-name">
							<Form.Label>
								<Translate text={'Contact Information'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								type="text"
								name="contactInformation"
								value={ contactInformation }
								maxLength={ INPUT_MAX_LENGTH }
								required
								onChange={(e) => { handleChange(e); }}
							/>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Contact Information'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group controlId="altpContact-departmentName">
							<Form.Label>
								<Translate text={'Department Name'} /> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								as="select"
								name="departmentName"
								value={ (departmentName) ? departmentName : '' }
								required
								onChange={(e) => { handleChange(e); handleDepartmentNameChange(e); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
									)}
								</Translator>

								{ (Array.isArray(departmentOptions)) &&
									departmentOptions.map((item, index) => {
										return (
											<option value={ item.name } data-organization-id={ item.organizationId } key={ index }>
												{ item.name }
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Department Name'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group controlId="altpContact-departmentCode">
							<Form.Label>
								<Translate text={'Department Code'} />
							</Form.Label>
							<Form.Control
								type="text"
								value={ departmentCode }
								readOnly
							/>
						</Form.Group>
					</Col>
					<Col md={6}>
						<Form.Group controlId="altpContact-enabled">
							<FormSlideToggle
								id="enabled"
								name="enabled"
								title="Enabled"
								value={ (enabled) ? 'yes' : 'no' }
								options={[
									{ value: 'no', label: 'No' },
									{ value: 'yes', label: 'Yes' }
								]}
								handleOnChange={ handleEnabledChange }
							/>
						</Form.Group>

						{ result.id &&
							<>
								<Form.Group controlId="createdBy">
									<Form.Label>
										<Translate text={'Created By'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ result.createdBy }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="modifiedBy">
									<Form.Label>
										<Translate text={'Modified By'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ result.modifiedBy }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="createdDate">
									<Form.Label>
										<Translate text={'Date Created'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ getSimplifiedDate(result.createdDate) }
										readOnly
									/>
								</Form.Group>
								<Form.Group controlId="modifiedDate">
									<Form.Label>
										<Translate text={'Date Modified'} />
									</Form.Label>
									<Form.Control
										type="text"
										value={ getSimplifiedDate(result.modifiedDate) }
										readOnly
									/>
								</Form.Group>
							</>
						}
					</Col>
				</Row>
			</Form>
		</div>
	);
}
export default AltpContactsForm;
