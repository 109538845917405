import { rest } from 'msw';

import { resolvers as authenticateResolvers } from './resolvers/authenticate';
import { resolvers as referenceDataResolvers } from './resolvers/referenceData';
import { resolvers as contentResolvers } from './resolvers/content';
import { resolvers as tenderResolvers } from './resolvers/tender';
import { resolvers as standingOfferResolvers } from './resolvers/standingOffer';
import { resolvers as notificationResolvers } from './resolvers/notification';
import { resolvers as altpResolvers } from './resolvers/altp';

const env = window.env;

export const handlers = [
	// authenticateService
	rest.post(`${env.BASE_API_URL}/authenticate`, authenticateResolvers.user),
	// referenceDataService
	rest.get(`${env.BASE_API_URL}/data`, referenceDataResolvers.data),
	// contentService
	rest.get(`${env.BASE_API_URL}/content`, contentResolvers.page),
	rest.get(`${env.BASE_API_URL}/content/navigation`, contentResolvers.navigation),
	// tenderService
	rest.get(`${env.BASE_API_URL}/tenders`, tenderResolvers.tender),
	// standingOfferService
	rest.get(`${env.BASE_API_URL}/standingoffers`, standingOfferResolvers.standingOffer),
	// notificationService
	rest.post(`${env.BASE_API_URL}/notification`, notificationResolvers.post),
	// altpService
	rest.get(`${env.BASE_API_URL}/altps`, altpResolvers.altp)
];
