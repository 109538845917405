import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { Translate, Translator } from 'react-translated';
import MetaTags, { ReactTitle } from 'react-meta-tags';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import {base64ToString} from '../../helpers/fileHelpers';
import {PAGE_TITLE_BASE} from '../../config/constants';

function ContentPage({ onRender }) {
	const content = useSelector(state => state.content);
	const loading = !content.success && !content.error;
	const [pageTitle, setPageTitle] = useState('');
	const [pageContent, setPageContent] = useState('');

	useEffect(() => {
		if (onRender) {
			onRender(true);
		}
	}, []);

	useEffect(() => {
		if (content.success && content.data && content.data.content && content.data.title) {
			let contentString = base64ToString(content.data.content);
			contentString = contentString.replaceAll('<table>', '<div class="table-responsive"><table>').replaceAll('</table>', '</table></div>');
			setPageContent(contentString);
			setPageTitle(content.data.title);
		} else if (!content.request && (content.success || content.error)) {
			setPageContent('');
			setPageTitle('404 - Not Found');
		}
	}, [content]);

	return (
		<>
			{ !loading &&
				<Translator>
					{({ translate }) => (
						<>
							<ReactTitle
								title={
									translate({
										text: pageTitle + ' - {siteTitle}',
										data: { siteTitle: PAGE_TITLE_BASE }
									})
								}
							/>

							<MetaTags>
								<meta name="dcterms.title" content={ pageTitle } />
								<meta property="og:title" content={ pageTitle } />
							</MetaTags>
						</>
					)}
				</Translator>
			}

			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<ContentLoading loading={loading}>
					<Row>
						<Col>
							{ loading === false && pageContent !== '' &&
								<ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageContent}</ReactMarkdown>
							}

							{ loading === false && pageContent === '' &&
								<>
									<h1><Translate text="404 - Not Found" /></h1>
								</>
							}
						</Col>
					</Row>
				</ContentLoading>
			</Container>

			<Footer />
		</>
	);
}

export default ContentPage;
