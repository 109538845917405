import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { referenceTableService } from '../../../services/referenceTableService';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import FormSearch from '../../forms/FormSearch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

export default function SearchUNSPSC(props) {
	const {
		method,
		commodities,
		currentCommodity,
		newCommodity
	} = props;

	const {
		UNSPSC_LEVEL_1,
		UNSPSC_LEVEL_2,
		UNSPSC_LEVEL_3,
		UNSPSC_LEVEL_4
	} = require('../../../config/constants');

	// State data.
	const referenceData = useSelector(state => state.referenceData);

	const [hasValue, setHasValue] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [hasSearch, setHasSearch] = useState(false);
	const [keywordResults, setKeywordResults] = useState(null);
	const [keywordError, hasKeywordError] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [unspscLevel1Options, setUnspscLevel1Options] = useState([]);
	const [unspscLevel2Options, setUnspscLevel2Options] = useState([]);
	const [unspscLevel3Options, setUnspscLevel3Options] = useState([]);
	const [commodityLevel1, setCommodityLevel1] = useState(null);
	const [commodityLevel2, setCommodityLevel2] = useState(null);
	const [commodityLevel3, setCommodityLevel3] = useState(null);
	const [commodityLevel4, setCommodityLevel4] = useState(null);
	const [manualFieldsDisplay, setManualFieldsDisplay] = useState([]);
	const [manualCommodityLevel1, setManualCommodityLevel1] = useState(null);
	const [manualCommodityLevel2, setManualCommodityLevel2] = useState(null);
	const [manualCommodityLevel3, setManualCommodityLevel3] = useState(null);

	// Refs.
	const wrapperRef = useRef(null);

	// Setting the commodities to display after a keyword search result has been selected.
	useEffect(() => {
		if (Array.isArray(commodities) && commodities.length) {
			setCommodityLevel1(commodities.find(item => item.levelType == UNSPSC_LEVEL_1));
			setCommodityLevel2(commodities.find(item => item.levelType == UNSPSC_LEVEL_2));
			setCommodityLevel3(commodities.find(item => item.levelType == UNSPSC_LEVEL_3));
			setCommodityLevel4(commodities.find(item => item.levelType == UNSPSC_LEVEL_4));
		}
	}, [commodities]);

	// Seeing which manual dropdowns should be displayed, based on the level of the new commodity.
	useEffect(() => {
		if (newCommodity && newCommodity.levelType) {
			switch (newCommodity.levelType) {
				case UNSPSC_LEVEL_1:
					setManualFieldsDisplay([]);

					break;
				case UNSPSC_LEVEL_2:
					setManualFieldsDisplay([UNSPSC_LEVEL_1]);

					break;
				case UNSPSC_LEVEL_3:
					setManualFieldsDisplay([UNSPSC_LEVEL_1, UNSPSC_LEVEL_2]);

					break;
				case UNSPSC_LEVEL_4:
					setManualFieldsDisplay([UNSPSC_LEVEL_1, UNSPSC_LEVEL_2, UNSPSC_LEVEL_3]);

					break;
				default:
					break;
			}
		}
	}, [newCommodity]);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (Array.isArray(referenceData.data.unspscLevelDataList)) {
				setUnspscLevel1Options(referenceData.data.unspscLevelDataList.filter(item => item.levelType == UNSPSC_LEVEL_1));
			}
		}
	}, [referenceData]);

	const handleSearch = (value) => {
		if (value && value.trim() !== '') {
			setKeyword(value);
			setHasSearch(true);
			hasKeywordError(false);
			setIsSearching(true);

			referenceTableService.unspscGet(null, { keyword: value })
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error && Array.isArray(response)) {
							setKeywordResults(response);
						} else if (response.error) {
							hasKeywordError(true);
							setKeywordResults(null);
						}

						setIsSearching(false);
					}
				);
		} else {
			setKeyword('');
			setHasSearch(false);
			setKeywordResults(null);
			hasKeywordError(false);
			setIsSearching(false);
		}
	};

	const handleSelectResult = (item) => {
		let levels = [];

		levels.push(item);

		// If the selected item has parent items, it will have nested `parentUnspscData` objects up to 3 additional levels.
		if (item.parentUnspscData) {
			levels.push(item.parentUnspscData);

			if (item.parentUnspscData.parentUnspscData) {
				levels.push(item.parentUnspscData.parentUnspscData);

				if (item.parentUnspscData.parentUnspscData.parentUnspscData) {
					levels.push(item.parentUnspscData.parentUnspscData.parentUnspscData);
				}
			}
		}

		if (props.handleOnChangeMultiple) {
			props.handleOnChangeMultiple(levels);
		}

		if (props.handleSelectCurrent) {
			props.handleSelectCurrent(levels[0]);
		}

		setHasValue(true);

		const scrollPosition = wrapperRef.current.getBoundingClientRect().top + window.pageYOffset - 105;
		window.scrollTo({
			top: scrollPosition
		});
	};

	const handleChangeSelected = (commodity) => {
		if (props.handleSelectCurrent) {
			props.handleSelectCurrent(commodity);
		}
	};

	const handleGetUnspscByLevel = (selectedId, level, requestLevel) => {
		if (selectedId && requestLevel) {
			referenceTableService.unspscGet({ unspscId: selectedId, level: requestLevel })
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error && Array.isArray(response)) {
							switch (level) {
								case UNSPSC_LEVEL_1:
									setUnspscLevel2Options(response);
									break;
								case UNSPSC_LEVEL_2:
									setUnspscLevel3Options(response);
									break;
								default:
									break;
							}
						}
					}
				);
		}
	};

	const handleDropdownChange = async (event, level) => {
		const { value } = event.target;
		let requestLevel;
		let commodityData;

		switch (level) {
			case UNSPSC_LEVEL_1:
				requestLevel = UNSPSC_LEVEL_2;

				if (Array.isArray(unspscLevel1Options)) {
					const found = unspscLevel1Options.find(item => item.id == value);

					if (found) {
						commodityData = found;
					}
				}

				setManualCommodityLevel1(value);
				setManualCommodityLevel2(null);
				setManualCommodityLevel3(null);

				break;
			case UNSPSC_LEVEL_2:
				requestLevel = UNSPSC_LEVEL_3;

				if (Array.isArray(unspscLevel2Options)) {
					const found = unspscLevel2Options.find(item => item.id == value);

					if (found) {
						commodityData = found;
					}
				}

				setManualCommodityLevel2(value);
				setManualCommodityLevel3(null);

				break;
			case UNSPSC_LEVEL_3:
				requestLevel = UNSPSC_LEVEL_4;

				if (Array.isArray(unspscLevel3Options)) {
					const found = unspscLevel3Options.find(item => item.id == value);

					if (found) {
						commodityData = found;
					}
				}

				setManualCommodityLevel3(value);

				break;
			default:
				break;
		}

		if (commodityData && commodityData.id && props.handleOnChangeMultiple) {
			props.handleOnChangeMultiple(commodityData);
		}

		handleGetUnspscByLevel(value, level, requestLevel);
	};

	return (
		<div className="unspsc-selector" ref={ wrapperRef }>
			{ (method == 'keyword') &&
				<>
					{ hasValue && commodities &&
						<div className="unspsc-selector__selected">
							<table className="data-table unspsc-selector__selected-table">
								<thead>
									<tr>
										<th><Translate text={'Level'} /></th>
										<th><Translate text={'Description'} /></th>
										<th><Translate text={'Code'} /></th>
										<th><Translate text={'Enabled'} /></th>
										<th hidden><Translate text={'Controls'} /></th>
									</tr>
								</thead>

								<tbody>
									{ commodityLevel1 && commodityLevel1.description &&
										<tr className={`unspsc-selector__selected-table-row unspsc-selector__selected-table-row--level-1${(currentCommodity.id == commodityLevel1.id && currentCommodity.levelType == commodityLevel1.levelType) ? ' unspsc-selector__selected-table-row--selected' : ''}`}>
											<td>1</td>
											<td><span className="unspsc-selector__selected-table-desc">{ commodityLevel1.description }</span></td>
											<td>{ commodityLevel1.code }</td>
											<td><Translate text={ commodityLevel1.enabled ? 'Yes' : 'No' } /></td>
											<td>
												<Button
													variant="link"
													className={'mb-0'}
													onClick={() => handleChangeSelected(commodityLevel1)}
												>
													<FontAwesomeIcon icon={ faPencilAlt } />
													<span className="sr-only">
														<Translate text={'Edit'} /> { commodityLevel1.description }
													</span>
												</Button>
											</td>
										</tr>
									}

									{ commodityLevel2 && commodityLevel2.description &&
										<tr className={`unspsc-selector__selected-table-row unspsc-selector__selected-table-row--level-2${(currentCommodity.id == commodityLevel2.id && currentCommodity.levelType == commodityLevel2.levelType) ? ' unspsc-selector__selected-table-row--selected' : ''}`}>
											<td>2</td>
											<td><span className="unspsc-selector__selected-table-desc">{ commodityLevel2.description }</span></td>
											<td>{ commodityLevel2.code }</td>
											<td><Translate text={ commodityLevel2.enabled ? 'Yes' : 'No' } /></td>
											<td>
												<Button
													variant="link"
													className={'mb-0'}
													onClick={() => handleChangeSelected(commodityLevel2)}
												>
													<FontAwesomeIcon icon={ faPencilAlt } />
													<span className="sr-only">
														<Translate text={'Edit'} /> { commodityLevel2.description }
													</span>
												</Button>
											</td>
										</tr>
									}

									{ commodityLevel3 && commodityLevel3.description &&
										<tr className={`unspsc-selector__selected-table-row unspsc-selector__selected-table-row--level-3${(currentCommodity.id == commodityLevel3.id && currentCommodity.levelType == commodityLevel3.levelType) ? ' unspsc-selector__selected-table-row--selected' : ''}`}>
											<td>3</td>
											<td><span className="unspsc-selector__selected-table-desc">{ commodityLevel3.description }</span></td>
											<td>{ commodityLevel3.code }</td>
											<td><Translate text={ commodityLevel3.enabled ? 'Yes' : 'No' } /></td>
											<td>
												<Button
													variant="link"
													className={'mb-0'}
													onClick={() => handleChangeSelected(commodityLevel3)}
												>
													<FontAwesomeIcon icon={ faPencilAlt } />
													<span className="sr-only">
														<Translate text={'Edit'} /> { commodityLevel3.description }
													</span>
												</Button>
											</td>
										</tr>
									}

									{ commodityLevel4 && commodityLevel4.description &&
										<tr className={`unspsc-selector__selected-table-row unspsc-selector__selected-table-row--level-4${(currentCommodity.id == commodityLevel4.id && currentCommodity.levelType == commodityLevel4.levelType) ? ' unspsc-selector__selected-table-row--selected' : ''}`}>
											<td>4</td>
											<td><span className="unspsc-selector__selected-table-desc">{ commodityLevel4.description }</span></td>
											<td>{ commodityLevel4.code }</td>
											<td><Translate text={ commodityLevel4.enabled ? 'Yes' : 'No' } /></td>
											<td>
												<Button
													variant="link"
													className={'mb-0'}
													onClick={() => handleChangeSelected(commodityLevel4)}
												>
													<FontAwesomeIcon icon={ faPencilAlt } />
													<span className="sr-only">
														<Translate text={'Edit'} /> { commodityLevel4.description }
													</span>
												</Button>
											</td>
										</tr>
									}
								</tbody>
							</table>
						</div>
					}

					{ (!hasValue || !commodities) &&
						<div className="unspsc-selector__search">
							<div className="unspsc-selector__search-form">
								<label htmlFor="admin-unspsc-search">
									<Translate text={'Search Commodities'} />
								</label>
								<FormSearch
									id="admin-unspsc-search"
									ariaLabel={'Search Commodities'}
									searchValue={ keyword }
									onSearch={ handleSearch }
								/>

								{ keywordError &&
									<div className="invalid-feedback show error-unspsc-keyword-search">
										<Translate text={'Error: Could not complete search. Please try again later.'} />
									</div>
								}
							</div>

							{ isSearching &&
								<div className="unspsc-selector__search-loader">
									<Spinner animation="border" role="status">
										<span className="sr-only"><Translate text={'Loading'} /></span>
									</Spinner>
								</div>
							}

							{ (hasSearch && !isSearching) &&
								<>
									{ (Array.isArray(keywordResults) && keywordResults.length > 0) &&
										<ListGroup variant="flush" className="unspsc-selector__search-results">
											{ keywordResults.map((item, index) => {
												if (item.description && item.levelType) {
													return (
														<Translator key={ index }>
															{({ translate }) => (
																<ListGroup.Item className="unspsc-selector__search-results-item" data-level-type={ item.levelType }>
																	<Button
																		variant="link"
																		data-hover-text={ translate({ text: 'Select' }) }
																		onClick={() => handleSelectResult(item) }
																	>
																		{ item.description }
																		{ item.code &&
																			<span className="unspsc-selector__search-results-item-code">
																				<strong>{ item.code }</strong>
																			</span>
																		}
																	</Button>
																</ListGroup.Item>
															)}
														</Translator>
													);
												}

												return null;
											})}
										</ListGroup>
									}

									{ (!keywordResults || (Array.isArray(keywordResults) && keywordResults.length == 0)) &&
										<Alert>
											<span className="alert-message">
												<Translate text={'No commodities matched your search.'} />
											</span>
										</Alert>
									}
								</>
							}
						</div>
					}
				</>
			}

			{ (method == 'manual') &&
				<div className="unspsc-selector__manual">
					{ (manualFieldsDisplay.includes(UNSPSC_LEVEL_1)) &&
						<Form.Group controlId="unspscLevel1" className="unspsc-selector__manual-level1">
							<Form.Label>
								<span>
									<Translate text={'Parent Commodity (Level 1)'} /> <span className="required">*</span>
								</span>
							</Form.Label>
							<Form.Control
								as="select"
								value={ manualCommodityLevel1 ? manualCommodityLevel1 : '' }
								required
								onChange={(e) => { handleDropdownChange(e, UNSPSC_LEVEL_1); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
									)}
								</Translator>

								{ (Array.isArray(unspscLevel1Options)) &&
									unspscLevel1Options.map((item, index) => {
										return (
											<option
												key={ index }
												value={ item.id }
											>
												{ item.description + ' - ' + item.code }
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Commodity Level 1'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
					}

					{ (manualFieldsDisplay.includes(UNSPSC_LEVEL_2)) &&
						<Form.Group controlId="unspscLevel2" className="unspsc-selector__manual-level2">
							<Form.Label>
								<span>
									<Translate text={'Parent Commodity (Level 2)'} /> <span className="required">*</span>
								</span>
							</Form.Label>
							<Form.Control
								as="select"
								value={ manualCommodityLevel2 ? manualCommodityLevel2 : '' }
								required
								onChange={(e) => { handleDropdownChange(e, UNSPSC_LEVEL_2); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
									)}
								</Translator>

								{ (Array.isArray(unspscLevel2Options)) &&
									unspscLevel2Options.map((item, index) => {
										return (
											<option
												key={ index }
												value={ item.id }
											>
												{ item.description + ' - ' + item.code }
											</option>
										);
									})
								}
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate
									text={'{formLabel} is required'}
									data={{
										formLabel: 'Commodity Level 2'
									}}
								/>
							</Form.Control.Feedback>
						</Form.Group>
					}

					{ (manualFieldsDisplay.includes(UNSPSC_LEVEL_3)) &&
						<Form.Group controlId="unspscLevel3" className="unspsc-selector__manual-level3">
							<Form.Label>
								<span>
									<Translate text={'Parent Commodity (Level 3)'} /> <span className="required">*</span>
								</span>
							</Form.Label>
							<Form.Control
								as="select"
								value={ manualCommodityLevel3 ? manualCommodityLevel3 : '' }
								required
								onChange={(e) => { handleDropdownChange(e, UNSPSC_LEVEL_3); }}
							>
								<Translator>
									{({ translate }) => (
										<option value="">
											{ translate({ text: '- Select -' }) }
										</option>
									)}
								</Translator>

								{ (Array.isArray(unspscLevel3Options)) &&
									unspscLevel3Options.map((item, index) => {
										return (
											<option
												key={ index }
												value={ item.id }
											>
												{ item.description + ' - ' + item.code }
											</option>
										);
									})
								}
							</Form.Control>
						</Form.Group>
					}
				</div>
			}
		</div>
	);
}
