import React, {useEffect, useState} from 'react';
import { Translate, Translator } from 'react-translated';
import { ReactTitle } from 'react-meta-tags';

import { dataLayerPageView } from '../../helpers/miscHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import {
	CONTENT_ERROR_MESSAGE,
	PAGE_TITLE_BASE,
	ROLE_MAS,
	ROLE_NAME_MAPPING,
	ROLE_NONE,
	ROLE_SUPPLIER,
	USER_STATUS_ACTIVE,
	USER_STATUS_PENDING
} from '../../config/constants';
import {Button, Form} from 'react-bootstrap';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {userActions} from '../../store/actions/userActions';
import {base64ToString} from '../../helpers/fileHelpers';
import rehypeRaw from 'rehype-raw';
import ReactMarkdown from 'react-markdown';
import {error, setHasChanges, success} from '../../store/actions/globalMessagesActions';
import {userDataActions} from '../../store/actions/userDataActions';

function AccountDisabled() {
	const history = useHistory();
	let location = useLocation();
	const currentPath = location.pathname;
	const dispatch = useDispatch();
	const env = window.env;

	// State
	const authenticate = useSelector(state => state.authenticate);
	const language = useSelector(state => state.language);
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const referenceData = useSelector(state => state.referenceData);
	const activateUser = useSelector(state => state.activateUser);
	const content = useSelector(state => state.content);

	const [loading, setLoading] = useState(true);

	const [pageTitle, setPageTitle] = useState('');
	const [pageContent, setPageContent] = useState('');
	const [termsOfUseContent, setTermsOfUseContent] = useState('');

	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [organization, setOrganization] = useState('');
	const [role, setRole] = useState(null);
	const [acceptTerms, setAcceptTerms] = useState(false);
	const [user, setUser] = useState(false);
	const [procurementEntities, setProcurementEntities] = useState([]);

	useEffect(() => {
		const pageTitle = 'Request Activation - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			pageTitle,
			language.code
		);
	}, []);

	const handleOrganizationChange = (id, value) => {
		setOrganization(value);
	};

	const handleRoleChange = (event) => {
		const value = event.target.value;
		setRole(value);
		setAcceptTerms(false);
		setOrganization('');
	};

	const handleAcceptTermsChange = (e) => {
		setAcceptTerms(e.target.checked);
	};

	const handleSubmit = async (event) => {
		event.currentTarget.blur();
		event.preventDefault();
		event.stopPropagation();

		if (getUser.user && getUser.user.userStatus && getUser.user.userStatus === USER_STATUS_PENDING) {
			dispatch(error(
				'Error: A user account request to post and manage procurement notices is already in progress.',
				<p><Translate
					text={'If you require assistance, please <Link>.'}
					renderMap={{
						renderLink: () => <a href="/submit-ticket" target="_blank" rel="noopener noreferrer"><Translate text={'submit a ticket'} /></a> // eslint-disable-line react/display-name
					}}
				/></p>
			));
			return;
		}

		const form = event.currentTarget;

		setHasErrors(false);
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			setHasErrors(true);
			return;
		}

		setValidated(true);

		if (authenticate && authenticate.success) {
			const data = {
				'role': role,
				'procurementEntity': role === ROLE_MAS ? organization : '',
				'touAccepted': acceptTerms
			};

			dispatch(userActions.activateUser(data, role));
		} else {
			dispatch(error('Error: User could not be activated. Please try again later.'));
		}
	};

	// User Role/Org Set
	useEffect(() => {
		if (activateUser.success) {
			if (activateUser.callback === ROLE_MAS) {
				dispatch(success('User activation successfully requested.'));
			} else {
				dispatch(success('User successfully activated.'));
			}

			// Logout
			dispatch(setHasChanges(false));
			dispatch(userDataActions.logout());
			window.location.href = (env.BASE_URL) ? env.BASE_URL + '/saml/logout' : '/saml/logout';
		} else if (activateUser.error) {
			dispatch(error('Error: User could not be activated. Please try again later.'));
		}
	}, [activateUser]);

	useEffect(() => {
		if (referenceData && referenceData.success && referenceData.data.procurementEntityDataList && Array.isArray(referenceData.data.procurementEntityDataList) && referenceData.data.procurementEntityDataList.length) {
			setProcurementEntities(referenceData.data.procurementEntityDataList.map(entity => ({ name: entity.name, value: entity.description })));
		}
	}, [referenceData]);

	useEffect(() => {
		//if (authenticate && authenticate.success && getUser && getUser.success && getUser.user && (((getUser.user.roleData !== ROLE_SUPPLIER && getUser.user.roleData !== ROLE_MAS && getUser.user.roleData !== ROLE_NONE && userData.data.authorities[0].authority === ROLE_NONE) || (getUser.user.termsOfUseAccepted === true && getUser.user.enabled === true)))) {
		if (authenticate && authenticate.success && getUser && getUser.success && getUser.user && getUser.user.termsOfUseAccepted === true && getUser.user.userStatus === USER_STATUS_ACTIVE) {
			setLoading(true);
			history.replace('/');
		}
	}, [authenticate, getUser, userData]);

	useEffect(() => {
		if (getUser.success && getUser.user) {
			setUser(getUser.user);
			if (getUser.user && getUser.user.procurementEntityName) {
				setOrganization(getUser.user.procurementEntityName);
			}

			if (getUser.user && getUser.user.roleData) {
				setRole(getUser.user.roleData);
			}
		}
	}, [getUser]);

	useEffect(() => {
		if (authenticate && (authenticate.success || authenticate.error) && getUser && (getUser.success || getUser.error) && content && (content.success || content.error)) {
			setLoading(false);
		}
	}, [authenticate, getUser, content]);

	useEffect(() => {
		if (content.success && content.data) {
			if (content.data.content) {
				setPageContent(base64ToString(content.data.content));
			}

			setPageTitle(content.data.title || 'Account Disabled');
		} else if (!content.request && (content.success || content.error)) {
			setPageContent('');
			setPageTitle('404 - Not Found');
		}
	}, [content]);

	useEffect(() => {
		if (content.success && content.data && Array.isArray(content.data.sections)) {
			let sectionContent = false;
			if (role) {
				sectionContent = content.data.sections.find(section => section.slug === 'terms-of-use-' + role);
				setTermsOfUseContent(sectionContent && sectionContent.content ? base64ToString(sectionContent.content) : CONTENT_ERROR_MESSAGE);
			} else {
				setTermsOfUseContent(false);
			}
		}
	}, [role, content]);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: pageTitle + ' - {siteTitle}',
								data: { siteTitle: PAGE_TITLE_BASE }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<ContentLoading loading={loading}>
					<Row>
						{ !(loading) &&
							<Col lg={{span: 8, offset: 2}}>
								<h1>
									<Translate text={'Request a Procurement Portal account'} />
								</h1>

								{ pageContent !== '' &&
									<ReactMarkdown rehypePlugins={[rehypeRaw]}>{pageContent}</ReactMarkdown>
								}

								<Form noValidate validated={validated} onSubmit={handleSubmit}>
									<Row>
										<Col>

											<Form.Group controlId="role" role="group">
												<h4>
													<Translate text={'Role'} /> <span className="required">*</span>
												</h4>
												<Form.Check
													label={ ROLE_NAME_MAPPING[ROLE_SUPPLIER] }
													name="role"
													id="role-supplier"
													className="form-check-big max-width-none"
													type="radio"
													value={ ROLE_SUPPLIER }
													required={ !role }
													checked={ role == ROLE_SUPPLIER }
													onChange={(e) => {
														handleRoleChange(e);

														if (e.target.validity.valid) {
															e.target.closest('.form-group').classList.remove('is-invalid');
														}
													}}
													onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
												/>
												<Form.Check
													label={ ROLE_NAME_MAPPING[ROLE_MAS] }
													name="role"
													id="role-mas"
													className="form-check-big max-width-none"
													type="radio"
													value={ ROLE_MAS }
													required={ !role }
													checked={ role == ROLE_MAS }
													onChange={(e) => {
														handleRoleChange(e);

														if (e.target.validity.valid) {
															e.target.closest('.form-group').classList.remove('is-invalid');
														}
													}}
													onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
												/>
												<Form.Control.Feedback type="invalid">
													<Translate text={'Role is required.'}/>
												</Form.Control.Feedback>
											</Form.Group>

											{role === ROLE_MAS &&
												<>
													<div className="box box--blue-light">
														<p>
															<Translate text={'If you are presently working for a public sector entity and are authorized to manage public procurement notices on behalf of your organization, you can request an access account.'} />
														</p>
														<p>
															<Translate
																text={'If your entity is not included in the listing below, please <Link> to request your organization to be added.'}
																renderMap={{
																	renderLink: () => <a href="/submit-ticket" target="_blank" rel="noopener noreferrer"><Translate text={'submit a support form'} /></a> // eslint-disable-line react/display-name
																}}
															/>
														</p>
													</div>
													<Form.Group controlId="organization">
														<Form.Label><Translate text={'Organization'}/> <span
															className="required">*</span></Form.Label>
														<Form.Control as="select" required
															onChange={(e) => handleOrganizationChange(e.currentTarget.selectedIndex, e.currentTarget.value)}
															value={organization}>
															<Translator>
																{({translate}) => (
																	<>
																		<option value="">
																			{translate({text: '- Select -'})}
																		</option>

																		{
																			procurementEntities.map((item, index) => (
																				<option value={item.value} key={index}>
																					{item.name}
																				</option>
																			))
																		}
																	</>
																)}
															</Translator>
														</Form.Control>

														<Form.Control.Feedback type="invalid">
															<Translate text={'Organization is required.'}/>
														</Form.Control.Feedback>
													</Form.Group>
												</>
											}

											{(role && role !== ROLE_NONE) && termsOfUseContent !== false && termsOfUseContent !== '' &&
												<fieldset>
													<legend><Translate text={'Terms of Use'} /></legend>
													{termsOfUseContent !== '' &&
														<div>
															<ReactMarkdown rehypePlugins={[rehypeRaw]}>{termsOfUseContent}</ReactMarkdown>
														</div>
													}

													<Translator>
														{({translate}) => (
															<>
																<Form.Group role="group" className={'terms-of-use-form-group'} aria-label={ translate({ text: 'Terms of Use' }) }>
																	<Form.Check
																		inline
																		label={ translate({ text: 'I have read and understand the Terms of Use and agree to abide by the restrictions on use of the Procurement Portal outlined in this document.' }) }
																		name="acceptTermsOfUse"
																		id="acceptTermsOfUse"
																		className="form-check-big"
																		type="checkbox"
																		value="yes"
																		required={ true }
																		checked={ user && user.termsOfUseAccepted === true ? true : acceptTerms }
																		disabled={ user && user.termsOfUseAccepted === true }
																		onChange={(e) => {
																			handleAcceptTermsChange(e);

																			if (e.target.validity.valid) {
																				e.target.closest('.form-group').classList.remove('is-invalid');
																			}
																		}}
																		onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
																	/>
																	<Form.Control.Feedback type="invalid">
																		<Translate text={'Accepting Terms of Use is required'} />
																	</Form.Control.Feedback>
																</Form.Group>
															</>
														)}
													</Translator>
												</fieldset>
											}

											{ validated && hasErrors &&
												<div className="invalid-feedback show">
													<p><Translate text="Form contains errors. Please fill out all required fields." /></p>
												</div>
											}

											<Button type={'submit'}><Translate text={'Request Access'} /></Button>
										</Col>
									</Row>
								</Form>
							</Col>
						}
					</Row>
				</ContentLoading>
			</Container>

			<Footer />
		</>
	);
}

export default AccountDisabled;
