import { standingOfferConstants } from '../constants/standingOfferConstants';
import { standingOfferService } from '../../services/standingOfferService';

export const standingOfferActions = {
	getStandingOffers
};

function getStandingOffers(data, callback) {
	return dispatch => {
		dispatch(request());

		standingOfferService.getStandingOffers(data)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: standingOfferConstants.STANDING_OFFER_REQUEST }; }

	function success(data, callback) { return { type: standingOfferConstants.STANDING_OFFER_SUCCESS, data, callback }; }

	function error(callback) { return { type: standingOfferConstants.STANDING_OFFER_ERROR, callback }; }
}
