import React from 'react';
import { Translate } from 'react-translated';

import Button from 'react-bootstrap/Button';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleDoubleLeft,
	faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons';

function Pagination(props) {
	const { data } = props;

	if (!data) {
		return null;
	}

	let pageCount;
	let pageItems = [];

	if (data.totalRecords && data.numberOfRecords) {
		pageCount = Math.ceil(data.totalRecords / data.numberOfRecords);
	}

	const handleClick = (event, page) => {
		event.target.blur();

		if (props.handlePaginationChange) {
			props.handlePaginationChange(page);
		}
	};

	if (pageCount > 1) {
		for (let i = 1; i <= pageCount; i++) {
			// since we're seeing huge pagination numbers, only adding buttons for the first & last 100.
			// adding 1000s of these buttons to the DOM slows the browser down considerably.
			if (i > 100 && i < (pageCount - 100)) {
				continue;
			}

			let itemClasses = 'results-pagination__list-item';

			if (data.currentPage === i) {
				itemClasses += ' results-pagination__list-item--active';
			} else if (data.currentPage > 1 && data.currentPage === (i + 1)) {
				itemClasses += ' results-pagination__list-item--active-sibling';
			}

			pageItems.push(
				<li className={ itemClasses } key={ i }>
					<Button
						variant="link"
						className={ (data.currentPage === i) ? 'results-pagination__btn results-pagination__btn--active' : 'results-pagination__btn' }
						onClick={(e) => handleClick(e, i) }
					>
						{ i }
					</Button>
				</li>
			);
		}

		return (
			<div className="results-pagination">
				<Button
					variant="link"
					className="results-pagination__btn results-pagination__prev"
					disabled={ (parseInt(data.currentPage, 10) == 1) }
					onClick={(e) => handleClick(e, parseInt(data.currentPage, 10) - 1) }
				>
					<FontAwesomeIcon icon={ faAngleDoubleLeft } />
					<span className="sr-only">
						<Translate text="Previous page" />
					</span>
				</Button>
				<ol className="results-pagination__list">
					{ pageItems }
				</ol>
				<Button
					variant="link"
					className="results-pagination__btn results-pagination__next"
					disabled={ (parseInt(data.currentPage, 10) == pageCount) }
					onClick={(e) => handleClick(e, parseInt(data.currentPage, 10) + 1) }
				>
					<FontAwesomeIcon icon={ faAngleDoubleRight } />
					<span className="sr-only">
						<Translate text="Next page" />
					</span>
				</Button>
			</div>
		);
	}

	return null;
}

export default Pagination;
