import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';
import { setHasChanges, error, success } from '../../../store/actions/globalMessagesActions';

import { Button, ButtonToolbar } from 'react-bootstrap';

import EditCommodity from './EditCommodity';
import AddCommodity from './AddCommodity';

function Commodities({ isActive }) {
	const dispatch = useDispatch();

	// State data.
	const postReferenceTable = useSelector(state => state.postReferenceTable);

	const [view, setView] = useState(null);

	useEffect(() => {
		if (!isActive) {
			setView(null);
		}
	}, [isActive]);

	// Updated changes.
	useEffect(() => {
		if (postReferenceTable.callback === 'edit-commodity') {
			if (postReferenceTable.success) {
				dispatch(success('Commodity successfully updated.'));
				dispatch(setHasChanges(false));
				dispatch(referenceTableActions.resetUpdateReferenceTable());
				setView(null);
			} else if (postReferenceTable.error) {
				if (postReferenceTable.status == 409) {
					dispatch(error('Error: Commodity code already in the system.'));
				} else {
					dispatch(error('Error: Commodity could not be updated. Please try again later.'));
				}

				dispatch(referenceTableActions.resetUpdateReferenceTable());
			}
		}

		if (postReferenceTable.callback === 'add-commodity') {
			if (postReferenceTable.success) {
				dispatch(success('Commodity successfully created.'));
				dispatch(setHasChanges(false));
				dispatch(referenceTableActions.resetUpdateReferenceTable());
				setView(null);
			} else if (postReferenceTable.error) {
				if (postReferenceTable.status == 409) {
					dispatch(error('Error: Commodity code already in the system.'));
				} else {
					dispatch(error('Error: Commodity could not be created. Please try again later.'));
				}

				dispatch(referenceTableActions.resetUpdateReferenceTable());
			}
		}
	}, [postReferenceTable]);

	return (
		<div className={'box box--blue-light box--wide admin-commodities'}>
			{ !view &&
				<>
					<h2>
						<Translate text={'Commodities'} />
					</h2>

					<ButtonToolbar>
						<Button onClick={() =>  setView('edit') }>
							<Translate text={'Edit Commodity'} />
						</Button>
						<Button onClick={() =>  setView('add') }>
							<Translate text={'Add Commodity'} />
						</Button>
					</ButtonToolbar>
				</>
			}

			{ view == 'edit' &&
				<EditCommodity handleCancel={() => setView(null) } />
			}

			{ view == 'add' &&
				<AddCommodity handleCancel={() => setView(null) } />
			}
		</div>
	);
}

export default Commodities;
