/* eslint-disable id-length */
import React, {useEffect, useState} from 'react';
import MetaTags, { ReactTitle } from 'react-meta-tags';
import { useLocation, useHistory } from 'react-router-dom';
import {Translate, Translator} from 'react-translated';
import {useSelector, shallowEqual} from 'react-redux';

import { dataLayerPageView } from '../../helpers/miscHelpers';
import { isLoggedIn, isRole } from '../../helpers/userHelpers';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import Button from 'react-bootstrap/Button';
import {base64ToString} from '../../helpers/fileHelpers';
import {isArray} from 'validate.js';
import ContentLoading from '../misc/ContentLoading';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function Home() {
	const {
		ROLE_NONE,
		ROLE_SUPPLIER,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		CONTENT_ERROR_MESSAGE,
		ROLE_PROCUREMENT_ADMIN,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL
	} = require('../../config/constants');
	const location = useLocation();
	const currentPath = location.pathname;
	const env = window.env;
	const history = useHistory();

	// State data.
	const language = useSelector(state => state.language);
	const authenticate = useSelector(state => state.authenticate);
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const content = useSelector(state => state.content);

	const loading = !content.success && !content.error;
	const [topCopy, setTopCopy] = useState('');
	const [supplierInfo, setSupplierInfo] = useState('');
	const [masInfo, setMasInfo] = useState('');
	const [noticesInfo, setNoticesInfo] = useState('');
	const [policiesInfo, setPoliciesInfo] = useState('');
	const [infoBoxCount, setInfoBoxCount] = useState(0);

	// Temporarily Disable API Content – Static copy set in state above
	useEffect(() => {
		if (content.success && content.data) {
			let infoBoxes = 0;
			if (isArray(content.data.sections)) {
				content.data.sections.forEach(section => {
					switch (section.slug) {
						case 'home-supplier-info':
							infoBoxes++;
							setSupplierInfo(base64ToString(section.content));
							break;
						case 'home-pse-info':
							infoBoxes++;
							setMasInfo(base64ToString(section.content));
							break;
						case 'home-top-copy':
							setTopCopy(base64ToString(section.content));
							break;
						case 'home-notices-info':
							infoBoxes++;
							setNoticesInfo(base64ToString(section.content));
							break;
						case 'home-policies-info':
							infoBoxes++;
							setPoliciesInfo(base64ToString(section.content));
							break;
						default:
							break;
					}
				});
			} else {
				setSupplierInfo(CONTENT_ERROR_MESSAGE);
				setMasInfo(CONTENT_ERROR_MESSAGE);
				setTopCopy(CONTENT_ERROR_MESSAGE);
				setNoticesInfo(CONTENT_ERROR_MESSAGE);
				setPoliciesInfo(CONTENT_ERROR_MESSAGE);
			}
			setInfoBoxCount(infoBoxes || 0);
		} else if (!content.request && (content.success || content.error)) {
			setSupplierInfo(CONTENT_ERROR_MESSAGE);
			setMasInfo(CONTENT_ERROR_MESSAGE);
			setTopCopy(CONTENT_ERROR_MESSAGE);
			setNoticesInfo(CONTENT_ERROR_MESSAGE);
			setPoliciesInfo(CONTENT_ERROR_MESSAGE);
		}
	}, [content]);

	useEffect(() => {
		const title = 'Procurement Portal - Government of Nova Scotia';

		dataLayerPageView(
			currentPath,
			title,
			language.code
		);
	}, [language]);

	// User Data state.
	useEffect(() => {
		if (isLoggedIn(userData)) {
			if (isRole(userData, [ROLE_SUPPLIER, ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) {
				history.replace('/tenders');
			}
		}
	}, [userData]);

	useEffect(() => {
		if (isLoggedIn(userData)) {
			if (userData && userData.success && userData.data && userData.data.authorities && Array.isArray(userData.data.authorities) && userData.data.authorities.length && userData.data.authorities[0] && userData.data.authorities[0].authority && userData.data.authorities[0].authority === ROLE_NONE) {
				history.replace('/request-activation');
			}
		}
	}, [getUser, userData, authenticate]);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<>
						<ReactTitle
							title={
								translate({ text: 'Procurement Portal - Government of Nova Scotia' })
							}
						/>

						<MetaTags>
							<meta name="dcterms.title" content={ translate({ text: 'Procurement Portal - Government of Nova Scotia' }) } />
							<meta property="og:title" content={ translate({ text: 'Procurement Portal - Government of Nova Scotia' }) } />
						</MetaTags>
					</>
				)}
			</Translator>

			<Header />

			<ContentLoading loading={loading}>
				<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
					<Row className={'mb-md-3 mb-lg-5'}>
						<Col>
							{ loading === false && topCopy !== '' &&
								<ReactMarkdown rehypePlugins={[rehypeRaw]}>{topCopy}</ReactMarkdown>
							}
						</Col>
					</Row>
					<Row className={'mb-md-3 mb-lg-5'}>
						{loading === false && supplierInfo !== '' &&
							<Col lg={12 / infoBoxCount} className={'mb-3'}>
								<div className="home-box box box--blue-light box--border box--mobile-wide box--full-height">
									<ReactMarkdown>{supplierInfo}</ReactMarkdown>
									<div className={'button-container'}>
										<Button href={`${(env.BASE_URL) ? env.BASE_URL : ''}/saml/login`}>
											<Translate text={'Login/Create Account'}/>
										</Button>
									</div>
								</div>
							</Col>
						}

						{loading === false && masInfo !== '' &&
							<Col lg={12 / infoBoxCount} className={'mb-3'}>
								<div className="home-box box box--blue-light box--border box--mobile-wide box--full-height">
									<ReactMarkdown>{masInfo}</ReactMarkdown>
									<div className={'button-container'}>
										<Button href={`${(env.BASE_URL) ? env.BASE_URL : ''}/saml/login`}>
											<Translate text={'Login/Create Account'}/>
										</Button>
									</div>
								</div>
							</Col>
						}

						{loading === false && noticesInfo !== '' &&
							<Col lg={12 / infoBoxCount} className={'mb-3'}>
								<div className="home-box box box--blue-light box--border box--mobile-wide box--full-height">
									<ReactMarkdown>{noticesInfo}</ReactMarkdown>
								</div>
							</Col>
						}

						{loading === false && policiesInfo !== '' &&
							<Col lg={12 / infoBoxCount} className={'mb-3'}>
								<div className="home-box box box--blue-light box--border box--mobile-wide box--full-height">
									<ReactMarkdown>{policiesInfo}</ReactMarkdown>
								</div>
							</Col>
						}
					</Row>
				</Container>
			</ContentLoading>
			<Footer />
		</>
	);
}

export default Home;
