module.exports = {
	isRole: isRole,
	isOrganization: isOrganization,
	isEndUserEntity: isEndUserEntity,
	isDeptPublicWorks: isDeptPublicWorks,
	isLoggedIn: isLoggedIn,
	isNotLoggedIn: isNotLoggedIn,
	isAuthenticated: isAuthenticated,
	isActive: isActive,
	isPseManager: isPseManager
};

function isRole(userData, roles) {
	if (userData.data && userData.data.authorities && userData.data.authorities.length) {
		const userRole = userData.data.authorities[0].authority;

		if (Array.isArray(roles) && roles.includes(userRole)) {
			return true;
		} else if (userRole == roles) {
			return true;
		}
	}

	return false;
}

function isOrganization(user, organization) {
	if (user && user.organizationId && user.organizationId === organization) {
		return true;
	} else if (user && user.procurementEntityName && user.procurementEntityName === organization) {
		return true;
	}

	return false;
}

function isEndUserEntity(user, endUserEntityOrganizationId) {
	if (user && user.endUserEntityOrganizationId && user.endUserEntityOrganizationId === endUserEntityOrganizationId) {
		return true;
	}

	return false;
}

function isDeptPublicWorks(user) {
	const { ENTITY_PUBLIC_WORKS_ID } = require('../config/constants');

	if (user && user.endUserEntityOrganizationId && user.endUserEntityOrganizationId === ENTITY_PUBLIC_WORKS_ID) {
		return true;
	}

	return false;
}

function isLoggedIn(userData) {
	if (userData.success && userData.data.jwtToken) {
		return true;
	}
}

function isNotLoggedIn(userData) {
	if (userData.error || (userData.success && !userData.data.jwtToken)) {
		return true;
	}
}

function isAuthenticated(authenticate, userData) {
	if ((authenticate.success && authenticate.data.jwttoken) || (userData.success && userData.data.jwtToken)) {
		return true;
	}
}

function isActive(user) {
	const { USER_STATUS_ACTIVE } = require('../config/constants');

	if (user && user.userStatus === USER_STATUS_ACTIVE) {
		return true;
	}
}

function isPseManager(userData) {
	const { ROLE_MAS_ACCOUNT_MANAGER } = require('../config/constants');

	if (userData.data && userData.data.authorities && userData.data.authorities.length) {
		const userRole = userData.data.authorities[0].authority;

		if (userRole == ROLE_MAS_ACCOUNT_MANAGER) {
			return true;
		}
	}

	return false;
}

