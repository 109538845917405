import axios from 'axios';
import { authHeader } from '../helpers/authHeader';
const env = window.env;

export const referenceTableService = {
	get,
	post,
	unspscGet,
	unspscEnableCheck
};

function get(slug, params) {
	if (!slug) {
		return handleErrorResponse();
	}

	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/admin/${slug}`,
		params: params,
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function post(slug, data, params) {
	if (!slug) {
		return handleErrorResponse();
	}

	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/admin/${slug}`,
		data: data,
		params: params,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function unspscGet(params, data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/admin/unspsc/get`,
		params: params,
		data: data,
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function unspscEnableCheck(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/admin/unspsc/enable/check`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => response)
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return [];
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line

	return { error: 'Error: Network Error', status: (error.response && error.response.status) ? error.response.status : null };
}
