// Helper functions.
/* eslint-disable id-length */

module.exports = {
	scrollUp,
	getNumberWithOrdinal,
	validateHealthNumber,
	dataLayerPageView,
	currencyFormat,
	numValidate,
	formatFilterData,
	cleanAmountValue
};

function scrollUp() {
	const element = document.getElementById('main');

	if (element) {
		const position = element.getBoundingClientRect().top + window.pageYOffset;
		window.scrollTo({ top: position, behavior: 'smooth' });
	}
}

function getNumberWithOrdinal(n) {
	const s = ['th', 'st', 'nd', 'rd'];
	const v = n % 100;

	return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function validateHealthNumber(cardNumber, region) {
	if (!cardNumber) {
		return false;
	}

	switch (region) {
		case 'NL':
			// 12 numeric; a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 12) && validateMod10(cardNumber));
		case 'PE':
			// 8 numeric; a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 8) && validateMod10(cardNumber));
		case 'NS':
			// 10 numeric; a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 10) && validateMod10(cardNumber));
		case 'NB':
			// 9 numeric; a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9) && validateMod10(cardNumber));
		case 'QC':
			// 12 digits (4 alpha, 8 numeric)
			return validateLength(cardNumber, 12);
		case 'ON':
			// 10 digits (optional: 1 or 2 alpha characters for version codes if applicable); a valid MOD
			// 10 check digited number
			return (validateLength(cardNumber, 10) || validateLength(cardNumber, 11) || validateLength(cardNumber, 12));
		case 'MB':
			// 9 numeric
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9));
		case 'SK':
			// 9 numeric; validation is done via a lookup table provided to CIHI by Saskatchewan Health
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9));
		case 'AB':
			// 9 numeric; the 5th digit is the check digit. The algorithm for validating the HCN involves
			// 2 tables: Table A and Table B.
			// Table A is 0246813579
			// Table B is 0987654321
			// Add together these values:
			// 1st digit of HCN +
			// 3rd digit of HCN +
			// 6th digit of HCN +
			// 8th digit of HCN +
			// Table A value at position (value of 2nd digit of HCN + 1) +
			// Table A value at position (value of 4th digit of HCN + 1) +
			// Table A value at position (value of 7th digit of HCN + 1) +
			// Table A value at position (value of 9th digit of HCN + 1)
			// Divide that total by 10, giving a remainder.
			// The 5th digit of HCN must = Table B value at position (remainder + 1).
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9));
		case 'BC':
			// 10 numeric; the validation is done using the first 10 digits only, with the 10th digit as the
			// check digit.
			// Add together these values:
			// 2nd digit of HCN times 2 +
			// 3rd digit of HCN times 4 +
			// 4th digit of HCN times 8 +
			// 5th digit of HCN times 5 +
			// 6th digit of HCN times 10 +
			// 7th digit of HCN times 9 +
			// 8th digit of HCN times 7 +
			// 9th digit of HCN times 3
			// Divide the total by 11, giving a remainder.
			// The 10th digit of HCN must = 11 − remainder.
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 10));
		case 'YT':
			// 9 numeric; a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9) && validateMod10(cardNumber));
		case 'NT':
			// 8 digits (1 alpha [N, M, T, D, H]), 7 numeric; MOD 10 validation applied on first 8 digits
			// with a 0 inserted as the first digit
			return validateLength(cardNumber, 8);
		case 'NU':
			// 9 numeric; first digit must be 1; last digit must be in (3, 4, 5, 6, 7, 8) and the 2nd through
			// 8th digits of the HCN must be a valid MOD 10 check digited number
			return (validateNumeric(cardNumber) && validateLength(cardNumber, 9));
		default:
			return true;
	}
}

function validateNumeric(value) {
	return !isNaN(value);
}

function validateLength(value, length) {
	return (value.toString().length === length);
}

function validateMod10(value) {
	const algorithmNum = value.slice(0, -1);
	const checkDigit = parseInt(value.substr(-1), 10);

	// From the rightmost digit (excluding the check digit) and moving left
	const arr = algorithmNum.split('').reverse();

	const products = arr.map((character, index) => {
		// double the value of every second digit
		const num = parseInt(character, 10);

		if (index % 2 === 0) {
			const product = num * 2;
			// If the result of this doubling operation is greater than 9 (e.g., 8 × 2 = 16),
			// then add the digits of the result (e.g., 16: 1 + 6 = 7, 18: 1 + 8 = 9) or,
			// equivalently, subtract 9 from the result
			return product > 9 ? product - 9 : product;
		}

		return num;
	});

	const sum = products.reduce((prevVal, currVal) => { return prevVal + currVal; }, 0);

	// Take the sum of all the digits (including the check digit).
	const totalSum = sum + checkDigit;

	// If the total modulo 10 is equal to 0 (if the total ends in zero)
	// then the number is valid according to the Luhn formula; otherwise it is not valid.
	return (totalSum > 0) && totalSum % 10 === 0;
}

function dataLayerPageView(url, title, lang) {
	const { LANGUAGE_ENGLISH_CODE } = require('../config/constants');

	window.dataLayer = window.dataLayer || [];
	if (window.dataLayer) {
		window.dataLayer.push({
			'event': 'Virtual Page View',
			'virtualPageURL': url,
			'virtualPageTitle': title,
			'virtualLanguage': (lang) ? lang : LANGUAGE_ENGLISH_CODE
		});
	}
}

function currencyFormat(value, code, hideCurrency) {
	if (!value) {
		return;
	}

	const string = value.toString().replace(/[^0-9.]/gm, '');
	if (string && !isNaN(string)) {
		let formatter, currencyString;

		switch (code) {
			case 'USD':
				formatter = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD'
				});
				currencyString = 'USD';
				break;
			case 'EUR':
				formatter = new Intl.NumberFormat('en-GB', {
					style: 'currency',
					currency: 'EUR'
				});
				break;
			case 'GBP':
				formatter = new Intl.NumberFormat('en-GB', {
					style: 'currency',
					currency: 'GBP'
				});
				break;
			case 'CAD':
			default:
				formatter = new Intl.NumberFormat('en-CA', {
					style: 'currency',
					currency: 'CAD'
				});
				currencyString = 'CAD';
				break;
		}

		let formattedValue = formatter.format(string);

		if (currencyString && !hideCurrency) {
			formattedValue = formattedValue + ' ' + currencyString;
		}

		return formattedValue;
	}

	return value;
}

function numValidate(event) {
	var theEvent = event || window.event;
	var key = theEvent.keyCode || theEvent.which;
	var isShift = theEvent.shiftKey;
	var value = theEvent.target.value;

	// If multiple dots entered.
	if (key == 46 && value.split('.').length == 2) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) {
			theEvent.preventDefault();
		}

		return false;
	}

	// Don't validate the input if below delete, backspace, and tab keys were pressed
	if (key == 8 || key == 46 || key == 9 || key == 190) {
		return;
	}

	if (theEvent.type == 'paste') {
		key = theEvent.clipboardData.getData('text');

		if (typeof key === 'string' || key instanceof String) {
			key = cleanAmountValue(key);
		}
	} else {
		key = String.fromCharCode(key);
	}

	var regex = new RegExp(/^[0-9]+([.][0-9]+)?$/);

	if (!regex.test(key) || isShift) {
		theEvent.returnValue = false;
		if (theEvent.preventDefault) {
			theEvent.preventDefault();
		}
	}
}

function formatFilterData(filterData) {
	if (!filterData) {
		return;
	}

	const data = {
		filters: Object.keys(filterData).map((key) => {
			const value = filterData[key];

			if (Array.isArray(value)) {
				return { key: key, values: value };
			} else if (typeof value === 'object' && value !== null) {
				const from = value.from || null;
				const to = value.to || null;

				if (!from && !to) {
					return null;
				}

				return { key: key, value: from, valueTo: to };
			} else if (value === null) {
				return null;
			}

			return { key: key, value: value };
		}).filter(item => item !== null)
	};

	return data;
}

function cleanAmountValue(value) {
	const cleanValue = value.replaceAll('$', '').replaceAll('£', '').replaceAll('€', '').replaceAll(',', '');

	return cleanValue;
}
