import { tenderConstants } from '../constants/tenderConstants';

export function tender(state = {}, action) {
	switch (action.type) {
		case tenderConstants.TENDERS_REQUEST:
			return {
				request: true
			};
		case tenderConstants.TENDERS_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback,
				single: action.single
			};
		case tenderConstants.TENDERS_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		default:
			return state;
	}
}

export function watchlist(state = {}, action) {
	switch (action.type) {
		case tenderConstants.TENDERS_WATCHLIST_REQUEST:
			return {
				request: true
			};
		case tenderConstants.TENDERS_WATCHLIST_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case tenderConstants.TENDERS_WATCHLIST_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		default:
			return state;
	}
}

export function tenderPost(state = {}, action) {
	switch (action.type) {
		case tenderConstants.TENDERS_POST_REQUEST:
			return {
				request: true
			};
		case tenderConstants.TENDERS_POST_SUCCESS:
			return {
				success: true,
				update: action.update,
				data: action.data,
				callback: action.callback
			};
		case tenderConstants.TENDERS_POST_ERROR:
			return {
				error: true,
				status: action.status,
				callback: action.callback
			};
		case tenderConstants.TENDERS_POST_RESET:
			return {};
		default:
			return state;
	}
}

export function tenderWatchlist(state = {}, action) {
	switch (action.type) {
		case tenderConstants.TENDERS_WATCHLIST_POST_REQUEST:
			return {
				request: true
			};
		case tenderConstants.TENDERS_WATCHLIST_POST_SUCCESS:
			return {
				success: true,
				update: action.update,
				data: action.data,
				watch: action.watch,
				callback: action.callback
			};
		case tenderConstants.TENDERS_WATCHLIST_POST_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		case tenderConstants.TENDERS_WATCHLIST_POST_RESET:
			return {};
		default:
			return state;
	}
}

export function currentTender(state = {}, action) {
	switch (action.type) {
		case tenderConstants.CURRENT_TENDER_UPDATE:
			return action.data;
		case tenderConstants.CURRENT_TENDER_RESET:
			return {};
		default:
			return state;
	}
}
