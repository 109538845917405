import { searchConstants } from '../constants/searchConstants';

export function search(state = {}, action) {
	switch (action.type) {
		case searchConstants.SEARCH_UPDATE:
			return {
				updated: true,
				category: action.category,
				fields: action.fields,
				filters: action.filters
			};
		default:
			return state;
	}
}
