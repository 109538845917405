import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { Translate } from 'react-translated';

export default function FormSlideToggle(props) {
	const {
		options,
		id,
		name,
		title,
		helpText,
		value,
		required,
		isStatic,
		size,
		isInline,
		ariaLabel,
		readOnly
	} = props;
	const [fieldValue, setFieldValue] = useState(value);

	useEffect(() => {
		setFieldValue(value);
	}, [value]);

	const handleOnChange = (event) => {
		const { id, value } = event.target;

		if (!isStatic) {
			setFieldValue(value);
		}

		if (props.handleOnChange) {
			props.handleOnChange(id, value);
		}
	};

	const handleOnBlur = (event) => {
		const { id, value } = event.target;

		if (props.handleOnBlur) {
			props.handleOnBlur(id, value);
		}
	};

	if (Array.isArray(options) && options.length) {
		return (
			<div className={`inline-radio slide-toggle ${(size === 'sm') ? 'slide-toggle--sm' : ''} ${(isInline) ? 'slide-toggle--inline' : ''}`} role="group" aria-labelledby={ (title) ? `${id}-title` : null }>
				{ ariaLabel &&
					<span className='sr-only'><Translate text={ ariaLabel } /></span>
				}

				{ title &&
					<h4 id={`${id}-title`} className="slide-toggle__title">
						{ title }
						{ required &&
							<span className="required">*</span>
						}
					</h4>
				}

				<div className="slide-toggle__control">
					{ helpText &&
						<p className="slide-toggle__help-text">
							{ helpText }
						</p>
					}

					<div className="slide-toggle__bar">
						{
							options.map((item, index) => {
								if (item.label) {
									let colorClass = 'slide-toggle__item--default';

									switch (item.color) {
										case 'success':
											colorClass = 'slide-toggle__item--success';
											break;
										case 'danger':
											colorClass = 'slide-toggle__item--danger';
											break;
										case 'grayDark':
											colorClass = 'slide-toggle__item--gray-dark';
											break;
										default:
											break;
									}

									return (
										<Form.Check
											key={ index }
											inline
											label={ item.label }
											value={ item.value }
											type="radio"
											id={`${id}-option-${index}`}
											name={`${id}-${name}`}
											className={`slide-toggle__item ${colorClass}`}
											checked={
												(value !== null && typeof value !== 'undefined' && item.value.toString() === value.toString()) ||
												(fieldValue !== null && typeof fieldValue !== 'undefined' && item.value.toString() === fieldValue.toString())
											}
											required={ (required) ? true : null }
											data-toggle-input={ item.label }
											onChange={ handleOnChange }
											onBlur={ handleOnBlur }
											readOnly={readOnly}
											disabled={readOnly}
										/>
									);
								}

								return null;
							})
						}
					</div>
				</div>
			</div>
		);
	}

	return null;
}
