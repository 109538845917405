import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-translated';
import { Row, Col, Tab, Badge, Nav } from 'react-bootstrap';

import { referenceDataActions } from '../../store/actions/referenceDataActions';

import AltpContacts from './reference-tables/AltpContacts';
import Commodities from './reference-tables/Commodities';
import EndUserEntities from './reference-tables/EndUserEntities';
import ProcurementEntities from './reference-tables/ProcurementEntities';
import ProcurementMethods from './reference-tables/ProcurementMethods';
import SolicitationTypes from './reference-tables/SolicitationTypes';
import TradeAgreements from './reference-tables/TradeAgreements';

function TabTitle(props) {
	const { title, count } = props;

	return (
		<>
			<span className="tab-title tab-title--uppercase"><Translate text={title} /></span>
			{ count &&
			<Badge variant="primary">{ count }</Badge>
			}
		</>
	);
}

function ReferenceTables() {
	const dispatch = useDispatch();

	const postReferenceTable = useSelector(state => state.postReferenceTable);

	// State data.
	const [key, setKey] = useState('altp-contacts');

	// Updated changes.
	useEffect(() => {
		if (postReferenceTable.success) {
			dispatch(referenceDataActions.getReferenceData());
		}
	}, [postReferenceTable]);

	return (
		<Row className="mt-2">
			<Col>
				<Tab.Container defaultActiveKey={key} onSelect={(k) => setKey(k)}>
					<Row>
						<Col md={12}>
							<Nav role="tablist" as="nav" className={'nav-tabs small mb-0'}>
								<Nav.Item as={Nav.Link} eventKey="altp-contacts">
									<TabTitle title="ALTP Contacts" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="commodities">
									<TabTitle title="Commodities" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="end-user-entities">
									<TabTitle title="End User Entities" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="procurement-entities">
									<TabTitle title="Procurement Entities" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="procurement-methods">
									<TabTitle title="Procurement Methods" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="solicitation-types">
									<TabTitle title="Solicitation Types" />
								</Nav.Item>
								<Nav.Item as={Nav.Link} eventKey="trade-agreements">
									<TabTitle title="Trade Agreements" />
								</Nav.Item>
							</Nav>
						</Col>
					</Row>
					<Row className={'mt-0'}>
						<Col md={12}>
							<Tab.Content>
								<Tab.Pane eventKey="altp-contacts">
									<AltpContacts isActive={ key == 'altp-contacts' } />
								</Tab.Pane>
								<Tab.Pane eventKey="commodities">
									<Commodities isActive={ key == 'commodities' } />
								</Tab.Pane>
								<Tab.Pane eventKey="end-user-entities">
									<EndUserEntities isActive={ key == 'end-user-entities' } />
								</Tab.Pane>
								<Tab.Pane eventKey="procurement-entities">
									<ProcurementEntities isActive={ key == 'procurement-entities' } />
								</Tab.Pane>
								<Tab.Pane eventKey="procurement-methods">
									<ProcurementMethods isActive={ key == 'procurement-methods' } />
								</Tab.Pane>
								<Tab.Pane eventKey="solicitation-types">
									<SolicitationTypes isActive={ key == 'solicitation-types' } />
								</Tab.Pane>
								<Tab.Pane eventKey="trade-agreements">
									<TradeAgreements isActive={ key == 'trade-agreements' } />
								</Tab.Pane>
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</Col>
		</Row>
	);
}

export default ReferenceTables;
