import { altpConstants } from '../constants/altpConstants';
import { altpService } from '../../services/altpService';

export const altpActions = {
	getAltps,
	postAltp,
	resetPostAltp
};

function getAltps(params, callback, filters) {
	return dispatch => {
		dispatch(request());

		altpService.getAltps(params, filters)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: altpConstants.ALTP_GET_REQUEST }; }

	function success(data, callback) { return { type: altpConstants.ALTP_GET_SUCCESS, data, callback }; }

	function error(callback) { return { type: altpConstants.ALTP_GET_ERROR, callback }; }
}

function postAltp(data, responseCallback) {
	return dispatch => {
		dispatch(request());

		altpService.postAltp(data)
			.then(
				response => {
					if (response && !response.error) {
						dispatch(success(response, true));
					} else {
						dispatch(error(response.status ? response.status : null));
					}
				}
			);
	};

	function request() { return { type: altpConstants.ALTP_POST_REQUEST }; }

	function success(data, update) { return { type: altpConstants.ALTP_POST_SUCCESS, data: data, update: update, callback: responseCallback }; }

	function error(status) { return { type: altpConstants.ALTP_POST_ERROR, status: status, callback: responseCallback }; }
}

function resetPostAltp() {
	return {
		type: altpConstants.ALTP_POST_RESET
	};
}
