import { altpConstants } from '../constants/altpConstants';

export function altpGet(state = {}, action) {
	switch (action.type) {
		case altpConstants.ALTP_GET_REQUEST:
			return {
				request: true
			};
		case altpConstants.ALTP_GET_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case altpConstants.ALTP_GET_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		default:
			return state;
	}
}

export function altpPost(state = {}, action) {
	switch (action.type) {
		case altpConstants.ALTP_POST_REQUEST:
			return {
				request: true
			};
		case altpConstants.ALTP_POST_SUCCESS:
			return {
				success: true,
				update: action.update,
				data: action.data,
				callback: action.callback
			};
		case altpConstants.ALTP_POST_ERROR:
			return {
				error: true,
				status: action.status,
				callback: action.callback
			};
		case altpConstants.ALTP_POST_RESET:
			return {};
		default:
			return state;
	}
}
