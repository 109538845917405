import React from 'react';
import { Translate } from 'react-translated';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

function Footer() {
	return (
		<Container as="footer" fluid={ true } className="footer">
			<Row>
				<Col md={6}>
					<Nav>
						<Nav.Item>
							<Nav.Link
								href="https://www.twitter.com/nsgov"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="Twitter" />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								href="https://www.youtube.com/nsgov"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="YouTube" />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								href="https://www.facebook.com/nsgov"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="Facebook" />
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Nav>
						<Nav.Item>
							<Nav.Link
								href="https://beta.novascotia.ca/privacy"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="Privacy" />
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								href="https://beta.novascotia.ca/terms"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="Terms" />
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Nav className="footer-copyright">
						<Nav.Item>
							<Nav.Link
								href="https://beta.novascotia.ca/copyright/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Translate text="Crown copyright &copy; Government of Nova Scotia" />
							</Nav.Link>
						</Nav.Item>
					</Nav>
				</Col>
			</Row>
		</Container>
	);
}

export default Footer;
