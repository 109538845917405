import React, { useEffect, useState, useRef } from 'react';
import { Translator } from 'react-translated';
import sanitizeHtml from 'sanitize-html';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import {
	faSearch
} from '@fortawesome/free-solid-svg-icons';

export default function FormSearch(props) {
	const {
		searchValue,
		id,
		ariaLabel,
		placeholder,
		standAlone
	} = props;

	const [value, setValue] = useState('');
	const [isSearching, setIsSearching] = useState(searchValue ? true : false);
	const searchInputRef = useRef(null);
	const searchBtnRef = useRef(null);
	const searchClearRef = useRef(null);

	useEffect(() => {
		setValue(sanitizeHtml(searchValue));
	}, [searchValue]);

	const handleOnChange = (event) => {
		const { value } = event.target;

		if (value !== '') {
			setValue(sanitizeHtml(value));
		} else {
			setValue('');

			if (isSearching) {
				setIsSearching(false);

				if (props.onSearch) {
					props.onSearch('');
				}
			}
		}
	};

	const handleOnKeyPress = (event) => {
		const theEvent = event || window.event;
		const key = theEvent.keyCode || theEvent.which;

		if (key === 13) { // ENTER
			event.preventDefault();
			searchBtnRef.current.click();
		}
	};

	const handleClick = () => {
		setIsSearching(true);

		if (props.onSearch) {
			props.onSearch(value);
		}
	};

	const handleClearClick = () => {
		setValue('');
		setIsSearching(false);

		if (props.onSearch) {
			props.onSearch('');
		}
	};

	return (
		<div className={standAlone ? 'search-form mr-0' : 'search-form'}>
			<Form.Group>
				<Translator>
					{({ translate }) => (
						<Form.Control
							type="text"
							className={ (value) ? 'search-form__input search-form__input--has-search' : 'search-form__input' }
							value={ value }
							id={ id }
							placeholder={ placeholder }
							maxLength={75}
							aria-label={
								translate({
									text: (ariaLabel) ? ariaLabel : 'Search'
								})
							}
							onChange={ handleOnChange }
							onKeyPress={ handleOnKeyPress }
							ref={ searchInputRef }
						/>
					)}
				</Translator>
			</Form.Group>
			<Translator>
				{({ translate }) => (
					<>
						<Button
							variant="primary"
							className="search-form__btn"
							aria-label={
								translate({
									text: 'Submit search'
								})
							}
							ref={ searchBtnRef }
							onClick={ handleClick }
						>
							<FontAwesomeIcon size="lg" icon={ faSearch } />
						</Button>

						{ value &&
							<Button
								variant="link"
								className="search-form__clear"
								size="sm"
								type="button"
								aria-label={
									translate({
										text: 'Clear search'
									})
								}
								ref={ searchClearRef }
								onClick={ handleClearClick }
							>
								<FontAwesomeIcon size="lg" icon={ faTimesCircle } />
							</Button>
						}
					</>
				)}
			</Translator>
		</div>
	);
}
