/* eslint-disable id-length */
import React, {useEffect} from 'react';
import {ReactTitle} from 'react-meta-tags';
import {useLocation} from 'react-router-dom';
import {Translate, Translator} from 'react-translated';
import {useSelector, useDispatch} from 'react-redux';

import {dataLayerPageView} from '../../helpers/miscHelpers';
import {tenderActions} from '../../store/actions/tenderActions';

import {Button, ButtonToolbar, Container, Col, Row} from 'react-bootstrap';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import TenderForm from '../tenders/TenderForm';

function PostTender() {
	const { PAGE_TITLE_BASE } = require('../../config/constants');
	const location = useLocation();
	const currentPath = location.pathname;
	const dispatch = useDispatch();

	// State data.
	const language = useSelector(state => state.language);
	const postTender = useSelector(state => state.postTender);

	const isLoading = postTender.request;

	useEffect(() => {
		const title = 'Post a Tender - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			title,
			language.code
		);
	}, [language]);

	// Jump user to top is loaded in the middle.
	useEffect(() => {
		window.scrollTo(0, 0);
		document.documentElement.scrollTop;

		dispatch(tenderActions.resetCurrentTender());
	}, []);

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{pageTitle} - {siteTitle}',
								data: { siteTitle: PAGE_TITLE_BASE, pageTitle: 'Post a Tender' }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<ContentLoading loading={isLoading} isFixed={true}>
				<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
					<Row>
						<Col md={{ span: 9, order: 'last' }}>
							<div className="post-tender">
								<Row className="pt-3 pb-2">
									<Col lg={7}>
										<h1 className="mt-0"><Translate text={'Post a Tender'} /></h1>
									</Col>
									<Col lg={5}>
										<ButtonToolbar className="justify-content-end">
											<Button href={'/need-help'}>
												<Translate text={'Need Help?'} />
											</Button>
										</ButtonToolbar>
									</Col>
								</Row>

								<TenderForm
									isEdit={false}
									callback="post-tender"
								/>
							</div>
						</Col>
						<Col md={{ span: 3, order: 'first' }}>
							<ButtonToolbar className="pt-3">
								<Button
									variant="link"
									href="/tenders"
								>
									<Translate text={'Back to Tenders'} />
								</Button>
							</ButtonToolbar>
						</Col>
					</Row>
				</Container>
			</ContentLoading>
			<Footer />
		</>
	);
}

export default PostTender;
