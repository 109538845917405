import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
	userData as userDataReducer,
	userDataPersist as userDataPersistReducer
} from './reducers/userDataReducer';
import { authenticate as authenticateReducer } from './reducers/authenticateReducer';
import {
	globalMessages as globalMessagesReducer,
	hasChanges as hasChangesReducer
} from './reducers/globalMessagesReducer';
import { referenceData as referenceDataReducer } from './reducers/referenceDataReducer';
import { language as languageReducer } from './reducers/languageReducer';
import { content as contentReducer, navigation as navigationReducer } from './reducers/contentReducer';
import {
	tender as tenderReducer,
	tenderPost as tenderPostReducer,
	tenderWatchlist as tenderWatchlistReducer,
	watchlist as watchlistReducer,
	currentTender as currentTenderReducer
} from './reducers/tenderReducer';
import { standingOffer as standingOfferReducer } from './reducers/standingOfferReducer';
import { notification as notificationReducer, getNotifications as getNotificationsReducer } from './reducers/notificationReducer';
import {
	altpGet as altpReducer,
	altpPost as altpPostReducer
} from './reducers/altpReducer';
import { search as searchReducer } from './reducers/searchReducer';
import {
	userGet as userGetReducer,
	usersGet as usersGetReducer,
	usersGetRpid as usersGetRpidReducer,
	userPost as userPostReducer,
	userActivate as userActivateReducer,
	userAuthorize as userAuthorizeReducer,
	userDelete as userDeleteReducer,
	userUnauthenticated as unauthenticatedUserReducer,
	userEditing as userEditingReducer
} from './reducers/userReducer';
import { geography as geographyReducer } from './reducers/geographyReducer';
import {
	referenceTableGet as referenceTableGetReducer,
	referenceTablePost as referenceTablePostReducer
} from './reducers/referenceTableReducer';

const reducers = combineReducers({
	userData: userDataReducer,
	userDataPersist: userDataPersistReducer,
	authenticate: authenticateReducer,
	globalMessages: globalMessagesReducer,
	hasChanges: hasChangesReducer,
	referenceData: referenceDataReducer,
	language: languageReducer,
	content: contentReducer,
	navigation: navigationReducer,
	tender: tenderReducer,
	postTender: tenderPostReducer,
	postWatchlist: tenderWatchlistReducer,
	watchlist: watchlistReducer,
	currentTender: currentTenderReducer,
	standingOffer: standingOfferReducer,
	notification: notificationReducer,
	getNotifications: getNotificationsReducer,
	altp: altpReducer,
	altpPost: altpPostReducer,
	search: searchReducer,
	getUser: userGetReducer,
	getUsers: usersGetReducer,
	getUserRpid: usersGetRpidReducer,
	postUser: userPostReducer,
	activateUser: userActivateReducer,
	authorizeUser: userAuthorizeReducer,
	deleteUser: userDeleteReducer,
	unauthenticatedUser: unauthenticatedUserReducer,
	userEditing: userEditingReducer,
	geography: geographyReducer,
	getReferenceTable: referenceTableGetReducer,
	postReferenceTable: referenceTablePostReducer
});

const persistConfig = {
	key: 'root',
	whitelist: [
		'userDataPersist',
		'language'
	],
	storage
};

const persistedReducer = persistReducer(persistConfig, (state, action) => reducers(state, action));

export const store = createStore(
	persistedReducer,
	applyMiddleware(
		thunkMiddleware
	)
);

export const persistor = persistStore(store);
