// Helper functions.
/* eslint-disable id-length */

const { format, parse, parseISO, isValid, isAfter } = require('date-fns');

module.exports = {
	getFormattedDate,
	getFormattedDateTime,
	getFormattedDayTime,
	getFormattedMonth,
	getFormattedYear,
	getSimplifiedDate,
	getSimplifiedDateTime,
	getSimplifiedTime,
	hasClosingDatePassed
};

function getFormattedDate(value) {
	let dateString;
	const date = parseISO(value);

	if (date && isValid(date)) {
		dateString = format(date, 'MMM d, yyyy');
	}

	return dateString;
}

function getFormattedDateTime(value) {
	let dateString;
	const date = parseISO(value);

	if (date && isValid(date)) {
		dateString = format(date, 'MMM d, yyyy h:mm aaa');
	}

	return dateString;
}

function getFormattedDayTime(value) {
	let dateString;
	const date = parseISO(value);

	if (date && isValid(date)) {
		dateString = format(date, 'eeee, h:mm aaa');
	}

	return dateString;
}

function getFormattedMonth(value) {
	let dateString;

	if (null === value) {
		dateString = 'Present';
	} else {
		const date = parseISO(value);
		if (date && isValid(date)) {
			dateString = format(date, 'MMM yyyy');
		}
	}

	return dateString;
}

function getFormattedYear(value) {
	let dateString;

	if (null === value) {
		dateString = 'Present';
	} else {
		const date = parseISO(value);
		if (date && isValid(date)) {
			dateString = format(date, 'yyyy');
		}
	}

	return dateString;
}

function getSimplifiedDate(value) {
	let dateString;
	let date = parseISO(value);

	if (date && isValid(date)) {
		date = new Date(date.valueOf() + (date.getTimezoneOffset() * 60 * 1000));
		dateString = format(date, 'dd MMM yyyy');
	}

	return dateString;
}

function getSimplifiedDateTime(value) {
	let dateString;
	const date = parseISO(value);

	if (date && isValid(date)) {
		dateString = format(date, 'dd MMM yyyy h:mm aaa');
	}

	return dateString;
}

function getSimplifiedTime(value, isTwentyFour) {
	let timeString;

	if (isTwentyFour) {
		return value;
	}

	const time = parse(value, 'kk:mm:ss', new Date());

	if (time && isValid(time)) {
		timeString = format(time, 'h:mm aaa');
	}

	return timeString;
}

function hasClosingDatePassed(closingDate, closingTime) {
	let closingDateTime;
	let parsedClosingDate = parseISO(closingDate);
	let parsedClosingTime = parse(closingTime, 'kk:mm:ss', new Date());

	if (isValid(parsedClosingDate) && isValid(parsedClosingTime)) {
		closingDateTime = new Date(parsedClosingDate.getFullYear(), parsedClosingDate.getMonth(), parsedClosingDate.getDate(), parsedClosingTime.getHours(), parsedClosingTime.getMinutes(), parsedClosingTime.getSeconds());
	} else if (isValid(parsedClosingDate)) {
		closingDateTime = parsedClosingDate;
	}

	if (isAfter(new Date(), closingDateTime)) {
		return true;
	}

	return false;
}
