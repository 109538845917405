import 'core-js';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './store/index';
const env = window.env;

async function prepareWorkers() {
	if (process.env.NODE_ENV === 'development' && env.MOCK_API) {
		const { worker } = require('./mocks/browser');
		await worker.start();
	}
}

const is = (element, selector) => {
	element.matches = element.matches || element.webkitMatchesSelector || element.mozMatchesSelector || element.msMatchesSelector || element.oMatchesSelector || element.matchesSelector;
	return element.matches(selector);
};

if (document) {
	document.querySelector('html').addEventListener('click', (event) => {
		if (is(event.target, 'a[href*="://"]:not([href^="' + window.location.href.split('/').slice(0, 3).join('/') + '"])')) {
			event.preventDefault();
			window.open(event.target.getAttribute('href'), '_blank').focus();
		}
	});
}

prepareWorkers().then(() => {
	return ReactDOM.render(
		<React.StrictMode>
			<Router>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<App/>
					</PersistGate>
				</Provider>
			</Router>
		</React.StrictMode>,
		document.getElementById('root')
	) || null;
}).finally(() => {
	if (process.env.NODE_ENV !== 'development' || !env.MOCK_API) {
		serviceWorker.unregister();
	}
});
