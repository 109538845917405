import { referenceDataConstants } from '../constants/referenceDataConstants';

export function referenceData(state = {}, action) {
	switch (action.type) {
		case referenceDataConstants.DATA_REQUEST:
			return {
				request: true
			};
		case referenceDataConstants.DATA_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case referenceDataConstants.DATA_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}
