/* eslint-disable id-length */
import React, {useEffect, useState, useRef} from 'react';
import MetaTags, { ReactTitle } from 'react-meta-tags';
import {useHistory, useLocation} from 'react-router-dom';
import {Translate, Translator} from 'react-translated';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';

import { dataLayerPageView } from '../../helpers/miscHelpers';
import {isAuthenticated, isRole, isActive} from '../../helpers/userHelpers';
import { tenderActions } from '../../store/actions/tenderActions';
import { searchActions } from '../../store/actions/searchActions';
import { tenderService } from '../../services/tenderService';
import {success, error} from '../../store/actions/globalMessagesActions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alert from 'react-bootstrap/Alert';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import ContentLoading from '../misc/ContentLoading';
import FormSearch from '../forms/FormSearch';
import TabTitle from '../misc/TabTitle';
import TendersResults from '../tenders/TendersResults';
import TenderDetail from '../tenders/TenderDetail';
import TenderSidebar from '../tenders/TenderSidebar';
import TenderFilters from '../tenders/TenderFilters';
import AlternativeProcurementResults from '../altp/AlternativeProcurementResults';
import AltpFilters from '../altp/AltpFilters';
import PostAltpModal from '../altp/PostAltpModal';
import StandingOffersResults from '../standing-offers/StandingOffersResults';
import StandingOffersSidebar from '../standing-offers/StandingOffersSidebar';
import NotificationSignup from '../tenders/NotificationSignup';

function Tenders({ match }) {
	const tenderId = match.params.id;
	const history = useHistory();
	const {
		PAGE_TITLE_BASE,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		ROLE_SUPPLIER,
		ROLE_PROCUREMENT_ADMIN,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		TAB_TENDERS,
		TAB_ALTPS,
		TAB_STANDING_OFFERS,
		TAB_SUPPLIERS,
		ALL_TABS
	} = require('../../config/constants');
	const location = useLocation();
	const currentPath = location.pathname;
	const dispatch = useDispatch();

	// State data.
	const language = useSelector(state => state.language);
	const authenticate = useSelector(state => state.authenticate);
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const search = useSelector(state => state.search);
	const postTender = useSelector(state => state.postTender);
	const notification = useSelector(state => state.notification);
	const getNotifications = useSelector(state => state.getNotifications);

	const [isLoaded, setIsLoaded] = useState(false);
	const [pageTitle, setPageTitle] = useState('Public Notices and Standing Offer Opportunities');
	const [pageCopy, setPageCopy] = useState('Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers.  For access to additional features and functions, click “Log In” above.');
	const [tendersCopy, setTendersCopy] = useState('All Nova Scotia tender notices are posted below.');
	const [altpsCopy, setAltpsCopy] = useState('When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols.  Alternative Procurement award notices are posted below.');
	const [standingOffersCopy, setStandingOffersCopy] = useState('The standing offer contracts displayed below offer on-going onboarding opportunities for Suppliers.');
	const [showVaccineMessage, setShowVaccineMessage] = useState(false);
	const [tendersCount, setTendersCount] = useState(0);
	const [altpsCount, setAltpsCount] = useState(0);
	const [standingOffersCount, setStandingOffersCount] = useState(0);
	const [activeTab, setActiveTab] = useState(TAB_TENDERS);
	const [keyword, setKeyword] = useState('');
	const [myOrganization, setMyOrganization] = useState(null);
	const [mine, setMine] = useState(null);
	const [watchlist, setWatchlist] = useState(null);
	const [filterData, setFilterData] = useState(null);
	const [editAltp, setEditAltp] = useState(false);
	const [userNotification, setUserNotification] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchText, setSearchText] = useState('Search');
	const [tenderNotFound, setTenderNotFound] = useState(false);
	const searchRef = useRef(null);
	const tabsContainerRef = useRef(null);
	const tendersContentRef = useRef(null);

	useEffect(() => {
		if (currentPath && (currentPath == '/tenders/standing-offers' || currentPath.includes('/tenders/standing-offers'))) {
			setActiveTab(TAB_STANDING_OFFERS);
			setSearchText('Search Name, Commodity, Keywords');
		} else if (currentPath && (currentPath == '/tenders/suppliers' || currentPath.includes('/tenders/suppliers'))) {
			setActiveTab(TAB_SUPPLIERS);
			setSearchText('Search');
		} else if (currentPath && (currentPath == '/tenders/alternative-procurement' || currentPath.includes('/tenders/alternative-procurement'))) {
			setActiveTab(TAB_ALTPS);
			setSearchText('Search ALTP ID, Title, or Supplier');
		} else if ((currentPath && currentPath == '/tenders') || (location.state && location.state.tenderId)) {
			setActiveTab(TAB_TENDERS);
			setSearchText('Search Tender ID and Title');
		}

		if (location && location.state && location.state.tenderId && tabsContainerRef && tabsContainerRef.current) {
			tabsContainerRef.current.scrollIntoView();
		}

		setTenderNotFound(false);
	}, [location]);

	useEffect(() => {
		const title = pageTitle + ' - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			title,
			language.code
		);
	}, [language]);

	useEffect(() => {
		if (userData.success && !isLoaded) {
			if (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_SUPPLIER, ROLE_MAS_ACCOUNT_MANAGER])) {
				setPageTitle('Dashboard');
			}

			if (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER])) {
				setPageCopy('Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts available to broader Public Sector Entities.');
				setTendersCopy('All Nova Scotia tender notices are posted below. Use the “Created By” filter option and click “Apply Filters” to display only tender notices created by your organization or only those created by you. Click on the Tender ID to view notice details.');				setAltpsCopy('When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.');
				setStandingOffersCopy('The standing offers listed below may be available for broader Public Sector Entity use. Email the Procurement Specialist listed as the Contact with inquiries about purchasing off of any specific contract. Be sure to include the Contract Name and Ariba Contract ID with your email inquiry.');
			}

			if (isRole(userData, ROLE_SUPPLIER)) {
				setPageCopy('Select one of the tabs below to view Public Tender Notices, Alternative Procurement Award Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers. Click on the “Edit Notification Preferences” button to adjust your selections for the Tender Opportunity Notification Service (TONS) and to enable/disable the service.');
				setTendersCopy('All Nova Scotia tender notices are posted below. Use the filters to help find tender notices of interest to you. Add a specific tender notice to your “Watchlist” to receive a notification email when something related to that opportunity changes. Click on the Tender ID to see Notice Details, including how to access tender documents.');
				setAltpsCopy('When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used by public sector entities to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.');
				setStandingOffersCopy('The standing offer contracts displayed below offer on-going onboarding opportunities for Suppliers.');
			}

			if (!isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER])) {
				setShowVaccineMessage(true);
			}

			setIsLoaded(true);
		}
	}, [isLoaded, userData]);

	// Set current tender if ID.
	useEffect(() => {
		if (authenticate && authenticate.success) {
			if (tenderId) {
				setTenderNotFound(false);

				tenderService.getTenders({ tenderId: tenderId })
					.then(
						response => {
							if (response && typeof response === 'object' && !response.error && response.tenderDataList && response.tenderDataList.length) {
								dispatch(tenderActions.currentTenderUpdate(response.tenderDataList[0]));
								history.replace({
									pathname: '/tenders/' + tenderId,
									state: {
										tenderId: tenderId
									}
								});
							} else {
								setTenderNotFound(true);
							}
						}
					);
			}
		}
	}, [authenticate, tenderId]);

	// Load on search.
	useEffect(() => {
		if (search.updated && (search.fields || search.filters)) {
			if (search.fields.keyword || search.fields.myOrganization || search.fields.mine || search.fields.watchlist || search.filters) {
				let keywordValue, filterDataValue;

				if (search.fields.keyword) {
					keywordValue = search.fields.keyword;
					setKeyword(keywordValue);
				} else {
					setKeyword('');
				}

				if (search.fields.myOrganization) {
					setMyOrganization(search.fields.myOrganization);
				} else {
					setMyOrganization(null);
				}

				if (search.fields.mine) {
					setMine(search.fields.mine);
				} else {
					setMine(null);
				}

				if (search.fields.watchlist) {
					setWatchlist(search.fields.watchlist);
				} else {
					setWatchlist(null);
				}

				if (search.filters && typeof search.filters === 'object') {
					filterDataValue = search.filters;
					setFilterData(filterDataValue);
				} else {
					setFilterData(null);
				}

				setIsSearching(true);
			} else {
				setKeyword('');
				setFilterData(null);
				setIsSearching(false);
			}
		} else {
			setKeyword('');
			setFilterData(null);
			setIsSearching(false);
		}
	}, [search]);

	useEffect(() => {
		if (postTender.success && postTender.callback === 'cancel-tender') {
			dispatch(success('Tender successfully cancelled'));
			dispatch(tenderActions.resetCurrentTender());
			dispatch(tenderActions.resetPostTender());
			history.replace('/tenders');
		} else if (postTender.error && postTender.callback === 'cancel-tender') {
			dispatch(error('Error: Tender could not be cancelled. Please try again later.'));
		}

		if (postTender.success && postTender.callback === 'delete-tender') {
			dispatch(success('Tender successfully deleted'));
			dispatch(tenderActions.resetCurrentTender());
			dispatch(tenderActions.resetPostTender());
			history.replace('/tenders');
		} else if (postTender.error && postTender.callback === 'delete-tender') {
			dispatch(error('Error: Tender could not be deleted. Please try again later.'));
		}
	}, [postTender]);

	useEffect(() => {
		if (getNotifications.success && getNotifications.data && getNotifications.data.length && getNotifications.data[0]) {
			setUserNotification(getNotifications.data[0]);
		}
	}, [getNotifications]);

	const handlePostTenderClick = () => {
		history.push({
			pathname: '/tenders/post'
		});
	};

	const handleChangeTabs = (eventKey) => {
		if (eventKey !== activeTab) {
			dispatch(tenderActions.resetCurrentTender());
		}

		if (eventKey == TAB_TENDERS) {
			history.replace({
				pathname: '/tenders'
			});

			setSearchText('Search Tender ID and Title');
		} else if (eventKey == TAB_STANDING_OFFERS) {
			history.replace({
				pathname: '/tenders/standing-offers'
			});

			setSearchText('Search');
		} else if (eventKey == TAB_ALTPS) {
			history.replace({
				pathname: '/tenders/alternative-procurement'
			});

			setSearchText('Search ALTP ID, Title, or Supplier');
		}

		setActiveTab(eventKey);

		setFilterData(null);
		setMyOrganization(null);
		setMine(null);
		setWatchlist(null);
	};

	const handleAltpModalClose = () => {
		setEditAltp(false);
	};

	const handleEditAltp = (altp) => {
		setEditAltp(altp);
	};

	const handleSearch = (value) => {
		dispatch(tenderActions.resetCurrentTender());

		if (activeTab == TAB_TENDERS) {
			history.replace('/tenders');
		}

		dispatch(searchActions.update({
			category: (value) ? activeTab : ALL_TABS,
			fields: {
				keyword: value,
				myOrganization: myOrganization,
				mine: mine,
				watchlist: watchlist
			},
			filters: (filterData) ? filterData : null
		}));

		if (tabsContainerRef && tabsContainerRef.current) {
			tabsContainerRef.current.scrollIntoView();
		}
	};

	const handleFilter = (data, mine, watchlist, myOrganization) => {
		const isEmpty = (data) ? Object.values(data).every(x => x === null || x === '') : true;

		dispatch(searchActions.update({
			category: activeTab,
			fields: {
				keyword: keyword,
				myOrganization: myOrganization,
				mine: mine,
				watchlist: watchlist
			},
			filters: (!isEmpty) ? data : null
		}));

		if (tabsContainerRef && tabsContainerRef.current) {
			tabsContainerRef.current.scrollIntoView();
		}
	};

	return (
		<>
			<Translator>
				{({ translate }) => (
					<>
						<ReactTitle
							title={
								translate({
									text: '{pageTitle} - {siteTitle}',
									data: { siteTitle: PAGE_TITLE_BASE, pageTitle: pageTitle }
								})
							}
						/>

						<MetaTags>
							<meta name="dcterms.title" content={ pageTitle } />
							<meta property="og:title" content={ pageTitle } />
						</MetaTags>
					</>
				)}
			</Translator>

			<Header />

			<ContentLoading loading={ userData.request || postTender.request || notification.request }>
				<Container as="main" fluid={true} className="main" id="main" tabIndex="-1">
					{ isAuthenticated(authenticate, userData) &&
						<>
							<Row>
								<Col lg={{ span: 9, order: 'last' }}>
									<Row>
										<Col lg={ (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER, ROLE_SUPPLIER])) ? 5 : 7 }>
											<h1 className="mt-0"><Translate text={ pageTitle } /></h1>
										</Col>
										<Col lg={ (isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER, ROLE_SUPPLIER])) ? 7 : 5 }>
											<ButtonToolbar className="justify-content-end">
												{ isActive(getUser.user) &&
													<>
														{ (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER])) &&
															<>
																{ (activeTab == TAB_TENDERS) &&
																	<Button onClick={handlePostTenderClick}>
																		<Translate text={'Post a Tender'} />
																	</Button>
																}

																{ (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_ADMIN, ROLE_MAS_ACCOUNT_MANAGER]) && activeTab == TAB_ALTPS) &&
																	<PostAltpModal buttonVariant={'primary'} buttonLabel={'Create an ALTP Notice'} editAltp={ editAltp } onClose={ handleAltpModalClose }/>
																}
															</>
														}

														{ (activeTab == TAB_TENDERS) && ((getNotifications.success && (userNotification || (!getNotifications.data || !getNotifications.data.length || !getNotifications.data[0]))) || getNotifications.error) && (isRole(userData, [ROLE_SUPPLIER])) &&
															<NotificationSignup editNotification={userNotification} />
														}
													</>
												}

												<Button variant="secondary" href={'/need-help'}>
													<Translate text={'Need Help?'} />
												</Button>
											</ButtonToolbar>
										</Col>
										<Col xs={ 12 }>
											<p>{ pageCopy }</p>
										</Col>
									</Row>
								</Col>
								<Col lg={{ span: 3, order: 'first' }} className="tenders-search" ref={ searchRef }>
									<Translator>
										{({ translate }) => (
											<FormSearch
												searchValue={ (search.fields && search.fields.keyword) ? search.fields.keyword : '' }
												placeholder={
													translate({
														text: searchText
													})
												}
												ariaLabel={
													translate({
														text: searchText
													})
												}
												onSearch={ handleSearch }
											/>
										)}
									</Translator>
								</Col>
							</Row>
							<Row>
								<Col lg={{ span: 3 }}>
									<div className={ (activeTab !== TAB_TENDERS) ? 'd-none' : null }>
										<>
											<div className={ (location.state && location.state.tenderId) ? 'd-none' : null }>
												<TenderFilters
													defaultMyOrganization={ myOrganization }
													defaultMine={ mine }
													defaultWatchlist={ watchlist }
													defaultFilterData={ filterData }
													handleFilter={ handleFilter }
												/>
											</div>

											{ location.state && location.state.tenderId &&
												<TenderSidebar />
											}
										</>
									</div>

									<div className={ (activeTab !== TAB_ALTPS) ? 'd-none' : null }>
										<AltpFilters
											defaultMine={ mine }
											defaultFilterData={ filterData }
											handleFilter={ handleFilter }
										/>
									</div>

									<div className={ (activeTab !== TAB_STANDING_OFFERS) ? 'd-none' : null }>
										<StandingOffersSidebar />
									</div>
								</Col>
								<Col lg={{ span: 9 }} ref={ tabsContainerRef }>
									<Tabs defaultActiveKey={ activeTab } activeKey={ activeTab } onSelect={ handleChangeTabs }>
										<Tab eventKey={ TAB_TENDERS } title={ <TabTitle title={ <Translate text={'Tenders'} /> } count={ (tendersCount) ? tendersCount : '0' } /> }>
											<div ref={ tendersContentRef }>
												{ location.state && location.state.tenderId &&
													<>
														<TenderDetail />

														{ tenderNotFound &&
															<Alert variant="light">
																<span className="alert-message"><Translate text={'Tender Not Found'} /></span>
															</Alert>
														}
													</>
												}

												<div className={ (location.state && location.state.tenderId) ? 'd-none' : null }>
													<p>{ tendersCopy }</p>

													{ showVaccineMessage &&
														<div className="box box--blue-light">
															<p>
																<Translate
																	text={'As of March 21, 2022, proof of vaccination will not be required for most government workplaces. However, suppliers working on behalf of the Nova Scotia Government and entering High-Risk Settings must adhere by the <FirstLink> protocol and complete the Supplier Attestation Form if they have not previously submitted a form. For more information and to complete a COVID-19 Vaccination Attestation form, <SecondLink>.'}
																	renderMap={{
																		renderFirstLink: () => <a href="https://novascotia.ca/coronavirus/docs/COVID-19-Protocol-for-mandatory-vaccination-high-risk-settings-en.pdf" target="_blank" rel="noopener noreferrer"><Translate text={'COVID-19 Mandatory Vaccination Protocol in High-Risk Settings'} /></a>, // eslint-disable-line react/display-name
																		renderSecondLink: () => <a href="https://beta.novascotia.ca/covid-19-supplier-attestation-procurement" target="_blank" rel="noopener noreferrer"><Translate text={'click here'} /></a> // eslint-disable-line react/display-name
																	}}
																/>
															</p>
														</div>
													}

													<TendersResults
														isVisible={ (activeTab == TAB_TENDERS) }
														isSearching={ isSearching }
														handleUpdateTendersCount={(count) => setTendersCount(count) }
													/>
												</div>
											</div>
										</Tab>
										<Tab eventKey={ TAB_ALTPS } title={ <TabTitle title={ <Translate text={'Alternative Procurement'} /> } count={ (altpsCount) ? altpsCount : '0' } /> }>
											<div ref={ tendersContentRef }>
												<div className={ (location.state && location.state.altpId) ? 'd-none' : null }>
													<p>{ altpsCopy }</p>

													<AlternativeProcurementResults
														isVisible={ (activeTab == TAB_ALTPS) }
														isSearching={ isSearching }
														handleUpdateAltpsCount={(count) => setAltpsCount(count) }
														handleEditAltp={ handleEditAltp }
													/>
												</div>
											</div>
										</Tab>
										<Tab eventKey={ TAB_STANDING_OFFERS } title={ <TabTitle title={ <Translate text={'Standing Offer Contracts'} /> } count={ (standingOffersCount) ? standingOffersCount : '0' } /> }>
											<div ref={ tendersContentRef }>
												<p>{ standingOffersCopy }</p>

												<StandingOffersResults
													isVisible={ (activeTab == TAB_STANDING_OFFERS) }
													isSearching={ isSearching }
													handleUpdateStandingOffersCount={(count) => setStandingOffersCount(count) }
												/>
											</div>
										</Tab>
									</Tabs>
								</Col>
							</Row>
						</>
					}
				</Container>
			</ContentLoading>
			<Footer />
		</>
	);
}

export default Tenders;
