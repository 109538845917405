import React, {useEffect,  useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import NumberFormat from 'react-number-format';

import { referenceTableService } from '../../../services/referenceTableService';
import { setHasChanges } from '../../../store/actions/globalMessagesActions';

import { Form, InputGroup, Modal, Button } from 'react-bootstrap';

import FormSlideToggle from '../../forms/FormSlideToggle';

function CommodityFields(props) {
	const {
		data,
		isNew,
		preCode,
		handleUpdateData,
		handleUpdateChildren
	} = props;

	const {
		INPUT_MAX_LENGTH,
		UNSPSC_LEVEL_1,
		UNSPSC_LEVEL_2,
		UNSPSC_LEVEL_3,
		UNSPSC_LEVEL_4,
		UNSPSC_NEW_CODE_LENGTH
	} = require('../../../config/constants');

	const dispatch = useDispatch();

	// State data.
	const referenceData = useSelector(state => state.referenceData);
	const hasChanges = useSelector(state => state.hasChanges);

	const [codeLength, setCodeLength] = useState(8);
	const [categoriesList, setCategoriesList] = useState([]);

	// Setting code field length based on level type.
	useEffect(() => {
		if (data && data.levelType) {
			switch (data.levelType) {
				case UNSPSC_LEVEL_1:
					setCodeLength(2);
					break;
				case UNSPSC_LEVEL_2:
					setCodeLength(4);
					break;
				case UNSPSC_LEVEL_3:
					setCodeLength(6);
					break;
				case UNSPSC_LEVEL_4:
				default:
					setCodeLength(8);
					break;
			}
		}
	}, [data]);

	useEffect(() => {
		if (referenceData && referenceData.success && referenceData.data) {
			if (Array.isArray(referenceData.data.tenderCategoryDataList)) {
				setCategoriesList(referenceData.data.tenderCategoryDataList);
			}
		}
	}, []);

	const checkChanges = () => {
		if (!hasChanges) {
			dispatch(setHasChanges(true));
		}
	};

	const handleChange = (event, isNumeric, trim) => {
		const { name, value } = event.target;
		let val;

		if (isNumeric) {
			if (value) {
				val = parseInt(value, 10);
			} else {
				val = null;
			}
		} else if (value) {
			if (trim) {
				val = value.replace(/\s/g, '');
			} else {
				val = value;
			}
		} else {
			val = '';
		}

		if (handleUpdateData) {
			handleUpdateData({...data, [name]: val });
		}

		checkChanges();
	};

	const handleEnabledChange = (id, value) => {
		let val;

		if (value == 'yes') {
			val = true;
		} else {
			val = false;

			handleUpdateChildren(false);

			if (!isNew && data.levelType !== UNSPSC_LEVEL_4) {
				referenceTableService.unspscEnableCheck(data)
					.then(
						response => {
							if (response && response !== false) {
								setShowDisableWarning(true);

								if (handleUpdateChildren) {
									handleUpdateChildren(true);
								} else {
									handleUpdateChildren(false);
								}
							}
						}
					);
			}
		}

		if (handleUpdateData) {
			handleUpdateData({...data, enabled: val });
		}

		checkChanges();
	};

	const [showDisableWarning, setShowDisableWarning] = useState(false);
	const handleCloseDisableWarning = () => setShowDisableWarning(false);

	const handleRevertEnableChange = () => {
		if (handleUpdateData) {
			handleUpdateData({...data, enabled: true });
		}

		setShowDisableWarning(false);
	};

	return (
		<>
			<Form.Group controlId="commodity-code">
				<Form.Label>
					<Translate text={'Commodity Code'} /> <span className="required">*</span>
				</Form.Label>

				{ isNew &&
					<InputGroup>
						{ preCode &&
							<InputGroup.Text>{ preCode }</InputGroup.Text>
						}

						<NumberFormat
							customInput={ Form.Control }
							type="text"
							name="code"
							value={ data && data.code && data.code.toString() ? data.code : '' }
							minLength={ UNSPSC_NEW_CODE_LENGTH }
							maxLength={ UNSPSC_NEW_CODE_LENGTH }
							allowNegative={ false }
							allowLeadingZeros={ true }
							required={ true }
							isInvalid={ data && data.code && data.code.toString().length !== UNSPSC_NEW_CODE_LENGTH }
							onChange={(e) => { handleChange(e, false, true); }}
						/>
					</InputGroup>
				}

				{ !isNew &&
					<NumberFormat
						customInput={ Form.Control }
						type="text"
						name="code"
						value={ data && data.code && data.code.toString() ? data.code : '' }
						minLength={ codeLength }
						maxLength={ codeLength }
						allowNegative={ false }
						allowLeadingZeros={ true }
						required={ true }
						isInvalid={ data && data.code && data.code.toString().length !== codeLength }
						onChange={(e) => { handleChange(e, true, true); }}
					/>
				}

				<Form.Control.Feedback type="invalid">
					<Translate
						text={'{formLabel} is required'}
						data={{
							formLabel: 'Commodity Code'
						}}
					/>
				</Form.Control.Feedback>
			</Form.Group>

			<Form.Group controlId="commodity-description">
				<Form.Label>
					<Translate text={'Description (English)'} /> <span className="required">*</span>
				</Form.Label>
				<Form.Control
					type="text"
					name="description"
					value={ data && data.description ? data.description : '' }
					maxLength={ INPUT_MAX_LENGTH }
					required
					onChange={(e) => { handleChange(e); }}
				/>
				<Form.Control.Feedback type="invalid">
					<Translate
						text={'{formLabel} is required'}
						data={{
							formLabel: 'Description (English)'
						}}
					/>
				</Form.Control.Feedback>
			</Form.Group>

			<Form.Group controlId="commodity-category">
				<Form.Label>
					<Translate text={'Tender Category'} /> <span className="required">*</span>
				</Form.Label>
				<Form.Control
					as="select"
					name="category"
					value={ data && data.category ? data.category : '' }
					required
					disabled={ !categoriesList || !categoriesList.length }
					onChange={(e) => { handleChange(e); }}
				>
					<Translator>
						{({translate}) => (
							<option value="">
								{ translate({text: '- Select -'}) }
							</option>
						)}
					</Translator>

					{ (Array.isArray(categoriesList)) &&
						categoriesList.map((item, index) => {
							return (
								<option value={ item.name } key={ index }>
									{ item.description }
								</option>
							);
						})
					}
				</Form.Control>
				<Form.Control.Feedback type="invalid">
					<Translate
						text={'{formLabel} is required'}
						data={{
							formLabel: 'Tender Category'
						}}
					/>
				</Form.Control.Feedback>
			</Form.Group>

			<Translator>
				{({ translate }) => (
					<Form.Group controlId="commodity-enabled">
						<FormSlideToggle
							id="commodity-enabled"
							name="enabled"
							title={ translate({ text: 'Enabled' }) }
							value={ (data && data.enabled) ? 'yes' : 'no' }
							options={[
								{ value: 'no', label: 'No' },
								{ value: 'yes', label: 'Yes' }
							]}
							handleOnChange={ handleEnabledChange }
						/>
					</Form.Group>
				)}
			</Translator>

			{ !isNew &&
				<Modal show={ showDisableWarning } onHide={ handleCloseDisableWarning } centered>
					<Modal.Header closeButton>
						<h2><Translate text={'Disabling this commodity will also disable all its children. Continue?'} /></h2>
					</Modal.Header>
					<Modal.Footer>
						<Button variant="secondary" onClick={ handleRevertEnableChange }><Translate text={'No, Keep Enabled'} /></Button>
						<Button variant="primary" onClick={ handleCloseDisableWarning }><Translate text={'Yes, Continue'} /></Button>
					</Modal.Footer>
				</Modal>
			}
		</>
	);
}

export default CommodityFields;
