import { authenticateConstants } from '../constants/authenticateConstants';
import { authenticateService } from '../../services/authenticateService';

export const authenticateActions = {
	retrieve
};

function retrieve(rpid) {
	return dispatch => {
		dispatch(request());
		authenticateService.retrieve(rpid)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);

	};

	function request() { return { type: authenticateConstants.AUTH_REQUEST }; }

	function success(data) { return { type: authenticateConstants.AUTH_SUCCESS, data }; }

	function error() { return { type: authenticateConstants.AUTH_ERROR }; }
}
