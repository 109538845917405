import { userDataConstants } from '../constants/userDataConstants';

export function userData(state = {}, action) {
	switch (action.type) {
		case userDataConstants.USER_DATA_REQUEST:
			return {
				request: true
			};
		case userDataConstants.USER_DATA_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case userDataConstants.USER_DATA_ERROR:
			return {
				error: true
			};
		case userDataConstants.LOGOUT:
			return {};
		default:
			return state;
	}
}

export function userDataPersist(state = {}, action) {
	switch (action.type) {
		case userDataConstants.USER_DATA_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case userDataConstants.USER_DATA_ERROR:
			return {
				error: true,
				data: {}
			};
		case userDataConstants.LOGOUT:
			return {};
		default:
			return state;
	}
}
