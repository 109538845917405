import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import { useSelector, useDispatch } from 'react-redux';

import { geographyActions } from '../../store/actions/geographyActions';

import Form from 'react-bootstrap/Form';

export default function FormCountry(props) {
	const {
		id,
		name,
		value,
		required,
		refValue,
		disabled,
		readOnly
	} = props;
	const dispatch = useDispatch();
	const {
		LANGUAGE_FRENCH
	} = require('../../config/constants');

	const fieldName = name || 'country';

	// State data.
	const authenticate = useSelector(state => state.authenticate);
	const language = useSelector(state => state.language);
	const geography = useSelector(state => state.geography);

	const [options, setOptions] = useState([]);

	// Getting geography data.
	useEffect(() => {
		if (authenticate && authenticate.success && !geography.init) {
			dispatch(geographyActions.get());
		}
	}, [authenticate, geography]);

	// Listen to geography data state.
	useEffect(() => {
		if (geography.success && geography.data.countryDataList) {
			setOptions(geography.data.countryDataList);
		}
	}, [geography]);

	const handleChange = (event) => {
		if (props.handleOnChange) {
			props.handleOnChange(event);
		}
	};

	const handleBlur = (event) => {
		if (props.handleOnBlur) {
			props.handleOnBlur(event);
		}
	};

	return (
		<Translator>
			{({translate}) => (
				<Form.Group controlId={ id }>
					<Form.Label>
						<Translate text="Country" />

						{ required &&
							<span className="required">*</span>
						}
					</Form.Label>
					<Form.Control
						as="select"
						name={ fieldName }
						ref={ refValue }
						value={ (value) ? value : '' }
						required={ (required) ? true : null }
						disabled={ (disabled || readOnly) ? true : null }
						onChange={ handleChange }
						onBlur={ handleBlur }
						readOnly={ readOnly }
					>
						<option value="">
							{ translate({ text: '- Select -' }) }
						</option>

						{ (Array.isArray(options)) &&
							options.map((item, index) => {
								return (
									<option value={ (language && language.value == LANGUAGE_FRENCH) ? item.nameFr : item.nameEn } key={ index }>
										{ (language && language.value == LANGUAGE_FRENCH) ? item.nameFr : item.nameEn }
									</option>
								);
							})
						}
					</Form.Control>
					<Form.Control.Feedback type="invalid">
						<Translate
							text={'{formLabel} is required'}
							data={{
								formLabel: 'Country'
							}}
						/>
					</Form.Control.Feedback>
				</Form.Group>
			)}
		</Translator>
	);
}
