import React, { useState, useEffect, useRef } from 'react';
import { Translate } from 'react-translated';
import { useHistory, useLocation } from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import prependHttp from 'prepend-http';
import { parseISO } from 'date-fns';

import { tenderActions } from '../../store/actions/tenderActions';
import { getSimplifiedDate, getSimplifiedTime } from '../../helpers/dateHelpers';
import { currencyFormat } from '../../helpers/miscHelpers';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormSlideToggle from '../forms/FormSlideToggle';
import DocLink from '../misc/DocLink';
import TenderClone from './TenderClone';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink,
	faPlus,
	faMinus
} from '@fortawesome/free-solid-svg-icons';
import {isOrganization, isEndUserEntity, isDeptPublicWorks, isRole} from '../../helpers/userHelpers';
import Table from 'react-bootstrap/Table';

function TenderDetail() {
	const {
		BLANK_VALUE,
		DOCUMENT_TYPE_TENDER,
		DOCUMENT_TYPE_ADDENDUM,
		ATTACHMENT_TYPE_TENDER,
		CLOSING_TIME_FORMAT_TWENTY_FOUR,
		ROLE_MAS,
		ROLE_MAS_ACCOUNT_MANAGER,
		ROLE_PROCUREMENT_ADMIN,
		ROLE_PROCUREMENT_REGULAR,
		ROLE_PROCUREMENT_INTERNAL,
		ROLE_SUPPLIER,
		TENDER_STATUS_CANCELLED,
		TENDER_STATUS_AWARDED,
		TENDER_STATUS_DRAFT,
		TENDER_STATUS_SCHEDULED,
		UNSPSC_MAX_DISPLAY,
		CLOSING_LOCATION_BIDX,
		ENTITY_PUBLIC_WORKS_ID
	} = require('../../config/constants');
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [tenderDocs, setTenderDocs] = useState(null);
	const [addendumDocs, setAddendumDocs] = useState(null);
	const [procurementEntity, setProcurementEntity] = useState(null);
	const [procurementEntityAddress, setProcurementEntityAddress] = useState(null);
	const [closingLocation, setClosingLocation] = useState(null);
	const [currencyOptions, setCurrencyOptions] = useState([]);
	const [awardTotals, setAwardTotals] = useState([]);
	const [watchListLoading, setWatchListLoading] = useState(false);
	const [isInWatchList, setIsInWatchList] = useState(false);
	const [fromStandingOffers, setFromStandingOffers] = useState(false);
	const [canEdit, setCanEdit] = useState(false);
	const [canClone, setCanClone] = useState(false);
	const [canCancel, setCanCancel] = useState(false);
	const [canDelete, setCanDelete] = useState(false);
	const [showMoreCommodities, setShowMoreCommodities] = useState(false);
	const firstCommodityRef = useRef(null);

	const currentTender = useSelector(state => state.currentTender);
	const userData = useSelector(state => state.userData);
	const getUser = useSelector(state => state.getUser, shallowEqual);
	const referenceData = useSelector(state => state.referenceData);
	const postWatchlist = useSelector(state => state.postWatchlist);
	const watchlist = useSelector(state => state.watchlist);

	useEffect(() => {
		if (currentTender.id) {
			if (Array.isArray(currentTender.attachments)) {
				const tenders = currentTender.attachments.filter(item => item.documentType == DOCUMENT_TYPE_TENDER);
				const addendums = currentTender.attachments.filter(item => item.documentType == DOCUMENT_TYPE_ADDENDUM);

				if (tenders) {
					setTenderDocs(tenders);
				}

				if (addendums) {
					setAddendumDocs(addendums);
				}
			}

			if (currentTender.procumentEntityData && currentTender.procumentEntityData.name) {
				setProcurementEntity(currentTender.procumentEntityData);

				let address = '';

				if (currentTender.procumentEntityData.streetAddress) {
					address += currentTender.procumentEntityData.streetAddress;
				}

				if (currentTender.procumentEntityData.unitNumber) {
					address += ' ' + currentTender.procumentEntityData.unitNumber;
				}

				if (currentTender.procumentEntityData.streetAddress || currentTender.procumentEntityData.unitNumber) {
					address += ',\n';
				}

				if (currentTender.procumentEntityData.city) {
					address += currentTender.procumentEntityData.city;
				}

				if (currentTender.procumentEntityData.province) {
					address += ' ' + currentTender.procumentEntityData.province;
				}

				if (currentTender.procumentEntityData.postalCode) {
					address += ' ' + currentTender.procumentEntityData.postalCode;
				}

				if (currentTender.procumentEntityData.city || currentTender.procumentEntityData.province || currentTender.procumentEntityData.postalCode) {
					address += ',\n';
				}

				if (currentTender.procumentEntityData.country) {
					address += currentTender.procumentEntityData.country;
				}

				setProcurementEntityAddress(address);
			}

			if (currentTender.closingLocationOtherText) {
				setClosingLocation(currentTender.closingLocationOtherText);
			} else if (currentTender.closingLocation) {
				setClosingLocation(currentTender.closingLocation);
			}
		}
	}, [currentTender]);

	useEffect(() => {
		if (referenceData.success && referenceData.data && referenceData.data.currencyDataList) {
			setCurrencyOptions(referenceData.data.currencyDataList.map(currency => ({ value: currency.code, label: currency.name })));
		}
	}, [referenceData]);

	// Getting award totals for each currency.
	useEffect(() => {
		setAwardTotals([]);

		if (Array.isArray(currentTender.tenderAwardData) && referenceData.success && referenceData.data && Array.isArray(referenceData.data.currencyDataList)) {
			referenceData.data.currencyDataList.forEach(element => {
				const filteredAwardData = currentTender.tenderAwardData.filter(item => item.currency == element.name);

				if (filteredAwardData.length) {
					const sum = filteredAwardData.reduce((accumulator, object) => {
						return accumulator + parseFloat(object.awardAmount);
					}, 0);

					setAwardTotals(awardTotals => [...awardTotals, { total: sum, currency: element.code }]);
				}
			});
		}
	}, [currentTender, referenceData]);

	useEffect(() => {
		if (!watchlist) {
			dispatch(tenderActions.getWatchlist());
		}
	}, []);

	useEffect(() => {
		if (postWatchlist.success || postWatchlist.error) {
			setWatchListLoading(false);
		}

		if (postWatchlist.success) {
			dispatch(tenderActions.getWatchlist(postWatchlist.callback));
		}
	}, [postWatchlist]);

	useEffect(() => {
		if (watchlist.success && Array.isArray(watchlist.data) && currentTender && currentTender.id) {
			const found = watchlist.data.find(item => item.id == currentTender.id);
			setIsInWatchList((found && found.id) ? true : false);
		}
	}, [watchlist, currentTender]);

	useEffect(() => {
		if (location.state && location.state.standingOffers) {
			setFromStandingOffers(true);
		}
	}, [location]);

	// Edit & cancel permissions.
	useEffect(() => {
		if (userData && userData.success && currentTender) {
			const internalUserCheck = (!isDeptPublicWorks(getUser.user) && isEndUserEntity(getUser.user, currentTender.endUserEntityOrganizationId)) || (isDeptPublicWorks(getUser.user) && currentTender.closingLocation === CLOSING_LOCATION_BIDX && currentTender.endUserEntityOrganizationId === ENTITY_PUBLIC_WORKS_ID);

			// Edit.
			if (
				(isRole(userData, ROLE_PROCUREMENT_ADMIN)) ||
				(isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId))
			) {
				setCanEdit(true);
			} else if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId)) {
				if (internalUserCheck) {
					setCanEdit(true);
				}
			} else {
				setCanEdit(false);
			}

			// Clone.
			if (
				(isRole(userData, ROLE_PROCUREMENT_ADMIN)) ||
				(isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId))
			) {
				setCanClone(true);
			} else if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId)) {
				if (internalUserCheck) {
					setCanClone(true);
				}
			} else {
				setCanClone(false);
			}

			// Cancel.
			if (
				(isRole(userData, ROLE_PROCUREMENT_ADMIN) && ![TENDER_STATUS_CANCELLED, TENDER_STATUS_AWARDED, TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus)) ||
				(isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId) && ![TENDER_STATUS_CANCELLED, TENDER_STATUS_AWARDED, TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus))
			) {
				setCanCancel(true);
			} else if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId) && ![TENDER_STATUS_CANCELLED, TENDER_STATUS_AWARDED, TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus)) {
				if (internalUserCheck) {
					setCanCancel(true);
				}
			} else {
				setCanCancel(false);
			}

			// Delete.
			if (
				(isRole(userData, ROLE_PROCUREMENT_ADMIN) && [TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus)) ||
				(isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_REGULAR, ROLE_MAS_ACCOUNT_MANAGER]) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId) && [TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus))
			) {
				setCanDelete(true);
			} else if (isRole(userData, ROLE_PROCUREMENT_INTERNAL) && currentTender.procumentEntityData && currentTender.procumentEntityData.organizationId && isOrganization(getUser.user, currentTender.procumentEntityData.organizationId) && [TENDER_STATUS_DRAFT, TENDER_STATUS_SCHEDULED].includes(currentTender.tenderStatus)) {
				if (internalUserCheck) {
					setCanDelete(true);
				}
			} else {
				setCanDelete(false);
			}
		} else {
			setCanEdit(false);
			setCanClone(false);
			setCanCancel(false);
			setCanDelete(false);
		}
	}, [userData, getUser, currentTender]);

	const handleBack = () => {
		dispatch(tenderActions.resetCurrentTender());

		if (fromStandingOffers) {
			history.replace('/tenders/standing-offers');
		} else {
			history.replace('/tenders');
		}
	};

	const handleEdit = () => {
		history.push(`/tenders/${encodeURIComponent(currentTender.tenderId)}/edit`);
	};

	const [showCancel, setShowCancel] = useState(false);
	const handleCloseCancel = () => setShowCancel(false);
	const handleShowCancel = () => setShowCancel(true);

	const handleConfirmCancel = () => {
		setShowCancel(false);

		const updatedData = {
			...currentTender,
			changeType: 'SAVE',
			tenderStatus: TENDER_STATUS_CANCELLED
		};
		dispatch(tenderActions.postTender(JSON.stringify(updatedData), 'cancel-tender'));
	};

	const [showDelete, setShowDelete] = useState(false);
	const handleCloseDelete = () => setShowDelete(false);
	const handleShowDelete = () => setShowDelete(true);

	const handleConfirmDelete = () => {
		setShowDelete(false);

		const updatedData = {
			...currentTender,
			changeType: 'DELETE'
		};
		dispatch(tenderActions.postTender(JSON.stringify(updatedData), 'delete-tender'));
	};

	const formatCurrency = (value, currency) => {
		const currencyObject = currencyOptions.find(item => item.label === currency);
		const currencyCode = (currencyObject && currencyObject.value) ? currencyObject.value : 'USD';
		const formatter = new Intl.NumberFormat(document.documentElement.getAttribute('lang') || 'en', {
			style: 'currency',
			currency: !currencyCode || currencyCode === 'CAD' ? 'USD' : currencyCode
		});
		return formatter.format(value);
	};

	const handleToggleWatchlist = (value) => {
		setWatchListLoading(true);
		dispatch(tenderActions.postWatchlist(currentTender, (value === 'yes'), currentTender.id));
	};

	const handleToggleCommodities = (event) => {
		event.preventDefault();

		firstCommodityRef.current.scrollIntoView();
		firstCommodityRef.current.focus();
		setShowMoreCommodities(value => !value);
	};

	const commodityItem = (item) => {
		if (item) {
			return (
				<>
					<Row>
						<Col md={6} lg={3}>
							<h4>
								<Translate text={'Commodity Level 1'} />
							</h4>
							<p>
								{ item.unspscLevel1 || BLANK_VALUE }
							</p>
						</Col>
						<Col md={6} lg={3}>
							<h4>
								<Translate text={'Commodity Level 2'} />
							</h4>
							<p>
								{ item.unspscLevel2 || BLANK_VALUE }
							</p>
						</Col>
						<Col md={6} lg={3}>
							<h4>
								<Translate text={'Commodity Level 3'} />
							</h4>
							<p>
								{ item.unspscLevel3 || BLANK_VALUE }
							</p>
						</Col>
						<Col md={6} lg={3}>
							<h4>
								<Translate text={'Commodity Level 4'} />
							</h4>
							<p>
								{ item.unspscLevel4 || BLANK_VALUE }
							</p>
						</Col>
					</Row>

					{ item.category &&
						<h4>
							<Translate text={'Category'} />: <span className="font-weight-normal">{ item.category }</span>
						</h4>
					}
				</>
			);
		}
	};

	if (currentTender.id) {
		return (
			<>
				<div className="tender-detail">
					<ButtonToolbar className="justify-content-between">
						<Button
							variant="link"
							onClick={ handleBack }
						>
							<Translate text={'Back to List'} />
						</Button>

						<ButtonToolbar>
							{ canEdit && currentTender.tenderId &&
								<Button
									size="sm"
									onClick={handleEdit}
								>
									<Translate text={'Edit My Tender'}/>
								</Button>
							}

							{ canClone && currentTender.tenderId && currentTender.tenderStatus !== TENDER_STATUS_DRAFT &&
								<TenderClone />
							}

							{ canCancel && !canDelete &&
								<Button
									variant="danger"
									size="sm"
									onClick={handleShowCancel}
								>
									<Translate text={'Cancel Tender'}/>
								</Button>
							}

							{ canDelete &&
								<Button
									variant="danger"
									size="sm"
									onClick={handleShowDelete}
								>
									<Translate text={'Delete Tender'}/>
								</Button>
							}
						</ButtonToolbar>

						{ (isRole(userData, ROLE_SUPPLIER)) &&
							<Form.Group controlId={'watched-tender'}>
								<FormSlideToggle
									id={'watched-tender'}
									name={'watched-tender'}
									title="Watched"
									value={ (isInWatchList) ? 'yes' : 'no' }
									options={[
										{ value: 'no', label: 'No' },
										{ value: 'yes', label: 'Yes' }
									]}
									size={'sm'}
									readOnly={ watchListLoading }
									handleOnChange={(id, value) => { handleToggleWatchlist(value); }}
								/>
							</Form.Group>
						}
					</ButtonToolbar>

					<div className="tender-detail__row">
						<Row>
							<Col lg={4}>
								<h4>
									<Translate text={'Tender ID'} />
								</h4>
								<p className="large">
									{ currentTender.tenderId || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Tender Title'} />
								</h4>
								<p className="large">
									{ currentTender.title || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Closing Date & Time'} /> <Translate text={'(Atlantic Time)'} />
								</h4>
								<p className="large">
									{ getSimplifiedDate(currentTender.closingDate) || BLANK_VALUE }
									{ currentTender.closingTime &&
										<>
											<br />
											{ getSimplifiedTime(currentTender.closingTime, currentTender.closingDateDisplay && currentTender.closingDateDisplay == CLOSING_TIME_FORMAT_TWENTY_FOUR) }
										</>
									}
								</p>
							</Col>
						</Row>
					</div>

					<div className="tender-detail__row">
						<Row>
							<Col lg={8}>
								<h4>
									<Translate text={'Description'} />
								</h4>
								<p className="line-breaks">
									{ currentTender.description || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Closing Location'} />
								</h4>
								<p>
									{ closingLocation || BLANK_VALUE }
								</p>
							</Col>
						</Row>
					</div>

					{ (Array.isArray(currentTender.tenderCategoryValueList) && currentTender.tenderCategoryValueList.length > 0) &&
						<div className="tender-detail__row">
							<h4>
								<Translate text={'Categories'} />
							</h4>

							{ currentTender.tenderCategoryValueList.map((item, index) => {
								return (
									<Row key={ index }>
										<Col lg={12}>
											<p>
												{ item.description || BLANK_VALUE }
											</p>
										</Col>
									</Row>
								);
							})}
						</div>
					}

					{ (Array.isArray(currentTender.unspscLevelData)) &&
						<>
							{ currentTender.unspscLevelData.map((item, index) => {
								if (index < UNSPSC_MAX_DISPLAY) {
									return (
										<div className="tender-detail__row" key={ index } tabIndex="-1" ref={(index == 0) ? firstCommodityRef : null }>
											{ commodityItem(item) }
										</div>
									);
								}

								return null;
							})}

							<div className={(!showMoreCommodities) ? 'tender-detail__hide-more' : null}>
								{ currentTender.unspscLevelData.map((item, index) => {
									if (index >= UNSPSC_MAX_DISPLAY) {
										return (
											<div className="tender-detail__row" key={ index }>
												{ commodityItem(item) }
											</div>
										);
									}

									return null;
								})}
							</div>

							{ (currentTender.unspscLevelData.length > UNSPSC_MAX_DISPLAY) &&
								<div className="text-center">
									{ !showMoreCommodities &&
										<div className="mb-2 font-weight-bold">
											<Translate
												text="Showing 1 of {total} commodities"
												data={{
													total: currentTender.unspscLevelData.length
												}}
											/>
										</div>
									}

									<Button
										variant="secondary"
										onClick={ handleToggleCommodities }
									>
										<FontAwesomeIcon icon={(!showMoreCommodities) ? faPlus : faMinus } /> <Translate text={(!showMoreCommodities) ? 'Show All Commodities' : 'Show Less Commodities'} />
									</Button>
								</div>
							}
						</>
					}

					<div className="tender-detail__row">
						<Row>
							<Col lg={8}>
								<h4>
									<Translate text={'Estimated Duration of Contract (months)'} />
								</h4>
								<p className="line-breaks">
									{ currentTender.expectedDurationOfContract || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Tender Documents'} />
								</h4>

								{ (Array.isArray(tenderDocs)) &&
									<div className="tender-detail__docs">
										{ tenderDocs.map((item, index) => {
											if (item.filename || item.url) {
												return (
													<div className="tender-detail__docs-item" key={ index }>
														{ item.filename &&
															<DocLink
																key={ index }
																data={ item }
																attachmentType={ ATTACHMENT_TYPE_TENDER }
															/>
														}

														{ item.url &&
															<div className="doc-link">
																<span className="doc-link__icon">
																	<FontAwesomeIcon icon={ faLink } />
																</span>

																<span className="doc-link__text">
																	<a href={ prependHttp(item.url) } target="_blank" rel="noopener noreferrer">{ item.url }</a>
																</span>
															</div>
														}
													</div>
												);
											}

											return null;
										})}

										{ (!tenderDocs.length > 0) &&
											BLANK_VALUE
										}
									</div>
								}
							</Col>
						</Row>
					</div>

					<div className="tender-detail__row">
						<Row>
							<Col lg={4}>
								<h4>
									<Translate text={'Public Opening Location'} />
								</h4>
								<p>
									{ currentTender.publicOpeningLocation || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Public Opening Date & Time'} />
								</h4>
								<p>
									{ getSimplifiedDate(currentTender.publicOpeningDate) || BLANK_VALUE }
									{ currentTender.publicOpeningTime &&
										<>
											<br />
											{ getSimplifiedTime(currentTender.publicOpeningTime) }
										</>
									}
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Addendum Documents / Notes'} />
								</h4>

								{ (Array.isArray(addendumDocs)) &&
									<div className="tender-detail__docs">
										{ addendumDocs.map((item, index) => {
											if (item.filename || item.notes) {
												return (
													<div className="tender-detail__docs-item" key={ index }>
														{ item.filename &&
															<DocLink
																key={ index }
																data={ item }
																attachmentType={ ATTACHMENT_TYPE_TENDER }
															/>
														}

														{ item.notes &&
															<div className="line-breaks">
																{ item.notes }
															</div>
														}
													</div>
												);
											}

											return null;
										})}

										{ (!addendumDocs.length > 0) &&
											BLANK_VALUE
										}
									</div>
								}
							</Col>
						</Row>
					</div>

					<div className="tender-detail__row">
						<Row>
							<Col lg={4}>
								<h4>
									<Translate text={'Submission Language'} />
								</h4>
								<p>
									{ currentTender.submissionLanguage || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Pick Up Fee'} />
								</h4>
								<p>
									{ currentTender.pickUpFee || BLANK_VALUE }
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Pick Up Fee Terms of Payment'} />
								</h4>
								<p>
									{ currentTender.termsOfPayment || BLANK_VALUE }
								</p>
							</Col>
						</Row>
					</div>

					<div className="tender-detail__row">
						<Row>
							<Col>
								<h4>
									<Translate text={'Memorandum'} />
								</h4>
								<p className="line-breaks">
									{ currentTender.memo || BLANK_VALUE }
								</p>
							</Col>
						</Row>
					</div>

					<div className="tender-detail__row">
						<Row>
							<Col lg={4}>
								<h4>
									<Translate text={'Procurement Entity'} />
								</h4>
								<p>
									{ procurementEntity && procurementEntity.name ? procurementEntity.name : BLANK_VALUE }
								</p>

								{ procurementEntityAddress &&
									<p className="line-breaks">
										{ procurementEntityAddress }
									</p>
								}
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Contact Name'} />
								</h4>
								<p>
									{ currentTender.contactName &&
										<>
											<span>
												{ currentTender.contactName }
											</span><br />
										</>
									}
								</p>
							</Col>
							<Col lg={4}>
								<h4>
									<Translate text={'Contact Method'} />
								</h4>
								<p>
									{ currentTender.contactEmail &&
										<>
											<a href={`mailto:${currentTender.contactEmail}`}>
												{ currentTender.contactEmail }
											</a><br />
										</>
									}

									{ currentTender.contactPhoneNumber &&
										<>
											<span>
												{ currentTender.contactPhoneNumber }
											</span>
										</>
									}
								</p>
							</Col>
							<Col lg={8}>
								<h4>
									<Translate text={'Contact Info'} />
								</h4>
								<p className="line-breaks">
									{ currentTender.contactDetails || BLANK_VALUE }
								</p>
							</Col>

							{ procurementEntity && procurementEntity.name && procurementEntity.name === 'Province of Nova Scotia' &&
								<Col lg={4}>
									<h4>
										<Translate text={'End User Entity'} />
									</h4>
									<p>
										{ currentTender.endUserEntity || BLANK_VALUE }
									</p>
								</Col>
							}
						</Row>
					</div>

					{ currentTender && currentTender.closingDate && parseISO(currentTender.closingDate) <= new Date() && currentTender.tenderBidInformationDataList && Array.isArray(currentTender.tenderBidInformationDataList) && currentTender.tenderBidInformationDataList.length > 0 &&
						<div className="tender-detail__row">
							<Row>
								<Col lg={12}>
									<h4>
										<Translate text={'Bid Details'} />
									</h4>

									<Table bordered hover responsive>
										<thead>
											<tr>
												<th><Translate text={'Supplier'} /></th>
												<th><Translate text={'Bid Amount'} /></th>
												<th><Translate text={'Currency'} /></th>
												<th><Translate text={'Additional Notes'} /></th>
											</tr>
										</thead>
										<tbody>
											{ currentTender.tenderBidInformationDataList.map((bid, index) => {
												return (
													<tr key={'bide-data-' + index}>
														<td>{bid.supplier || BLANK_VALUE}</td>
														<td>{currencyOptions && bid.bidAmount ? formatCurrency(bid.bidAmount, bid.currency) : BLANK_VALUE}</td>
														<td>{bid.currency || BLANK_VALUE}</td>
														<td>{bid.additionalNotes || BLANK_VALUE}</td>
													</tr>
												);
											})}
										</tbody>
									</Table>
								</Col>
							</Row>
						</div>
					}

					{ currentTender && ((currentTender.tenderAwardData && Array.isArray(currentTender.tenderAwardData) && currentTender.tenderAwardData.length > 0) || currentTender.awardMemo) &&
						<div className="tender-detail__row">
							<Row>
								<Col lg={12}>
									{ (currentTender.tenderAwardData && Array.isArray(currentTender.tenderAwardData) && currentTender.tenderAwardData.length > 0) &&
										<>
											<h4>
												<Translate text={'Award Details'} />
											</h4>

											<div className="award-data">
												<div className="table-responsive has-overflow">
													<div className="data-table award-table">
														<Table bordered hover>
															<thead>
																<tr>
																	<th className="supplier-col"><Translate text={'Supplier'} /></th>
																	<th className="supplier-location-col"><Translate text={'Supplier Location'} /></th>
																	<th className="award-amount-col"><Translate text={'Award Amount'} /></th>
																	<th className="currency-col"><Translate text={'Currency'} /></th>
																	<th className="award-date-col"><Translate text={'Award Date'} /></th>
																	<th className="additional-details-col"><Translate text={'Additional Details'} /></th>
																</tr>
															</thead>
															<tbody>
																{ currentTender.tenderAwardData.map((award, index) => {
																	return (
																		<tr key={'award-data-' + index}>
																			<td className="supplier-col">{award.supplier || BLANK_VALUE}</td>
																			<td className="supplier-location-col">{award.supplierLocation || BLANK_VALUE}</td>
																			<td className="award-amount-col">{currencyOptions && award.awardAmount ? formatCurrency(award.awardAmount, award.currency) : BLANK_VALUE}</td>
																			<td className="currency-col">{award.currency || BLANK_VALUE}</td>
																			<td className="award-date-col">{award.awardDate ? getSimplifiedDate(award.awardDate) : BLANK_VALUE}</td>
																			<td className="additional-details-col">{award.additionalDetails || BLANK_VALUE}</td>
																		</tr>
																	);
																})}
															</tbody>
														</Table>
													</div>
												</div>
												<div>
													<h4><Translate text={'Total Award Amount'} /></h4>
													{ Array.isArray(awardTotals) && awardTotals.map(item => {
														return (
															<div>{ currencyFormat(item.total, item.currency, true) }</div>
														);
													})}
												</div>
											</div>
										</>
									}

									{ currentTender.awardMemo &&
										<div className="mt-4">
											<h4><Translate text={'Award Memo'} /></h4>
											<p className="line-breaks">
												{ currentTender.awardMemo || BLANK_VALUE }
											</p>
										</div>
									}
								</Col>
							</Row>
						</div>
					}
				</div>

				{ canCancel &&
					<Modal show={ showCancel } onHide={ handleCloseCancel } centered>
						<Modal.Header closeButton>
							<h2><Translate text={'Cancel this tender?'} /></h2>
						</Modal.Header>
						<Modal.Body>
							<p><Translate text={'If cancelling a notice with an *Open* status, please add the cancellation reason to the *Addendum Notes* field.'} /></p>
							<p className="mb-0"><Translate text={'If cancelling a notice with an *Expired* status, please add the cancellation reason to the *Award Memo* field.'} /></p>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={ handleConfirmCancel }><Translate text={'Yes, Cancel'} /></Button>
							<Button variant="primary" onClick={ handleCloseCancel }><Translate text={'Back to Tender Detail'} /></Button>
						</Modal.Footer>
					</Modal>
				}

				{ canDelete &&
					<Modal show={ showDelete } onHide={ handleCloseDelete } centered>
						<Modal.Header closeButton>
							<h2><Translate text={'Delete this tender? This action cannot be reversed.'} /></h2>
						</Modal.Header>
						<Modal.Footer>
							<Button variant="danger" onClick={ handleConfirmDelete }><Translate text={'Yes, Delete'} /></Button>
							<Button variant="primary" onClick={ handleCloseDelete }><Translate text={'Back to Tender Detail'} /></Button>
						</Modal.Footer>
					</Modal>
				}
			</>
		);
	}

	return null;
}

export default TenderDetail;
