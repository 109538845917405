/* eslint-disable id-length */
import React from 'react';
import { Translate } from 'react-translated';
import { useSelector } from 'react-redux';

import { isRole } from '../../helpers/userHelpers';
import { ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER } from '../../config/constants';

function StandingOffersSidebar() {
	const userData = useSelector(state => state.userData);

	return (
		<div className="filters-box">
			<p>
				<a href="https://beta.novascotia.ca/federal-standing-offers-and-supply-arrangements-canadian-collaborative-procurement-initiative" target="_blank" rel="noopener noreferrer"><Translate text={'Canadian Collaborative Procurement Initiative'} /></a>
			</p>

			{ !isRole(userData, [ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS, ROLE_MAS_ACCOUNT_MANAGER]) &&
				<p>
					<a href="https://beta.novascotia.ca/standing-offers?_ga=2.152826811.323378698.1666606216-167371204.1618405107" target="_blank" rel="noopener noreferrer"><Translate text={'More information on Standing Offers'} /></a>
				</p>
			}
		</div>
	);
}

export default StandingOffersSidebar;
