/* eslint-disable id-length */
import React, {useEffect, useState} from 'react';
import {ReactTitle} from 'react-meta-tags';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Translate, Translator} from 'react-translated';
import {userActions} from '../../store/actions/userActions';
import {searchActions} from '../../store/actions/searchActions';
import {isRole, isLoggedIn, isPseManager} from '../../helpers/userHelpers';
import {dataLayerPageView} from '../../helpers/miscHelpers';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import {Container, Row, Col, Tab, Badge, Nav, ButtonToolbar} from 'react-bootstrap';
import Users from '../admin/Users';
import UserFilters from '../admin/users/UserFilters';
import ReferenceTables from '../admin/ReferenceTables';
import ContentLoading from '../misc/ContentLoading';
import FormSearch from '../forms/FormSearch';
import Button from 'react-bootstrap/Button';

function TabTitle(props) {
	const { title, count } = props;

	return (
		<>
			<span className="tab-title tab-title--uppercase"><Translate text={title} /></span>
			{ count &&
			<Badge variant="primary">{ count }</Badge>
			}
		</>
	);
}

function Admin() {
	const {
		PAGE_TITLE_BASE,
		ROLE_PROCUREMENT_ADMIN
	} = require('../../config/constants');
	const dispatch = useDispatch();
	let history = useHistory();
	let location = useLocation();
	const currentPath = location.pathname;

	// State data.
	const userData = useSelector(state => state.userData);
	const authenticate = useSelector(state => state.authenticate);
	const userEditing = useSelector(state => state.userEditing);
	const search = useSelector(state => state.search);

	const [authenticated, setAuthenticated] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [filterData, setFilterData] = useState(null);

	useEffect(() => {
		const pageTitle = 'Admin - ' + PAGE_TITLE_BASE;

		dataLayerPageView(
			currentPath,
			pageTitle,
			'en'
		);
	}, []);

	const [key, setKey] = useState('reference-tables');

	// Getting reference data.
	useEffect(() => {
		if (authenticate && authenticate.success) {
			setAuthenticated(true);
		}
	}, [authenticate]);

	useEffect(() => {
		if ((userData.success && !isRole(userData, ROLE_PROCUREMENT_ADMIN) && !isPseManager(userData)) || userData.error) {
			history.replace('/');
		}

		if (isPseManager(userData)) {
			setKey('users');
		}
	}, [userData]);

	// Load on search.
	useEffect(() => {
		if (search.updated && (search.fields || search.filters)) {
			if (search.fields.keyword || search.fields.myOrganization || search.fields.mine || search.fields.watchlist || search.filters) {
				let keywordValue, filterDataValue;

				if (search.fields.keyword) {
					keywordValue = search.fields.keyword;
					setKeyword(keywordValue);
				} else {
					setKeyword('');
				}

				if (search.filters && typeof search.filters === 'object') {
					filterDataValue = search.filters;
					setFilterData(filterDataValue);
				} else {
					setFilterData(null);
				}
			} else {
				setKeyword('');
				setFilterData(null);
			}
		} else {
			setKeyword('');
			setFilterData(null);
		}
	}, [search]);

	const handleSelectTab = (key) => {
		setKey(key);
		dispatch(userActions.userIsEditing(false));
	};

	const handleSearch = (value) => {
		dispatch(searchActions.update({
			category: key,
			fields: {
				keyword: value
			},
			filters: (filterData) ? filterData : null
		}));
	};

	const handleFilter = (data) => {
		const isEmpty = (data) ? Object.values(data).every(x => x === null || x === '') : true;

		dispatch(searchActions.update({
			category: key,
			fields: {
				keyword: keyword
			},
			filters: (!isEmpty) ? data : null
		}));
	};

	return (
		<>
			<Translator>
				{({ translate }) => (
					<ReactTitle
						title={
							translate({
								text: '{pageTitle} - {siteTitle}',
								data: { siteTitle: PAGE_TITLE_BASE, pageTitle: 'Admin' }
							})
						}
					/>
				)}
			</Translator>

			<Header />

			<Container as="main" fluid={ true } className="main" id="main" tabIndex="-1">
				<ContentLoading>
					{ (authenticated || isLoggedIn(userData)) &&
						<Row>
							<Col md={7}>
								<h1 className="mt-0"><Translate text={(isPseManager(userData)) ? 'PSE Account Manager' : 'Admin'} /></h1>
							</Col>
							<Col md={5}>
								<ButtonToolbar className="justify-content-end">
									<Button variant="secondary" href={'/tenders'}>
										<Translate text={'Back to Dashboard'} />
									</Button>
								</ButtonToolbar>
							</Col>

							{ !isPseManager(userData) &&
								<Col lg={3} className={ (userEditing || key !== 'users') ? 'd-none' : null }>
									<Translator>
										{({ translate }) => (
											<FormSearch
												standAlone
												placeholder={
													translate({
														text: 'Search Users'
													})
												}
												ariaLabel={
													translate({
														text: 'Search Users'
													})
												}
												searchValue={ (search.fields && search.fields.keyword) ? search.fields.keyword : '' }
												onSearch={ handleSearch }
											/>
										)}
									</Translator>

									<UserFilters handleFilter={ handleFilter } />
								</Col>
							}

							<Col lg={ (!userEditing && key == 'users' && !isPseManager(userData)) ? 9 : 12 }>
								<Tab.Container defaultActiveKey={key} activeKey={key} onSelect={ handleSelectTab }>
									<Row>
										<Col md={12}>
											<Nav role="tablist" as="nav" className={'nav-tabs mb-0'}>
												{ !isPseManager(userData) &&
													<Nav.Item as={Nav.Link} eventKey="reference-tables">
														<TabTitle title="Reference Tables" />
													</Nav.Item>
												}

												<Nav.Item as={Nav.Link} eventKey="users">
													<TabTitle title="Users" />
												</Nav.Item>
											</Nav>
										</Col>
									</Row>
									<Row className={'mt-0'}>
										<Col md={12}>
											<Tab.Content>
												{ !isPseManager(userData) &&
													<Tab.Pane eventKey="reference-tables">
														<ReferenceTables />
													</Tab.Pane>
												}

												<Tab.Pane eventKey="users">
													<Users isUsersActive={ key == 'users' } />
												</Tab.Pane>
											</Tab.Content>
										</Col>
									</Row>
								</Tab.Container>
							</Col>
						</Row>
					}
				</ContentLoading>
			</Container>

			<Footer />
		</>
	);
}

export default Admin;
