import { referenceTableConstants } from '../constants/referenceTableConstants';

export function referenceTableGet(state = {}, action) {
	switch (action.type) {
		case referenceTableConstants.REFERENCE_TABLE_REQUEST:
			return {
				...state,
				request: true,
				success: false,
				error: false
			};
		case referenceTableConstants.REFERENCE_TABLE_SUCCESS:
			return {
				...state,
				request: false,
				success: true,
				error: false,
				[action.slug]: action.data
			};
		case referenceTableConstants.REFERENCE_TABLE_ERROR:
			return {
				...state,
				request: false,
				success: false,
				error: true
			};
		default:
			return state;
	}
}

export function referenceTablePost(state = {}, action) {
	switch (action.type) {
		case referenceTableConstants.REFERENCE_TABLE_POST_REQUEST:
			return {
				...state,
				request: true,
				success: false,
				error: false,
				status: null,
				callback: null
			};
		case referenceTableConstants.REFERENCE_TABLE_POST_SUCCESS:
			return {
				...state,
				request: false,
				success: true,
				error: false,
				status: null,
				[action.slug]: action.data,
				callback: action.callback
			};
		case referenceTableConstants.REFERENCE_TABLE_POST_ERROR:
			return {
				...state,
				request: false,
				success: false,
				error: true,
				status: action.status,
				callback: action.callback
			};
		case referenceTableConstants.REFERENCE_TABLE_POST_RESET:
			return {};
		default:
			return state;
	}
}
