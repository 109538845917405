import { notificationConstants } from '../constants/notificationConstants';
import { notificationService } from '../../services/notificationService';

export const notificationActions = {
	signUp,
	getNotifications,
	reset
};

function signUp(data, callback) {
	return dispatch => {
		dispatch(request());
		notificationService.post(data)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: notificationConstants.NOTIFICATION_REQUEST }; }

	function success(data, callback) { return { type: notificationConstants.NOTIFICATION_SUCCESS, data, callback: callback }; }

	function error(callback) { return { type: notificationConstants.NOTIFICATION_ERROR, callback: callback }; }
}

function getNotifications(callback) {
	return dispatch => {
		dispatch(request());
		notificationService.get()
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: notificationConstants.NOTIFICATION_GET_REQUEST }; }

	function success(data, callback) { return { type: notificationConstants.NOTIFICATION_GET_SUCCESS, data: data, callback: callback }; }

	function error(callback) { return { type: notificationConstants.NOTIFICATION_GET_ERROR, callback: callback }; }
}

function reset() {
	return {
		type: notificationConstants.NOTIFICATION_RESET
	};
}
