import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { tenderService } from '../../services/tenderService';

import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import FormSlideToggle from '../forms/FormSlideToggle';
import FormSearch from '../forms/FormSearch';
import Tooltip from '../misc/Tooltip';

export default function FormUNSPSC(props) {
	const {
		index,
		item,
		filterLevels,
		hideLevels,
		disabled,
		selectAllLevel2,
		isGlobalSelectAll,
		defaultMethod
	} = props;

	// State data.
	const referenceData = useSelector(state => state.referenceData);

	const methodKeyword = 'keyword';
	const methodManual = 'manual-select';
	const [method, setMethod] = useState(defaultMethod || methodKeyword);
	const [hasValue, setHasValue] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [hasSearch, setHasSearch] = useState(false);
	const [keywordResults, setKeywordResults] = useState(null);
	const [keywordError, hasKeywordError] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [unspscLevel1Options, setUnspscLevel1Options] = useState([]);
	const [unspscLevel2Options, setUnspscLevel2Options] = useState([]);
	const [unspscLevel3Options, setUnspscLevel3Options] = useState([]);
	const [unspscLevel4Options, setUnspscLevel4Options] = useState([]);
	const level1 = 'LEVEL1';
	const level2 = 'LEVEL2';
	const level3 = 'LEVEL3';
	const level4 = 'LEVEL4';
	const level1Name = 'unspscLevel1';
	const level2Name = 'unspscLevel2';
	const level3Name = 'unspscLevel3';
	const level4Name = 'unspscLevel4';
	const [wrapperClasses, setWrapperClasses] = useState(['unspsc-selector']);

	// Refs.
	const wrapperRef = useRef(null);
	const level1Ref = useRef(null);
	const level2Ref = useRef(null);
	const level3Ref = useRef(null);
	const level4Ref = useRef(null);

	// Update classes.
	useEffect(() => {
		if (Array.isArray(hideLevels)) {
			if (hideLevels.includes(level1)) {
				setWrapperClasses(classes => [...classes, 'unspsc-selector--hide-level1']);
			}

			if (hideLevels.includes(level2)) {
				setWrapperClasses(classes => [...classes, 'unspsc-selector--hide-level2']);
			}

			if (hideLevels.includes(level3)) {
				setWrapperClasses(classes => [...classes, 'unspsc-selector--hide-level3']);
			}

			if (hideLevels.includes(level4)) {
				setWrapperClasses(classes => [...classes, 'unspsc-selector--hide-level4']);
			}
		}
	}, [hideLevels]);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (Array.isArray(referenceData.data.unspscLevelDataList)) {
				setUnspscLevel1Options(referenceData.data.unspscLevelDataList.filter(item => item.levelType == level1));
			}
		}
	}, [referenceData]);

	// If has value.
	useEffect(() => {
		if (item.unspscLevel1 || item.unspscLevel2 || item.unspscLevel3 || item.unspscLevel4) {
			setHasValue(true);
		} else {
			setHasValue(false);
		}
	}, [item]);

	// Set option values when loaded.
	useEffect(() => {
		if (unspscLevel1Options && level1Ref.current) {
			level1Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
		}
	}, [unspscLevel1Options, level1Ref]);

	useEffect(() => {
		if (unspscLevel2Options && level2Ref.current) {
			level2Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
		}
	}, [unspscLevel2Options, level2Ref]);

	useEffect(() => {
		if (unspscLevel3Options && level3Ref.current) {
			level3Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
		}
	}, [unspscLevel3Options, level3Ref]);

	useEffect(() => {
		if (item && method == methodManual) {
			if (level1Ref.current && item.unspscLevel1 !== '' && !unspscLevel2Options.length) {
				level1Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
			}

			if (level2Ref.current && item.unspscLevel2 !== '' && !unspscLevel3Options.length) {
				level2Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
			}

			if (level3Ref.current && item.unspscLevel3 !== '' && !unspscLevel4Options.length) {
				level3Ref.current.dispatchEvent(new CustomEvent('change', { bubbles: true }));
			}
		}
	}, [method]);

	const handleGetUnspscByLevel = (selectedId, level, requestLevel) => {
		if (selectedId && requestLevel) {
			tenderService.getUnspsc({ unspscId: selectedId, level: requestLevel })
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error && Array.isArray(response)) {
							switch (level) {
								case level1:
									setUnspscLevel2Options(response);
									break;
								case level2:
									setUnspscLevel3Options(response);
									break;
								case level3:
									setUnspscLevel4Options(response);
									break;
								default:
									break;
							}
						}
					}
				);
		}
	};

	const handleMethodChange = (id, value) => {
		setMethod(value);
	};

	const handleSearch = (value) => {
		if (value && value.trim() !== '') {
			setKeyword(value);
			setHasSearch(true);
			hasKeywordError(false);
			setIsSearching(true);

			tenderService.getUnspsc(null, { keyword: value })
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error && Array.isArray(response)) {
							let results = response;

							if (Array.isArray(filterLevels)) {
								results = results.filter(item => item.levelType && !filterLevels.includes(item.levelType));
							}

							setKeywordResults(results);
						} else if (response.error) {
							hasKeywordError(true);
							setKeywordResults(null);
						}

						setIsSearching(false);
					}
				);
		} else {
			setKeyword('');
			setHasSearch(false);
			setKeywordResults(null);
			hasKeywordError(false);
			setIsSearching(false);
		}
	};

	const handleFormatResultLevel = (item) => {
		let levelName;
		const val = item.description;

		switch (item.levelType) {
			case level1:
				levelName = level1Name;
				break;
			case level2:
				levelName = level2Name;
				break;
			case level3:
				levelName = level3Name;
				break;
			case level4:
				levelName = level4Name;
				break;
			default:
				break;
		}

		return { name: levelName, value: val };
	};

	const handleUpdateManualDropdowns = (item) => {
		if (!item.levelType) {
			return;
		}

		let requestLevel;

		switch (item.levelType) {
			case level1:
				requestLevel = level2;
				break;
			case level2:
				requestLevel = level3;
				break;
			case level3:
				requestLevel = level4;
				break;
			default:
				break;
		}

		handleGetUnspscByLevel(item.id, item.levelType, requestLevel);
	};

	const handleSelectResult = (item) => {
		if (props.handleOnChangeMultiple) {
			let levels = [];

			levels.push(handleFormatResultLevel(item));
			handleUpdateManualDropdowns(item);

			// If the selected item has parent items, it will have nested `parentUnspscData` objects up to 3 additional levels.
			if (item.parentUnspscData) {
				levels.push(handleFormatResultLevel(item.parentUnspscData));
				handleUpdateManualDropdowns(item.parentUnspscData);

				if (item.parentUnspscData.parentUnspscData) {
					levels.push(handleFormatResultLevel(item.parentUnspscData.parentUnspscData));
					handleUpdateManualDropdowns(item.parentUnspscData.parentUnspscData);

					if (item.parentUnspscData.parentUnspscData.parentUnspscData) {
						levels.push(handleFormatResultLevel(item.parentUnspscData.parentUnspscData.parentUnspscData));
						handleUpdateManualDropdowns(item.parentUnspscData.parentUnspscData.parentUnspscData);
					}
				}
			}

			props.handleOnChangeMultiple(levels);
		}

		setHasValue(true);

		const scrollPosition = wrapperRef.current.getBoundingClientRect().top + window.pageYOffset - 105;
		window.scrollTo({
			top: scrollPosition
		});
	};

	const handleDropdownChange = async (event, level) => {
		const selectedId = event.target.options[event.target.selectedIndex].dataset.id; // this is the `data-id` value on the <option>.
		let requestLevel;

		switch (level) {
			case level1:
				requestLevel = level2;
				if (!(event.nativeEvent instanceof CustomEvent)) {
					setUnspscLevel2Options([]);
					setUnspscLevel3Options([]);
					setUnspscLevel4Options([]);

					if (props.handleOnChangeMultiple) {
						props.handleOnChangeMultiple([
							{ name: event.target.name, value: event.target.value },
							{ name: level2Name, value: '' },
							{ name: level3Name, value: '' },
							{ name: level4Name, value: '' }
						]);
					}
				}
				break;
			case level2:
				requestLevel = level3;
				if (!(event.nativeEvent instanceof CustomEvent)) {
					setUnspscLevel3Options([]);
					setUnspscLevel4Options([]);

					if (props.handleOnChangeMultiple) {
						props.handleOnChangeMultiple([
							{ name: event.target.name, value: event.target.value },
							{ name: level3Name, value: '' },
							{ name: level4Name, value: '' }
						]);
					}
				}
				break;
			case level3:
				requestLevel = level4;
				if (!(event.nativeEvent instanceof CustomEvent)) {
					setUnspscLevel4Options([]);

					if (props.handleOnChangeMultiple) {
						props.handleOnChangeMultiple([
							{ name: event.target.name, value: event.target.value },
							{ name: level4Name, value: '' }
						]);
					}
				}
				break;
			default:
				if (!(event.nativeEvent instanceof CustomEvent)) {
					if (props.handleOnChange) {
						props.handleOnChange({ name: event.target.name, value: event.target.value });
					}
				}
				break;
		}

		handleGetUnspscByLevel(selectedId, level, requestLevel);
	};

	const handleLevel2SelectAllChange = (event) => {
		const { checked } = event.target;

		if (checked) {
			if (props.handleOnChangeSelectAllLevel2) {
				props.handleOnChangeSelectAllLevel2(true);
			}
		} else if (props.handleOnChangeSelectAllLevel2) {
			props.handleOnChangeSelectAllLevel2(false);
		}
	};

	return (
		<div className={ wrapperClasses.join(' ') } ref={ wrapperRef }>
			<div className="unspsc-selector__toggle">
				<Translator>
					{({ translate }) => (
						<FormSlideToggle
							size="sm"
							id={`unspsc-toggle-${index}`}
							title={ translate({ text: 'Search via:' }) }
							value={ method }
							options={[
								{ value: methodKeyword, label: translate({ text: 'Keyword' }) },
								{ value: methodManual, label: translate({ text: 'Manual Select' }) }
							]}
							readOnly={ (isGlobalSelectAll) ? true : false }
							handleOnChange={ handleMethodChange }
						/>
					)}
				</Translator>
			</div>

			{ hasValue &&
				<div className="unspsc-selector__selected">
					<ul className="unspsc-selector__selected-tree">
						{ item.unspscLevel1 &&
							<li className="unspsc-selector__selected-tree--level-1">{ item.unspscLevel1 }</li>
						}

						{ item.unspscLevel2 &&
							<li className="unspsc-selector__selected-tree--level-2">{ item.unspscLevel2 }</li>
						}

						{ selectAllLevel2 && item.selectAll && (Array.isArray(unspscLevel2Options)) &&
							unspscLevel2Options.map((item, index) => {
								return (
									<li className="unspsc-selector__selected-tree--level-2 select-all" key={ index }>{ item.description }</li>
								);
							})
						}

						{ item.unspscLevel3 &&
							<li className="unspsc-selector__selected-tree--level-3">{ item.unspscLevel3 }</li>
						}

						{ item.unspscLevel4 &&
							<li className="unspsc-selector__selected-tree--level-4">{ item.unspscLevel4 }</li>
						}
					</ul>
				</div>
			}

			{ ((method == methodKeyword || !method) && !hasValue) &&
				<div className="unspsc-selector__search">
					<div className="unspsc-selector__search-form">
						<label htmlFor={`unspsc-search-${index}`}>
							<Translate text={'Search Commodities'} />
						</label>
						<FormSearch
							id={`unspsc-search-${index}`}
							searchValue={ keyword }
							onSearch={ handleSearch }
						/>

						{ keywordError &&
							<div className="invalid-feedback show error-unspsc-keyword-search">
								<Translate text={'Error: Could not complete search. Please try again later.'} />
							</div>
						}
					</div>

					{ isSearching &&
						<div className="unspsc-selector__search-loader">
							<Spinner animation="border" role="status">
								<span className="sr-only"><Translate text={'Loading'} /></span>
							</Spinner>
						</div>
					}

					{ (hasSearch && !isSearching) &&
						<>
							{ (Array.isArray(keywordResults) && keywordResults.length > 0) &&
								<ListGroup variant="flush" className="unspsc-selector__search-results">
									{ keywordResults.map((item, index) => {
										if (item.description && item.levelType) {
											return (
												<Translator key={ index }>
													{({ translate }) => (
														<ListGroup.Item className="unspsc-selector__search-results-item" data-level-type={ item.levelType }>
															<Button
																variant="link"
																data-hover-text={ translate({ text: 'Select' }) }
																onClick={() => handleSelectResult(item) }
															>
																{ item.description }
															</Button>
														</ListGroup.Item>
													)}
												</Translator>
											);
										}

										return null;
									})}
								</ListGroup>
							}

							{ (!keywordResults || (Array.isArray(keywordResults) && keywordResults.length == 0)) &&
								<Alert>
									<span className="alert-message">
										<Translate text={'No commodities matched your search.'} />
									</span>
								</Alert>
							}
						</>
					}
				</div>
			}

			{ method == methodManual &&
				<div className="unspsc-selector__manual">
					<Form.Group controlId={`unspscLevel1-${index}`} className="unspsc-selector__manual-level1">
						<Form.Label>
							<span>
								<Translate text={'Commodity Level 1'} /> <span className="required">*</span>
							</span>
						</Form.Label>
						<Form.Control
							as="select"
							ref={level1Ref}
							name="unspscLevel1"
							value={ (item.unspscLevel1) ? item.unspscLevel1 : '' }
							required
							disabled={ !unspscLevel1Options || !unspscLevel1Options.length || disabled }
							onChange={(e) => { handleDropdownChange(e, level1); }}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
								)}
							</Translator>

							{ (Array.isArray(unspscLevel1Options)) &&
								unspscLevel1Options.map((item, index) => {
									return (
										<option
											key={ index }
											value={ item.description }
											data-id={ item.id }
										>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Commodity Level 1'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId={`unspscLevel2-${index}`} className="unspsc-selector__manual-level2">
						<Form.Label>
							<span>
								<Translate text={'Commodity Level 2'} /> <span className="required">*</span>
							</span>

							{ item.unspscLevel1 && selectAllLevel2 &&
								<span className="d-flex align-items-center">
									<Translator>
										{({translate}) => (
											<Form.Check
												label={ translate({ text: 'Select All' }) }
												name={`unspscLevel2-select-all-${index}`}
												id={`unspscLevel2-select-all-${index}`}
												className="form-check-big mb-0 mt-1 mt-md-0 font-weight-bold"
												type="checkbox"
												value="1"
												checked={ item.selectAll ? true : false }
												onChange={ handleLevel2SelectAllChange }
											/>
										)}
									</Translator>

									<span className="ml-1">
										<Tooltip text={'Selecting All for a Level 2 Commodity\n\n• By choosing this option, you will receive email notifications of all tender notices for all Level 2 commodities connected to the Level 1 selection.\n• This option may be removed at any time and individual Level 2 commodities may be added as desired.'} size="sm" />
									</span>
								</span>
							}
						</Form.Label>
						<Form.Control
							as="select"
							ref={level2Ref}
							name="unspscLevel2"
							value={ (item.unspscLevel2) ? item.unspscLevel2 : '' }
							required={ (selectAllLevel2 && item.selectAll) ? false : true }
							disabled={ !unspscLevel2Options || !unspscLevel2Options.length || disabled || item.selectAll }
							onChange={(e) => { handleDropdownChange(e, level2); }}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
								)}
							</Translator>

							{ (Array.isArray(unspscLevel2Options)) &&
								unspscLevel2Options.map((item, index) => {
									return (
										<option
											key={ index }
											value={ item.description }
											data-id={ item.id }
										>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
						<Form.Control.Feedback type="invalid">
							<Translate
								text={'{formLabel} is required'}
								data={{
									formLabel: 'Commodity Level 2'
								}}
							/>
						</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId={`unspscLevel3-${index}`} className="unspsc-selector__manual-level3">
						<Form.Label>
							<span>
								<Translate text={'Commodity Level 3'} />
							</span>
						</Form.Label>
						<Form.Control
							as="select"
							ref={level3Ref}
							name="unspscLevel3"
							value={ (item.unspscLevel3) ? item.unspscLevel3 : '' }
							disabled={ !unspscLevel3Options || !unspscLevel3Options.length || disabled }
							onChange={(e) => { handleDropdownChange(e, level3); }}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
								)}
							</Translator>

							{ (Array.isArray(unspscLevel3Options)) &&
								unspscLevel3Options.map((item, index) => {
									return (
										<option
											key={ index }
											value={ item.description }
											data-id={ item.id }
										>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
					</Form.Group>

					<Form.Group controlId={`unspscLevel4-${index}`} className="unspsc-selector__manual-level4">
						<Form.Label>
							<span>
								<Translate text={'Commodity Level 4'} />
							</span>
						</Form.Label>
						<Form.Control
							as="select"
							ref={level4Ref}
							name="unspscLevel4"
							value={ (item.unspscLevel4) ? item.unspscLevel4 : '' }
							disabled={ !unspscLevel4Options || !unspscLevel4Options.length || disabled }
							onChange={(e) => { handleDropdownChange(e, level4); }}
						>
							<Translator>
								{({ translate }) => (
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
								)}
							</Translator>

							{ (Array.isArray(unspscLevel4Options)) &&
								unspscLevel4Options.map((item, index) => {
									return (
										<option
											key={ index }
											value={ item.description }
											data-id={ item.id }
										>
											{ item.description }
										</option>
									);
								})
							}
						</Form.Control>
					</Form.Group>
				</div>
			}
		</div>
	);
}
