import { referenceTableConstants } from '../constants/referenceTableConstants';
import { referenceTableService } from '../../services/referenceTableService';

export const referenceTableActions = {
	getReferenceTable,
	updateReferenceTable,
	resetUpdateReferenceTable
};

function getReferenceTable(slug, params, data, isPost) {
	return dispatch => {
		dispatch(request());

		if (isPost) {
			referenceTableService.post(slug, data, params)
				.then(
					response => {
						if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
							dispatch(success(response));
						} else {
							dispatch(error(response.status ? response.status : null));
						}
					}
				);
		} else {
			referenceTableService.get(slug, params)
				.then(
					response => {
						if (response && typeof response === 'object' && !response.error) {
							dispatch(success(response));
						} else {
							dispatch(error());
						}
					}
				);
		}

	};

	function request() { return { type: referenceTableConstants.REFERENCE_TABLE_REQUEST }; }

	function success(data) { return { type: referenceTableConstants.REFERENCE_TABLE_SUCCESS, slug: slug, data }; }

	function error() { return { type: referenceTableConstants.REFERENCE_TABLE_ERROR }; }
}

function updateReferenceTable(slug, data, callback, params) {
	return dispatch => {
		dispatch(request());

		referenceTableService.post(slug, data, params)
			.then(
				response => {
					if (response && (!('errorCode' in response) || null === response.errorCode) && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(response.status ? response.status : null));
					}
				}
			);
	};

	function request() { return { type: referenceTableConstants.REFERENCE_TABLE_POST_REQUEST }; }

	function success(data) { return { type: referenceTableConstants.REFERENCE_TABLE_POST_SUCCESS, slug: slug, data, callback: callback }; }

	function error(status) { return { type: referenceTableConstants.REFERENCE_TABLE_POST_ERROR, status: status, callback: callback }; }
}

function resetUpdateReferenceTable() {
	return {
		type: referenceTableConstants.REFERENCE_TABLE_POST_RESET
	};
}
