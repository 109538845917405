import { store } from '../store';

export function authHeader() {
	const state = store.getState();

	let authHeader = {};

	if (state && state.authenticate && state.authenticate.success) {
		authHeader = { 'Authorization': 'Bearer ' + state.authenticate.data.jwttoken };
	} else if (state && state.userData && state.userData.success) {
		authHeader = { 'Authorization': 'Bearer ' + state.userData.data.jwtToken };
	}

	return authHeader;
}
