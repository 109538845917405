/* eslint-disable no-unused-vars */

export const resolvers = {
	altp
};

function altp(req, res, ctx) {
	// GET
	if (req.method === 'GET') {
		return res(
			ctx.status(200),
			ctx.json({
				'paginationData': {
					'currentPage': 1,
					'numberOfRecords': 3,
					'totalRecords': 3
				},
				'altpDataList': [
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 1,
						'altpId': 'HRM-21-1027',
						'projectId': '1003420021',
						'title': 'Deep Freeze Maint. Renwal MAC & ENT',
						'additionalDetails': '',
						'department': 'Halifax Regional Municipality (HRM)',
						'division': '',
						'circumstance': '',
						'category': '',
						'awardDate': 'Apr 11 2022',
						'awardedAmount': '$91,812',
						'vendor': 'Faronics Corporation',
						'contactInformation': ''
					},
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 2,
						'altpId': 'CW52937',
						'projectId': '1003420021',
						'title': 'Trimble TSC7 Data Collector for Survey Gear',
						'additionalDetails': '',
						'department': 'Transportation and Infrastructure Renewal',
						'division': '',
						'circumstance': '',
						'category': '',
						'awardDate': 'Apr 10 2022',
						'awardedAmount': '$13,338',
						'vendor': 'Cansel Survey Equipment Inc.',
						'contactInformation': ''
					},
					{
						'errorMessage': '',
						'errorCode': '',
						'id': 3,
						'altpId': 'LT202109',
						'projectId': '1003420021',
						'title': 'Ellucian ERPS Modernization',
						'additionalDetails': '',
						'department': 'Saint Marys University',
						'division': '',
						'circumstance': '',
						'category': '',
						'awardDate': 'Apr 11 2022',
						'awardedAmount': '$91,812',
						'vendor': 'Ellucian ERPS Modernization',
						'contactInformation': ''
					}
				]
			})
		);
	}
}
