import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import prependHttp from 'prepend-http';
import { v4 as uuidv4 } from 'uuid';

import { bytesToSize } from '../../helpers/fileHelpers';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import UploadAttachment from './UploadAttachment';
import DocLink from '../misc/DocLink';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLink
} from '@fortawesome/free-solid-svg-icons';

function Attachments(props) {
	const {
		title,
		titleBefore,
		documentType,
		validated,
		required,
		data,
		additionalField,
		readOnly
	} = props;
	const {
		DOC_MAX_QUANTITY,
		DOC_MAX_TOTAL_FILESIZE
	} = require('../../config/constants');

	if (!documentType || !data) {
		return null;
	}

	const [uploads, setUploads] = useState([{ documentType: documentType }]);
	const [hasTotalFilesizeError, setHasTotalFilesizeError] = useState(false);

	const totalFilesizeString = bytesToSize(DOC_MAX_TOTAL_FILESIZE);
	const totalFilesizeError = `Total filesize exceeds the ${totalFilesizeString} limit.`;

	useEffect(() => {
		if (data && data.attachments) {
			let currentUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
			currentUploads = currentUploads.filter(item => item && item.documentType == documentType);

			if (currentUploads.length) {
				setUploads(currentUploads);
			} else {
				setUploads([{ documentType: documentType }]);
			}
		}
	}, [data]);

	const handleAddFile = () => {
		let otherUploads = (Array.isArray(data.attachments)) ? [...data.attachments] : [];
		otherUploads = otherUploads.filter(item => item && item.documentType !== documentType);

		let items = [...uploads];
		items.push({
			tempId: uuidv4(),
			documentType: documentType
		});

		const updatedFiles = otherUploads.concat(items);

		if (props.handleUpdateAttachments) {
			props.handleUpdateAttachments(updatedFiles);
		}
	};

	const handleAttachmentChanges = (updatedFiles) => {
		let filesizeError = false;
		const filesizeSum = updatedFiles.reduce((accumulator, object) => {
			if (object.filesize) {
				return accumulator + object.filesize;
			}

			return accumulator;
		}, 0);

		if (filesizeSum > DOC_MAX_TOTAL_FILESIZE) {
			filesizeError = true;
		} else {
			filesizeError = false;
		}

		setHasTotalFilesizeError(filesizeError);

		if (props.handleUpdateAttachments) {
			props.handleUpdateAttachments(updatedFiles);
		}

		if (props.handleCheckTotalFilesize) {
			props.handleCheckTotalFilesize(filesizeError);
		}
	};

	return (
		<>
			<Form.Group className={`form-custom-file ${(additionalField) ? 'form-custom-file--additional-field' : ''} repeater`}>
				{ title &&
					<div>
						<h4>
							{ titleBefore }
							{ title }
						</h4>
					</div>
				}

				{ !readOnly &&
					<>
						{ uploads.map((item, index) => {
							if (item.changeType == 'DELETE') {
								return false;
							}

							const key = item.id || item.tempId;

							return (
								<div className="upload-item repeater-item" key={ key || index }>
									<UploadAttachment
										item={ item }
										index={ index }
										validated={ validated }
										required={ required }
										documentType={ documentType }
										title={ title }
										data={ data }
										hasTotalFilesizeError={ hasTotalFilesizeError }
										additionalField={ additionalField }
										handleUpdateAttachments={(data) => { handleAttachmentChanges(data); }}
									/>
								</div>
							);
						})}

						<div className="upload-controls repeater-controls">
							{ hasTotalFilesizeError &&
								<div className="upload-controls__feedback">
									<div className="invalid-feedback show error-attachment-upload-feedback">
										<Translate text={ totalFilesizeError } />
									</div>
								</div>
							}

							{ (uploads && uploads.length < DOC_MAX_QUANTITY) &&
								<Translator>
									{({ translate }) => (
										<div className="upload-controls__btn">
											<Button
												onClick={ handleAddFile }
												aria-label={
													translate({
														text: (title) ? 'Add Another - {type}' : 'Add Another',
														data: { type: title }
													})
												}
											>
												<Translate text={'Add Another'} />
											</Button>
										</div>
									)}
								</Translator>
							}
						</div>
					</>
				}

				{ (readOnly && Array.isArray(uploads) && uploads.length > 0) &&
					<div className="post-tender__docs">
						{ uploads.map((item, index) => {
							return (
								<div className="post-tender__docs-item" key={ index }>
									{ item.filename &&
										<DocLink
											key={ index }
											data={ item }
										/>
									}

									{ item.url &&
										<div className="doc-link">
											<span className="doc-link__icon">
												<FontAwesomeIcon icon={ faLink } />
											</span>

											<span className="doc-link__text">
												<a href={ prependHttp(item.url) } target="_blank" rel="noopener noreferrer">{ item.url }</a>
											</span>
										</div>
									}

									{ item.notes &&
										<div className="line-breaks">
											{ item.notes }
										</div>
									}
								</div>
							);
						})}
					</div>
				}
			</Form.Group>
		</>
	);
}

export default Attachments;
