import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Row, Col, Nav, Navbar, NavItem, Form, Button} from 'react-bootstrap';
import {Translate, Translator} from 'react-translated';
import {getSimplifiedDate} from '../../../helpers/dateHelpers';
import {isPseManager} from '../../../helpers/userHelpers';
import {userActions} from '../../../store/actions/userActions';
import FormSlideToggle from '../../forms/FormSlideToggle';
import {
	LANGUAGE_ENGLISH,
	ROLE_MAS,
	ROLE_NAME_MAPPING,
	PSE_ROLE_NAME_MAPPING,
	ROLE_NONE, ROLE_PROCUREMENT_ADMIN,
	ROLE_PROCUREMENT_REGULAR,
	ROLE_PROCUREMENT_INTERNAL,
	ROLE_SUPPLIER,
	ROLE_MAS_ACCOUNT_MANAGER,
	USER_STATUS_ACTIVE,
	USER_STATUS_INACTIVE
} from '../../../config/constants';

function UserForm(props) {
	const {
		handleCloseForm,
		user
	} = props;

	const dispatch = useDispatch();

	const referenceData = useSelector(state => state.referenceData);
	const userData = useSelector(state => state.userData);

	const [validated, setValidated] = useState(false);
	const [email, setEmail] = useState('');
	const [userRole, setUserRole] = useState('');
	const [organization, setOrganization] = useState('');
	const [endUserEntity, setEndUserEntity] = useState('');
	const [procurementEntities, setProcurementEntities] = useState([]);
	const [roles, setRoles] = useState(ROLE_NAME_MAPPING);
	const [isCurrentUser, setIsCurrentUser] = useState(false);

	const formSubmitRef = useRef(null);

	const [currentUser, setCurrentUser] = useState({
		'id': null,
		'firstName': '',
		'middleName': '',
		'lastName': '',
		'emailAddress': '',
		'roleData': '',
		'userType': 'Regular',
		'procurementEntityName': '',
		'endUserEntity': '',
		'language': '',
		'rpid': '',
		'termsOfUseAccepted': 'false',
		'userStatus': USER_STATUS_ACTIVE,
		'newUser': true
	});

	useEffect(() => {
		if (isPseManager(userData)) {
			setRoles(PSE_ROLE_NAME_MAPPING);
		}
	}, [userData]);

	useEffect(() => {
		if (userData.data && userData.data.userID && currentUser.id && userData.data.userID === currentUser.id) {
			setIsCurrentUser(true);
		} else {
			setIsCurrentUser(false);
		}
	}, [userData, currentUser]);

	const handleUserRoleChange = (id, value) => {
		setUserRole(value);
	};

	const handleOrganizationChange = (event) => {
		const { value } = event.target;
		const dataset = event.target.options[event.target.selectedIndex].dataset;
		let org = value;
		let entity;

		if (dataset.entity) {
			org = dataset.org;
			entity = dataset.entity;
		} else {
			org = value;
			entity = null;
		}

		setOrganization(org);
		setEndUserEntity(entity);
	};

	const handleSubmit = async (event) => {
		event.currentTarget.blur();
		event.preventDefault();
		event.stopPropagation();

		const form = event.currentTarget;

		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(true);
			return;
		}

		setValidated(true);

		const data = {
			'changeType': 'SAVE',
			'id': currentUser.id ? currentUser.id : null,
			'firstName': currentUser.firstName ? currentUser.firstName : null,
			'middleName': currentUser.middleName ? currentUser.middleName : null,
			'lastName': currentUser.lastName ? currentUser.lastName : null,
			'emailAddress': email ? email : null,
			'roleData': userRole ? userRole : null,
			'procurementEntityName': organization ? organization : null,
			'endUserEntity': endUserEntity ? endUserEntity : null,
			'language': currentUser.language ? currentUser.language : LANGUAGE_ENGLISH,
			'rpid': currentUser.rpid ? currentUser.rpid : null,
			'termsOfUseAccepted': (userRole === ROLE_PROCUREMENT_REGULAR || userRole === ROLE_PROCUREMENT_INTERNAL || userRole === ROLE_PROCUREMENT_ADMIN || userRole === ROLE_MAS || userRole === ROLE_MAS_ACCOUNT_MANAGER) ? 'true' : currentUser.roleData !== userRole || !currentUser.termsOfUseAccepted ? 'false' : 'true',
			'userStatus': currentUser.userStatus,
			'newUser': currentUser.id ? false : true
		};

		dispatch(userActions.updateUser(data));
		handleCloseForm();
	};

	const handleClose = () => {
		handleCloseForm();
	};

	const handleToggleStatus = async () => {
		if (currentUser.id) {
			if (currentUser.roleData && (currentUser.roleData !== ROLE_NONE || currentUser.id === 1) && (currentUser.procurementEntityName || (currentUser.roleData !== ROLE_MAS && currentUser.roleData !== ROLE_PROCUREMENT_REGULAR && currentUser.roleData !== ROLE_PROCUREMENT_INTERNAL))) {
				const activate = currentUser.userStatus !== USER_STATUS_ACTIVE || (user.roleData === ROLE_NONE && user.id !== 1);
				const status = (!activate) ? 'deactivate' : 'activate';
				const confirmChange = confirm('Are you sure you would like to ' + status + ' ' + currentUser.firstName.trim() + ' ' + currentUser.lastName.trim() + '?'); // eslint-disable-line no-alert

				if (!confirmChange) {
					return false;
				}

				const data = {
					'userId': currentUser.id ? currentUser.id : null,
					'role': currentUser.roleData ? currentUser.roleData : null,
					'approvalType': activate ? 'APPROVE' : 'DENY'
				};
				dispatch(userActions.authorizeUser(data, activate ? 'APPROVE' : 'DENY'));
				handleCloseForm();
			} else if (formSubmitRef && formSubmitRef.current && formSubmitRef.current.click) {
				formSubmitRef.current.click();
			}
		}
	};

	const handleDelete = () => {
		if (currentUser.id) {
			const confirmChange = confirm('Are you sure you would like to remove ' + currentUser.firstName.trim() + ' ' + currentUser.lastName.trim() + '?'); // eslint-disable-line no-alert

			if (!confirmChange) {
				return false;
			}

			dispatch(userActions.deleteUser(currentUser));
		}
		handleCloseForm();
	};

	// User changes
	useEffect(() => {
		if (typeof user != 'boolean') {
			if (!user.id) {
				handleCloseForm();
			}

			setCurrentUser(user);
			setEmail(user.emailAddress ? user.emailAddress : '');
			setUserRole(user.roleData ? user.roleData : '');
			setOrganization(user.procurementEntityName ? user.procurementEntityName : '');
			setEndUserEntity(user.endUserEntity ? user.endUserEntity  : '');
		}
	}, [user]);

	useEffect(() => {
		if (referenceData && referenceData.success && referenceData.data.procurementEntityDataList && Array.isArray(referenceData.data.procurementEntityDataList) && referenceData.data.procurementEntityDataList.length) {
			setProcurementEntities(referenceData.data.procurementEntityDataList.map(entity => ({ name: entity.name, value: entity.name, endUserEntityDataList: entity.endUserEntityDataList })));
		}
	}, [referenceData]);

	return (
		<div className={'box box--blue-light box--wide'}>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Row className="align-items-center">
					<Col md={5}>
						<h2 className="mb-2"><Translate text={'Edit User'} /></h2>
					</Col>
					<Col md={7}>
						<Navbar className={'form-nav justify-content-end'}>
							<Nav>
								<NavItem>
									<Button variant="primary" onClick={() => handleClose()}>
										<Translate text={'Back to List'}/>
									</Button>
								</NavItem>

								{currentUser && currentUser.id && !isCurrentUser &&
								<NavItem>
									<Button variant="primary" onClick={() => handleToggleStatus()}>
										<Translate
											text={(currentUser.userStatus == USER_STATUS_ACTIVE && (user.roleData !== ROLE_NONE || user.id === 1)) ? 'Deactivate User' : 'Activate User'}/>
									</Button>
								</NavItem>
								}

								{ (currentUser && currentUser.id && currentUser.userStatus == USER_STATUS_INACTIVE) &&
								<NavItem>
									<Button variant="primary" onClick={() => handleDelete()}>
										<Translate text={'Remove User'}/>
									</Button>
								</NavItem>
								}
								<NavItem>
									<Button variant="success" type="submit" ref={formSubmitRef}>
										<Translate text={currentUser.id ? 'Save User' : 'Create User'}/>
									</Button>
								</NavItem>
							</Nav>
						</Navbar>
					</Col>
				</Row>

				<Row>
					<Col md={6}>
						{ !isPseManager(userData) &&
							<Form.Group controlId="userStatus">
								<Form.Label><Translate text={'Status'}/></Form.Label>
								<Form.Control type="text" readOnly value={currentUser.userStatus}/>
							</Form.Group>
						}

						{ ([ROLE_MAS, ROLE_SUPPLIER, ROLE_MAS_ACCOUNT_MANAGER, ROLE_PROCUREMENT_REGULAR].includes(userRole)) &&
							<Form.Group controlId="termsOfUseAccepted">
								<Translator>
									{({translate}) => (
										<FormSlideToggle
											id="termsOfUseAccepted"
											name="termsOfUseAccepted"
											title="Terms of Use Accepted"
											value={ currentUser.termsOfUseAccepted || 'false' }
											options={[
												{ value: 'false', label: translate({text: 'No'}) },
												{ value: 'true', label: translate({text: 'Yes'}) }
											]}
											readOnly
										/>
									)}
								</Translator>
							</Form.Group>
						}

						<Form.Group controlId="userRole">
							<Form.Label>
								<Translate text={'User Role'}/> <span className="required">*</span>
							</Form.Label>
							<Form.Control
								as="select"
								required
								onChange={(e) => (!isCurrentUser) ? handleUserRoleChange(e.currentTarget.selectedIndex, e.currentTarget.value) : null}
								value={userRole}
								readOnly={ (isCurrentUser) ? true : false }
								disabled={ (isCurrentUser) ? true : false }
							>
								<Translator>
									{({translate}) => (
										<>
											<option value="">
												{translate({text: '- Select -'})}
											</option>

											{
												Object.keys(roles).filter(key => (currentUser.id === 1 && key === ROLE_NONE) || (currentUser.id !== 1 && key !== ROLE_NONE && key !== ROLE_SUPPLIER)).map((key, index) => (
													<option value={key} key={index}>
														{translate({text: roles[key]})}
													</option>
												))
											}
										</>
									)}
								</Translator>
							</Form.Control>
							<Form.Control.Feedback type="invalid">
								<Translate text={'User Role is required.'}/>
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group controlId="organization">
							<Form.Label>
								<Translate text={'Organization'}/> { (userRole === ROLE_MAS || userRole === ROLE_PROCUREMENT_REGULAR || userRole === ROLE_PROCUREMENT_INTERNAL || userRole === ROLE_MAS_ACCOUNT_MANAGER) && <span className="required">*</span> }
							</Form.Label>
							<Form.Control
								as="select"
								required={(userRole === ROLE_MAS || userRole === ROLE_PROCUREMENT_REGULAR || userRole === ROLE_PROCUREMENT_INTERNAL || userRole === ROLE_MAS_ACCOUNT_MANAGER)}
								onChange={(e) => handleOrganizationChange(e)}
								value={endUserEntity || organization}
								disabled={(isPseManager(userData)) ? true : false}
							>
								<Translator>
									{({translate}) => (
										<>
											<option value="">
												{translate({text: '- Select -'})}
											</option>

											{
												procurementEntities.map((item, index) => {
													const org = item.value;

													return (
														<React.Fragment key={index}>
															<option value={ item.value }>
																{ item.name }
															</option>

															{ (Array.isArray(item.endUserEntityDataList) && item.endUserEntityDataList.length > 0) &&
																<>
																	<optgroup label={' - '}>
																		{ item.endUserEntityDataList.map((item, index) => {
																			return (
																				<option value={ item.name } key={index} data-org={ org } data-entity={ item.name } selected={ item.name == endUserEntity }>
																					{ item.name }
																				</option>
																			);
																		})}
																	</optgroup>
																	<option disabled>{' - '}</option>
																</>
															}
														</React.Fragment>
													);
												})
											}
										</>
									)}
								</Translator>
							</Form.Control>

							<Form.Control.Feedback type="invalid">
								<Translate text={'Organization is required.'}/>
							</Form.Control.Feedback>
						</Form.Group>
					</Col>

					<Col md={6}>
						<Form.Group controlId="firstName">
							<Form.Label><Translate text={'First Name'}/></Form.Label>
							<Form.Control type="text" readOnly value={currentUser.firstName}/>
						</Form.Group>

						<Form.Group controlId="middleName">
							<Form.Label><Translate text={'Middle Name'}/></Form.Label>
							<Form.Control type="text" readOnly value={currentUser.middleName}/>
						</Form.Group>

						<Form.Group controlId="lastName">
							<Form.Label><Translate text={'Last Name'}/></Form.Label>
							<Form.Control type="text" readOnly value={currentUser.lastName}/>
						</Form.Group>

						<Form.Group controlId="emailAddress">
							<Form.Label><Translate text={'Email Address'}/></Form.Label>
							<Form.Control type="text" readOnly value={email}/>
						</Form.Group>

						{ !isPseManager(userData) &&
							<>
								<Form.Group controlId="createdDate">
									<Form.Label><Translate text={'Date Created'}/></Form.Label>
									<Form.Control type="text" readOnly value={getSimplifiedDate(currentUser.createdDate)}/>
								</Form.Group>

								<Form.Group controlId="modifiedDate">
									<Form.Label><Translate text={'Date Modified'}/></Form.Label>
									<Form.Control type="text" readOnly value={getSimplifiedDate(currentUser.modifiedDate)}/>
								</Form.Group>
							</>
						}
					</Col>
				</Row>
			</Form>
		</div>
	);
}
export default UserForm;
