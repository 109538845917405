export const PAGE_TITLE_BASE = 'Procurement Portal';
export const PAGE_TITLE_BASE_FR = 'Procurement Portal';
export const GUEST_RPID = 'GUEST';
export const ROLE_NONE = 'NONE';
export const ROLE_SUPPLIER = 'SUPPLIER';
export const ROLE_MAS = 'MAS';
export const ROLE_MAS_ACCOUNT_MANAGER = 'MAS_ACCOUNT_MANAGER';
export const ROLE_PROCUREMENT_REGULAR = 'PROCUREMENT_REGULAR';
export const ROLE_PROCUREMENT_INTERNAL = 'PNS_INTERNAL';
export const ROLE_PROCUREMENT_ADMIN = 'PROCUREMENT_ADMIN';
export const ROLE_NAME_MAPPING = {
	[ROLE_SUPPLIER]: 'Supplier',
	[ROLE_MAS]: 'Public Sector Entities',
	[ROLE_MAS_ACCOUNT_MANAGER]: 'PSE Account Manager',
	[ROLE_PROCUREMENT_REGULAR]: 'Regular',
	[ROLE_PROCUREMENT_INTERNAL]: 'Internal',
	[ROLE_PROCUREMENT_ADMIN]: 'Admin',
	[ROLE_NONE]: 'Guest'
};
export const PSE_ROLE_NAME_MAPPING = {
	[ROLE_MAS]: 'Public Sector Entities',
	[ROLE_MAS_ACCOUNT_MANAGER]: 'PSE Account Manager'
};
export const USER_STATUS_ACTIVE = 'ACTIVE';
export const USER_STATUS_INACTIVE = 'INACTIVE';
export const USER_STATUS_PENDING = 'PENDING';
export const TENDERS_EMAIL = 'ptenders@novascotia.ca';
export const TENDERS_PHONE = '902-424-3333';
export const PNS_ORG_ID = '100000';
export const PAGINATION_LIMIT = 20;
export const ALTP_PAGINATION_LIMIT = 6;
export const TENDERS_PAGINATION_LIMIT = 6;
export const USERS_PAGINATION_LIMIT = 6;
export const REPEATER_ITEM_LIMIT = 5;
export const BLANK_VALUE = '–';
export const INPUT_MAX_LENGTH = 100;
export const TEXTAREA_MAX_LENGTH = 100;
export const NUMBER_MAX_LENGTH = 10;
export const MESSAGE_MAX_LENGTH = 500;
export const DESCRIPTION_MAX_LENGTH = 2500;
export const MULTI_SELECT_MAX_OPTIONS = 10;
export const DOC_CONTENT_TYPE_DOCX = 'DOCX';
export const DOC_CONTENT_TYPE_PDF = 'PDF';
export const DOC_CONTENT_TYPE_PNG = 'PNG';
export const DOC_CONTENT_TYPE_JPEG = 'JPEG';
export const DOC_CONTENT_TYPE_TEXT = 'TEXT';
export const DOC_CONTENT_TYPE_DOC = 'DOC';
export const DOC_CONTENT_TYPE_XLS = 'XLS';
export const DOC_CONTENT_TYPE_XLSX = 'XLSX';
export const DOC_CONTENT_TYPE_MSG = 'MSG';
export const DOC_CONTENT_TYPE_ZIP = 'ZIP';
export const DOC_MIME_TYPE_PDF = 'application/pdf';
export const DOC_MIME_TYPE_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const DOC_MIME_TYPE_PNG = 'image/png';
export const DOC_MIME_TYPE_JPEG = 'image/jpeg';
export const DOC_MIME_TYPE_TEXT = 'text/plain';
export const DOC_MIME_TYPE_DOC = 'application/msword';
export const DOC_MIME_TYPE_XLS = 'application/vnd.ms-excel';
export const DOC_MIME_TYPE_XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const DOC_MIME_TYPE_MSG = 'application/vnd.ms-outlook';
export const DOC_MIME_TYPE_ZIP = 'application/zip';
export const DOC_MIME_TYPE_X_ZIP = 'application/x-zip-compressed';
export const DOC_CONTENT_TYPES = [
	DOC_CONTENT_TYPE_PDF,
	DOC_CONTENT_TYPE_ZIP,
	DOC_CONTENT_TYPE_JPEG,
	DOC_CONTENT_TYPE_PNG,
	DOC_CONTENT_TYPE_DOC,
	DOC_CONTENT_TYPE_DOCX,
	DOC_CONTENT_TYPE_TEXT,
	DOC_CONTENT_TYPE_XLS,
	DOC_CONTENT_TYPE_XLSX
];
export const DOC_MIME_TYPES = [
	DOC_MIME_TYPE_PDF,
	DOC_MIME_TYPE_ZIP,
	DOC_MIME_TYPE_X_ZIP,
	DOC_MIME_TYPE_JPEG,
	DOC_MIME_TYPE_PNG,
	DOC_MIME_TYPE_DOC,
	DOC_MIME_TYPE_DOCX,
	DOC_MIME_TYPE_TEXT,
	DOC_MIME_TYPE_XLS,
	DOC_MIME_TYPE_XLSX
];
export const DOC_MAX_FILESIZE = 157286400;
export const DOC_MAX_TOTAL_FILESIZE = 157286400;
export const DOC_MAX_FILENAME = 75;
export const DOC_MAX_QUANTITY = 10;
export const INACTIVITY_TIMEOUT = 1800000;
export const LANGUAGE_ENGLISH = 'ENGLISH';
export const LANGUAGE_ENGLISH_LABEL = 'English';
export const LANGUAGE_ENGLISH_CODE = 'en';
export const LANGUAGE_FRENCH = 'FRENCH';
export const LANGUAGE_FRENCH_LABEL = 'Français';
export const LANGUAGE_FRENCH_CODE = 'fr';
export const LANGUAGE_MAPPINGS = [
	{ name: LANGUAGE_ENGLISH, code: LANGUAGE_ENGLISH_CODE },
	{ name: LANGUAGE_FRENCH, code: LANGUAGE_FRENCH_CODE }
];
export const LANGUAGE_OPTIONS = [
	{
		name: LANGUAGE_ENGLISH,
		label: LANGUAGE_ENGLISH_LABEL
	},
	{
		name: LANGUAGE_FRENCH,
		label: LANGUAGE_FRENCH_LABEL
	}
];
export const CONTENT_ERROR_MESSAGE = 'Error: Content could not be retrieved.';
export const CONTACT_EMAIL = 'procure@novascotia.ca';
export const CONTACT_PHONE = '1-902-424-3333';
export const DOCUMENT_TYPE_TENDER = 'TENDER_OTHER';
export const DOCUMENT_TYPE_ADDENDUM = 'TENDER_ADDENDUM';
export const ATTACHMENT_TYPE_TENDER = 'TENDER';
export const ATTACHMENT_TYPE_STANDING_OFFER = 'STANDING_OFFER';
export const ATTACHMENT_TYPE_ALTP = 'ALTP';
export const TENDER_STATUS_OPEN = 'OPEN';
export const TENDER_STATUS_ISSUED = 'ISSUED';
export const TENDER_STATUS_CLOSED = 'CLOSED';
export const TENDER_STATUS_AWARDED = 'AWARDED';
export const TENDER_STATUS_EXPIRED = 'EXPIRED';
export const TENDER_STATUS_CANCELLED = 'CANCELLED';
export const TENDER_STATUS_DRAFT = 'DRAFT';
export const TENDER_STATUS_SCHEDULED = 'SCHEDULED';
export const TAB_TENDERS = 'tenders';
export const TAB_ALTPS = 'altps';
export const TAB_STANDING_OFFERS = 'standing-offers';
export const TAB_SUPPLIERS = 'suppliers';
export const ALL_TABS = 'all';
export const SORT_DATE_CREATED_ASC = 'DATE_CREATED_ASC';
export const SORT_DATE_CREATED_DESC = 'DATE_CREATED_DESC';
export const SORT_DATE_MODIFIED_ASC = 'DATE_MODIFIED_ASC';
export const SORT_DATE_MODIFIED_DESC = 'DATE_MODIFIED_DESC';
export const SORT_POSTED_DATE_ASC = 'POSTED_DATE_ASC';
export const SORT_POSTED_DATE_DESC = 'POSTED_DATE_DESC';
export const SORT_CLOSING_DATE_ASC = 'CLOSING_DATE_ASC';
export const SORT_CLOSING_DATE_DESC = 'CLOSING_DATE_DESC';
export const SORT_AWARD_DATE_ASC = 'AWARD_DATE_ASC';
export const SORT_AWARD_DATE_DESC = 'AWARD_DATE_DESC';
export const SORT_ORG_ID_ASC = 'ORG_ID_ASC';
export const SORT_ORG_ID_DESC = 'ORG_ID_DESC';
export const SORT_SECTOR_ID_ASC = 'SECTOR_ID_ASC';
export const SORT_SECTOR_ID_DESC = 'SECTOR_ID_DESC';
export const UNSPSC_MAX_QUANTITY = 10;
export const UNSPSC_MAX_DISPLAY = 1;
export const AWARD_MAX_QUANTITY = 70;
export const BID_MAX_QUANTITY = 70;
export const AWARD_DURATION_TODAY = 'DAY';
export const AWARD_DURATION_LAST_SEVEN_DAYS = 'WEEK';
export const AWARD_DURATION_LAST_FOURTEEN_DAYS = 'TWO_WEEK';
export const AWARD_DURATION_LAST_THIRTY_DAYS = 'MONTH';
export const AWARD_DURATION_CURRENT_YEAR = 'YEAR';
export const TENDER_NEXT_CALL_DATE = 'DATE';
export const TENDER_NEXT_CALL_ID = 'TENDER_ID';
export const TRADE_AGREEMENT_NONE_CODE = 'TA-01';
export const NOT_APPLICABLE = 'Not Applicable';
export const COUNTRY_CANADA = 'Canada';
export const COUNTRY_US = 'United States';
export const COUNTRY_US_FR = 'États-Unis';
export const DIVERSITY_ANSWER_NONE = 'None of the above';
export const BAD_CHARACTERS_REGEX = /\s|%|\/|\+|=|\(|\)|\[|\]/g;
export const BAD_CHARACTERS_MESSAGE = 'No spaces allowed, and some characters are restricted.';
export const PROCUREMENT_METHOD_ACAN = 'Advance Contract Award Notice';
export const SOLICITATION_TYPE_ACAN = 'Advance Contract Award Notice';
export const ENTITY_PUBLIC_WORKS_ID = '100119';
export const PROCUREMENT_ENTITY_PNS = 'Province of Nova Scotia';
export const UNSPSC_LEVEL_1 = 'LEVEL1';
export const UNSPSC_LEVEL_2 = 'LEVEL2';
export const UNSPSC_LEVEL_3 = 'LEVEL3';
export const UNSPSC_LEVEL_4 = 'LEVEL4';
export const UNSPSC_NEW_CODE_LENGTH = 2; // rest of the digits determined by parents.
export const DEFAULT_CLOSING_TIME = '14:00:00';
export const CLOSING_TIME_FORMAT_TWELVE = '12-hour';
export const CLOSING_TIME_FORMAT_TWENTY_FOUR = '24-hour';
export const CLOSING_TIME_OPTIONS = [
	{
		value: '9:00:00',
		description: '9:00 am'
	},
	{
		value: '9:30:00',
		description: '9:30 am'
	},
	{
		value: '10:00:00',
		description: '10:00 am'
	},
	{
		value: '10:30:00',
		description: '10:30 am'
	},
	{
		value: '11:00:00',
		description: '11:00 am'
	},
	{
		value: '11:30:00',
		description: '11:30 am'
	},
	{
		value: '12:00:00',
		description: '12:00 pm'
	},
	{
		value: '12:30:00',
		description: '12:30 pm'
	},
	{
		value: '13:00:00',
		description: '1:00 pm'
	},
	{
		value: '13:30:00',
		description: '1:30 pm'
	},
	{
		value: '14:00:00',
		description: '2:00 pm'
	},
	{
		value: '14:30:00',
		description: '2:30 pm'
	},
	{
		value: '15:00:00',
		description: '3:00 pm'
	},
	{
		value: '15:30:00',
		description: '3:30 pm'
	},
	{
		value: '16:00:00',
		description: '4:00 pm'
	},
	{
		value: '16:30:00',
		description: '4:30 pm'
	},
	{
		value: '17:00:00',
		description: '5:00 pm'
	},
	{
		value: '17:30:00',
		description: '5:30 pm'
	},
	{
		value: '18:00:00',
		description: '6:00 pm'
	}
];
export const CLOSING_LOCATION_DISCOVERY = 'discovery.ariba.com';
export const CLOSING_LOCATION_BIDX = 'www.bidx.com';
export const CLOSING_LOCATION_OTHER = 'Other';
export const CLOSING_LOCATION_OPTIONS = [
	{
		value: CLOSING_LOCATION_DISCOVERY,
		description: CLOSING_LOCATION_DISCOVERY
	},
	{
		value: CLOSING_LOCATION_BIDX,
		description: CLOSING_LOCATION_BIDX
	},
	{
		value: CLOSING_LOCATION_OTHER,
		description: CLOSING_LOCATION_OTHER
	}
];
