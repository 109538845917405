/* eslint-disable id-length */
import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import { useSelector } from 'react-redux';
import { format, parse, parseISO, isValid } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import FormSlideToggle from '../forms/FormSlideToggle';
import FormCustomSelect from '../forms/FormCustomSelect';
import FormDatePicker from '../forms/FormDatePicker';
import {isRole} from '../../helpers/userHelpers';
import {ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_SUPPLIER, ROLE_MAS_ACCOUNT_MANAGER} from '../../config/constants';

function TenderFilters(props) {
	const userData = useSelector(state => state.userData);

	const [categoryOptions, setCategoryOptions] = useState([]);
	const [entityOptions, setEntityOptions] = useState([]);
	const [statusOptions, setStatusOptions] = useState([]);
	const [solicitationTypeOptions, setSolicitationTypeOptions] = useState([]);
	const [filterData, setFilterData] = useState((props.defaultFilterData) ? props.defaultFilterData : {});
	const [mine, setMine] = useState((props.defaultMine) ? props.defaultMine : null);
	const [mineValue, setMineValue] = useState(null);
	const [myOrganization, setMyOrganization] = useState((props.defaultMyOrganization) ? props.defaultMyOrganization : null);
	const [watchlist, setWatchlist] = useState((props.defaultWatchlist) ? props.defaultWatchlist : null);
	const dateFormat = 'dd/MM/yyyy';

	const {
		procurementEntity,
		category,
		tenderStatus,
		solicitationType,
		closingDate,
		postDate,
		awardDate
	} = filterData;

	// State data.
	const referenceData = useSelector(state => state.referenceData);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (Array.isArray(referenceData.data.procurementEntityDataList)) {
				setEntityOptions(referenceData.data.procurementEntityDataList);
			}

			if (Array.isArray(referenceData.data.tenderCategoryDataList)) {
				let options = [];
				referenceData.data.tenderCategoryDataList.map(item => options.push({ value: item.name, label: item.description }));
				setCategoryOptions(options);
			}

			if (Array.isArray(referenceData.data.tenderStatusDataList)) {
				let options = [];
				referenceData.data.tenderStatusDataList.map(item => options.push({ value: item.name, label: item.description }));
				setStatusOptions(options);
			}

			if (Array.isArray(referenceData.data.solicitationTypeDataList)) {
				let options = [];
				referenceData.data.solicitationTypeDataList.map(item => options.push({ value: item.description, label: item.description }));
				setSolicitationTypeOptions(options);
			}
		}
	}, [referenceData]);

	const handleChangeFilter = (event) => {
		const { name, value } = event.target;
		let val;

		if (value !== '') {
			val = value;
		} else {
			val = null;
		}

		setFilterData(data => ({...data, [name]: val }));
	};

	const handleChangeFilterMultiple = (values, name) => {
		let val = [];

		if (Array.isArray(values)) {
			values.map(item => val.push(item.value));
		} else {
			val = null;
		}

		setFilterData(data => ({...data, [name]: val }));
	};

	const handleChangeMine = (event) => {
		const value = event.target.value;

		setMineValue(value);

		if (value == 'me') {
			setMyOrganization(null);
			setMine(true);
		} else if (value == 'organization') {
			setMyOrganization(true);
			setMine(null);
		} else {
			setMyOrganization(null);
			setMine(null);
		}
	};

	const handleChangeWatchlist = (name, value) => {
		if (value == 'yes') {
			setWatchlist(true);
		} else {
			setWatchlist(null);
		}
	};

	const handleChangeDateFilter = (name, value, type) => {
		let formattedVal;
		const parsedVal = parseISO(value);

		if (isValid(parsedVal)) {
			formattedVal = format(parsedVal, dateFormat);
		}

		if (type == 'from') {
			setFilterData(data => ({
				...data,
				[name]: {
					...data[name],
					from: formattedVal
				}
			}));
		} else if (type == 'to') {
			setFilterData(data => ({
				...data,
				[name]: {
					...data[name],
					to: formattedVal
				}
			}));
		} else {
			setFilterData(data => ({ ...data, [name]: formattedVal }));
		}
	};

	const handleSearch = (event) => {
		event.preventDefault();
		let updatedFilterData = filterData;

		if (closingDate && closingDate.from && !closingDate.to) {
			updatedFilterData = {
				...updatedFilterData,
				closingDate: {
					...updatedFilterData.closingDate,
					to: format(new Date(), dateFormat)
				}
			};
		}

		if (postDate && postDate.from && !postDate.to) {
			updatedFilterData = {
				...updatedFilterData,
				postDate: {
					...updatedFilterData.postDate,
					to: format(new Date(), dateFormat)
				}
			};
		}

		if (awardDate && awardDate.from && !awardDate.to) {
			updatedFilterData = {
				...updatedFilterData,
				awardDate: {
					...updatedFilterData.awardDate,
					to: format(new Date(), dateFormat)
				}
			};
		}

		setFilterData(updatedFilterData);

		if (props.handleFilter) {
			props.handleFilter(updatedFilterData, mine, watchlist, myOrganization);
		}
	};

	const handleReset = (event) => {
		event.preventDefault();

		if (props.handleFilter) {
			props.handleFilter(null);
		}

		setFilterData({});
		setMyOrganization(null);
		setMine(null);
		setMineValue(null);
		setWatchlist(null);
	};

	return (
		<div className="filters-box">
			<h3 className="filters-box__title"><Translate text={'Filters'} /></h3>

			<ButtonToolbar className="filters-box__top-controls justify-content-between">
				<Button
					variant="link"
					onClick={ handleSearch }
				>
					<Translate text={'Apply Filters'} />
				</Button>

				<Button
					variant="link"
					onClick={ handleReset }
				>
					<Translate text={'Reset'} />
				</Button>
			</ButtonToolbar>

			{ (isRole(userData, [ROLE_MAS, ROLE_PROCUREMENT_ADMIN, ROLE_PROCUREMENT_REGULAR, ROLE_PROCUREMENT_INTERNAL, ROLE_MAS_ACCOUNT_MANAGER])) &&
				<Form.Group controlId="tender-filterMine">
					<Form.Label><Translate text={'Created By'} /></Form.Label>
					<Form.Control
						as="select"
						value={ mineValue ? mineValue : '' }
						onChange={ handleChangeMine }
					>
						<Translator>
							{({ translate }) => (
								<>
									<option value="">
										{ translate({ text: '- Select -' }) }
									</option>
									<option value="organization">
										{ translate({ text: 'My Organization' }) }
									</option>
									<option value="me">
										{ translate({ text: 'Me' }) }
									</option>
								</>
							)}
						</Translator>
					</Form.Control>
				</Form.Group>
			}

			{ (isRole(userData, ROLE_SUPPLIER)) &&
				<Form.Group controlId={'tender-filterWatchlist'}>
					<FormSlideToggle
						id={'tender-filterWatchlist'}
						name={'tender-watchlist'}
						title="Watchlist"
						size="sm"
						value={ (watchlist) ? 'yes' : 'no' }
						options={[
							{ value: 'no', label: 'All' },
							{ value: 'yes', label: 'Watched' }
						]}
						handleOnChange={ handleChangeWatchlist }
					/>
				</Form.Group>
			}

			<Form.Group controlId="filterIssuer">
				<Form.Label><Translate text={'Organization'} /></Form.Label>
				<Form.Control
					as="select"
					name="procurementEntity"
					value={ (procurementEntity) ? procurementEntity : '' }
					disabled={ !entityOptions || !entityOptions.length || mine || myOrganization }
					onChange={ handleChangeFilter }
				>
					<Translator>
						{({ translate }) => (
							<>
								<option value="">
									{ translate({ text: '- Select -' }) }
								</option>

								{ (Array.isArray(entityOptions)) &&
									entityOptions.map((item, index) => {
										return (
											<React.Fragment key={index}>
												{ (!item.endUserEntityDataList || !item.endUserEntityDataList.length) &&
													<option value={ item.name }>
														{ item.name }
													</option>
												}

												{ (Array.isArray(item.endUserEntityDataList) && item.endUserEntityDataList.length > 0) &&
													<>
														<optgroup label={ translate({ text: '- {procurementEntity}, Departments and Partners -', data: { procurementEntity: item.name } }) }>
															{ item.endUserEntityDataList.map((item, index) => {
																return (
																	<option value={ item.name } key={index}>
																		{ item.name }
																	</option>
																);
															})}
														</optgroup>
														<option disabled>{' - '}</option>
													</>
												}
											</React.Fragment>
										);
									})
								}
							</>
						)}
					</Translator>
				</Form.Control>
			</Form.Group>

			<Form.Group>
				<FormCustomSelect
					id="filterCategory"
					name="category"
					label="Category"
					placeholder="- Select -"
					value={ (Array.isArray(category) && category.length) ? category.map(item => { return { value: item, label: item }; }) : null }
					options={ categoryOptions }
					height={44}
					isMulti={true}
					handleOnChange={(id, values) => handleChangeFilterMultiple(values, 'category')}
				/>
			</Form.Group>

			<Form.Group>
				<FormCustomSelect
					id="filterStatus"
					name="tenderStatus"
					label="Status"
					placeholder="- Select -"
					value={ (Array.isArray(tenderStatus) && tenderStatus.length) ? tenderStatus.map(item => { return { value: item, label: item }; }) : null }
					options={ statusOptions }
					height={44}
					isMulti={true}
					handleOnChange={(id, values) => handleChangeFilterMultiple(values, 'tenderStatus')}
				/>
			</Form.Group>

			<Form.Group>
				<FormCustomSelect
					id="filterSolicitationType"
					name="solicitationType"
					label="Solicitation Type"
					placeholder="- Select -"
					value={ (Array.isArray(solicitationType) && solicitationType.length) ? solicitationType.map(item => { return { value: item, label: item }; }) : null }
					options={ solicitationTypeOptions }
					height={44}
					isMulti={true}
					handleOnChange={(id, values) => handleChangeFilterMultiple(values, 'solicitationType')}
				/>
			</Form.Group>

			<Form.Group className="filter-date-range">
				<h4><Translate text={'Closing Date'} /></h4>
				<Translator>
					{({ translate }) => (
						<Row>
							<Col>
								<Form.Label htmlFor="filterFromClosingDate" aria-label={ translate({ text: 'Closing Date - From' }) }>
									<Translate text={'From'} />
								</Form.Label>
								<FormDatePicker
									id="filterFromClosingDate"
									name="fromClosingDate"
									value={(closingDate) ? closingDate.from : null}
									placeholder="false"
									maxDate={ (closingDate && closingDate.to) ? parse(closingDate.to, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('closingDate', value, 'from') }
								/>
							</Col>
							<Col>
								<Form.Label htmlFor="filterToClosingDate" aria-label={ translate({ text: 'Closing Date - To' }) }>
									<Translate text={'To'} />
								</Form.Label>
								<FormDatePicker
									id="filterToClosingDate"
									name="toClosingDate"
									value={(closingDate) ? closingDate.to : null}
									placeholder="false"
									minDate={ (closingDate && closingDate.from) ? parse(closingDate.from, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('closingDate', value, 'to') }
								/>
							</Col>
						</Row>
					)}
				</Translator>
			</Form.Group>

			<Form.Group className="filter-date-range">
				<h4><Translate text={'Posted Date'} /></h4>
				<Translator>
					{({ translate }) => (
						<Row>
							<Col>
								<Form.Label htmlFor="filterFromPostedDate" aria-label={ translate({ text: 'Posted Date - From' }) }>
									<Translate text={'From'} />
								</Form.Label>
								<FormDatePicker
									id="filterFromPostedDate"
									name="fromPostDate"
									value={(postDate) ? postDate.from : null}
									placeholder="false"
									maxDate={ (postDate && postDate.to) ? parse(postDate.to, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('postDate', value, 'from') }
								/>
							</Col>
							<Col>
								<Form.Label htmlFor="filterToPostedDate" aria-label={ translate({ text: 'Posted Date - To' }) }>
									<Translate text={'To'} />
								</Form.Label>
								<FormDatePicker
									id="filterToPostedDate"
									name="toPostDate"
									value={(postDate) ? postDate.to : null}
									placeholder="false"
									minDate={ (postDate && postDate.from) ? parse(postDate.from, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('postDate', value, 'to') }
								/>
							</Col>
						</Row>
					)}
				</Translator>
			</Form.Group>

			<Form.Group className="filter-date-range">
				<h4><Translate text={'Award Date'} /></h4>
				<Translator>
					{({ translate }) => (
						<Row>
							<Col>
								<Form.Label htmlFor="filterFromAwardDate" aria-label={ translate({ text: 'Award Date - From' }) }>
									<Translate text={'From'} />
								</Form.Label>
								<FormDatePicker
									id="filterFromAwardDate"
									name="fromAwardDate"
									value={(awardDate) ? awardDate.from : null}
									placeholder="false"
									maxDate={ (awardDate && awardDate.to) ? parse(awardDate.to, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('awardDate', value, 'from') }
								/>
							</Col>
							<Col>
								<Form.Label htmlFor="filterToAwardDate" aria-label={ translate({ text: 'Award Date - To' }) }>
									<Translate text={'To'} />
								</Form.Label>
								<FormDatePicker
									id="filterToAwardDate"
									name="toAwardDate"
									value={(awardDate) ? awardDate.to : null}
									placeholder="false"
									minDate={ (awardDate && awardDate.from) ? parse(awardDate.from, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('awardDate', value, 'to') }
								/>
							</Col>
						</Row>
					)}
				</Translator>
			</Form.Group>

			<Button onClick={ handleSearch }>
				<Translate text={'Apply Filters'} />
			</Button>
		</div>
	);
}

export default TenderFilters;
