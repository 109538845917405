import axios from 'axios';
import { authHeader } from '../helpers/authHeader';
const env = window.env;

export const geographyService = {
	get
};

function get() {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/geography`,
		headers: Object.assign({}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return {};
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
