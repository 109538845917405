/* eslint-disable id-length */
import React, { useState, useEffect } from 'react';
import { Translate, Translator } from 'react-translated';
import { useSelector } from 'react-redux';
import { format, parse, parseISO, isValid } from 'date-fns';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import FormDatePicker from '../../forms/FormDatePicker';

function UserFilters(props) {
	const {
		ROLE_NAME_MAPPING,
		ROLE_SUPPLIER,
		ROLE_NONE
	} = require('../../../config/constants');
	const [entityOptions, setEntityOptions] = useState([]);
	const [roleOptions, setRoleOptions] = useState([]);
	const [filterData, setFilterData] = useState((props.defaultFilterData) ? props.defaultFilterData : {});
	const dateFormat = 'dd/MM/yyyy';

	const {
		procurementEntity,
		userRoleEn,
		createdDate
	} = filterData;

	// State data.
	const referenceData = useSelector(state => state.referenceData);

	useEffect(() => {
		let filteredRoleOptions = [];

		Object.keys(ROLE_NAME_MAPPING).filter(key => (key !== ROLE_SUPPLIER && key !== ROLE_NONE)).map((key) => {
			return filteredRoleOptions.push({ value: key, description: ROLE_NAME_MAPPING[key] });
		});

		setRoleOptions(filteredRoleOptions);
	}, []);

	// Listen to reference data.
	useEffect(() => {
		if (referenceData && referenceData.data) {
			if (Array.isArray(referenceData.data.procurementEntityDataList)) {
				setEntityOptions(referenceData.data.procurementEntityDataList);
			}
		}
	}, [referenceData]);

	const handleChangeFilter = (event) => {
		const { name, value } = event.target;
		let val;

		if (value !== '') {
			val = value;
		} else {
			val = null;
		}

		setFilterData(data => ({...data, [name]: val }));
	};

	const handleChangeDateFilter = (name, value, type) => {
		let formattedVal;
		const parsedVal = parseISO(value);

		if (isValid(parsedVal)) {
			formattedVal = format(parsedVal, dateFormat);
		}

		if (type == 'from') {
			setFilterData(data => ({
				...data,
				[name]: {
					...data[name],
					from: formattedVal
				}
			}));
		} else if (type == 'to') {
			setFilterData(data => ({
				...data,
				[name]: {
					...data[name],
					to: formattedVal
				}
			}));
		} else {
			setFilterData(data => ({ ...data, [name]: formattedVal }));
		}
	};

	const handleSearch = (event) => {
		event.preventDefault();
		let updatedFilterData = filterData;

		if (createdDate && createdDate.from && !createdDate.to) {
			updatedFilterData = {
				...updatedFilterData,
				createdDate: {
					...updatedFilterData.createdDate,
					to: format(new Date(), dateFormat)
				}
			};
		}

		setFilterData(updatedFilterData);

		if (props.handleFilter) {
			props.handleFilter(updatedFilterData, null, null);
		}
	};

	const handleReset = (event) => {
		event.preventDefault();

		if (props.handleFilter) {
			props.handleFilter(null);
		}

		setFilterData({});
	};

	return (
		<div className="filters-box">
			<h3 className="filters-box__title"><Translate text={'Filters'} /></h3>

			<ButtonToolbar className="filters-box__top-controls justify-content-between">
				<Button
					variant="link"
					onClick={ handleSearch }
				>
					<Translate text={'Apply Filters'} />
				</Button>

				<Button
					variant="link"
					onClick={ handleReset }
				>
					<Translate text={'Reset'} />
				</Button>
			</ButtonToolbar>

			<Form.Group controlId="filterUsersOrganization">
				<Form.Label><Translate text={'Organization'} /></Form.Label>
				<Form.Control
					as="select"
					name="procurementEntity"
					value={ (procurementEntity) ? procurementEntity : '' }
					required
					disabled={ !entityOptions || !entityOptions.length }
					onChange={ handleChangeFilter }
				>
					<Translator>
						{({ translate }) => (
							<>
								<option value="">
									{ translate({ text: '- Select -' }) }
								</option>

								{ (Array.isArray(entityOptions)) &&
									entityOptions.map((item, index) => {
										return (
											<React.Fragment key={index}>
												<option value={ item.name }>
													{ item.name }
												</option>

												{ (Array.isArray(item.endUserEntityDataList) && item.endUserEntityDataList.length > 0) &&
													<>
														<optgroup label={ translate({ text: '- {procurementEntity}, Departments and Partners -', data: { procurementEntity: item.name } }) }>
															{ item.endUserEntityDataList.map((item, index) => {
																return (
																	<option value={ item.name } key={index}>
																		{ item.name }
																	</option>
																);
															})}
														</optgroup>
														<option disabled>{' - '}</option>
													</>
												}
											</React.Fragment>
										);
									})
								}
							</>
						)}
					</Translator>
				</Form.Control>
			</Form.Group>

			<Form.Group controlId="filterUsersRole">
				<Form.Label><Translate text={'User Role'} /></Form.Label>
				<Form.Control
					as="select"
					name="userRoleEn"
					value={ (userRoleEn) ? userRoleEn : '' }
					required
					disabled={ !roleOptions || !roleOptions.length }
					onChange={ handleChangeFilter }
				>
					<Translator>
						{({ translate }) => (
							<>
								<option value="">
									{ translate({ text: '- Select -' }) }
								</option>

								{ (Array.isArray(roleOptions)) &&
									roleOptions.map((item, index) => {
										return (
											<option value={ item.value } key={ index }>
												{ item.description }
											</option>
										);
									})
								}
							</>
						)}
					</Translator>
				</Form.Control>
			</Form.Group>

			<Form.Group className="filter-date-range">
				<h4><Translate text={'Date Created'} /></h4>
				<Translator>
					{({ translate }) => (
						<Row>
							<Col>
								<Form.Label htmlFor="filterUsersFromCreatedDate" aria-label={ translate({ text: 'Date Created - From' }) }>
									<Translate text={'From'} />
								</Form.Label>
								<FormDatePicker
									id="filterUsersFromCreatedDate"
									name="fromCreatedDate"
									value={(createdDate) ? createdDate.from : null}
									placeholder="false"
									maxDate={ (createdDate && createdDate.to) ? parse(createdDate.to, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('createdDate', value, 'from') }
								/>
							</Col>
							<Col>
								<Form.Label htmlFor="filterUsersToCreatedDate" aria-label={ translate({ text: 'Created Date - To' }) }>
									<Translate text={'To'} />
								</Form.Label>
								<FormDatePicker
									id="filterUsersToCreatedDate"
									name="toCreatedDate"
									value={(createdDate) ? createdDate.to : null}
									placeholder="false"
									minDate={ (createdDate && createdDate.from) ? parse(createdDate.from, dateFormat, new Date()) : null }
									handleOnChange={(value) => handleChangeDateFilter('createdDate', value, 'to') }
								/>
							</Col>
						</Row>
					)}
				</Translator>
			</Form.Group>

			<Button onClick={ handleSearch }>
				<Translate text={'Apply Filters'} />
			</Button>
		</div>
	);
}

export default UserFilters;
