import axios from 'axios';
import qs from 'qs';
import { authHeader } from '../helpers/authHeader';
const env = window.env;

export const tenderService = {
	getTenders,
	postTender,
	getUnspsc,
	getProcurementEntities,
	postWatchlist,
	getWatchlist
};

function getTenders(params, filters) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/tenders`,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader()),
		params: params,
		data: filters,
		paramsSerializer: function(params) {
			return qs.stringify(params, { encode: false });
		}
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getWatchlist() {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/user/tenders`,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader()),
		params: {watchlist: true}
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function postTender(data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/tender`,
		data: data,
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function postWatchlist(data, watch) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/user/tender`,
		data: data,
		params: {watchlist: watch},
		headers: Object.assign({'Content-Type': 'application/json'}, authHeader())
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getUnspsc(params, data) {
	const requestConfig = {
		method: 'POST',
		url: `${env.BASE_API_URL}/unspsc`,
		headers: Object.assign({}, authHeader()),
		params: params,
		data: data
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getProcurementEntities(data) {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/procuremententities`,
		headers: Object.assign({}, authHeader()),
		params: data
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return [];
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line

	return { error: 'Error: Network Error', status: (error.response && error.response.status) ? error.response.status : null };
}
