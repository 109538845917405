import { contentConstants } from '../constants/contentConstants';
import { contentService } from '../../services/contentService';

export const contentActions = {
	getPage,
	getNavigation
};

function getPage(slug) {
	return dispatch => {
		dispatch(request());

		contentService.getPage(slug)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);

	};

	function request() { return { type: contentConstants.CONTENT_PAGE_REQUEST }; }

	function success(data) { return { type: contentConstants.CONTENT_PAGE_SUCCESS, data }; }

	function error() { return { type: contentConstants.CONTENT_PAGE_ERROR }; }
}

function getNavigation(slug) {
	return dispatch => {
		dispatch(request());

		contentService.getNavigation(slug)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response));
					} else {
						dispatch(error());
					}
				}
			);

	};

	function request() { return { type: contentConstants.CONTENT_NAVIGATION_REQUEST }; }

	function success(data) { return { type: contentConstants.CONTENT_NAVIGATION_SUCCESS, data }; }

	function error() { return { type: contentConstants.CONTENT_NAVIGATION_ERROR }; }
}
