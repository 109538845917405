import { searchConstants } from '../constants/searchConstants';

export const searchActions = {
	update
};

function update(data) {
	return {
		type: searchConstants.SEARCH_UPDATE,
		category: data.category,
		fields: data.fields,
		filters: data.filters
	};
}
