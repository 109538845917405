import { userConstants } from '../constants/userConstants';

export function userGet(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_SINGLE_GET_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_SINGLE_GET_SUCCESS:
			return {
				success: true,
				user: action.user
			};
		case userConstants.USER_SINGLE_GET_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}

export function usersGet(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_GET_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_GET_SUCCESS:
			return {
				...state,
				success: true,
				[action.status]: action.users
			};
		case userConstants.USER_GET_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}

export function usersGetRpid(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_GET_RPID_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_GET_RPID_SUCCESS:
			return {
				success: true,
				user: action.user,
				callback: action.callback
			};
		case userConstants.USER_GET_RPID_ERROR:
			return {
				error: true
			};
		case userConstants.USER_GET_RPID_RESET:
			return {};
		default:
			return state;
	}
}

export function userPost(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_POST_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_POST_SUCCESS:
			return {
				success: true,
				user: action.user,
				callback: action.callback
			};
		case userConstants.USER_POST_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		case userConstants.USER_POST_RESET:
			return {};
		default:
			return state;
	}
}

export function userActivate(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_ACTIVATE_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_ACTIVATE_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case userConstants.USER_ACTIVATE_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		case userConstants.USER_ACTIVATE_RESET:
			return {};
		default:
			return state;
	}
}

export function userAuthorize(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_AUTHORIZE_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_AUTHORIZE_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case userConstants.USER_AUTHORIZE_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		case userConstants.USER_AUTHORIZE_RESET:
			return {};
		default:
			return state;
	}
}

export function userDelete(state = {}, action) {
	switch (action.type) {
		case userConstants.USER_DELETE_REQUEST:
			return {
				request: true
			};
		case userConstants.USER_DELETE_SUCCESS:
			return {
				success: true
			};
		case userConstants.USER_DELETE_ERROR:
			return {
				error: true
			};
		case userConstants.USER_DELETE_RESET:
			return {};
		default:
			return state;
	}
}

export function userUnauthenticated(state = false, action) {
	switch (action.type) {
		case userConstants.USER_UNAUTHENTICATED:
			return true;
		default:
			return state;
	}
}

export function userEditing(state = false, action) {
	switch (action.type) {
		case userConstants.USER_IS_EDITING:
			return action.isEditing;
		default:
			return state;
	}
}
