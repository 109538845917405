import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-translated';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

function Tooltip({ text, link, btnText, children, size }) {
	const buttonText = btnText || 'More info';

	const handleBtnClick = (event) => {
		event.stopPropagation();
	};

	return (
		<OverlayTrigger
			placement="top"
			trigger="click"
			rootClose
			overlay={
				<Popover>
					<Popover.Content>
						{ text &&
							<>
								{ link &&
									<a href={ link } target="_blank" rel="noopener noreferrer">
										<Translate text={ text } />
									</a>
								}

								{ !link &&
									<Translate text={ text } />
								}
							</>
						}

						{ children }
					</Popover.Content>
				</Popover>
			}
		>
			<Button
				className={`popover-btn${(size == 'sm') ? ' popover-btn--small' : ''}`}
				variant="link"
				onClick={ handleBtnClick }
			>
				<FontAwesomeIcon icon={ faInfoCircle }/>
				<span className="sr-only">
					<Translate text={ buttonText } />
				</span>
			</Button>
		</OverlayTrigger>
	);
}

export default Tooltip;
