import { geographyConstants } from '../constants/geographyConstants';

export function geography(state = {}, action) {
	switch (action.type) {
		case geographyConstants.GEO_REQUEST:
			return {
				request: true,
				init: true
			};
		case geographyConstants.GEO_SUCCESS:
			return {
				success: true,
				init: true,
				data: action.data
			};
		case geographyConstants.GEO_ERROR:
			return {
				error: true,
				init: true
			};
		default:
			return state;
	}
}
