import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Translate } from 'react-translated';

import * as globalMessagesActions from '../../store/actions/globalMessagesActions';
import { fileService } from '../../services/fileService';

import Spinner from 'react-bootstrap/Spinner';

// Icons.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFile,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFileVideo,
	faFileAudio,
	faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

function DocLink(props) {
	const {
		data,
		isLoading,
		isInline,
		customText
	} = props;
	const {
		DOC_CONTENT_TYPE_DOC,
		DOC_CONTENT_TYPE_DOCX,
		DOC_CONTENT_TYPE_PDF,
		DOC_CONTENT_TYPE_XLS,
		DOC_CONTENT_TYPE_XLSX,
		DOC_CONTENT_TYPE_MP4,
		DOC_CONTENT_TYPE_MOV,
		DOC_CONTENT_TYPE_MP3
	} = require('../../config/constants');
	const dispatch = useDispatch();

	if (!data) {
		return null;
	}

	const [isDownloading, setIsDownloading] = useState(false);
	const [showLoadingMessage, setShowLoadingMessage] = useState(false);
	let icon = faFile;
	let text;

	if (!data.guid) {
		icon = faExclamationTriangle;
	} else if ([DOC_CONTENT_TYPE_DOCX, DOC_CONTENT_TYPE_DOC].includes(data.documentContentType)) {
		icon = faFileWord;
	} else if (data.documentContentType === DOC_CONTENT_TYPE_PDF) {
		icon = faFilePdf;
	} else if ([DOC_CONTENT_TYPE_XLS, DOC_CONTENT_TYPE_XLSX].includes(data.documentContentType)) {
		icon = faFileExcel;
	} else if ([DOC_CONTENT_TYPE_MP4, DOC_CONTENT_TYPE_MOV].includes(data.documentContentType)) {
		icon = faFileVideo;
	} else if (data.documentContentType === DOC_CONTENT_TYPE_MP3) {
		icon = faFileAudio;
	}

	if (data.guid) {
		text = <a href="#" className="doc-link__link" onClick={(event) => handleFileDownload(event, data.guid)} download>{ customText ? customText : data.filename + (data.attachmentSize > 0 ? ' (' + data.attachmentSize + '  ' + data.attachmentSizeUnits + ')' : '')}</a>
	} else {
		text = ((customText) ? customText : data.filename) + (data.attachmentSize > 0 ? ' (' + data.attachmentSize + '  ' + data.attachmentSizeUnits + ')' : '');
	}

	const handleFileDownload = async (event, guid) => {
		event.preventDefault();

		if (!isDownloading) {
			setIsDownloading(true);

			await fileService.getStream(guid)
				.then(
					response => {
						if (response && !response.error) {
							if (window.navigator.msSaveBlob) {
								const blob = new Blob([response]);
								window.navigator.msSaveOrOpenBlob(blob, data.filename);
							} else {
								const blob = new Blob([response]);
								const downloadLink = document.createElement('a');
								downloadLink.href = window.URL.createObjectURL(blob);
								downloadLink.download = data.filename;
								downloadLink.click();
							}
						} else {
							dispatch(globalMessagesActions.error('Error: File could not be downloaded.'));
						}
					}
				);

			setIsDownloading(false);
			setShowLoadingMessage(false);
		}
	};

	useEffect(() => {
		setIsDownloading(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (isDownloading && !isInline) {
			window.messageTimeout = setTimeout(function() {
				setShowLoadingMessage(true);
			}, 10000);
		} else {
			clearTimeout(window.messageTimeout);
			setShowLoadingMessage(false);
		}
	}, [isDownloading]);

	return (
		<div className={`doc-link ${(isInline) ? 'doc-link--inline' : ''} ${(isDownloading) ? 'doc-link--downloading' : ''}`}>
			{ icon &&
				<span className="doc-link__icon">
					{ !isInline && !isDownloading &&
						<FontAwesomeIcon icon={ icon } />
					}

					{ isDownloading &&
						<Spinner animation="border" role="status" className="doc-link__loader" />
					}
				</span>
			}

			<span className="doc-link__text">
				{ text }

				{ showLoadingMessage &&
					<span className="doc-link__loading-message">
						<Translate text={'Download is in progress. Please wait...'} />
					</span>
				}
			</span>
		</div>
	);
}

export default DocLink;
