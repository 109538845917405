/* eslint-disable id-length */
export default {
	'Nova Scotia': {
		en: 'Nova Scotia',
		fr: 'Nova Scotia (FR)'
	},
	'Nova Scotia Procurement': {
		en: 'Nova Scotia Procurement',
		fr: 'Nova Scotia Procurement (FR)'
	},
	'{siteTitle} - Home': {
		en: '{siteTitle} - Home',
		fr: '{siteTitle} - Home (FR)'
	},
	'{siteTitle} - Not Found': {
		en: '{siteTitle} - Not Found',
		fr: '{siteTitle} - Not Found (FR)'
	},
	'Procurement Portal - Government of Nova Scotia': {
		en: 'Procurement Portal - Government of Nova Scotia',
		fr: 'Procurement Portal - Government of Nova Scotia (FR)'
	},
	'Search': {
		en: 'Search',
		fr: 'Search (FR)'
	},
	'Search Tender ID and Title': {
		en: 'Search Tender ID and Title',
		fr: 'Search Tender ID and Title (FR)'
	},
	'Search ALTP ID, Title, or Supplier': {
		en: 'Search ALTP ID, Title, or Supplier',
		fr: 'Search ALTP ID, Title, or Supplier (FR)'
	},
	'Submit search': {
		en: 'Submit search',
		fr: 'Submit search (FR)'
	},
	'Log In': {
		en: 'Log In',
		fr: 'Log In (FR)'
	},
	'Account Settings': {
		en: 'Account Settings',
		fr: 'Account Settings (FR)'
	},
	'Email Address': {
		en: 'Email Address',
		fr: 'Email Address (FR)'
	},
	'Change Account Details': {
		en: 'Change Account Details',
		fr: 'Change Account Details (FR)'
	},
	'Suppliers': {
		en: 'Suppliers',
		fr: 'Suppliers (FR)'
	},
	'Municipalities, Academic <Break>Institutions, School Boards': {
		en: 'Municipalities, Academic <Break>Institutions, School Boards',
		fr: 'Municipalities, Academic <Break>Institutions, School Boards (FR)'
	},
	'NS Health Authority': {
		en: 'NS Health Authority',
		fr: 'NS Health Authority (FR)'
	},
	'Privacy': {
		en: 'Privacy',
		fr: 'Privacy (FR)'
	},
	'Terms': {
		en: 'Terms',
		fr: 'Terms (FR)'
	},
	'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.': {
		en: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in.',
		fr: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in. (FR)'
	},
	'Crown copyright © Government of Nova Scotia': {
		en: 'Crown copyright © Government of Nova Scotia',
		fr: 'Crown copyright © Government of Nova Scotia (FR)'
	},
	'Procurement training and services portal': {
		en: 'Procurement training and services portal',
		fr: 'Procurement training and services portal (FR)'
	},
	'Previous page': {
		en: 'Previous page',
		fr: 'Previous page (FR)'
	},
	'Next page': {
		en: 'Next page',
		fr: 'Next page (FR)'
	},
	'More info': {
		en: 'More info',
		fr: 'More info (FR)'
	},
	'Close': {
		en: 'Close',
		fr: 'Close (FR)'
	},
	'Close notification': {
		en: 'Close notification',
		fr: 'Close notification (FR)'
	},
	'Yes': {
		en: 'Yes',
		fr: 'Yes (FR)'
	},
	'No': {
		en: 'No',
		fr: 'No (FR)'
	},
	'Please choose an option': {
		en: 'Please choose an option',
		fr: 'Please choose an option (FR)'
	},
	'Nova Scotia Procurement - Home': {
		en: 'Nova Scotia Procurement - Home',
		fr: 'Nova Scotia Procurement - Home (FR)'
	},
	'Training And Support For Suppliers': {
		en: 'Training And Support For Suppliers',
		fr: 'Training And Support For Suppliers (FR)'
	},
	'Login/Create Account': {
		en: 'Login/Create Account',
		fr: 'Login/Create Account (FR)'
	},
	'Enter As Municipality, Academic Institution, or School Board Employee': {
		en: 'Enter As Municipality, Academic Institution, or School Board Employee',
		fr: 'Enter As Municipality, Academic Institution, or School Board Employee (FR)'
	},
	'Tender Opportunities': {
		en: 'Tender Opportunities',
		fr: 'Tender Opportunities (FR)'
	},
	'Category': {
		en: 'Category',
		fr: 'Category (FR)'
	},
	'- Select -': {
		en: '- Select -',
		fr: '- Select - (FR)'
	},
	'New Opportunities': {
		en: 'New Opportunities',
		fr: 'New Opportunities (FR)'
	},
	'No Existing Opportunities': {
		en: 'No Existing Opportunities',
		fr: 'No Existing Opportunities (FR)'
	},
	'See All New Opportunities': {
		en: 'See All New Opportunities',
		fr: 'See All New Opportunities (FR)'
	},
	'See All Ending Opportunities': {
		en: 'See All Ending Opportunities',
		fr: 'See All Ending Opportunities (FR)'
	},
	'No Existing Tenders': {
		en: 'No Existing Tenders',
		fr: 'No Existing Tenders (FR)'
	},
	'Error loading tenders': {
		en: 'Error loading tenders',
		fr: 'Error loading tenders (FR)'
	},
	'Error loading standing offers': {
		en: 'Error loading standing offers',
		fr: 'Error loading standing offers (FR)'
	},
	'No opportunities matched your search. Try using filters.': {
		en: 'No opportunities matched your search. Try using filters.',
		fr: 'No opportunities matched your search. Try using filters. (FR)'
	},
	'Tenders Ending Soon': {
		en: 'Tenders Ending Soon',
		fr: 'Tenders Ending Soon (FR)'
	},
	'Date Ending: ': {
		en: 'Date Ending: ',
		fr: 'Date Ending:  (FR)'
	},
	'Current Standing Offers': {
		en: 'Current Standing Offers',
		fr: 'Current Standing Offers (FR)'
	},
	'See All Current Standing Offers': {
		en: 'See All Current Standing Offers',
		fr: 'See All Current Standing Offers (FR)'
	},
	'No Current Standing Offers': {
		en: 'No Current Standing Offers',
		fr: 'No Current Standing Offers (FR)'
	},
	'Sign Up For Notifications': {
		en: 'Sign Up For Notifications',
		fr: 'Sign Up For Notifications (FR)'
	},
	'Get Notifications': {
		en: 'Get Notifications',
		fr: 'Get Notifications (FR)'
	},
	'Business Email': {
		en: 'Business Email',
		fr: 'Business Email (FR)'
	},
	'Your Industry (1)': {
		en: 'Your Industry (1)',
		fr: 'Your Industry (1) (FR)'
	},
	'Your Industry (2)': {
		en: 'Your Industry (2)',
		fr: 'Your Industry (2) (FR)'
	},
	'Your Industry (3)': {
		en: 'Your Industry (3)',
		fr: 'Your Industry (3) (FR)'
	},
	'Email Us Now': {
		en: 'Email Us Now',
		fr: 'Email Us Now (FR)'
	},
	'Notification Signup': {
		en: 'Notification Signup',
		fr: 'Notification Signup (FR)'
	},
	'Successfully subscribed to notifications.': {
		en: 'Successfully subscribed to notifications.',
		fr: 'Successfully subscribed to notifications. (FR)'
	},
	'Error subscribing to notifications. Please try again.': {
		en: 'Error subscribing to notifications. Please try again.',
		fr: 'Error subscribing to notifications. Please try again. (FR)'
	},
	'First Name is required.': {
		en: 'First Name is required.',
		fr: 'First Name is required. (FR)'
	},
	'First Name can not be less than 2 characters': {
		en: 'First Name can not be less than 2 characters',
		fr: 'First Name can not be less than 2 characters (FR)'
	},
	'Last Name is required.': {
		en: 'Last Name is required.',
		fr: 'Last Name is required. (FR)'
	},
	'Last Name can not be less than 2 characters': {
		en: 'Last Name can not be less than 2 characters',
		fr: 'Last Name can not be less than 2 characters (FR)'
	},
	'Company Name is required.': {
		en: 'Company Name is required.',
		fr: 'Company Name is required. (FR)'
	},
	'Your Industry (1) is required.': {
		en: 'Your Industry (1) is required.',
		fr: 'Your Industry (1) is required. (FR)'
	},
	'Enter an email address': {
		en: 'Enter an email address',
		fr: 'Enter an email address (FR)'
	},
	'Enter an email address in the correct format, like name@example.com': {
		en: 'Enter an email address in the correct format, like name@example.com',
		fr: 'Enter an email address in the correct format, like name@example.com (FR)'
	},
	'Email address does not match': {
		en: 'Email address does not match',
		fr: 'Email address does not match (FR)'
	},
	'See All Opportunities': {
		en: 'See All Opportunities',
		fr: 'See All Opportunities (FR)'
	},
	'procure@novascotia.ca': {
		en: 'procure@novascotia.ca',
		fr: 'procure@novascotia.ca (FR)'
	},
	'1-902-424-3333': {
		en: '1-902-424-3333',
		fr: '1-902-424-3333 (FR)'
	},
	'Search Opportunities': {
		en: 'Search Opportunities',
		fr: 'Search Opportunities (FR)'
	},
	'Dashboard': {
		en: 'Dashboard',
		fr: 'Dashboard (FR)'
	},
	'Public Notices and Standing Offer Opportunities': {
		en: 'Public Notices and Standing Offer Opportunities',
		fr: 'Public Notices and Standing Offer Opportunities (FR)'
	},
	'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers.  For access to additional features and functions, click “Log In” above.': {
		en: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers.  For access to additional features and functions, click “Log In” above.',
		fr: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers.  For access to additional features and functions, click “Log In” above. (FR)'
	},
	'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts available to broader Public Sector Entities.': {
		en: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts available to broader Public Sector Entities.',
		fr: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Notices, or current Standing Offer Contracts available to broader Public Sector Entities. (FR)'
	},
	'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Award Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers. Click on the “Edit Notification Preferences” button to adjust your selections for the Tender Opportunity Notification Service (TONS) and to enable/disable the service.': {
		en: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Award Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers. Click on the “Edit Notification Preferences” button to adjust your selections for the Tender Opportunity Notification Service (TONS) and to enable/disable the service.',
		fr: 'Select one of the tabs below to view Public Tender Notices, Alternative Procurement Award Notices, or current Standing Offer Contracts with on-going onboarding of Suppliers. Click on the “Edit Notification Preferences” button to adjust your selections for the Tender Opportunity Notification Service (TONS) and to enable/disable the service. (FR)'
	},
	'All Nova Scotia tender notices are posted below.': {
		en: 'All Nova Scotia tender notices are posted below.',
		fr: 'All Nova Scotia tender notices are posted below. (FR)'
	},
	'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols.  Alternative Procurement award notices are posted below.': {
		en: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols.  Alternative Procurement award notices are posted below.',
		fr: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols.  Alternative Procurement award notices are posted below. (FR)'
	},
	'The standing offer contracts displayed below offer on-going onboarding opportunities for Suppliers.': {
		en: 'The standing offer contracts displayed below offer on-going onboarding opportunities for Suppliers.',
		fr: 'The standing offer contracts displayed below offer on-going onboarding opportunities for Suppliers. (FR)'
	},
	'All Nova Scotia tender notices are posted below. Use the “Created By” filter option and click “Apply Filters” to display only tender notices created by your organization or only those created by you. Click on the Tender ID to view notice details.': {
		en: 'All Nova Scotia tender notices are posted below. Use the “Created By” filter option and click “Apply Filters” to display only tender notices created by your organization or only those created by you. Click on the Tender ID to view notice details.',
		fr: 'All Nova Scotia tender notices are posted below. Use the “Created By” filter option and click “Apply Filters” to display only tender notices created by your organization or only those created by you. Click on the Tender ID to view notice details. (FR)'
	},
	'All Nova Scotia tender notices are posted below. Use the filters to help find tender notices of interest to you. Add a specific tender notice to your “Watchlist” to receive a notification email when something related to that opportunity changes. Click on the Tender ID to see Notice Details, including how to access tender documents.': {
		en: 'All Nova Scotia tender notices are posted below. Use the filters to help find tender notices of interest to you. Add a specific tender notice to your “Watchlist” to receive a notification email when something related to that opportunity changes. Click on the Tender ID to see Notice Details, including how to access tender documents.',
		fr: 'All Nova Scotia tender notices are posted below. Use the filters to help find tender notices of interest to you. Add a specific tender notice to your “Watchlist” to receive a notification email when something related to that opportunity changes. Click on the Tender ID to see Notice Details, including how to access tender documents. (FR)'
	},
	'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.': {
		en: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.',
		fr: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below. (FR)'
	},
	'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used by public sector entities to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.': {
		en: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used by public sector entities to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below.',
		fr: 'When urgent, specialized or exceptional circumstances are encountered, alternative procurement practices may be used by public sector entities to purchase goods, services or construction in alignment with provincial protocols. Alternative Procurement (ALTP) award notices are posted below. (FR)'
	},
	'The standing offers listed below may be available for broader Public Sector Entity use. Email the Procurement Specialist listed as the Contact with inquiries about purchasing off of any specific contract. Be sure to include the Contract Name and Ariba Contract ID with your email inquiry.': {
		en: 'The standing offers listed below may be available for broader Public Sector Entity use. Email the Procurement Specialist listed as the Contact with inquiries about purchasing off of any specific contract. Be sure to include the Contract Name and Ariba Contract ID with your email inquiry.',
		fr: 'The standing offers listed below may be available for broader Public Sector Entity use. Email the Procurement Specialist listed as the Contact with inquiries about purchasing off of any specific contract. Be sure to include the Contract Name and Ariba Contract ID with your email inquiry. (FR)'
	},
	'Post a Tender': {
		en: 'Post a Tender',
		fr: 'Post a Tender (FR)'
	},
	'Edit Tender': {
		en: 'Edit Tender',
		fr: 'Edit Tender (FR)'
	},
	'Need Help?': {
		en: 'Need Help?',
		fr: 'Need Help? (FR)'
	},
	'Tenders': {
		en: 'Tenders',
		fr: 'Tenders (FR)'
	},
	'Standing Offers': {
		en: 'Standing Offers',
		fr: 'Standing Offers (FR)'
	},
	'Standing Offer Contracts': {
		en: 'Standing Offer Contracts',
		fr: 'Standing Offer Contracts (FR)'
	},
	'Choose File': {
		en: 'Choose File',
		fr: 'Choose File (FR)'
	},
	'Choose File - {type} #{number}': {
		en: 'Choose File - {type} #{number}',
		fr: 'Choose File - {type} #{number} (FR)'
	},
	'Choose File #{number}': {
		en: 'Choose File #{number}',
		fr: 'Choose File #{number} (FR)'
	},
	'Remove': {
		en: 'Remove',
		fr: 'Remove (FR)'
	},
	'Remove - {type} #{number}': {
		en: 'Remove - {type} #{number}',
		fr: 'Remove - {type} #{number} (FR)'
	},
	'Remove #{number}': {
		en: 'Remove #{number}',
		fr: 'Remove #{number} (FR)'
	},
	'Tender successfully saved': {
		en: 'Tender successfully saved',
		fr: 'Tender successfully saved (FR)'
	},
	'Tender successfully edited': {
		en: 'Tender successfully edited',
		fr: 'Tender successfully edited (FR)'
	},
	'Tender draft successfully saved': {
		en: 'Tender draft successfully saved',
		fr: 'Tender draft successfully saved (FR)'
	},
	'Error: Tender could not be submitted. Please try again later.': {
		en: 'Error: Tender could not be submitted. Please try again later.',
		fr: 'Error: Tender could not be submitted. Please try again later. (FR)'
	},
	'Error: Tender could not be edited. Please try again later.': {
		en: 'Error: Tender could not be edited. Please try again later.',
		fr: 'Error: Tender could not be edited. Please try again later. (FR)'
	},
	'Error: Tender draft could not be saved. Please try again later.': {
		en: 'Error: Tender draft could not be saved. Please try again later.',
		fr: 'Error: Tender draft could not be saved. Please try again later. (FR)'
	},
	'This tender is currently saved as draft.': {
		en: 'This tender is currently saved as draft.',
		fr: 'This tender is currently saved as draft. (FR)'
	},
	'View All Tenders': {
		en: 'View All Tenders',
		fr: 'View All Tenders (FR)'
	},
	'Closing Date has passed, editing is limited.': {
		en: 'Closing Date has passed, editing is limited.',
		fr: 'Closing Date has passed, editing is limited. (FR)'
	},
	'Categorization': {
		en: 'Categorization',
		fr: 'Categorization (FR)'
	},
	'Tender ID': {
		en: 'Tender ID',
		fr: 'Tender ID (FR)'
	},
	'Solicitation Type': {
		en: 'Solicitation Type',
		fr: 'Solicitation Type (FR)'
	},
	'Title': {
		en: 'Title',
		fr: 'Title (FR)'
	},
	'Tender Title': {
		en: 'Tender Title',
		fr: 'Tender Title (FR)'
	},
	'Procurement Method': {
		en: 'Procurement Method',
		fr: 'Procurement Method (FR)'
	},
	'Commodity Level 1': {
		en: 'Commodity Level 1',
		fr: 'Commodity Level 1 (FR)'
	},
	'Commodity Level 2': {
		en: 'Commodity Level 2',
		fr: 'Commodity Level 2 (FR)'
	},
	'Commodity Level 3': {
		en: 'Commodity Level 3',
		fr: 'Commodity Level 3 (FR)'
	},
	'Commodity Level 4': {
		en: 'Commodity Level 4',
		fr: 'Commodity Level 4 (FR)'
	},
	'Remove - Commodity #{number}': {
		en: 'Remove - Commodity #{number}',
		fr: 'Remove - Commodity #{number} (FR)'
	},
	'Issuer Information': {
		en: 'Issuer Information',
		fr: 'Issuer Information (FR)'
	},
	'Procurement Entity': {
		en: 'Procurement Entity',
		fr: 'Procurement Entity (FR)'
	},
	'Contact Name': {
		en: 'Contact Name',
		fr: 'Contact Name (FR)'
	},
	'Contact Method': {
		en: 'Contact Method',
		fr: 'Contact Method (FR)'
	},
	'Email': {
		en: 'Email',
		fr: 'Email (FR)'
	},
	'Procurement Entity Contact Email': {
		en: 'Procurement Entity Contact Email',
		fr: 'Procurement Entity Contact Email (FR)'
	},
	'Phone Number': {
		en: 'Phone Number',
		fr: 'Phone Number (FR)'
	},
	'Contact Phone Number': {
		en: 'Contact Phone Number',
		fr: 'Contact Phone Number (FR)'
	},
	'Procurement Entity Contact Phone': {
		en: 'Procurement Entity Contact Phone',
		fr: 'Procurement Entity Contact Phone (FR)'
	},
	'{label} is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.': {
		en: '{label} is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.',
		fr: '{label} is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols. (FR)'
	},
	'Contact Details': {
		en: 'Contact Details',
		fr: 'Contact Details (FR)'
	},
	'End User Entity': {
		en: 'End User Entity',
		fr: 'End User Entity (FR)'
	},
	'Entity goods/services being procured for (i.e. Department of Community Services; Nova Scotia Health Authority, etc.)': {
		en: 'Entity goods/services being procured for (i.e. Department of Community Services; Nova Scotia Health Authority, etc.)',
		fr: 'Entity goods/services being procured for (i.e. Department of Community Services; Nova Scotia Health Authority, etc.) (FR)'
	},
	'Trade Agreement': {
		en: 'Trade Agreement',
		fr: 'Trade Agreement (FR)'
	},
	'Trade Agreement (Select all that apply)': {
		en: 'Trade Agreement (Select all that apply)',
		fr: 'Trade Agreement (Select all that apply) (FR)'
	},
	'Tender Information': {
		en: 'Tender Information',
		fr: 'Tender Information (FR)'
	},
	'Closing Date': {
		en: 'Closing Date',
		fr: 'Closing Date (FR)'
	},
	'Closing Time': {
		en: 'Closing Time',
		fr: 'Closing Time (FR)'
	},
	'Closing Time (Atlantic Time)': {
		en: 'Closing Time (Atlantic Time)',
		fr: 'Closing Time (Atlantic Time) (FR)'
	},
	'Closing Time Date Format': {
		en: 'Closing Time Date Format',
		fr: 'Closing Time Date Format (FR)'
	},
	'12-hour clock': {
		en: '12-hour clock',
		fr: '12-hour clock (FR)'
	},
	'24-hour clock': {
		en: '24-hour clock',
		fr: '24-hour clock (FR)'
	},
	'Closing Date & Time': {
		en: 'Closing Date & Time',
		fr: 'Closing Date & Time (FR)'
	},
	'(Atlantic Time)': {
		en: '(Atlantic Time)',
		fr: '(Atlantic Time) (FR)'
	},
	'Closing Location': {
		en: 'Closing Location',
		fr: 'Closing Location (FR)'
	},
	'Closing Location Other': {
		en: 'Closing Location Other',
		fr: 'Closing Location Other (FR)'
	},
	'Public Opening Date (Optional)': {
		en: 'Public Opening Date (Optional)',
		fr: 'Public Opening Date (Optional) (FR)'
	},
	'Public Opening Time (Optional)': {
		en: 'Public Opening Time (Optional)',
		fr: 'Public Opening Time (Optional) (FR)'
	},
	'Public Opening Location (Optional)': {
		en: 'Public Opening Location (Optional)',
		fr: 'Public Opening Location (Optional) (FR)'
	},
	'All times in AST': {
		en: 'All times in AST',
		fr: 'All times in AST (FR)'
	},
	'Submission Language': {
		en: 'Submission Language',
		fr: 'Submission Language (FR)'
	},
	'Pick Up Fee': {
		en: 'Pick Up Fee',
		fr: 'Pick Up Fee (FR)'
	},
	'Pick Up Fee Terms of Payment': {
		en: 'Pick Up Fee Terms of Payment',
		fr: 'Pick Up Fee Terms of Payment (FR)'
	},
	'Description': {
		en: 'Description',
		fr: 'Description (FR)'
	},
	'Expected Duration of Contract': {
		en: 'Expected Duration of Contract',
		fr: 'Expected Duration of Contract (FR)'
	},
	'Estimated Duration of Contract (months)': {
		en: 'Estimated Duration of Contract (months)',
		fr: 'Estimated Duration of Contract (months) (FR)'
	},
	'Estimated # of months contract is expected to be in force': {
		en: 'Estimated # of months contract is expected to be in force',
		fr: 'Estimated # of months contract is expected to be in force (FR)'
	},
	'Were sustainable procurement criteria used for this purchase?': {
		en: 'Were sustainable procurement criteria used for this purchase?',
		fr: 'Were sustainable procurement criteria used for this purchase? (FR)'
	},
	'Primary source of sustainable procurement criteria used': {
		en: 'Primary source of sustainable procurement criteria used',
		fr: 'Primary source of sustainable procurement criteria used (FR)'
	},
	'Primary reason for not using sustainable procurement criteria': {
		en: 'Primary reason for not using sustainable procurement criteria',
		fr: 'Primary reason for not using sustainable procurement criteria (FR)'
	},
	'Addendum Documents': {
		en: 'Addendum Documents',
		fr: 'Addendum Documents (FR)'
	},
	'Addendum Notes': {
		en: 'Addendum Notes',
		fr: 'Addendum Notes (FR)'
	},
	'Addendum Documents / Notes': {
		en: 'Addendum Documents / Notes',
		fr: 'Addendum Documents / Notes (FR)'
	},
	'Notes': {
		en: 'Notes',
		fr: 'Notes (FR)'
	},
	'Document Location URL': {
		en: 'Document Location URL',
		fr: 'Document Location URL (FR)'
	},
	'Memo': {
		en: 'Memo',
		fr: 'Memo (FR)'
	},
	'Memorandum': {
		en: 'Memorandum',
		fr: 'Memorandum (FR)'
	},
	'Tender Post Date': {
		en: 'Tender Post Date',
		fr: 'Tender Post Date (FR)'
	},
	'Unless a future date is specified, selecting the “Publish Notice” button below will publish the tender immediately.': {
		en: 'Unless a future date is specified, selecting the “Publish Notice” button below will publish the tender immediately.',
		fr: 'Unless a future date is specified, selecting the “Publish Notice” button below will publish the tender immediately. (FR)'
	},
	'Submit': {
		en: 'Submit',
		fr: 'Submit (FR)'
	},
	'{formLabel} is required': {
		en: '{formLabel} is required',
		fr: '{formLabel} is required (FR)'
	},
	'Form contains errors. Please fill out all required fields.': {
		en: 'Form contains errors. Please fill out all required fields.',
		fr: 'Form contains errors. Please fill out all required fields. (FR)'
	},
	'Add Another': {
		en: 'Add Another',
		fr: 'Add Another (FR)'
	},
	'Add Another - {type}': {
		en: 'Add Another - {type}',
		fr: 'Add Another - {type} (FR)'
	},
	'Alternative Procurement': {
		en: 'Alternative Procurement',
		fr: 'Alternative Procurement (FR)'
	},
	'Successfully deleted alternative procurement notice.': {
		en: 'Successfully deleted alternative procurement notice.',
		fr: 'Successfully deleted alternative procurement notice. (FR)'
	},
	'Error deleting alternative procurement notice. Please try again.': {
		en: 'Error deleting alternative procurement notice. Please try again.',
		fr: 'Error deleting alternative procurement notice. Please try again. (FR)'
	},
	'Sign Up For Email Notifications': {
		en: 'Sign Up For Email Notifications',
		fr: 'Sign Up For Email Notifications (FR)'
	},
	'Create an Alternative Procurement Notice': {
		en: 'Create an Alternative Procurement Notice',
		fr: 'Create an Alternative Procurement Notice (FR)'
	},
	'Create an ALTP Notice': {
		en: 'Create an ALTP Notice',
		fr: 'Create an ALTP Notice (FR)'
	},
	'Edit an Alternative Procurement Notice': {
		en: 'Edit an Alternative Procurement Notice',
		fr: 'Edit an Alternative Procurement Notice (FR)'
	},
	'Delete this Alternative Procurement Notice?': {
		en: 'Delete this Alternative Procurement Notice?',
		fr: 'Delete this Alternative Procurement Notice? (FR)'
	},
	'Return to ALTPs': {
		en: 'Return to ALTPs',
		fr: 'Return to ALTPs (FR)'
	},
	'Sort': {
		en: 'Sort',
		fr: 'Sort (FR)'
	},
	'Posted Date (ASC)': {
		en: 'Posted Date (ASC)',
		fr: 'Posted Date (ASC) (FR)'
	},
	'Posted Date (DESC)': {
		en: 'Posted Date (DESC)',
		fr: 'Posted Date (DESC) (FR)'
	},
	'Closing Date (ASC)': {
		en: 'Closing Date (ASC)',
		fr: 'Closing Date (ASC) (FR)'
	},
	'Closing Date (DESC)': {
		en: 'Closing Date (DESC)',
		fr: 'Closing Date (DESC) (FR)'
	},
	'Date Created (ASC)': {
		en: 'Date Created (ASC)',
		fr: 'Date Created (ASC) (FR)'
	},
	'Date Created (DESC)': {
		en: 'Date Created (DESC)',
		fr: 'Date Created (DESC) (FR)'
	},
	'Date Modified (ASC)': {
		en: 'Date Modified (ASC)',
		fr: 'Date Modified (ASC) (FR)'
	},
	'Date Modified (DESC)': {
		en: 'Date Modified (DESC)',
		fr: 'Date Modified (DESC) (FR)'
	},
	'Date Modified (most recent)': {
		en: 'Date Modified (most recent)',
		fr: 'Date Modified (most recent) (FR)'
	},
	'ID': {
		en: 'ID',
		fr: 'ID (FR)'
	},
	'ST': {
		en: 'ST',
		fr: 'ST (FR)'
	},
	'UNSPSC': {
		en: 'UNSPSC',
		fr: 'UNSPSC (FR)'
	},
	'Issuer': {
		en: 'Issuer',
		fr: 'Issuer (FR)'
	},
	'Organization': {
		en: 'Organization',
		fr: 'Organization (FR)'
	},
	'Posted': {
		en: 'Posted',
		fr: 'Posted (FR)'
	},
	'Posted Date': {
		en: 'Posted Date',
		fr: 'Posted Date (FR)'
	},
	'Date Posted': {
		en: 'Date Posted',
		fr: 'Date Posted (FR)'
	},
	'Closes': {
		en: 'Closes',
		fr: 'Closes (FR)'
	},
	'Date Closes': {
		en: 'Date Closes',
		fr: 'Date Closes (FR)'
	},
	'Status': {
		en: 'Status',
		fr: 'Status (FR)'
	},
	'Vendor': {
		en: 'Vendor',
		fr: 'Vendor (FR)'
	},
	'Award Date': {
		en: 'Award Date',
		fr: 'Award Date (FR)'
	},
	'Awarded Amount': {
		en: 'Awarded Amount',
		fr: 'Awarded Amount (FR)'
	},
	'Awarded Date': {
		en: 'Awarded Date',
		fr: 'Awarded Date (FR)'
	},
	'Awarded Vendor': {
		en: 'Awarded Vendor',
		fr: 'Awarded Vendor (FR)'
	},
	'Awarded Memo': {
		en: 'Awarded Memo',
		fr: 'Awarded Memo (FR)'
	},
	'Bid Amount': {
		en: 'Bid Amount',
		fr: 'Bid Amount (FR)'
	},
	'Controls': {
		en: 'Controls',
		fr: 'Controls (FR)'
	},
	'Edit': {
		en: 'Edit',
		fr: 'Edit (FR)'
	},
	'Alternative Procurement Notices': {
		en: 'Alternative Procurement Notices',
		fr: 'Alternative Procurement Notices (FR)'
	},
	'No Alternative Procurement notices available.': {
		en: 'No Alternative Procurement notices available.',
		fr: 'No Alternative Procurement notices available. (FR)'
	},
	'No opportunities matched your search. Try using different filters.': {
		en: 'No opportunities matched your search. Try using different filters.',
		fr: 'No opportunities matched your search. Try using different filters. (FR)'
	},
	'or': {
		en: 'or',
		fr: 'or (FR)'
	},
	'Call Us': {
		en: 'Call Us',
		fr: 'Call Us (FR)'
	},
	'Successfully created a new alternative procurement notice.': {
		en: 'Successfully created a new alternative procurement notice.',
		fr: 'Successfully created a new alternative procurement notice. (FR)'
	},
	'Successfully edited the alternative procurement notice.': {
		en: 'Successfully edited the alternative procurement notice.',
		fr: 'Successfully edited the alternative procurement notice. (FR)'
	},
	'Error creating a new alternative procurement notice. Please try again.': {
		en: 'Error creating a new alternative procurement notice. Please try again.',
		fr: 'Error creating a new alternative procurement notice. Please try again. (FR)'
	},
	'Error editing the alternative procurement notice. Please try again.': {
		en: 'Error editing the alternative procurement notice. Please try again.',
		fr: 'Error editing the alternative procurement notice. Please try again. (FR)'
	},
	'Post an Alternative Procurement Notice': {
		en: 'Post an Alternative Procurement Notice',
		fr: 'Post an Alternative Procurement Notice (FR)'
	},
	'ALTP ID': {
		en: 'ALTP ID',
		fr: 'ALTP ID (FR)'
	},
	'ALTP ID is required.': {
		en: 'ALTP ID is required.',
		fr: 'ALTP ID is required. (FR)'
	},
	'Project ID': {
		en: 'Project ID',
		fr: 'Project ID (FR)'
	},
	'Project ID is required.': {
		en: 'Project ID is required.',
		fr: 'Project ID is required. (FR)'
	},
	'Title is required.': {
		en: 'Title is required.',
		fr: 'Title is required. (FR)'
	},
	'Additional Details': {
		en: 'Additional Details',
		fr: 'Additional Details (FR)'
	},
	'Additional Details is required.': {
		en: 'Additional Details is required.',
		fr: 'Additional Details is required. (FR)'
	},
	'Use this space to include any overall or additional information about the tender award. If a tender is NOT awarded and is cancelled after the closing date, please ensure the reason(s) for cancelling without award are entered here.': {
		en: 'Use this space to include any overall or additional information about the tender award. If a tender is NOT awarded and is cancelled after the closing date, please ensure the reason(s) for cancelling without award are entered here.',
		fr: 'Use this space to include any overall or additional information about the tender award. If a tender is NOT awarded and is cancelled after the closing date, please ensure the reason(s) for cancelling without award are entered here. (FR)'
	},
	'Award Memo': {
		en: 'Award Memo',
		fr: 'Award Memo (FR)'
	},
	'Agency/Department': {
		en: 'Agency/Department',
		fr: 'Agency/Department (FR)'
	},
	'Agency/Department is required.': {
		en: 'Agency/Department is required.',
		fr: 'Agency/Department is required. (FR)'
	},
	'Division': {
		en: 'Division',
		fr: 'Division (FR)'
	},
	'Division is required.': {
		en: 'Division is required.',
		fr: 'Division is required. (FR)'
	},
	'Awarded Amount is required.': {
		en: 'Awarded Amount is required.',
		fr: 'Awarded Amount is required. (FR)'
	},
	'Award Date is required.': {
		en: 'Award Date is required.',
		fr: 'Award Date is required. (FR)'
	},
	'Vendor is required.': {
		en: 'Vendor is required.',
		fr: 'Vendor is required. (FR)'
	},
	'Vendor Contact Information': {
		en: 'Vendor Contact Information',
		fr: 'Vendor Contact Information (FR)'
	},
	'Back to List': {
		en: 'Back to List',
		fr: 'Back to List (FR)'
	},
	'Edit My Tender': {
		en: 'Edit My Tender',
		fr: 'Edit My Tender (FR)'
	},
	'Cancel Tender': {
		en: 'Cancel Tender',
		fr: 'Cancel Tender (FR)'
	},
	'Cancel this tender?': {
		en: 'Cancel this tender?',
		fr: 'Cancel this tender? (FR)'
	},
	'If cancelling a notice with an *Open* status, please add the cancellation reason to the *Addendum Notes* field.': {
		en: 'If cancelling a notice with an *Open* status, please add the cancellation reason to the *Addendum Notes* field.',
		fr: 'If cancelling a notice with an *Open* status, please add the cancellation reason to the *Addendum Notes* field. (FR)'
	},
	'If cancelling a notice with an *Expired* status, please add the cancellation reason to the *Award Memo* field.': {
		en: 'If cancelling a notice with an *Expired* status, please add the cancellation reason to the *Award Memo* field.',
		fr: 'If cancelling a notice with an *Expired* status, please add the cancellation reason to the *Award Memo* field. (FR)'
	},
	'Yes, Cancel': {
		en: 'Yes, Cancel',
		fr: 'Yes, Cancel (FR)'
	},
	'Delete Tender': {
		en: 'Delete Tender',
		fr: 'Delete Tender (FR)'
	},
	'Delete this tender? This action cannot be reversed.': {
		en: 'Delete this tender? This action cannot be reversed.',
		fr: 'Delete this tender? This action cannot be reversed. (FR)'
	},
	'Yes, Delete': {
		en: 'Yes, Delete',
		fr: 'Yes, Delete (FR)'
	},
	'Back to Tender Detail': {
		en: 'Back to Tender Detail',
		fr: 'Back to Tender Detail (FR)'
	},
	'Back to Tenders': {
		en: 'Back to Tenders',
		fr: 'Back to Tenders (FR)'
	},
	'Tender successfully cancelled': {
		en: 'Tender successfully cancelled',
		fr: 'Tender successfully cancelled (FR)'
	},
	'Error: Tender could not be cancelled. Please try again later.': {
		en: 'Error: Tender could not be cancelled. Please try again later.',
		fr: 'Error: Tender could not be cancelled. Please try again later. (FR)'
	},
	'Tender successfully deleted': {
		en: 'Tender successfully deleted',
		fr: 'Tender successfully deleted (FR)'
	},
	'Error: Tender could not be deleted. Please try again later.': {
		en: 'Error: Tender could not be deleted. Please try again later.',
		fr: 'Error: Tender could not be deleted. Please try again later. (FR)'
	},
	'Commodities (UNSPSC) Code and Description': {
		en: 'Commodities (UNSPSC) Code and Description',
		fr: 'Commodities (UNSPSC) Code and Description (FR)'
	},
	'Contact Info': {
		en: 'Contact Info',
		fr: 'Contact Info (FR)'
	},
	'Additional Contact Info': {
		en: 'Additional Contact Info',
		fr: 'Additional Contact Info (FR)'
	},
	'Tender Documents': {
		en: 'Tender Documents',
		fr: 'Tender Documents (FR)'
	},
	'Tender Documents<Req>': {
		en: 'Tender Documents<Req>',
		fr: 'Tender Documents<Req> (FR)'
	},
	'Opening Location': {
		en: 'Opening Location',
		fr: 'Opening Location (FR)'
	},
	'Opening Date': {
		en: 'Opening Date',
		fr: 'Opening Date (FR)'
	},
	'Opening Date & Time': {
		en: 'Opening Date & Time',
		fr: 'Opening Date & Time (FR)'
	},
	'Filters': {
		en: 'Filters',
		fr: 'Filters (FR)'
	},
	'- {procurementEntity} - End User Entities -': {
		en: '- {procurementEntity} - End User Entities -',
		fr: '- {procurementEntity} - End User Entities - (FR)'
	},
	'Error: File could not be downloaded.': {
		en: 'Error: File could not be downloaded.',
		fr: 'Error: File could not be downloaded. (FR)'
	},
	'Or': {
		en: 'Or',
		fr: 'Or (FR)'
	},
	'Apply Filters': {
		en: 'Apply Filters',
		fr: 'Apply Filters (FR)'
	},
	'Reset': {
		en: 'Reset',
		fr: 'Reset (FR)'
	},
	'Created By': {
		en: 'Created By',
		fr: 'Created By (FR)'
	},
	'Anyone': {
		en: 'Anyone',
		fr: 'Anyone (FR)'
	},
	'My Organization': {
		en: 'My Organization',
		fr: 'My Organization (FR)'
	},
	'Me': {
		en: 'Me',
		fr: 'Me (FR)'
	},
	'Department': {
		en: 'Department',
		fr: 'Department (FR)'
	},
	'Circumstance': {
		en: 'Circumstance',
		fr: 'Circumstance (FR)'
	},
	'Award Duration': {
		en: 'Award Duration',
		fr: 'Award Duration (FR)'
	},
	'Award Date Range': {
		en: 'Award Date Range',
		fr: 'Award Date Range (FR)'
	},
	'Awarded Today': {
		en: 'Awarded Today',
		fr: 'Awarded Today (FR)'
	},
	'Awarded Last 7 Days': {
		en: 'Awarded Last 7 Days',
		fr: 'Awarded Last 7 Days (FR)'
	},
	'Awarded Last 14 Days': {
		en: 'Awarded Last 14 Days',
		fr: 'Awarded Last 14 Days (FR)'
	},
	'Awarded Last 30 Days': {
		en: 'Awarded Last 30 Days',
		fr: 'Awarded Last 30 Days (FR)'
	},
	'Awarded Current Year': {
		en: 'Awarded Current Year',
		fr: 'Awarded Current Year (FR)'
	},
	'{num} Results': {
		en: '{num} Results',
		fr: '{num} Results (FR)'
	},
	'If Other': {
		en: 'If Other',
		fr: 'If Other (FR)'
	},
	'Summary Information': {
		en: 'Summary Information',
		fr: 'Summary Information (FR)'
	},
	'Bidding Details': {
		en: 'Bidding Details',
		fr: 'Bidding Details (FR)'
	},
	'Contact Information': {
		en: 'Contact Information',
		fr: 'Contact Information (FR)'
	},
	'Not Applicable': {
		en: 'Not Applicable',
		fr: 'Not Applicable (FR)'
	},
	'Opportunities & Contracts': {
		en: 'Opportunities & Contracts',
		fr: 'Opportunities & Contracts (FR)'
	},
	'Public Sector Entities': {
		en: 'Public Sector Entities',
		fr: 'Public Sector Entities (FR)'
	},
	'Procurement Entity Address': {
		en: 'Procurement Entity Address',
		fr: 'Procurement Entity Address (FR)'
	},
	'Street Address': {
		en: 'Street Address',
		fr: 'Street Address (FR)'
	},
	'Unit Number': {
		en: 'Unit Number',
		fr: 'Unit Number (FR)'
	},
	'City': {
		en: 'City',
		fr: 'City (FR)'
	},
	'Country': {
		en: 'Country',
		fr: 'Country (FR)'
	},
	'Province / State': {
		en: 'Province / State',
		fr: 'Province / State (FR)'
	},
	'Postal Code': {
		en: 'Postal Code',
		fr: 'Postal Code (FR)'
	},
	'Award Details': {
		en: 'Award Details',
		fr: 'Award Details (FR)'
	},
	'Bid Details': {
		en: 'Bid Details',
		fr: 'Bid Details (FR)'
	},
	'Bids': {
		en: 'Bids',
		fr: 'Bids (FR)'
	},
	'Remove bid': {
		en: 'Remove bid',
		fr: 'Remove bid (FR)'
	},
	'Total Award Amount': {
		en: 'Total Award Amount',
		fr: 'Total Award Amount (FR)'
	},
	'Enter supplier name as entered on contract documents': {
		en: 'Enter supplier name as entered on contract documents',
		fr: 'Enter supplier name as entered on contract documents (FR)'
	},
	'Add supplier location (City, Province/State, and Country) Example: Halifax, NS, CA': {
		en: 'Add supplier location (City, Province/State, and Country) Example: Halifax, NS, CA',
		fr: 'Add supplier location (City, Province/State, and Country) Example: Halifax, NS, CA (FR)'
	},
	'Enter amount awarded to the specific supplier': {
		en: 'Enter amount awarded to the specific supplier',
		fr: 'Enter amount awarded to the specific supplier (FR)'
	},
	'Enter amount of the bid from this supplier': {
		en: 'Enter amount of the bid from this supplier',
		fr: 'Enter amount of the bid from this supplier (FR)'
	},
	'Enter the date the contract  comes into effect.': {
		en: 'Enter the date the contract  comes into effect.',
		fr: 'Enter the date the contract  comes into effect. (FR)'
	},
	'Enter details that assists in identifying the breakdown of award (i.e. region, sevice location, trade or commodity type, etc.)': {
		en: 'Enter details that assists in identifying the breakdown of award (i.e. region, sevice location, trade or commodity type, etc.)',
		fr: 'Enter details that assists in identifying the breakdown of award (i.e. region, sevice location, trade or commodity type, etc.) (FR)'
	},
	'Supplier': {
		en: 'Supplier',
		fr: 'Supplier (FR)'
	},
	'Supplier Location': {
		en: 'Supplier Location',
		fr: 'Supplier Location (FR)'
	},
	'Award Amount': {
		en: 'Award Amount',
		fr: 'Award Amount (FR)'
	},
	'Currency': {
		en: 'Currency',
		fr: 'Currency (FR)'
	},
	'Add Another - Award': {
		en: 'Add Another - Award',
		fr: 'Add Another - Award (FR)'
	},
	'Add Another - Bid': {
		en: 'Add Another - Bid',
		fr: 'Add Another - Bid (FR)'
	},
	'Deactivate User': {
		en: 'Deactivate User',
		fr: 'Deactivate User (FR)'
	},
	'Activate User': {
		en: 'Activate User',
		fr: 'Activate User (FR)'
	},
	'Remove User': {
		en: 'Remove User',
		fr: 'Remove User (FR)'
	},
	'Save User': {
		en: 'Save User',
		fr: 'Save User (FR)'
	},
	'Create User': {
		en: 'Create User',
		fr: 'Create User (FR)'
	},
	'Enabled': {
		en: 'Enabled',
		fr: 'Enabled (FR)'
	},
	'Terms of Use Accepted': {
		en: 'Terms of Use Accepted',
		fr: 'Terms of Use Accepted (FR)'
	},
	'User Role': {
		en: 'User Role',
		fr: 'User Role (FR)'
	},
	'User Role is required.': {
		en: 'User Role is required.',
		fr: 'User Role is required. (FR)'
	},
	'Municipalities, Academic Institutions, School Boards': {
		en: 'Municipalities, Academic Institutions, School Boards',
		fr: 'Municipalities, Academic Institutions, School Boards (FR)'
	},
	'Regular': {
		en: 'Regular',
		fr: 'Regular (FR)'
	},
	'Admin': {
		en: 'Admin',
		fr: 'Admin (FR)'
	},
	'PSE Account Manager': {
		en: 'PSE Account Manager',
		fr: 'PSE Account Manager (FR)'
	},
	'Guest': {
		en: 'Guest',
		fr: 'Guest (FR)'
	},
	'First Name': {
		en: 'First Name',
		fr: 'First Name (FR)'
	},
	'Middle Name': {
		en: 'Middle Name',
		fr: 'Middle Name (FR)'
	},
	'Last Name': {
		en: 'Last Name',
		fr: 'Last Name (FR)'
	},
	'Error loading users': {
		en: 'Error loading users',
		fr: 'Error loading users (FR)'
	},
	'No users found': {
		en: 'No users found',
		fr: 'No users found (FR)'
	},
	'User successfully removed.': {
		en: 'User successfully removed.',
		fr: 'User successfully removed. (FR)'
	},
	'Error: User could not be removed. Please try again later.': {
		en: 'Error: User could not be removed. Please try again later.',
		fr: 'Error: User could not be removed. Please try again later. (FR)'
	},
	'Search Users': {
		en: 'Search Users',
		fr: 'Search Users (FR)'
	},
	'Loading': {
		en: 'Loading',
		fr: 'Loading (FR)'
	},
	'Name': {
		en: 'Name',
		fr: 'Name (FR)'
	},
	'Active': {
		en: 'Active',
		fr: 'Active (FR)'
	},
	'Disabled': {
		en: 'Disabled',
		fr: 'Disabled (FR)'
	},
	'Activate': {
		en: 'Activate',
		fr: 'Activate (FR)'
	},
	'Deny': {
		en: 'Deny',
		fr: 'Deny (FR)'
	},
	'Account Inactive': {
		en: 'Account Inactive',
		fr: 'Account Inactive (FR)'
	},
	'404 - Not Found': {
		en: '404 - Not Found',
		fr: '404 - Not Found (FR)'
	},
	'Please select the type of access you require.': {
		en: 'Please select the type of access you require.',
		fr: 'Please select the type of access you require. (FR)'
	},
	'Users': {
		en: 'Users',
		fr: 'Users (FR)'
	},
	'Terms of Use': {
		en: 'Terms of Use',
		fr: 'Terms of Use (FR)'
	},
	'Terms of Use have been accepted.': {
		en: 'Terms of Use have been accepted.',
		fr: 'Terms of Use have been accepted. (FR)'
	},
	'Error: User could not be updated. Please try again later.': {
		en: 'Error: User could not be updated. Please try again later.',
		fr: 'Error: User could not be updated. Please try again later. (FR)'
	},
	'I have read and understand the Terms of Use and agree to abide by the restrictions on use of the Procurement Portal outlined in this document.': {
		en: 'I have read and understand the Terms of Use and agree to abide by the restrictions on use of the Procurement Portal outlined in this document.',
		fr: 'I have read and understand the Terms of Use and agree to abide by the restrictions on use of the Procurement Portal outlined in this document. (FR)'
	},
	'Accepting Terms of Use is required': {
		en: 'Accepting Terms of Use is required',
		fr: 'Accepting Terms of Use is required (FR)'
	},
	'Error: Content could not be retrieved.': {
		en: 'Error: Content could not be retrieved.',
		fr: 'Error: Content could not be retrieved. (FR)'
	},
	'Request a Procurement Portal account': {
		en: 'Request a Procurement Portal account',
		fr: 'Request a Procurement Portal account (FR)'
	},
	'If you are presently working for a public sector entity and are authorized to manage public procurement notices on behalf of your organization, you can request an access account.': {
		en: 'If you are presently working for a public sector entity and are authorized to manage public procurement notices on behalf of your organization, you can request an access account.',
		fr: 'If you are presently working for a public sector entity and are authorized to manage public procurement notices on behalf of your organization, you can request an access account. (FR)'
	},
	'If your entity is not included in the listing below, please <Link> to request your organization to be added.': {
		en: 'If your entity is not included in the listing below, please <Link> to request your organization to be added.',
		fr: 'If your entity is not included in the listing below, please <Link> to request your organization to be added. (FR)'
	},
	'submit a support form': {
		en: 'submit a support form',
		fr: 'submit a support form (FR)'
	},
	'Add Award Details': {
		en: 'Add Award Details',
		fr: 'Add Award Details (FR)'
	},
	'Add a Bid': {
		en: 'Add a Bid',
		fr: 'Add a Bid (FR)'
	},
	'Inactive': {
		en: 'Inactive',
		fr: 'Inactive (FR)'
	},
	'Review Terms & Conditions': {
		en: 'Review Terms & Conditions',
		fr: 'Review Terms & Conditions (FR)'
	},
	'Your account has not yet been configured by an administrator. Please use one of the buttons below to request account activation, which may take up to 48 hours to process. Public facing portal information can also be accessed ': {
		en: 'Your account has not yet been configured by an administrator. Please use one of the buttons below to request account activation, which may take up to 48 hours to process. Public facing portal information can also be accessed ',
		fr: 'Your account has not yet been configured by an administrator. Please use one of the buttons below to request account activation, which may take up to 48 hours to process. Public facing portal information can also be accessed  (FR)'
	},
	'here.': {
		en: 'here.',
		fr: 'here. (FR)'
	},
	'Organization is required.': {
		en: 'Organization is required.',
		fr: 'Organization is required. (FR)'
	},
	'Role is required.': {
		en: 'Role is required.',
		fr: 'Role is required. (FR)'
	},
	'Request Activation': {
		en: 'Request Activation',
		fr: 'Request Activation (FR)'
	},
	'Error: The Terms of Use could not be accepted. Please try again later.': {
		en: 'Error: The Terms of Use could not be accepted. Please try again later.',
		fr: 'Error: The Terms of Use could not be accepted. Please try again later. (FR)'
	},
	'Tender ID is a unique identifier to your organization\'s tender notice and you may set this value as desired using an alphanumeric structure. The system will ensure you do not create a duplicate value.\n\nIf you are new to the service and are looking for a format to use for your tender numbering, the below is suggested.\n\nStart with your organizations acronym, followed by the year, adding a hyphen and ending with your organization\'s own unique three (3) digit number. i.e CORPX2022-001': {
		en: 'Tender ID is a unique identifier to your organization\'s tender notice and you may set this value as desired using an alphanumeric structure. The system will ensure you do not create a duplicate value.\n\nIf you are new to the service and are looking for a format to use for your tender numbering, the below is suggested.\n\nStart with your organizations acronym, followed by the year, adding a hyphen and ending with your organization\'s own unique three (3) digit number. i.e CORPX2022-001',
		fr: 'Tender ID is a unique identifier to your organization\'s tender notice and you may set this value as desired using an alphanumeric structure. The system will ensure you do not create a duplicate value.\n\nIf you are new to the service and are looking for a format to use for your tender numbering, the below is suggested.\n\nStart with your organizations acronym, followed by the year, adding a hyphen and ending with your organization\'s own unique three (3) digit number. i.e CORPX2022-001 (FR)'
	},
	'Select the Procurement Method applicable to this tender opportunity.  "Competitive - Open" means the solicitation of bids through a publicly posted solicitation type (document);\n\n"Competitive - Selective  Tendering" (per CETA) means a procurement method whereby only qualified suppliers are invited by the procuring entity to submit a tender.\n\n"Advance Contract Award Notice (ACAN)" means a public notice indicating to the supplier community that the organization intends to award a Good, Service or Construction contract to a pre-identified supplier, believed to be the only one capable of performing the work, thereby allowing other suppliers to signal their interest in bidding by submitting a statement of capabilities.': {
		en: 'Select the Procurement Method applicable to this tender opportunity.  "Competitive - Open" means the solicitation of bids through a publicly posted solicitation type (document);\n\n"Competitive - Selective  Tendering" (per CETA) means a procurement method whereby only qualified suppliers are invited by the procuring entity to submit a tender.\n\n"Advance Contract Award Notice (ACAN)" means a public notice indicating to the supplier community that the organization intends to award a Good, Service or Construction contract to a pre-identified supplier, believed to be the only one capable of performing the work, thereby allowing other suppliers to signal their interest in bidding by submitting a statement of capabilities.',
		fr: 'Select the Procurement Method applicable to this tender opportunity.  "Competitive - Open" means the solicitation of bids through a publicly posted solicitation type (document);\n\n"Competitive - Selective  Tendering" (per CETA) means a procurement method whereby only qualified suppliers are invited by the procuring entity to submit a tender.\n\n"Advance Contract Award Notice (ACAN)" means a public notice indicating to the supplier community that the organization intends to award a Good, Service or Construction contract to a pre-identified supplier, believed to be the only one capable of performing the work, thereby allowing other suppliers to signal their interest in bidding by submitting a statement of capabilities. (FR)'
	},
	'Choose the solicitation format being used to solicit bids or responses from bidders.\n\nExamples of solicitation document can be found under the Format Selection Protocol at: ': {
		en: 'Choose the solicitation format being used to solicit bids or responses from bidders.\n\nExamples of solicitation document can be found under the Format Selection Protocol at: ',
		fr: 'Choose the solicitation format being used to solicit bids or responses from bidders.\n\nExamples of solicitation document can be found under the Format Selection Protocol at: (FR)'
	},
	'https://procurement.novascotia.ca/media/53284/Protocols.pdf': {
		en: 'https://procurement.novascotia.ca/media/53284/Protocols.pdf',
		fr: 'https://procurement.novascotia.ca/media/53284/Protocols.pdf'
	},
	'Title of the notice needs to accurately reflect the procurement being tendered. Be specific.\n\nE.g. Drainage, Guard Rail, and Asphalt Concrete Patching and Repairing, Three Projects in Victoria County': {
		en: 'Title of the notice needs to accurately reflect the procurement being tendered. Be specific.\n\nE.g. Drainage, Guard Rail, and Asphalt Concrete Patching and Repairing, Three Projects in Victoria County',
		fr: 'Title of the notice needs to accurately reflect the procurement being tendered. Be specific.\n\nE.g. Drainage, Guard Rail, and Asphalt Concrete Patching and Repairing, Three Projects in Victoria County (FR)'
	},
	'This field must be populated with a description of the procurement. The information in this field provides suppliers with a more detailed outline of the Goods, Services or Construction required. E.g. The Province is requesting proposals from qualified consultants to conduct a Electric Vehicle Study that will aid in both short and long-term strategies to adopting electric vehicles (EV) and advancing the Province in becoming an EV-ready province.\n\nFor procurements that are at or above CETA <Link>, description must also include when applicable:\n\n1) a description of any options;\n2) whether it will involve negotiation;\n3) whether the process will involve electronic auctions;\n4) for recurring contracts, an estimate, if possible, of the timing of subsequent notices of intended procurement;\n5) if limiting the number of suppliers able to participate, include the criteria being used to limit participation, the number of suppliers that will be permitted to participate, and the submission address to request to participate.': {
		en: 'This field must be populated with a description of the procurement. The information in this field provides suppliers with a more detailed outline of the Goods, Services or Construction required. E.g. The Province is requesting proposals from qualified consultants to conduct a Electric Vehicle Study that will aid in both short and long-term strategies to adopting electric vehicles (EV) and advancing the Province in becoming an EV-ready province.\n\nFor procurements that are at or above CETA <Link>, description must also include when applicable:\n\n1) a description of any options;\n2) whether it will involve negotiation;\n3) whether the process will involve electronic auctions;\n4) for recurring contracts, an estimate, if possible, of the timing of subsequent notices of intended procurement;\n5) if limiting the number of suppliers able to participate, include the criteria being used to limit participation, the number of suppliers that will be permitted to participate, and the submission address to request to participate.',
		fr: 'This field must be populated with a description of the procurement. The information in this field provides suppliers with a more detailed outline of the Goods, Services or Construction required. E.g. The Province is requesting proposals from qualified consultants to conduct a Electric Vehicle Study that will aid in both short and long-term strategies to adopting electric vehicles (EV) and advancing the Province in becoming an EV-ready province.\n\nFor procurements that are at or above CETA <Link>, description must also include when applicable:\n\n1) a description of any options;\n2) whether it will involve negotiation;\n3) whether the process will involve electronic auctions;\n4) for recurring contracts, an estimate, if possible, of the timing of subsequent notices of intended procurement;\n5) if limiting the number of suppliers able to participate, include the criteria being used to limit participation, the number of suppliers that will be permitted to participate, and the submission address to request to participate. (FR)'
	},
	'thresholds': {
		en: 'thresholds',
		fr: 'thresholds (FR)'
	},
	'Select all applicable trade agreement(s).\n\nFor more detailed information about each trade agreement visit ': {
		en: 'Select all applicable trade agreement(s).\n\nFor more detailed information about each trade agreement visit ',
		fr: 'Select all applicable trade agreement(s).\n\nFor more detailed information about each trade agreement visit (FR)'
	},
	'https://procurement.novascotia.ca/policies-processes/trade-agreements.aspx': {
		en: 'https://procurement.novascotia.ca/policies-processes/trade-agreements.aspx',
		fr: 'https://procurement.novascotia.ca/policies-processes/trade-agreements.aspx'
	},
	'Sustainable procurement helps drive best value for government purchasing. Sustainability specifications and guidance can be found in the Sourcing Library or by contacting the Manager of Sustainable Procurement. Sustainable purchasing requirements for new product and service categories can be developed in consultation with you and your clients.': {
		en: 'Sustainable procurement helps drive best value for government purchasing. Sustainability specifications and guidance can be found in the Sourcing Library or by contacting the Manager of Sustainable Procurement. Sustainable purchasing requirements for new product and service categories can be developed in consultation with you and your clients.',
		fr: 'Sustainable procurement helps drive best value for government purchasing. Sustainability specifications and guidance can be found in the Sourcing Library or by contacting the Manager of Sustainable Procurement. Sustainable purchasing requirements for new product and service categories can be developed in consultation with you and your clients. (FR)'
	},
	'Existing Specification or Guidance: Sustainability specifications or guidance from Sourcing Library was used.\n\nClient Provided Requirements: Client Department provided sustainability requirements.\n\nNew Specification or Requirements: New sustainability specifications were developed for this category or project.': {
		en: 'Existing Specification or Guidance: Sustainability specifications or guidance from Sourcing Library was used.\n\nClient Provided Requirements: Client Department provided sustainability requirements.\n\nNew Specification or Requirements: New sustainability specifications were developed for this category or project.',
		fr: 'Existing Specification or Guidance: Sustainability specifications or guidance from Sourcing Library was used.\n\nClient Provided Requirements: Client Department provided sustainability requirements.\n\nNew Specification or Requirements: New sustainability specifications were developed for this category or project. (FR)'
	},
	'No market availability: Alternative product/service not available in local market.\n\nNo specifications available: Specifications not available for product/service category.\n\nTime constraints: Client required a short timeline for project.\n\nCost: Alternative product/service exists but increases costs of procurement.\n\nPerformance: Performance of alternative product/service is unknown or unacceptable.\n\nClient department did not support: Client department did not support alternative product/service for technical or operational reasons.\n\nNot Considered: Sustainability requirements were not considered for this purchase.': {
		en: 'No market availability: Alternative product/service not available in local market.\n\nNo specifications available: Specifications not available for product/service category.\n\nTime constraints: Client required a short timeline for project.\n\nCost: Alternative product/service exists but increases costs of procurement.\n\nPerformance: Performance of alternative product/service is unknown or unacceptable.\n\nClient department did not support: Client department did not support alternative product/service for technical or operational reasons.\n\nNot Considered: Sustainability requirements were not considered for this purchase.',
		fr: 'No market availability: Alternative product/service not available in local market.\n\nNo specifications available: Specifications not available for product/service category.\n\nTime constraints: Client required a short timeline for project.\n\nCost: Alternative product/service exists but increases costs of procurement.\n\nPerformance: Performance of alternative product/service is unknown or unacceptable.\n\nClient department did not support: Client department did not support alternative product/service for technical or operational reasons.\n\nNot Considered: Sustainability requirements were not considered for this purchase. (FR)'
	},
	'Provide the estimated duration of the contract in months. If not applicable (e.g., Request for Information, or Contract duration is to be specified in submissions), enter zero.': {
		en: 'Provide the estimated duration of the contract in months. If not applicable (e.g., Request for Information, or Contract duration is to be specified in submissions), enter zero.',
		fr: 'Provide the estimated duration of the contract in months. If not applicable (e.g., Request for Information, or Contract duration is to be specified in submissions), enter zero. (FR)'
	},
	'Upload tender documents or add the link where suppliers can access the tender documents. Total filesize limit of {filesize}.': {
		en: 'Upload tender documents or add the link where suppliers can access the tender documents. Total filesize limit of {filesize}.',
		fr: 'Upload tender documents or add the link where suppliers can access the tender documents. Total filesize limit of {filesize}. (FR)'
	},
	'Add link where suppliers can access tender documents.': {
		en: 'Add link where suppliers can access tender documents.',
		fr: 'Add link where suppliers can access tender documents. (FR)'
	},
	'Enter the date when the tender will close for bidding.\n\nSuppliers must always be provided a reasonable amount of time to prepare and submit responses, regardless of any prescribed minimum solicitation periods. The setting of a solicitation closing date must take into account the level of complexity of the procurement and the extent of subcontracting anticipated. Sufficient time must be allowed for a supplier to obtain the solicitation, and any additional material, if applicable, and to prepare and submit a response. Information on the time periods that must be followed during the procurement of goods, services and construction are outlined here <Link>.': {
		en: 'Enter the date when the tender will close for bidding.\n\nSuppliers must always be provided a reasonable amount of time to prepare and submit responses, regardless of any prescribed minimum solicitation periods. The setting of a solicitation closing date must take into account the level of complexity of the procurement and the extent of subcontracting anticipated. Sufficient time must be allowed for a supplier to obtain the solicitation, and any additional material, if applicable, and to prepare and submit a response. Information on the time periods that must be followed during the procurement of goods, services and construction are outlined here <Link>.',
		fr: 'Enter the date when the tender will close for bidding.\n\nSuppliers must always be provided a reasonable amount of time to prepare and submit responses, regardless of any prescribed minimum solicitation periods. The setting of a solicitation closing date must take into account the level of complexity of the procurement and the extent of subcontracting anticipated. Sufficient time must be allowed for a supplier to obtain the solicitation, and any additional material, if applicable, and to prepare and submit a response. Information on the time periods that must be followed during the procurement of goods, services and construction are outlined here <Link>. (FR)'
	},
	'Posting Time Periods for International Trade Agreements': {
		en: 'Posting Time Periods for International Trade Agreements',
		fr: 'Posting Time Periods for International Trade Agreements (FR)'
	},
	'This field represents the closing time associated with the submission of a bid and is always represented in Atlantic Time. The default value of this field is 2:00 PM, though users may change this time during notice creation to suit their organization\'s requirements.': {
		en: 'This field represents the closing time associated with the submission of a bid and is always represented in Atlantic Time. The default value of this field is 2:00 PM, though users may change this time during notice creation to suit their organization\'s requirements.',
		fr: 'This field represents the closing time associated with the submission of a bid and is always represented in Atlantic Time. The default value of this field is 2:00 PM, though users may change this time during notice creation to suit their organization\'s requirements. (FR)'
	},
	'Enter the full physical address or URL where the tender must be submitted.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.': {
		en: 'Enter the full physical address or URL where the tender must be submitted.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.',
		fr: 'Enter the full physical address or URL where the tender must be submitted.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate. (FR)'
	},
	'If a public opening is is part of your process, enter the date and time bids will be opened.': {
		en: 'If a public opening is is part of your process, enter the date and time bids will be opened.',
		fr: 'If a public opening is is part of your process, enter the date and time bids will be opened. (FR)'
	},
	'If a public opening is part of your process, enter the location where bids will be opened.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.': {
		en: 'If a public opening is part of your process, enter the location where bids will be opened.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate.',
		fr: 'If a public opening is part of your process, enter the location where bids will be opened.\n\nThis field value may be defaulted from the Procurement Entity profile managed by the Administrator, if a default value appears the user may amend this value as appropriate. (FR)'
	},
	'Select the language in which responses may be submitted.': {
		en: 'Select the language in which responses may be submitted.',
		fr: 'Select the language in which responses may be submitted. (FR)'
	},
	'If applicable, include any cost associated with accessing tender documents.': {
		en: 'If applicable, include any cost associated with accessing tender documents.',
		fr: 'If applicable, include any cost associated with accessing tender documents. (FR)'
	},
	'If applicable, include terms of payment to access tender documents.': {
		en: 'If applicable, include terms of payment to access tender documents.',
		fr: 'If applicable, include terms of payment to access tender documents. (FR)'
	},
	'Use this space to detail specific information about the procurement. I.E. site visits, additional instructions, notifications': {
		en: 'Use this space to detail specific information about the procurement. I.E. site visits, additional instructions, notifications',
		fr: 'Use this space to detail specific information about the procurement. I.E. site visits, additional instructions, notifications (FR)'
	},
	'An addendum is an additional document not included in the main part of the tender documents. It is an ad hoc item, usually compiled and executed after the main document, which contains additional terms, obligations or information. Total filesize limit of {filesize}.': {
		en: 'An addendum is an additional document not included in the main part of the tender documents. It is an ad hoc item, usually compiled and executed after the main document, which contains additional terms, obligations or information. Total filesize limit of {filesize}.',
		fr: 'An addendum is an additional document not included in the main part of the tender documents. It is an ad hoc item, usually compiled and executed after the main document, which contains additional terms, obligations or information. Total filesize limit of {filesize}. (FR)'
	},
	'Add additional details related to addendum which may be useful to the supplier.': {
		en: 'Add additional details related to addendum which may be useful to the supplier.',
		fr: 'Add additional details related to addendum which may be useful to the supplier. (FR)'
	},
	'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice.': {
		en: 'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice.',
		fr: 'Field value is defaulted from the Procurement Entity profile managed by the Administrator. If desired, users may amend this value when creating a notice. (FR)'
	},
	'Add any additional contact details relative to the procurement.': {
		en: 'Add any additional contact details relative to the procurement.',
		fr: 'Add any additional contact details relative to the procurement. (FR)'
	},
	'Enter a specific contact name for inquiries, or directions for contact e.g., Call and leave message; or Send email to below address.': {
		en: 'Enter a specific contact name for inquiries, or directions for contact e.g., Call and leave message; or Send email to below address.',
		fr: 'Enter a specific contact name for inquiries, or directions for contact e.g., Call and leave message; or Send email to below address. (FR)'
	},
	'Select the end user, or client, entity.': {
		en: 'Select the end user, or client, entity.',
		fr: 'Select the end user, or client, entity. (FR)'
	},
	'Error: User could not be activated. Please try again later.': {
		en: 'Error: User could not be activated. Please try again later.',
		fr: 'Error: User could not be activated. Please try again later. (FR)'
	},
	'User successfully activated.': {
		en: 'User successfully activated.',
		fr: 'User successfully activated. (FR)'
	},
	'User activation successfully requested.': {
		en: 'User activation successfully requested.',
		fr: 'User activation successfully requested. (FR)'
	},
	'Request Access': {
		en: 'Request Access',
		fr: 'Request Access (FR)'
	},
	'Your account is awaiting activation from an administrator. You can continue to browse as a guest until your account has been activated.': {
		en: 'Your account is awaiting activation from an administrator. You can continue to browse as a guest until your account has been activated.',
		fr: 'Your account is awaiting activation from an administrator. You can continue to browse as a guest until your account has been activated. (FR)'
	},
	'Send Me Notifications': {
		en: 'Send Me Notifications',
		fr: 'Send Me Notifications (FR)'
	},
	'Successfully signed up for notifications': {
		en: 'Successfully signed up for notifications',
		fr: 'Successfully signed up for notifications (FR)'
	},
	'Error: Could not sign up for notifications. Please try again later.': {
		en: 'Error: Could not sign up for notifications. Please try again later.',
		fr: 'Error: Could not sign up for notifications. Please try again later. (FR)'
	},
	'Search via:': {
		en: 'Search via:',
		fr: 'Search via: (FR)'
	},
	'Keyword': {
		en: 'Keyword',
		fr: 'Keyword (FR)'
	},
	'Manual Select': {
		en: 'Manual Select',
		fr: 'Manual Select (FR)'
	},
	'Error: Could not complete search. Please try again later.': {
		en: 'Error: Could not complete search. Please try again later.',
		fr: 'Error: Could not complete search. Please try again later. (FR)'
	},
	'No commodities matched your search.': {
		en: 'No commodities matched your search.',
		fr: 'No commodities matched your search. (FR)'
	},
	'Select': {
		en: 'Select',
		fr: 'Select (FR)'
	},
	'Clear': {
		en: 'Clear Selected',
		fr: 'Clear Selected (FR)'
	},
	'Error: Tender not available.': {
		en: 'Error: Tender not available.',
		fr: 'Error: Tender not available. (FR)'
	},
	'Notifications': {
		en: 'Notifications',
		fr: 'Notifications (FR)'
	},
	'Successfully updated notification preferences': {
		en: 'Successfully updated notification preferences',
		fr: 'Successfully updated notification preferences. (FR)'
	},
	'Error: Could not update notification preferences. Please try again later.': {
		en: 'Error: Could not update notification preferences. Please try again later.',
		fr: 'Error: Could not update notification preferences. Please try again later. (FR)'
	},
	'Edit Notification Preferences': {
		en: 'Edit Notification Preferences',
		fr: 'Edit Notification Preferences (FR)'
	},
	'Update Notification Preferences': {
		en: 'Update Notification Preferences',
		fr: 'Update Notification Preferences (FR)'
	},
	'Publish Notice': {
		en: 'Publish Notice',
		fr: 'Publish Notice (FR)'
	},
	'The Province uses the ALTP-related Ariba Contract Workspace number (CW#) in this field or in the case of one-time emergencies, the Purchase Order (PO) or Outline Agreement numbers. Other Public Sector Entities (PSEs) will want to choose a numbering system that works for their organization, which should be unique to improve searchability. Recommend using a prefix like Tender IDs.': {
		en: 'The Province uses the ALTP-related Ariba Contract Workspace number (CW#) in this field or in the case of one-time emergencies, the Purchase Order (PO) or Outline Agreement numbers. Other Public Sector Entities (PSEs) will want to choose a numbering system that works for their organization, which should be unique to improve searchability. Recommend using a prefix like Tender IDs.',
		fr: 'The Province uses the ALTP-related Ariba Contract Workspace number (CW#) in this field or in the case of one-time emergencies, the Purchase Order (PO) or Outline Agreement numbers. Other Public Sector Entities (PSEs) will want to choose a numbering system that works for their organization, which should be unique to improve searchability. Recommend using a prefix like Tender IDs. (FR)'
	},
	'A concise description of the untendered product or service procured using valid ALTP justification.': {
		en: 'A concise description of the untendered product or service procured using valid ALTP justification.',
		fr: 'A concise description of the untendered product or service procured using valid ALTP justification. (FR)'
	},
	'Choose the organization that applies from the list provided.': {
		en: 'Choose the organization that applies from the list provided.',
		fr: 'Choose the organization that applies from the list provided. (FR)'
	},
	'Select the circumstance from the dropdown list.': {
		en: 'Select the circumstance from the dropdown list.',
		fr: 'Select the circumstance from the dropdown list. (FR)'
	},
	'Click here to view the Alternative Procurement Practices Protocol.': {
		en: 'Click here to view the Alternative Procurement Practices Protocol.',
		fr: 'Click here to view the Alternative Procurement Practices Protocol. (FR)'
	},
	'Select category or categories (Goods, Services, Construction) that apply from the list provided. ': {
		en: 'Select category or categories (Goods, Services, Construction) that apply from the list provided. ',
		fr: 'Select category or categories (Goods, Services, Construction) that apply from the list provided. (FR)'
	},
	'Choose  the date that the purchase order, outline agreement, and/or the contract was executed.': {
		en: 'Choose  the date that the purchase order, outline agreement, and/or the contract was executed.',
		fr: 'Choose  the date that the purchase order, outline agreement, and/or the contract was executed. (FR)'
	},
	'Add in the original ALTP award.': {
		en: 'Add in the original ALTP award.',
		fr: 'Add in the original ALTP award. (FR)'
	},
	'Name of the Supplier that was awarded the contract.  Please be consistent by using the same spelling/form if previously used in other ALTP postings.': {
		en: 'Name of the Supplier that was awarded the contract.  Please be consistent by using the same spelling/form if previously used in other ALTP postings.',
		fr: 'Name of the Supplier that was awarded the contract.  Please be consistent by using the same spelling/form if previously used in other ALTP postings. (FR)'
	},
	'Include the contact information (name, title, email) of the person responsible for inquiries around the ALTP purchase.': {
		en: 'Include the contact information (name, title, email) of the person responsible for inquiries around the ALTP purchase.',
		fr: 'Include the contact information (name, title, email) of the person responsible for inquiries around the ALTP purchase. (FR)'
	},
	'Select appropriate currency.': {
		en: 'Select appropriate currency.',
		fr: 'Select appropriate currency. (FR)'
	},
	'Back to Dashboard': {
		en: 'Back to Dashboard',
		fr: 'Back to Dashboard (FR)'
	},
	'Ongoing Onboarding': {
		en: 'Ongoing Onboarding',
		fr: 'Ongoing Onboarding (FR)'
	},
	'Tender information must be provided by uploading document(s) or entering a URL to access tender document(s).': {
		en: 'Tender information must be provided by uploading document(s) or entering a URL to access tender document(s).',
		fr: 'Tender information must be provided by uploading document(s) or entering a URL to access tender document(s). (FR)'
	},
	'- {procurementEntity} - Departments -': {
		en: '- {procurementEntity} - Departments -',
		fr: '- {procurementEntity} - Departments - (FR)'
	},
	'COVID-19 Mandatory Vaccination Protocol in High-Risk Settings': {
		en: 'COVID-19 Mandatory Vaccination Protocol in High-Risk Settings',
		fr: 'COVID-19 Mandatory Vaccination Protocol in High-Risk Settings (FR)'
	},
	'click here': {
		en: 'click here',
		fr: 'click here (FR)'
	},
	'As of March 21, 2022, proof of vaccination will not be required for most government workplaces. However, suppliers working on behalf of the Nova Scotia Government and entering High-Risk Settings must adhere by the <FirstLink> protocol and complete the Supplier Attestation Form if they have not previously submitted a form. For more information and to complete a COVID-19 Vaccination Attestation form, <SecondLink>.': {
		en: 'As of March 21, 2022, proof of vaccination will not be required for most government workplaces. However, suppliers working on behalf of the Nova Scotia Government and entering High-Risk Settings must adhere by the <FirstLink> protocol and complete the Supplier Attestation Form if they have not previously submitted a form. For more information and to complete a COVID-19 Vaccination Attestation form, <SecondLink>.',
		fr: 'As of March 21, 2022, proof of vaccination will not be required for most government workplaces. However, suppliers working on behalf of the Nova Scotia Government and entering High-Risk Settings must adhere by the <FirstLink> protocol and complete the Supplier Attestation Form if they have not previously submitted a form. For more information and to complete a COVID-19 Vaccination Attestation form, <SecondLink>. (FR)'
	},
	'Canadian Collaborative Procurement Initiative': {
		en: 'Canadian Collaborative Procurement Initiative',
		fr: 'Canadian Collaborative Procurement Initiative (FR)'
	},
	'More information on Standing Offers': {
		en: 'More information on Standing Offers',
		fr: 'More information on Standing Offers (FR)'
	},
	'Showing 3 of {total} commodities': {
		en: 'Showing 3 of {total} commodities',
		fr: 'Showing 3 of {total} commodities (FR)'
	},
	'Show All Commodities': {
		en: 'Show All Commodities',
		fr: 'Show All Commodities (FR)'
	},
	'Show Less Commodities': {
		en: 'Show Less Commodities',
		fr: 'Show Less Commodities (FR)'
	},
	'No spaces allowed, and some characters are restricted.': {
		en: 'No spaces allowed, and some characters are restricted.',
		fr: 'No spaces allowed, and some characters are restricted. (FR)'
	},
	'Select All Commodities': {
		en: 'Select All Commodities',
		fr: 'Select All Commodities (FR)'
	},
	'Add all commodities related to your tender.\n\nThe commodity list is based on the hierarchy of United Nations Standard Products and Services Codes (UNSPSC). For guidance on selecting commodities, watch this <Link>.': {
		en: 'Add all commodities related to your tender.\n\nThe commodity list is based on the hierarchy of United Nations Standard Products and Services Codes (UNSPSC). For guidance on selecting commodities, watch this <Link>.',
		fr: 'Add all commodities related to your tender.\n\nThe commodity list is based on the hierarchy of United Nations Standard Products and Services Codes (UNSPSC). For guidance on selecting commodities, watch this <Link>.'
	},
	'Selecting All Commodities\n\n• Previously selected commodities will no longer be visible on your profile.\n• By choosing this option, you will receive email notifications of all tender notices published the previous day.\n• At any time, you can change your preferences and select individual commodities again.': {
		en: 'Selecting All Commodities\n\n• Previously selected commodities will no longer be visible on your profile.\n• By choosing this option, you will receive email notifications of all tender notices published the previous day.\n• At any time, you can change your preferences and select individual commodities again.',
		fr: 'Selecting All Commodities\n\n• Previously selected commodities will no longer be visible on your profile.\n• By choosing this option, you will receive email notifications of all tender notices published the previous day.\n• At any time, you can change your preferences and select individual commodities again. (FR)'
	},
	'Selecting All for a Level 2 Commodity\n\n• By choosing this option, you will receive email notifications of all tender notices for all Level 2 commodities connected to the Level 1 selection.\n• This option may be removed at any time and individual Level 2 commodities may be added as desired.': {
		en: 'Selecting All for a Level 2 Commodity\n\n• By choosing this option, you will receive email notifications of all tender notices for all Level 2 commodities connected to the Level 1 selection.\n• This option may be removed at any time and individual Level 2 commodities may be added as desired.',
		fr: 'Selecting All for a Level 2 Commodity\n\n• By choosing this option, you will receive email notifications of all tender notices for all Level 2 commodities connected to the Level 1 selection.\n• This option may be removed at any time and individual Level 2 commodities may be added as desired. (FR)'
	},
	'Copy Tender': {
		en: 'Copy Tender',
		fr: 'Copy Tender (FR)'
	},
	'Copy this tender?': {
		en: 'Copy this tender?',
		fr: 'Copy this tender? (FR)'
	},
	'Copying will result in a new draft tender. A new *Tender ID* is required.': {
		en: 'Copying will result in a new draft tender. A new *Tender ID* is required.',
		fr: 'Copying will result in a new draft tender. A new *Tender ID* is required. (FR)'
	},
	'Yes, Copy Tender': {
		en: 'Yes, Copy Tender',
		fr: 'Yes, Copy Tender (FR)'
	},
	'Tender successfully copied': {
		en: 'Tender successfully copied',
		fr: 'Tender successfully copied (FR)'
	},
	'Error: Tender could not be cloned. Please try again later.': {
		en: 'Error: Tender could not be copied. Please try again later.',
		fr: 'Error: Tender could not be copied. Please try again later. (FR)'
	},
	'Reference Tables': {
		en: 'Reference Tables',
		fr: 'Reference Tables (FR)'
	},
	'Error loading results': {
		en: 'Error loading results',
		fr: 'Error loading results (FR)'
	},
	'No results found': {
		en: 'No results found',
		fr: 'No results found (FR)'
	},
	'Procurement Methods': {
		en: 'Procurement Methods',
		fr: 'Procurement Methods (FR)'
	},
	'Edit Procurement Method': {
		en: 'Edit Procurement Method',
		fr: 'Edit Procurement Method (FR)'
	},
	'Add Procurement Method': {
		en: 'Add Procurement Method',
		fr: 'Add Procurement Method (FR)'
	},
	'Name (English)': {
		en: 'Name (English)',
		fr: 'Name (English) (FR)'
	},
	'Description (English)': {
		en: 'Description (English)',
		fr: 'Description (English) (FR)'
	},
	'Procurement Methods successfully updated.': {
		en: 'Procurement Methods successfully updated.',
		fr: 'Procurement Methods successfully updated. (FR)'
	},
	'Error: Procurement Methods could not be updated. Please try again later.': {
		en: 'Error: Procurement Methods could not be updated. Please try again later.',
		fr: 'Error: Procurement Methods could not be updated. Please try again later. (FR)'
	},
	'Solicitation Types': {
		en: 'Solicitation Types',
		fr: 'Solicitation Types (FR)'
	},
	'Edit Solicitation Type': {
		en: 'Edit Solicitation Type',
		fr: 'Edit Solicitation Type (FR)'
	},
	'Add Solicitation Type': {
		en: 'Add Solicitation Type',
		fr: 'Add Solicitation Type (FR)'
	},
	'Solicitation Types successfully updated.': {
		en: 'Solicitation Types successfully updated.',
		fr: 'Solicitation Types successfully updated. (FR)'
	},
	'Error: Solicitation Types could not be updated. Please try again later.': {
		en: 'Error: Solicitation Types could not be updated. Please try again later.',
		fr: 'Error: Solicitation Types could not be updated. Please try again later. (FR)'
	},
	'Error: Solicitation Type Description is already in use.': {
		en: 'Error: Solicitation Type Description is already in use.',
		fr: 'Error: Solicitation Type Description is already in use. (FR)'
	},
	'Trade Agreements': {
		en: 'Trade Agreements',
		fr: 'Trade Agreements (FR)'
	},
	'Edit Trade Agreement': {
		en: 'Edit Trade Agreement',
		fr: 'Edit Trade Agreement (FR)'
	},
	'Add Trade Agreement': {
		en: 'Add Trade Agreement',
		fr: 'Add Trade Agreement (FR)'
	},
	'Trade Agreements successfully updated.': {
		en: 'Trade Agreements successfully updated.',
		fr: 'Trade Agreements successfully updated. (FR)'
	},
	'Error: Trade Agreements could not be updated. Please try again later.': {
		en: 'Error: Trade Agreements could not be updated. Please try again later.',
		fr: 'Error: Trade Agreements could not be updated. Please try again later. (FR)'
	},
	'Error: Trade Agreement name/code is already in use.': {
		en: 'Error: Trade Agreement name/code is already in use.',
		fr: 'Error: Trade Agreement name/code is already in use. (FR)'
	},
	'Visible': {
		en: 'Visible',
		fr: 'Visible (FR)'
	},
	'Commodities': {
		en: 'Commodities',
		fr: 'Commodities (FR)'
	},
	'Edit Commodity': {
		en: 'Edit Commodity',
		fr: 'Edit Commodity (FR)'
	},
	'Add Commodity': {
		en: 'Add Commodity',
		fr: 'Add Commodity (FR)'
	},
	'Back to Commodities': {
		en: 'Back to Commodities',
		fr: 'Back to Commodities (FR)'
	},
	'Level': {
		en: 'Level',
		fr: 'Level (FR)'
	},
	'Code': {
		en: 'Code',
		fr: 'Code (FR)'
	},
	'Commodity Code': {
		en: 'Commodity Code',
		fr: 'Commodity Code (FR)'
	},
	'Tender Category': {
		en: 'Tender Category',
		fr: 'Tender Category (FR)'
	},
	'Back to Level Select': {
		en: 'Back to Level Select',
		fr: 'Back to Level Select (FR)'
	},
	'Commodity successfully updated.': {
		en: 'Commodity successfully updated.',
		fr: 'Commodity successfully updated. (FR)'
	},
	'Error: Commodity could not be updated. Please try again later.': {
		en: 'Error: Commodity could not be updated. Please try again later.',
		fr: 'Error: Commodity could not be updated. Please try again later. (FR)'
	},
	'Active Users': {
		en: 'Active Users',
		fr: 'Active Users (FR)'
	},
	'Inactive Users': {
		en: 'Inactive Users',
		fr: 'Inactive Users (FR)'
	},
	'Pending Users': {
		en: 'Pending Users',
		fr: 'Pending Users (FR)'
	},
	'Date Created': {
		en: 'Date Created',
		fr: 'Date Created (FR)'
	},
	'Date Modified': {
		en: 'Date Modified',
		fr: 'Date Modified (FR)'
	},
	'Procurement Entities': {
		en: 'Procurement Entities',
		fr: 'Procurement Entities (FR)'
	},
	'Organization ID': {
		en: 'Organization ID',
		fr: 'Organization ID (FR)'
	},
	'Sector ID': {
		en: 'Sector ID',
		fr: 'Sector ID (FR)'
	},
	'Edit Procurement Entity': {
		en: 'Edit Procurement Entity',
		fr: 'Edit Procurement Entity (FR)'
	},
	'Add Procurement Entity': {
		en: 'Add Procurement Entity',
		fr: 'Add Procurement Entity (FR)'
	},
	'Procurement Entities successfully updated.': {
		en: 'Procurement Entities successfully updated.',
		fr: 'Procurement Entities successfully updated. (FR)'
	},
	'Error: Procurement Entities could not be updated. Please try again later.': {
		en: 'Error: Procurement Entities could not be updated. Please try again later.',
		fr: 'Error: Procurement Entities could not be updated. Please try again later. (FR)'
	},
	'End User Entities': {
		en: 'End User Entities',
		fr: 'End User Entities (FR)'
	},
	'Edit End User Entity': {
		en: 'Edit End User Entity',
		fr: 'Edit End User Entity (FR)'
	},
	'Add End User Entity': {
		en: 'Add End User Entity',
		fr: 'Add End User Entity (FR)'
	},
	'End User Entities successfully updated.': {
		en: 'End User Entities successfully updated.',
		fr: 'End User Entities successfully updated. (FR)'
	},
	'Error: Name already in the system.': {
		en: 'Error: Name already in the system.',
		fr: 'Error: Name already in the system. (FR)'
	},
	'Error: End User Entities could not be updated. Please try again later.': {
		en: 'Error: End User Entities could not be updated. Please try again later.',
		fr: 'Error: End User Entities could not be updated. Please try again later. (FR)'
	},
	'ALTP Contacts': {
		en: 'ALTP Contacts',
		fr: 'ALTP Contacts (FR)'
	},
	'Search ALTP Contacts': {
		en: 'Search ALTP Contacts',
		fr: 'Search ALTP Contacts (FR)'
	},
	'Department Code': {
		en: 'Department Code',
		fr: 'Department Code (FR)'
	},
	'Department Name': {
		en: 'Department Name',
		fr: 'Department Name (FR)'
	},
	'Sector': {
		en: 'Sector',
		fr: 'Sector (FR)'
	},
	'Edit ALTP Contact': {
		en: 'Edit ALTP Contact',
		fr: 'Edit ALTP Contact (FR)'
	},
	'Add ALTP Contact': {
		en: 'Add ALTP Contact',
		fr: 'Add ALTP Contact (FR)'
	},
	'ALTP Contacts successfully updated.': {
		en: 'ALTP Contacts successfully updated.',
		fr: 'ALTP Contacts successfully updated. (FR)'
	},
	'Error: Department Code already in the system, or Department Name already in use.': {
		en: 'Error: Department Code already in the system, or Department Name already in use.',
		fr: 'Error: Department Code already in the system, or Department Name already in use. (FR)'
	},
	'Error: ALTP Contacts could not be updated. Please try again later.': {
		en: 'Error: ALTP Contacts could not be updated. Please try again later.',
		fr: 'Error: ALTP Contacts could not be updated. Please try again later. (FR)'
	},
	'Categories': {
		en: 'Categories',
		fr: 'Categories (FR)'
	},
	'Categories successfully updated.': {
		en: 'Categories successfully updated.',
		fr: 'Categories successfully updated. (FR)'
	},
	'Error: Categories could not be updated. Please try again later.': {
		en: 'Error: Categories could not be updated. Please try again later.',
		fr: 'Error: Categories could not be updated. Please try again later. (FR)'
	},
	'Edit Category': {
		en: 'Edit Category',
		fr: 'Edit Category (FR)'
	},
	'Add Category': {
		en: 'Add Category',
		fr: 'Add Category (FR)'
	},
	'The posting time you have selected is shorter than 10 days. Do you wish to proceed?': {
		en: 'The posting time you have selected is shorter than 10 days. Do you wish to proceed?',
		fr: 'The posting time you have selected is shorter than 10 days. Do you wish to proceed? (FR)'
	},
	'Yes, Continue to Publish': {
		en: 'Yes, Continue to Publish',
		fr: 'Yes, Continue to Publish (FR)'
	},
	'No, Return to Edit': {
		en: 'No, Return to Edit',
		fr: 'No, Return to Edit (FR)'
	},
	'Error: A user account request to post and manage procurement notices is already in progress.': {
		en: 'Error: A user account request to post and manage procurement notices is already in progress.',
		fr: 'Error: A user account request to post and manage procurement notices is already in progress. (FR)'
	},
	'If you require assistance, please <Link>.': {
		en: 'If you require assistance, please <Link>.',
		fr: 'If you require assistance, please <Link>. (FR)'
	},
	'submit a ticket': {
		en: 'submit a ticket',
		fr: 'submit a ticket (FR)'
	}
};
