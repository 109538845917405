import React, {useEffect, useRef, useState} from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';

import { referenceTableActions } from '../../../store/actions/referenceTableActions';
import { setHasChanges, error, success } from '../../../store/actions/globalMessagesActions';

import { Row, Col, Table, Spinner, Button, ButtonToolbar, Form } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus } from '@fortawesome/free-solid-svg-icons';
import ProcurementMethodsForm from './ProcurementMethodsForm';
import Pagination from '../../misc/Pagination';

function ProcurementMethods({ isActive }) {
	const {
		BLANK_VALUE,
		PAGINATION_LIMIT
	} = require('../../../config/constants');
	const dispatch = useDispatch();

	// State data
	const authenticate = useSelector(state => state.authenticate);
	const getReferenceTable = useSelector(state => state.getReferenceTable, shallowEqual);
	const postReferenceTable = useSelector(state => state.postReferenceTable);

	const [results, setResults] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [resultsNum, setResultsNum] = useState(PAGINATION_LIMIT);
	const [paginationData, setPaginationData] = useState({});
	const [isResultsLoading, setIsResultsLoading] = useState(false);
	const [noResultsMessage, setNoResultsMessage] = useState('\u00A0');
	const [currentResult, setCurrentResult] = useState(false);
	const [editForm, setEditForm] = useState(false);

	const resultsRef = useRef(null);

	const handleEditResult = (result) => {
		setCurrentResult(result);
		setEditForm(true);
	};

	const handleAddRow = () => {
		setCurrentResult({});
		setEditForm(true);
	};

	const handleCloseForm = () => {
		setCurrentResult(false);
		setEditForm(false);
	};

	// Getting data.
	const loadData = () => {
		setResults([]);
		setIsResultsLoading(true);
		dispatch(referenceTableActions.getReferenceTable('procurementmethods', {
			page: currentPage,
			numberOfRecords: resultsNum
		}));
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleResultsChange = (newNum) => {
		setResultsNum(newNum);
	};

	useEffect(() => {
		if (!isActive) {
			setCurrentResult(false);
			setEditForm(false);
		}
	}, [isActive]);

	useEffect(() => {
		if (authenticate && authenticate.success) {
			loadData();
		}
	}, [currentPage, authenticate, resultsNum]);

	// Listening to users state.
	useEffect(() => {
		if (getReferenceTable.request) {
			setNoResultsMessage('\u00A0');
		} else if (getReferenceTable.error) {
			setNoResultsMessage('Error loading results');
			setIsResultsLoading(false);
		}

		if (getReferenceTable.success && getReferenceTable.procurementmethods) {
			if (getReferenceTable.procurementmethods.procurementMethodDataList && getReferenceTable.procurementmethods.procurementMethodDataList.length) {
				setResults(getReferenceTable.procurementmethods.procurementMethodDataList);
				setPaginationData(getReferenceTable.procurementmethods.paginationData);
			} else {
				setResults([]);
				setPaginationData(false);
				setNoResultsMessage('No results found');
			}

			setIsResultsLoading(false);
		}
	}, [getReferenceTable]);

	// Updated changes.
	useEffect(() => {
		if (postReferenceTable.success && postReferenceTable.callback === 'procurement-methods') {
			dispatch(success('Procurement Methods successfully updated.', null, () => { resultsRef.current.focus(); }));
			setIsResultsLoading(true);
			setCurrentPage(1);
			loadData();
			dispatch(setHasChanges(false));
			dispatch(referenceTableActions.resetUpdateReferenceTable());
		} else if (postReferenceTable.error && postReferenceTable.callback === 'procurement-methods') {
			dispatch(error('Error: Procurement Methods could not be updated. Please try again later.', null, () => { resultsRef.current.focus(); }));
			dispatch(referenceTableActions.resetUpdateReferenceTable());
		}
	}, [postReferenceTable]);

	return (
		<>
			{ editForm &&
				<ProcurementMethodsForm handleCloseForm={ handleCloseForm } result={ currentResult } />
			}

			{ editForm === false &&
				<>
					<Row className={'mt-4 align-items-center'}>
						<Col md={7}>
							<h2><Translate text="Procurement Methods" /></h2>
						</Col>
						<Col md={5}>
							<ButtonToolbar className="justify-content-end">
								<Button variant="link" onClick={() => handleAddRow()}>
									<FontAwesomeIcon icon={ faPlus } /> <Translate text={'Add Row'} />
								</Button>
							</ButtonToolbar>
						</Col>
					</Row>

					<Row className={'mt-0'}>
						<Col>
							{ isResultsLoading &&
								<div className="text-center m-3">
									<Spinner animation="border" role="status" className="loader">
										<span className="sr-only"><Translate text={'Loading'} /></span>
									</Spinner>
								</div>
							}

							{(!isResultsLoading && results && results.length > 0) &&
								<>
									<Table bordered hover responsive ref={ resultsRef } className={'reference-table'}>
										<thead>
											<tr>
												<th className="name-col"><Translate text={'Name'} /></th>
												<th><Translate text={'Description'} /></th>
												<th><Translate text={'Enabled'} /></th>
												<th hidden><Translate text={'Controls'} /></th>
											</tr>
										</thead>

										{ results &&
											<tbody>
												{
													results.map((result, index) => {
														return (
															<tr key={ index }>
																<td className="name-col">{ result.name }</td>
																<td>{ (result.description) ? result.description : BLANK_VALUE }</td>
																<td><Translate text={(result.enabled) ? 'Yes' : 'No'} /></td>
																<td className={'row-actions'}>
																	<div className={'row-actions-buttons text-right'}>
																		<div>
																			<Button
																				variant="link"
																				className={'mb-0'}
																				onClick={() => handleEditResult(result)}
																			>
																				<FontAwesomeIcon icon={ faPencilAlt } />
																				<span className="sr-only">
																					<Translate text={'Edit'} /> { (result.description) ? result.description : '' }
																				</span>
																			</Button>
																		</div>
																	</div>
																</td>
															</tr>
														);
													})
												}
											</tbody>
										}
									</Table>

									<Row className="align-items-center">
										{ paginationData &&
											<Col md={6} className="text-center text-md-left">
												<Pagination
													data={ paginationData }
													handlePaginationChange={ handlePageChange }
												/>
											</Col>
										}

										<Col md={(paginationData) ? 6 : 12} className="text-center text-md-right">
											<label className="sort-control">
												<span><Translate text="Results" /></span>
												<Form.Control
													as="select"
													value={ resultsNum }
													onChange={(e) => { handleResultsChange(e.target.value); }}
												>
													<Translator>
														{({ translate }) => (
															<>
																<option value={PAGINATION_LIMIT}>
																	{ translate({ text: `${PAGINATION_LIMIT}` }) }
																</option>
																<option value={25}>
																	{ translate({ text: '25' }) }
																</option>
																<option value={50}>
																	{ translate({ text: '50' }) }
																</option>
																<option value={100}>
																	{ translate({ text: '100' }) }
																</option>
															</>
														)}
													</Translator>
												</Form.Control>
											</label>
										</Col>
									</Row>
								</>
							}

							{ !isResultsLoading && noResultsMessage && (!results || results.length <= 0) &&
								<div className={'alerts-container'}>
									<Alert variant="light">
										<span className="alert-message"><Translate text={ noResultsMessage } /></span>
									</Alert>
								</div>
							}
						</Col>
					</Row>
				</>
			}
		</>
	);
}

export default ProcurementMethods;
