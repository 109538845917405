import { contentConstants } from '../constants/contentConstants';

export function content(state = {}, action) {
	switch (action.type) {
		case contentConstants.CONTENT_PAGE_REQUEST:
			return {
				request: true
			};
		case contentConstants.CONTENT_PAGE_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case contentConstants.CONTENT_PAGE_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}

export function navigation(state = {}, action) {
	switch (action.type) {
		case contentConstants.CONTENT_NAVIGATION_REQUEST:
			return {
				request: true
			};
		case contentConstants.CONTENT_NAVIGATION_SUCCESS:
			return {
				success: true,
				data: action.data
			};
		case contentConstants.CONTENT_NAVIGATION_ERROR:
			return {
				error: true
			};
		default:
			return state;
	}
}
