import { userDataConstants } from '../constants/userDataConstants';
import { authenticateService } from '../../services/authenticateService';
const env = window.env;
export const userDataActions = {
	getUserData,
	logout
};

function getUserData() {
	return dispatch => {
		dispatch(request());

		if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && env.USERNAME_TOKEN) {
			authenticateService.accountLocal(env.USERNAME_TOKEN)
				.then(
					async response => {
						if (response && typeof response === 'object' && !response.error) {
							const userData = response;
							dispatch(success(userData));
						} else {
							dispatch(error());
						}
					}
				);
		} else {
			authenticateService.account()
				.then(
					async response => {
						if (response && typeof response === 'object' && !response.error) {
							const userData = response;
							dispatch(success(userData));
						} else {
							dispatch(error());
						}
					}
				);
		}
	};

	function request() { return { type: userDataConstants.USER_DATA_REQUEST }; }

	function success(data) { return { type: userDataConstants.USER_DATA_SUCCESS, data }; }

	function error() { return { type: userDataConstants.USER_DATA_ERROR }; }
}

function logout() {
	return { type: userDataConstants.LOGOUT };
}
