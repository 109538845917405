import React, { Fragment } from 'react';
import Select, { components } from 'react-select';
import Form from 'react-bootstrap/Form';
import { Translate, Translator } from 'react-translated';
import Alert from 'react-bootstrap/Alert';

export default function FormCustomSelect(props) {
	const {
		id,
		name,
		value,
		className,
		placeholder,
		label,
		labelInline,
		labelBefore,
		labelAfter,
		ariaLabel,
		options,
		height,
		disabled,
		required,
		afterMenu,
		isMulti,
		isSearchable,
		refValue,
		withinModal,
		readOnly
	} = props;

	const {
		MULTI_SELECT_MAX_OPTIONS
	} = require('../../config/constants');

	let customClasses;

	if (className) {
		customClasses = className;
	}

	if (isMulti) {
		if (customClasses) {
			customClasses += ' form-custom-select--is-multi';
		} else {
			customClasses = 'form-custom-select--is-multi';
		}
	}

	const customStyles = {
		control: base => ({
			...base,
			height: (height) ? parseInt(height, 10) : 42,
			minHeight: (height) ? parseInt(height, 10) : 42
		}),
		menuPortal: base => ({ ...base, zIndex: 9999 })
	};

	const CustomMenu = props => {
		const selectedOptions = props.getValue().length || 0;
		return (
			<Fragment>
				<components.Menu {...props}>
					{ selectedOptions < MULTI_SELECT_MAX_OPTIONS ? (props.children) : (<Alert variant={'light'}><span className="alert-message"><Translate text={'Maximum {num} options selected'} data={{ num: MULTI_SELECT_MAX_OPTIONS }} /></span></Alert>)}
					{ afterMenu &&
						<div className="form-custom-select__after-menu">
							{ afterMenu }
						</div>
					}
				</components.Menu>
			</Fragment>
		);
	};

	const CustomMultiValueLabel = props => {
		let labelText;

		if (props && props.data && props.data.value && Array.isArray(options)) {
			const selectedOption = options.find(item => item.value === props.data.value);

			if (selectedOption && selectedOption.label) {
				labelText = selectedOption.label;
			}
		}

		return (
			<Fragment>
				<components.MultiValueLabel {...props}>
					{ labelText || props.children }
				</components.MultiValueLabel>
			</Fragment>
		);
	};

	const handleOnChange = (event) => {
		if (props.handleOnChange) {
			props.handleOnChange(id, event);
		}
	};

	const handleOnBlur = (event) => {
		if (props.handleOnBlur) {
			props.handleOnBlur(id, event);
		}
	};

	return (
		<>
			{ label && !labelInline &&
				<Form.Label htmlFor={ (id) ? id + '-Input' : null }>
					{ labelBefore }

					<Translate text={ label } />
					{ required &&
						<span className="required">*</span>
					}

					{ labelAfter }
				</Form.Label>
			}

			<Translator>
				{({ translate }) => (
					<Select
						id={ id }
						inputId={ (id) ? id + '-Input' : null }
						name={ name }
						ref={refValue}
						value={ (!disabled) ? value : null }
						placeholder={ (placeholder) ? placeholder : null }
						options={ options }
						aria-label={ (label && labelInline) ? label : (ariaLabel) ? ariaLabel : null }
						components={{
							Menu: CustomMenu,
							MultiValueLabel: CustomMultiValueLabel
						}}
						className={ (customClasses) ? `form-custom-select ${customClasses}` : 'form-custom-select' }
						classNamePrefix="form-custom-select"
						isDisabled={ (disabled || !options || options.length < 1) }
						required={ (required) ? true : null }
						isMulti={!!isMulti}
						isSearchable={!!isSearchable}
						minMenuHeight={200}
						maxMenuHeight={200}
						menuPortalTarget={ (withinModal) ? null : document.body }
						closeMenuOnSelect={!isMulti}
						styles={ customStyles }
						theme={theme => ({
							...theme,
							colors: {
								...theme.colors,
								primary25: '#dddddd',
								primary50: '#f6f6f6',
								primary: '#677986'
							},
							menuPortal: base => ({ ...base, zIndex: 9999 })
						})}
						getOptionLabel={ label => translate({ text: (label) ? label.label : '' }) }
						onChange={(e) => { handleOnChange(e); }}
						onBlur={(e) => { handleOnBlur(e); }}
						readOnly={readOnly}
					/>
				)}
			</Translator>
		</>
	);
}
