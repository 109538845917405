import { standingOfferConstants } from '../constants/standingOfferConstants';

export function standingOffer(state = {}, action) {
	switch (action.type) {
		case standingOfferConstants.STANDING_OFFER_REQUEST:
			return {
				request: true
			};
		case standingOfferConstants.STANDING_OFFER_SUCCESS:
			return {
				success: true,
				data: action.data,
				callback: action.callback
			};
		case standingOfferConstants.STANDING_OFFER_ERROR:
			return {
				error: true,
				callback: action.callback
			};
		default:
			return state;
	}
}
