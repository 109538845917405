/* eslint-disable id-length */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translate, Translator } from 'react-translated';
import { v4 as uuidv4 } from 'uuid';

import { success, error } from '../../store/actions/globalMessagesActions';
import { notificationActions } from '../../store/actions/notificationActions';

import { Button, ButtonToolbar, Col, Row, Modal, Form } from 'react-bootstrap';
import FormCountry from '../forms/FormCountry';
import FormProvinceState from '../forms/FormProvinceState';
import FormEmail from '../forms/FormEmail';
import FormUNSPSC from '../forms/FormUNSPSC';
import FormSlideToggle from '../forms/FormSlideToggle';
import Tooltip from '../misc/Tooltip';

function NotificationSignup({ editNotification }) {
	const {
		INPUT_MAX_LENGTH,
		DIVERSITY_ANSWER_NONE
	} = require('../../config/constants');
	const dispatch = useDispatch();

	const userData = useSelector(state => state.userData);
	const referenceData = useSelector(state => state.referenceData);
	const notification = useSelector(state => state.notification);
	const getNotifications = useSelector(state => state.getNotifications);

	const [showModal, setShowModal] = useState(false);
	const [validated, setValidated] = useState(false);
	const [hasErrors, setHasErrors] = useState(false);
	const [questionsList, setQuestionsList] = useState([]);
	const initialData = {
		'changeType': 'SAVE',
		'enabled': editNotification && (!editNotification.enabled || editNotification.enabled == false) ? false : true,
		'id': editNotification && editNotification.id ? editNotification.id : null,
		'userId': editNotification && editNotification.userId ? editNotification.userId : '',
		'companyName': editNotification && editNotification.companyName ? editNotification.companyName : '',
		'country': editNotification && editNotification.country ? editNotification.country : 'Canada',
		'provinceState': editNotification && editNotification.provinceState ? editNotification.provinceState : '',
		'city': editNotification && editNotification.city ? editNotification.city : '',
		'username': editNotification && editNotification.username ? editNotification.username : '',
		'notificationEmail': editNotification && editNotification.notificationEmail ? editNotification.notificationEmail : '',
		'selectAll': editNotification && editNotification.selectAll ? editNotification.selectAll : null,
		'tonsUnspscLevelDataList': editNotification && editNotification.tonsUnspscLevelDataList ? editNotification.tonsUnspscLevelDataList : [{
			'changeType': 'SAVE',
			'unspscLevel1': '',
			'unspscLevel2': '',
			'unspscLevel3': '',
			'unspscLevel4': ''
		}],
		'tonsQuestionsAnswerDataList': editNotification && editNotification.tonsQuestionsAnswerDataList ? editNotification.tonsQuestionsAnswerDataList : []
	};

	const [data, setData] = useState(initialData);

	const {
		companyName,
		country,
		provinceState,
		city,
		username,
		notificationEmail,
		selectAll,
		tonsUnspscLevelDataList,
		tonsQuestionsAnswerDataList,
		enabled
	} = data;

	useEffect(() => {
		if (referenceData.success && referenceData.data) {
			if (Array.isArray(referenceData.data.tenderNotificationDiversityQuestionDataList)) {
				setQuestionsList(referenceData.data.tenderNotificationDiversityQuestionDataList);

				let items = [];
				referenceData.data.tenderNotificationDiversityQuestionDataList.map(item => {
					return items.push({
						question: item.question,
						ordinal: item.ordinal
					});
				});

				let updatedQuestions = data.tonsQuestionsAnswerDataList ? JSON.parse(JSON.stringify(data.tonsQuestionsAnswerDataList)) : [];
				items.forEach(item => {
					let found = false;
					updatedQuestions = updatedQuestions.map(question => {
						if (question.ordinal == item.ordinal) {
							found = true;
							question.question = item.question;
						}
						return question;
					});

					if (!found) {
						updatedQuestions.push(item);
					}
				});

				setData(data => ({ ...data, tonsQuestionsAnswerDataList: updatedQuestions }));
			}
		}
	}, [referenceData]);

	useEffect(() => {
		setUserData();
	}, [userData, editNotification]);

	useEffect(() => {
		if (notification.success) {
			dispatch(success(notification.callback === 'edit' ? 'Successfully updated notification preferences' : 'Successfully signed up for notifications'));
			dispatch(notificationActions.getNotifications('saved'));
		} else if (notification.error) {
			dispatch(error(notification.callback === 'edit' ? 'Error: Could not update notification preferences. Please try again later.' : 'Error: Could not sign up for notifications. Please try again later.'));
			resetData();
		}
	}, [notification]);

	useEffect(() => {
		if (getNotifications.success && getNotifications.callback === 'saved') {
			resetData();
		}
	}, [getNotifications]);

	const resetData = async () => {
		await setData(initialData);
		setValidated(false);
		setHasErrors(false);
		dispatch(notificationActions.reset());
	};

	const setUserData = () => {
		if (userData.success && userData.data) {
			let defaultEmail, defaultName, userId;
			const updatedData = JSON.parse(JSON.stringify(data));

			if (!data.notificationEmail) {
				if (userData.data.email) {
					defaultEmail = userData.data.email;
				}

				updatedData.notificationEmail = defaultEmail;
			}

			if (!data.username) {
				if (userData.data.firstname) {
					defaultName = userData.data.firstname;
				}

				if (userData.data.lastname) {
					defaultName += ' ' + userData.data.lastname;
				}

				updatedData.username = defaultName;
			}

			if (!data.userId) {
				if (userData.data.userID) {
					userId = userData.data.userID;
				}

				updatedData.userId = userId;
			}

			setData(updatedData);
		}
	};

	const handleChange = (event) => {
		const { name, value } = event.target;
		let val;

		if (value) {
			val = value;
		} else {
			val = '';
		}

		setData(data => ({...data, [name]: val }));
	};

	const handleToggleEnabled = (name, value) => {
		let val;

		if (value === 'yes') {
			val = true;
		} else {
			val = false;
		}

		setData(data => ({...data, 'enabled': val }));
	};

	const handleSelectAllChange = (event) => {
		const { checked } = event.target;
		const currentList = JSON.parse(JSON.stringify(data.tonsUnspscLevelDataList));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (checked) {
			items.map(item => {
				item.changeType = 'DELETE';
				return item;
			});
			
			items = [{
				'changeType': 'SAVE',
				'selectAll': true,
				'tempId': uuidv4()
			}, ...items];

			setData(data => ({
				...data,
				selectAll: true,
				tonsUnspscLevelDataList: items
			}));
		} else {
			items.map(item => {
				item.changeType = 'DELETE';
				return item;
			});

			items = [{
				'changeType': 'SAVE',
				'unspscLevel1': '',
				'unspscLevel2': '',
				'unspscLevel3': '',
				'unspscLevel4': ''
			}, ...items];

			setData(data => ({
				...data,
				selectAll: false,
				tonsUnspscLevelDataList: items
			}));
		}
	};

	const handleAddRepeaterItem = (event, list) => {
		event.currentTarget.blur();
		const currentList = JSON.parse(JSON.stringify(data[list]));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (!items) {
			items = [];
		}

		items.push({
			'changeType': 'SAVE',
			'tempId': uuidv4()
		});

		setData(data => ({...data, [list]: items }));
	};

	const handleRemoveRepeaterItem = (event, index, list) => {
		event.currentTarget.blur();
		const currentList = JSON.parse(JSON.stringify(data[list]));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (index > -1) {
			if (items[index] && items[index].id) {
				items[index]['changeType'] = 'DELETE';
			} else {
				items.splice(index, 1);
			}
		}

		setData(data => ({...data, [list]: items }));
	};

	const handleUnspscFieldChange = (levels, index) => {
		const currentList = JSON.parse(JSON.stringify(data.tonsUnspscLevelDataList));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (!items) {
			return false;
		}

		if (Array.isArray(levels)) {
			levels.map(item => {
				items[index][item.name] = item.value;
				return false;
			});
		} else if (levels) {
			items[index][levels.name] = levels.value;
		} else {
			items[index] = {
				...items[index],
				'changeType': 'SAVE',
				'unspscLevel1': '',
				'unspscLevel2': '',
				'unspscLevel3': '',
				'unspscLevel4': ''
			};
		}

		items[index]['changeType'] = 'SAVE';

		setData(data => ({...data, tonsUnspscLevelDataList: items }));
	};

	const handleUnspscSelectAllLevel2Change = (isSelected, index) => {
		const currentList = JSON.parse(JSON.stringify(data.tonsUnspscLevelDataList));
		let items = (Array.isArray(currentList)) ? [...currentList] : null;

		if (isSelected) {
			items[index].unspscLevel2 = '';
			items[index].unspscLevel3 = '';
			items[index].unspscLevel4 = '';
			items[index].selectAll = true;
		} else {
			items[index].selectAll = null;
		}

		setData(data => ({...data, tonsUnspscLevelDataList: items }));
	};

	const handleQuestionChange = (event, question, answer, index, isMultiple) => {
		const { checked } = event.target;
		const currentList = JSON.parse(JSON.stringify(data.tonsQuestionsAnswerDataList));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (!items) {
			return false;
		}

		if (items.length == 0 && Array.isArray(referenceData.data.tenderNotificationDiversityQuestionDataList)) {
			items = [...JSON.parse(JSON.stringify(referenceData.data.tenderNotificationDiversityQuestionDataList))];
		}

		items[index]['changeType'] = 'SAVE';
		items[index].question = question;

		if (isMultiple) {
			let answers = (items[index].answer) ? items[index].answer.split(';') : [];

			if (checked) {
				answers.push(answer);

				if (answer == DIVERSITY_ANSWER_NONE) {
					answers = answers.filter(item => item == DIVERSITY_ANSWER_NONE); // If 'None' has been checked, can't have any other values selected.
				} else {
					answers = answers.filter(item => item !== DIVERSITY_ANSWER_NONE); // If anything else has been checked, can't have 'None' selected.
				}
			} else {
				answers = answers.filter(item => item !== answer);
			}

			if (answers.length) {
				answers = answers.join(';');
			} else {
				answers = '';
			}

			items[index].answer = answers;
		} else {
			items[index].answer = answer;
		}

		setData(data => ({...data, tonsQuestionsAnswerDataList: items }));
	};

	const handleQuestionTextChange = (event, question, text, index) => {
		const currentList = JSON.parse(JSON.stringify(data.tonsQuestionsAnswerDataList));
		let items = (Array.isArray(currentList)) ? [...currentList] : [];

		if (!items) {
			return false;
		}

		if (items.length == 0 && Array.isArray(referenceData.data.tenderNotificationDiversityQuestionDataList)) {
			items = [...JSON.parse(JSON.stringify(referenceData.data.tenderNotificationDiversityQuestionDataList))];
		}

		items[index]['changeType'] = 'SAVE';
		items[index].question = question;
		items[index].certificationOrganization = text;

		setData(data => ({...data, tonsQuestionsAnswerDataList: items }));
	};

	const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		event.stopPropagation();

		if (form.checkValidity() !== false) {
			setHasErrors(false);
			setShowModal(false);

			let filteredUnspsc = [];

			if (Array.isArray(tonsUnspscLevelDataList) && tonsUnspscLevelDataList.length) {
				filteredUnspsc = tonsUnspscLevelDataList.filter(item => (item.unspscLevel1 && item.unspscLevel1 !== '') || (item.changeType == 'DELETE' && item.id) || (item.selectAll && item.changeType !== 'DELETE'));
			}

			const updatedData = {
				...data,
				tonsUnspscLevelDataList: filteredUnspsc
			};

			dispatch(notificationActions.signUp(updatedData, !updatedData.id ? 'new' : 'edit'));
		} else {
			setHasErrors(true);
		}

		setValidated(true);
	};

	return (
		<>
			<Button
				variant="primary"
				onClick={() => { setShowModal(true); setUserData(); }}
			>
				{ editNotification && editNotification.id &&
					<>
						<Translate text={'Edit Notification Preferences'} />
					</>
				}

				{ (!editNotification || !editNotification.id) &&
					<Translate text={'Send Me Notifications'} />
				}
			</Button>
			<Modal show={ showModal } onHide={() => { setShowModal(false); resetData(); }} backdrop="static" centered size="lg" className="tons-modal">
				<Translator>
					{({ translate }) => (
						<Form noValidate validated={ validated } onSubmit={ handleSubmit }>
							<Modal.Header closeButton>
								<h2>
									{editNotification && editNotification.id &&
										<Translate text={'Edit Notification Preferences'} />
									}

									{ (!editNotification || !editNotification.id) &&
										<Translate text={'Sign Up For Notifications'} />
									}
								</h2>
							</Modal.Header>
							<Modal.Body>
								{ editNotification && editNotification.id &&
									<Row>
										<Col md={12}>
											<Translator>
												{({ translate }) => (
													<FormSlideToggle
														id={'enabled'}
														name={'enabled'}
														title={ translate({ text: 'Enabled' }) }
														value={ enabled ? 'yes' : 'no' }
														options={[
															{ value: 'no', label: translate({ text: 'No' }) },
															{ value: 'yes', label: translate({ text: 'Yes' }) }
														]}
														handleOnChange={ handleToggleEnabled }
													/>
												)}
											</Translator>
										</Col>
									</Row>
								}
								<Row>
									<Col md={6}>
										<Form.Group controlId="companyName">
											<Form.Label>
												<Translate text={'Company Name'} /><span className="required">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="companyName"
												value={ companyName }
												required
												maxLength={ INPUT_MAX_LENGTH }
												onChange={ handleChange }
											/>
											<Form.Control.Feedback type="invalid">
												<Translate
													text={'{formLabel} is required'}
													data={{
														formLabel: translate({ text: 'Company Name' })
													}}
												/>
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<FormCountry
											id="country"
											value={ country }
											required={ true }
											handleOnChange={ handleChange }
										/>
									</Col>
									<Col md={6}>
										<FormProvinceState
											id="provinceState"
											value={ provinceState }
											country={ country }
											required={ true }
											handleOnChange={ handleChange }
										/>
									</Col>
									<Col md={6}>
										<Form.Group controlId="city">
											<Form.Label>
												<Translate text={'City'} /><span className="required">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="city"
												value={ city }
												required
												maxLength={ INPUT_MAX_LENGTH }
												onChange={ handleChange }
											/>
											<Form.Control.Feedback type="invalid">
												<Translate
													text={'{formLabel} is required'}
													data={{
														formLabel: translate({ text: 'City' })
													}}
												/>
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group controlId="username">
											<Form.Label>
												<Translate text={'Name'} /><span className="required">*</span>
											</Form.Label>
											<Form.Control
												type="text"
												name="username"
												value={ username }
												required
												maxLength={ INPUT_MAX_LENGTH }
												onChange={ handleChange }
											/>
											<Form.Control.Feedback type="invalid">
												<Translate
													text={'{formLabel} is required'}
													data={{
														formLabel: translate({ text: 'Name' })
													}}
												/>
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Translator>
											{({translate}) => (
												<FormEmail
													id="notificationEmail"
													name="notificationEmail"
													value={ notificationEmail }
													labelText={ translate({ text: 'Notification Email' }) }
													required
													handleOnChange={(id, value) => { handleChange({ target: { name: id, value: value }}); }}
												/>
											)}
										</Translator>
									</Col>
									<Col xs={12}>
										<h3 className="legend max-width-none d-md-flex justify-content-between">
											<span className="mr-1">
												<Translate text={'Commodities'} />
											</span>

											<span className="d-flex align-items-center">
												<Translator>
													{({translate}) => (
														<Form.Check
															label={ translate({ text: 'Select All Commodities' }) }
															name="commodities-select-all"
															id="commodities-select-all"
															className="form-check-big mb-0 mt-1 mt-md-0 font-weight-bold"
															type="checkbox"
															value="1"
															checked={ selectAll ? true : false }
															onChange={ handleSelectAllChange }
														/>
													)}
												</Translator>

												<span className="ml-1">
													<Tooltip text={'Selecting All Commodities\n\n• Previously selected commodities will no longer be visible on your profile.\n• By choosing this option, you will receive email notifications of all tender notices published the previous day.\n• At any time, you can change your preferences and select individual commodities again.'} size="sm" />
												</span>
											</span>
										</h3>
										<div className={`tons-commodities${(selectAll) ? ' tons-commodities--is-disabled' : ''}`}>
											{ !selectAll &&
												<div className="repeater">
													{ tonsUnspscLevelDataList && Array.isArray(tonsUnspscLevelDataList) && tonsUnspscLevelDataList.filter(item => item.changeType !== 'DELETE').length > 0 && tonsUnspscLevelDataList.map((item, index) => {
														if (item.changeType !== 'DELETE') {
															
															const key = item.id || item.tempId;

															return (
																<div className="repeater-item" key={ key || index }>
																	<Row>
																		<Col md={9}>
																			<FormUNSPSC
																				index={ index }
																				item={ item }
																				filterLevels={ ['LEVEL1', 'LEVEL3', 'LEVEL4'] }
																				hideLevels={ ['LEVEL3', 'LEVEL4'] }
																				selectAllLevel2={ true }
																				isGlobalSelectAll={ selectAll }
																				defaultMethod="manual-select"
																				handleOnChange={(level) => { handleUnspscFieldChange(level, index); }}
																				handleOnChangeMultiple={(levels) => { handleUnspscFieldChange(levels, index); }}
																				handleOnChangeSelectAllLevel2={(isSelected) => { handleUnspscSelectAllLevel2Change(isSelected, index); }}
																			/>
																		</Col>
																		<Col md={3} className="repeater-remove">
																			<Translator>
																				{({ translate }) => (
																					<Button
																						variant="link"
																						className="text-danger ml-2"
																						aria-label={
																							translate({
																								text: 'Remove - UNSPSC #{number}',
																								data: { number: (index.toString()) ? index + 1 : null }
																							})
																						}
																						disabled={ (selectAll) ? true : false }
																						onClick={(e) => { handleRemoveRepeaterItem(e, index, 'tonsUnspscLevelDataList'); }}
																					>
																						<Translate text={'Remove'} />
																					</Button>
																				)}
																			</Translator>
																		</Col>
																	</Row>
																</div>
															);
														}

														return null;
													})}

													<div className="repeater-controls">
														<Translator>
															{({ translate }) => (
																<Button
																	onClick={(e) => { handleAddRepeaterItem(e, 'tonsUnspscLevelDataList'); }}
																	aria-label={
																		translate({
																			text: 'Add Another - UNSPSC'
																		})
																	}
																	disabled={ (selectAll) ? true : false }
																>
																	<Translate text={'Add Another'} />
																</Button>
															)}
														</Translator>
													</div>
												</div>
											}
										</div>
									</Col>
									<Col xs={12}>
										{ (Array.isArray(questionsList)) &&
											questionsList.map((item, index) => {
												if (item.question && item.answers) {
													const answers = item.answers.split(';');

													return (
														<Translator key={ index }>
															{({translate}) => (
																<>
																	<Form.Group role="group" aria-label={ translate({ text: item.question }) }>
																		<h4>
																			{ (index + 1) + '. ' }<Translate text={ item.question } /><span className="required">*</span>
																		</h4>

																		{ (Array.isArray(answers)) &&
																			answers.map((answer, i) => {
																				let checked = null;

																				if (tonsQuestionsAnswerDataList && tonsQuestionsAnswerDataList[index] && tonsQuestionsAnswerDataList[index].answer) {
																					if (item.multipleSelection) {
																						const answers = tonsQuestionsAnswerDataList[index].answer.split(';');
																						checked = answers.find(item => item == answer);
																					} else {
																						checked = tonsQuestionsAnswerDataList[index].answer === answer;
																					}
																				}

																				return (
																					<Form.Check
																						key={ i }
																						label={ translate({ text: answer }) }
																						name={`diversity-question-${index}`}
																						id={`diversity-question-${index}-${i}`}
																						className="form-check-big"
																						type={ (item.multipleSelection) ? 'checkbox' : 'radio' }
																						value={ answer }
																						required={ tonsQuestionsAnswerDataList[index] && !tonsQuestionsAnswerDataList[index].answer }
																						checked={ checked ? true : false }
																						onChange={(e) => {
																							handleQuestionChange(e, item.question, answer, index, item.multipleSelection);

																							if (e.target.validity.valid) {
																								e.target.closest('.form-group').classList.remove('is-invalid');
																							}
																						}}
																						onInvalid={(e) => { e.target.closest('.form-group').classList.add('is-invalid'); }}
																					/>
																				);
																			})
																		}

																		<Form.Control.Feedback type="invalid">
																			<Translate text={'Please choose an option'} />
																		</Form.Control.Feedback>
																	</Form.Group>

																	{ item.label &&
																		<Form.Group controlId={`certificationOrganization-${index}`}>
																			<Form.Label>
																				<Translate text={ item.label } />
																			</Form.Label>
																			<Form.Control
																				className="max-width-none"
																				as="textarea"
																				name="certificationOrganization"
																				value={ (tonsQuestionsAnswerDataList && tonsQuestionsAnswerDataList[index] && tonsQuestionsAnswerDataList[index].certificationOrganization) ? tonsQuestionsAnswerDataList[index].certificationOrganization : '' }
																				maxLength={ 300 }
																				onChange={(e) => handleQuestionTextChange(e, item.question, e.target.value, index) }
																			/>
																		</Form.Group>
																	}
																</>
															)}
														</Translator>
													);
												}

												return null;
											})
										}
									</Col>
								</Row>

								{ validated && hasErrors &&
									<div className="invalid-feedback show">
										<p><Translate text={'Form contains errors. Please fill out all required fields.'} /></p>
									</div>
								}
							</Modal.Body>
							<Modal.Footer>
								<ButtonToolbar className="justify-content-end">
									<Button
										variant="primary"
										type="submit"
									>
										{ editNotification && editNotification.id &&
											<Translate text={'Update Notification Preferences'} />
										}

										{ (!editNotification || !editNotification.id) &&
											<Translate text={'Get Notifications'} />
										}
									</Button>
								</ButtonToolbar>
							</Modal.Footer>
						</Form>
					)}
				</Translator>
			</Modal>
		</>
	);
}
export default NotificationSignup;
