import React, { useState, useRef, useEffect } from 'react';
import { Translate } from 'react-translated';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';

import Form from 'react-bootstrap/Form';

export default function FormPhone(props) {
	const { INPUT_MAX_LENGTH } = require('../../config/constants');

	const {
		id,
		name,
		value,
		required,
		labelText,
		labelBefore,
		labelAfter,
		customError,
		disabled
	} = props;

	if (!id || !name) {
		return (
			<p>{ 'Error: ID & name required' }</p>
		);
	}

	// State data.
	const currentTender = useSelector(state => state.currentTender);

	const [rawValue, setRawValue] = useState(value);
	const length = 10;
	const label = (labelText) ? labelText : 'Phone Number';
	const errorMessage = (customError) ? customError : '{label} is a required field. Enter your 10-digit phone number without dashes, spaces, or symbols.';
	const fieldRef = useRef(null);

	useEffect(() => {
		// If on load, the number without mask characters is less than 10 characters long.
		if (currentTender.id && currentTender[name] && currentTender[name].toString().replaceAll('_', '').replaceAll('-', '').length < length) {
			fieldRef.current.setCustomValidity(errorMessage);
		}
	}, [currentTender]);

	const handleCheckValidity = (event) => {
		if (!event.target.checkValidity()) {
			event.target.closest('.form-group').classList.add('was-validated');
		} else {
			event.target.closest('.form-group').classList.remove('was-validated');
		}

		if (required && rawValue && rawValue.toString().length < length) {
			fieldRef.current.setCustomValidity(errorMessage);
		} else {
			fieldRef.current.setCustomValidity('');
		}
	};

	const handleOnChange = (id, value) => {
		if (props.handleOnChange) {
			props.handleOnChange(id, value);
		}
	};

	const handleOnBlur = (id, value) => {
		if (props.handleOnBlur) {
			props.handleOnBlur(id, value);
		}
	};

	return (
		<Form.Group controlId={ id }>
			<Form.Label>
				{ labelBefore }

				<Translate text={ label } />

				{ required &&
					<span className="required">*</span>
				}

				{ labelAfter }
			</Form.Label>
			<NumberFormat
				getInputRef={ fieldRef }
				name={ name }
				value={ value }
				customInput={ Form.Control }
				type="tel"
				maxLength={ INPUT_MAX_LENGTH }
				format="###-###-####"
				placeholder="###-###-####"
				mask="_"
				required={ (required) ? true : null }
				disabled={ (disabled) ? true : null }
				isValid={ (rawValue && rawValue.toString().length >= length) ? true : false }
				isInvalid={ (rawValue && rawValue.toString().length < length) ? true : false }
				onValueChange={(values) => {
					const { value } = values;
					setRawValue(value);
				}}
				onChange={(e) => { handleOnChange(e.target.name, e.target.value); }}
				onBlur={(e) => { handleOnBlur(e.target.name, e.target.value); handleCheckValidity(e); }}
			/>
			<Form.Control.Feedback type="invalid" className="error-phone-number">
				<Translate
					text={ errorMessage }
					data={{
						label: label
					}}
				/>
			</Form.Control.Feedback>
		</Form.Group>
	);
}
