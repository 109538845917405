import axios from 'axios';
import { authHeader } from '../helpers/authHeader';
const env = window.env;

export const contentService = {
	getPage,
	getNavigation
};

function getPage(slug) {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/contents`,
		headers: Object.assign({}, authHeader()),
		paramsSerializer: function(params) {
			return params.path ? 'path=' + decodeURIComponent(params.path) : '';
		},
		params: { 'path': slug }
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function getNavigation(slug) {
	const requestConfig = {
		method: 'GET',
		url: `${env.BASE_API_URL}/contents/navigation`,
		headers: Object.assign({}, authHeader()),
		paramsSerializer: function(params) {
			return params.path ? 'path=' + decodeURIComponent(params.path) : '';
		},
		params: { 'path': slug }
	};

	return axios(requestConfig)
		.then(response => handleResponse(response))
		.catch(error => handleErrorResponse(error));
}

function handleResponse(response) {
	const data = response.data;

	if (data.length === 0) {
		return [];
	}

	return data;
}

function handleErrorResponse(error) {
	console.log(error); // eslint-disable-line
	return {error: 'Error: Network Error'};
}
