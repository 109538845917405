import { tenderConstants } from '../constants/tenderConstants';
import { tenderService } from '../../services/tenderService';

export const tenderActions = {
	getTenders,
	postTender,
	resetPostTender,
	currentTenderUpdate,
	resetCurrentTender,
	postWatchlist,
	resetPostWatchlist,
	getWatchlist
};

function getTenders(params, callback, filters) {
	return dispatch => {
		dispatch(request());

		tenderService.getTenders(params, filters)
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: tenderConstants.TENDERS_REQUEST }; }

	function success(data, callback) { return { type: tenderConstants.TENDERS_SUCCESS, data, callback, single: params.tenderId ? true : null }; }

	function error(callback) { return { type: tenderConstants.TENDERS_ERROR, callback }; }
}

function getWatchlist(callback) {
	return dispatch => {
		dispatch(request());

		tenderService.getWatchlist()
			.then(
				response => {
					if (response && typeof response === 'object' && !response.error) {
						dispatch(success(response, callback));
					} else {
						dispatch(error(callback));
					}
				}
			);

	};

	function request() { return { type: tenderConstants.TENDERS_WATCHLIST_REQUEST }; }

	function success(data, callback) { return { type: tenderConstants.TENDERS_WATCHLIST_SUCCESS, data, callback }; }

	function error(callback) { return { type: tenderConstants.TENDERS_WATCHLIST_ERROR, callback }; }
}

function postTender(data, responseCallback) {
	return dispatch => {
		dispatch(request());

		tenderService.postTender(data)
			.then(
				response => {
					if (response && !response.error) {
						dispatch(success(response, true));
					} else {
						dispatch(error(response.status ? response.status : null));
					}
				}
			);
	};

	function request() { return { type: tenderConstants.TENDERS_POST_REQUEST }; }

	function success(data, update) { return { type: tenderConstants.TENDERS_POST_SUCCESS, data: data, update: update, callback: responseCallback }; }

	function error(status) { return { type: tenderConstants.TENDERS_POST_ERROR, status: status, callback: responseCallback }; }
}

function postWatchlist(data, watch, responseCallback) {
	return dispatch => {
		dispatch(request());

		tenderService.postWatchlist(data, watch)
			.then(
				response => {
					if (response && !response.error) {
						dispatch(success(response, watch));
					} else {
						dispatch(error());
					}
				}
			);
	};

	function request() { return { type: tenderConstants.TENDERS_WATCHLIST_POST_REQUEST }; }

	function success(data, watch) { return { type: tenderConstants.TENDERS_WATCHLIST_POST_SUCCESS, data: data, watch: watch, callback: responseCallback }; }

	function error() { return { type: tenderConstants.TENDERS_WATCHLIST_POST_ERROR, callback: responseCallback }; }
}

function resetPostWatchlist() {
	return {
		type: tenderConstants.TENDERS_WATCHLIST_POST_RESET
	};
}

function resetPostTender() {
	return {
		type: tenderConstants.TENDERS_POST_RESET
	};
}

function currentTenderUpdate(data) {
	if (data && typeof data === 'object') {
		return {
			type: tenderConstants.CURRENT_TENDER_UPDATE,
			data
		};
	}
}

function resetCurrentTender() {
	return {
		type: tenderConstants.CURRENT_TENDER_RESET
	};
}
